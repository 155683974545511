import { useQuery } from '@tanstack/react-query';
import { getWalesUseCase } from 'domain/use-cases/wale/getWales.use-case';
import { getRoad } from 'presentation/helpers/waleId';
import { getEndDate } from 'presentation/hooks/useEndDate';
import { useQueryParam } from 'presentation/hooks/useQueryParam';
import _ from 'lodash';
import { MaintenanceStatus } from 'domain/entities/wale.entity';
import { OperationStatus } from './OperationStatusIndicator';
import getWalesWithMetrics, { WaleWithMetrics } from './helpers/getWalesWithMetrics';

export const operationStatusToPretty: Record<OperationStatus, string> = {
  operational: 'Operational',
  'underperforming-metrics': 'Underperforming Metrics',
  'degraded-metrics': 'Degraded Metrics',
  'degraded-system': 'Degraded System',
  unknown: 'Unknown'
};

export const statusPrecedence: { [key in OperationStatus]: number } = {
  operational: 4,
  'underperforming-metrics': 3,
  'degraded-metrics': 2,
  'degraded-system': 1,
  unknown: 0
};

export const statusPrecedenceCompare = (
  a: Record<string, unknown> & { status: OperationStatus },
  b: Record<string, unknown> & { status: OperationStatus }
) => statusPrecedence[a.status] - statusPrecedence[b.status];

export type CorridorOverview = {
  value: string;
  label: string;
  status: OperationStatus;
  wales: Array<{
    id: string;
    maintenanceStatus: MaintenanceStatus[];
    status: OperationStatus;
    statusMessages: string[];
  }>;
  alertCounts: Record<OperationStatus, number>;
};

export const useCorridorOverviews = () => {
  const project = useQueryParam('project');
  const query = useQuery({
    queryKey: ['corridor-overviews', project],
    queryFn: getCorridorOverviews,
    refetchInterval: 1000 * 60 * 10
  });

  return query;
};

const DEV_ROADS_FOR_OVERVIEW = ['BW-HQB']

const getCorridorOverviews = async () => {
  const data = await getWalesUseCase();

  const activeWALEs = data
    ?.filter((wale) => wale.active && (wale.isProduction || DEV_ROADS_FOR_OVERVIEW.includes(getRoad(wale.waleId))))
    .map((wale) => ({
      ...wale,
      road: getRoad(wale.waleId)
    }));

  const endDate = getEndDate();

  const walesWithMetrics = await getWalesWithMetrics(endDate, activeWALEs);

  const groupedWALEs = _.groupBy(walesWithMetrics, 'road');

  const corridorOverviews = Object.keys(groupedWALEs)
    .map((key) => {
      const wales = groupedWALEs[key];
      const status = getCorridorStatus(wales);

      const alertCounts = wales.reduce(
        (acc, curr) => {
          acc[curr.status] += 1;
          return acc;
        },
        {
          'degraded-system': 0,
          'degraded-metrics': 0,
          'underperforming-metrics': 0,
          operational: 0,
          unknown: 0
        }
      );

      const corridorOverview: CorridorOverview = {
        value: key,
        label: key,
        status,
        wales: wales
          .map((wale) => ({
            id: wale.waleId,
            maintenanceStatus: wale.maintenanceStatus,
            status: wale.status,
            statusMessages: wale.statusMessages ?? []
          }))
          .filter((wale) => wale.status !== 'operational'),
        alertCounts
      };

      return corridorOverview;
    })
    .sort(statusPrecedenceCompare);

  return corridorOverviews;
};

const getCorridorStatus = (wales: WaleWithMetrics[]): OperationStatus => {
  const statuses = _.uniqBy(wales, 'status').map((wale) => wale.status);

  if (statuses.includes('degraded-system')) {
    return 'degraded-system';
  }

  if (statuses.includes('degraded-metrics')) {
    return 'degraded-metrics';
  }

  if (statuses.includes('underperforming-metrics')) {
    return 'underperforming-metrics';
  }

  return 'operational';
};
