import { genericErrorLog } from 'data/api/genericErrorMessage';
import { WaleDetection } from 'domain/entities/waleDetection.entity';
import {
  getWaleDetectionByDateUseCase,
  getWaleDetectionPairByIdUseCase,
  getPrevWaleDetectionByDateUseCase
} from 'domain/use-cases/waleDetection/getWaleDetection.use-case';
import { union } from 'lodash';
import { getWaleLanes } from 'presentation/helpers/waleId';
import { useUser } from 'presentation/hooks/data/useUser';
import { useAlert } from 'presentation/hooks/useAlert';
import { useQueryParam } from 'presentation/hooks/useQueryParam';
import { useWaleIdsByLocationAndLane } from 'presentation/hooks/useWaleIdsByLocationAndLane';
import { useEffect, useState } from 'react';

const useWaleEvent = ({
  waleId,
  selectedDate,
  videoSource,
  lane
}: {
  waleId: string | null;
  selectedDate: Date | null;
  videoSource: boolean;
  lane: number | null;
}) => {
  const [waleDetection, setWaleDetection] = useState<WaleDetection | null>(null);
  const { sendAlert } = useAlert();
  const getOtherEvent =
    (
      getEventByDate:
        | typeof getWaleDetectionByDateUseCase
        | typeof getPrevWaleDetectionByDateUseCase
    ) =>
    () => {
      if (!waleDetection) return;
      const nextDate = new Date(waleDetection.capturedAt);
      if (!nextDate) return;
      getEventByDate(waleDetection.waleId, nextDate, videoSource, lane ?? undefined)
        .then((newWaleDetection) => {
          if (!newWaleDetection) {
            sendAlert({ type: 'warning', message: `Detection Not Found for wale ${waleId}` });
            return;
          }
          setWaleDetection(newWaleDetection);
        })
        .catch(genericErrorLog);
    };

  const getNextEvent = getOtherEvent(getWaleDetectionByDateUseCase);
  const getPrevEvent = getOtherEvent(getPrevWaleDetectionByDateUseCase);
  useEffect(() => {
    if (waleId && selectedDate) {
      getPrevWaleDetectionByDateUseCase(waleId, selectedDate, videoSource, lane ?? undefined)
        .then(setWaleDetection)
        .catch(genericErrorLog);
    }
  }, [selectedDate, waleId]);
  return { waleDetection, getNextEvent, getPrevEvent, setWaleDetection };
};

const initialLocationAndLane = 'CO-I70W-242_0-L1';

interface WaleEventInput {
  waleId: string | null;
  selectedDate: Date | null;
  videoSource: boolean;
}

export const useWaleEventsViewModel = (
  setLocationInput: React.Dispatch<React.SetStateAction<string | null>>,
  videoSource: boolean,
  lane: number | null,
  initlocation: string | null,
  initdate: Date | null
) => {
  // Data
  const user = useUser();
  const walesByLocation = useWaleIdsByLocationAndLane();

  const readLanes = union(
    ...Array.from(walesByLocation.values()).map(({ front, back }) =>
      union(getWaleLanes(front), getWaleLanes(back))
    )
  ).sort();

  const [locations, setLocations] = useState<string[]>([]);
  const [frontWaleInputs, setFrontWaleInputs] = useState<WaleEventInput>({
    waleId: null,
    selectedDate: initdate,
    videoSource
  });
  const [backWaleInputs, setBackWaleInputs] = useState<WaleEventInput>({
    waleId: null,
    selectedDate: initdate,
    videoSource
  });
  // URL params
  const id = useQueryParam('id');

  // inputs
  const [inputs, setInputs] = useState<{
    selectedDate: Date | null;
    selectedLocation: string;
    eventIdInput: string | null;
  }>({
    selectedLocation: initlocation ?? initialLocationAndLane,
    selectedDate: id ? null : initdate || new Date(),
    eventIdInput: id
  });
  const { selectedLocation, selectedDate, eventIdInput } = inputs;

  // requested data
  const frontWaleDetection = useWaleEvent({ ...frontWaleInputs, lane });
  const backWaleDetection = useWaleEvent({ ...backWaleInputs, lane });

  // requests

  useEffect(() => {
    setLocations(Array.from(walesByLocation.keys()));
  }, [walesByLocation]);

  const changeInputs = (
    inputsParams: { selectedLocation: string; selectedDate: Date } | { eventIdInput: string }
  ) => {
    if ('eventIdInput' in inputsParams) {
      setInputs({ ...inputs, eventIdInput: inputsParams.eventIdInput });
    } else {
      setInputs({ ...inputs, ...inputsParams, eventIdInput: null });
    }
  };

  useEffect(() => {
    if (eventIdInput) {
      getWaleDetectionPairByIdUseCase(eventIdInput)
        .then(({ backEvent, frontEvent, locationAndLane }) => {
          if (frontEvent) {
            frontWaleDetection.setWaleDetection(frontEvent);
          }
          if (backEvent) {
            backWaleDetection.setWaleDetection(backEvent);
          }
          if (locationAndLane) {
            // only the input changes, but not the view model state (propagation to view model happens on "search")
            setLocationInput(locationAndLane);
          }
        })
        .catch(genericErrorLog);
      return;
    }
    if (selectedLocation) {
      setFrontWaleInputs({
        waleId: walesByLocation.get(selectedLocation)?.front ?? null,
        selectedDate,
        videoSource
      });
      setBackWaleInputs({
        waleId: walesByLocation.get(selectedLocation)?.back ?? null,
        selectedDate,
        videoSource
      });
    }
  }, [inputs, walesByLocation, videoSource]);

  return {
    data: {
      user,
      locations,
      walesByLocation,
      frontWaleId: frontWaleInputs.waleId,
      backWaleId: backWaleInputs.waleId,
      frontWaleDetection: frontWaleDetection.waleDetection,
      backWaleDetection: backWaleDetection.waleDetection,
      lanes: readLanes
    },
    inputs: {
      location: { selectedLocation },
      date: { selectedDate },
      eventId: { eventIdInput },
      setInputs: changeInputs
    },
    actions: {
      getNextWaleDetection: {
        frontWaleDetection: frontWaleDetection.getNextEvent,
        backWaleDetection: backWaleDetection.getNextEvent
      },
      getPrevWaleDetection: {
        frontWaleDetection: frontWaleDetection.getPrevEvent,
        backWaleDetection: backWaleDetection.getPrevEvent
      }
    }
  };
};
