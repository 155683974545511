/**
 * For a event that contains many capture return the filtered data of one of them.
 */
import _ from 'lodash';

const DEFAULT_ENGINE = 'OpenALPR';

const getEngineData = (vehicleEvent: any, captureIndex: any) => {
  if (!vehicleEvent.captures[captureIndex].alpr) return {};
  let engineData = _.find(vehicleEvent.captures[captureIndex].alpr, {
    engine: DEFAULT_ENGINE
  });
  if (!engineData) engineData = _.findLast(vehicleEvent.captures[captureIndex].alpr);
  if (!engineData) return {};
  return {
    ...engineData.data,
    engine: { prediction: engineData.engine }
  };
};

const getCaptureData = (vehicleEvent: any, captureIndex: any) => {
  const captureIndexValid =
    captureIndex >= 0 && captureIndex < (vehicleEvent?.captures?.length ?? 0);

  const capturedAt = captureIndexValid ? vehicleEvent.captures[captureIndex].capturedAt : undefined;

  const unknownPrediction = { prediction: '', score: 0 };

  const alprData = captureIndexValid ? getEngineData(vehicleEvent, captureIndex) ?? {} : {};

  if (!alprData.color || !alprData.color.length) alprData.color = [unknownPrediction];
  if (!alprData.make || !alprData.make.length) alprData.make = [unknownPrediction];
  if (!alprData.makeModel || !alprData.makeModel.length) alprData.makeModel = [unknownPrediction];
  if (!alprData.plateNumber || !alprData.plateNumber.length)
    alprData.plateNumber = [unknownPrediction];
  if (!alprData.plateState) alprData.plateState = unknownPrediction;

  return {
    imageUrl:
      captureIndexValid && captureIndex < (vehicleEvent?.imageUrls?.length ?? 0)
        ? vehicleEvent?.imageUrls[captureIndex]
        : '',
    capturedAt,
    alprData
  };
};

export default getCaptureData;
