import React, { useEffect, useState } from 'react';
import { Col, Image, Row } from 'react-bootstrap';

import defaultImage from 'assets/images/blissway_default.png';
import sensorAlert from 'assets/images/maintenance/sensor_alert.svg';
import batteryAlert from 'assets/images/maintenance/battery_alert.svg';
import cellularAlert from 'assets/images/maintenance/signal_cellular_nodata.svg';
import cameraAlert from 'assets/images/maintenance/videocam_off.svg';
import genericAlert from 'assets/images/maintenance/warning.svg';
import { VehicleEventMetrics, WaleDetection } from 'domain/entities/waleDetection.entity';
import { Link } from 'react-router-dom';
import { getLane, shortWaleId } from 'presentation/helpers/waleId';
import { MaintenanceStatus } from 'domain/entities/wale.entity';
import { useEndDate } from 'presentation/hooks/useEndDate';
import StatusTable, { WaleStatusMonitor } from './StatusTable';
import { getRoadShortDateTime } from '../../../services/timeUtils';
import { CaptureMetricsChart } from './CaptureMetricsChart';

type DisplayWaleMonitorInput = {
  waleId: string;
  captureData: WaleDetection;
  statusData: WaleStatusMonitor;
  metricsData: VehicleEventMetrics;
  pause: boolean;
  warningMinutes: number;
  dangerMinutes: number;
  lane?: number;
  hideWaleLane: () => void;
  showHideWaleButton: boolean;
  index: number;
};

export const MAINTENANCE_STATUS_ICONS = new Map([
  [MaintenanceStatus.LidarIssue, sensorAlert],
  [MaintenanceStatus.SensysIssue, sensorAlert],
  [MaintenanceStatus.PowerIssue, batteryAlert],
  [MaintenanceStatus.NetworkIssue, cellularAlert],
  [MaintenanceStatus.CameraIssue, cameraAlert],
  [MaintenanceStatus.OtherIssue, genericAlert]
]);

const DisplayWaleMonitor = ({
  waleId,
  captureData,
  statusData,
  metricsData,
  pause,
  warningMinutes,
  dangerMinutes,
  lane,
  hideWaleLane,
  showHideWaleButton,
  index
}: DisplayWaleMonitorInput) => {
  const [showWarning, setShowWarning] = useState(false);
  const [showDanger, setShowDanger] = useState(false);
  const { id, source, capturedAt, imageUrl } = captureData;
  const { maintenanceStatus } = statusData;

  const endDate = useEndDate();
  useEffect(() => {
    const secondsSinceLastCapture = capturedAt
      ? (endDate.getTime() - Date.parse(capturedAt)) / 1000
      : warningMinutes - 1;
    setShowWarning(secondsSinceLastCapture >= warningMinutes * 60);
    setShowDanger(secondsSinceLastCapture >= dangerMinutes * 60);
  }, [capturedAt, endDate]);

  const detectionTime = capturedAt ? getRoadShortDateTime(capturedAt) : 'Loading ...';

  const parseSource = (originalSource?: string) => {
    const text = originalSource ? originalSource.split('-')[1] : '-';
    if (text === 'magnetometer') return 'Sensys';
    return text.charAt(0).toUpperCase() + text.slice(1);
  };

  const imageStyle = pause ? 'wale-img fade-in my-0 mb-2 paused' : 'wale-img fade-in my-0 mb-2';
  let cardStyle = `bliss-card-v-${index % 3} my-0 px-4 py-0`;
  if (!statusData.vehicleScheduleOn) cardStyle += ' border-ooh border-2';
  else if (showDanger) cardStyle += ' border-danger border-2';
  else if (showWarning) cardStyle += ' border-warning border-2';

  let detectionTimeStyle = '';
  if (!statusData.vehicleScheduleOn) detectionTimeStyle = 'ooh-status';
  else if (showDanger) detectionTimeStyle = 'text-danger';
  else if (showWarning) detectionTimeStyle = 'text-warning';

  return (
    <div className={cardStyle}>
      <Row className="no-blue-link wale-title d-flex justify-content-around mt-1 pt-1">
        <Link
          style={{ marginTop: 'auto', marginBottom: 'auto' }}
          to={`/wale/settings?id=${waleId}`}
        >
          <h4>{`${shortWaleId(waleId)}${lane ? `-${lane}` : ''}`}</h4>
        </Link>
        <p className="captured-at">{parseSource(source)}</p>
        <p className={`captured-at ${detectionTimeStyle}`}>{detectionTime}</p>
      </Row>
      <Row className="wale-event-container">
        <div className="maintenance-icons-container">
          {Object.values(MaintenanceStatus)
            .filter((status) => maintenanceStatus.includes(status))
            .map((status) => (
              <Image
                key={status}
                src={MAINTENANCE_STATUS_ICONS.get(status)}
                className="maintenance-icon"
              />
            ))}
        </div>
        <Link
          to={`/wale/events?id=${id}&waleId=${waleId}${lane ? `&lane=${lane}` : ''}${
            capturedAt ? `&date=${capturedAt}` : ''
          }`}
        >
          <Image className={imageStyle} style={{ width: '100%' }} src={imageUrl || defaultImage} />
        </Link>
      </Row>
      <Row>
        <Col xs={7} style={{ padding: 0 }}>
          <CaptureMetricsChart metricsData={metricsData} waleId={waleId} lane={lane} />
        </Col>
        <Col xs={5}>
          <StatusTable
            show={!lane || (Boolean(waleId) && getLane(waleId) === lane)}
            statusData={statusData}
            hideWaleLane={hideWaleLane}
            showHideWaleButton={showHideWaleButton}
          />
        </Col>
      </Row>
    </div>
  );
};

export default DisplayWaleMonitor;
