import React from 'react';
import { CorridorDisplayValues, MetroLocationInfo } from './CorridorDisplay.view-model';

/* *
 * Toggle the selection of the corridor locations.
 * If at least one of the corridor locations is not selected, selects all locations.
 * Otherwise (i.e. all the corridor locations are selected), deselects all locations.
 * */
const onCorridorClick = (
  corridorLocations: MetroLocationInfo[],
  setCorridorLocations: (a: MetroLocationInfo[]) => void
) => {
  const isOneOfTheLocationsUnmarked = corridorLocations.find((loc) =>
    loc.chartValues.some((value) => value === CorridorDisplayValues.BASE)
  );
  const newCorridorArray = corridorLocations.map((loc) => ({
    ...loc,
    chartValues: loc.chartValues.map((laneValue) => {
      if (laneValue === CorridorDisplayValues.NOT_PRESENT) return laneValue;
      if (isOneOfTheLocationsUnmarked) return CorridorDisplayValues.SELECTED;
      return CorridorDisplayValues.BASE;
    })
  }));

  setCorridorLocations(newCorridorArray);
};

export const CorridorHeader = ({
  roadTitle,
  corridorLocations,
  setCorridorLocations
}: {
  roadTitle: string;
  corridorLocations: MetroLocationInfo[];
  setCorridorLocations: (a: MetroLocationInfo[]) => void;
}) => (
  /* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */
  <h4
    style={{ textAlign: 'center' }}
    onClick={() => onCorridorClick(corridorLocations, setCorridorLocations)}
    onKeyDown={() => onCorridorClick(corridorLocations, setCorridorLocations)}
  >
    {roadTitle}
  </h4>
);
