import { genericErrorLog } from 'data/api/genericErrorMessage';
import { LocationEvent } from 'domain/entities/locationEvent.entity';
import { TripWaleEvent } from 'domain/entities/tripWaleEvent.entity';
import getLocationEventImagesUseCase from 'domain/use-cases/waleDetection/getLocationEventImages.use-case';
import { useIsMounted } from 'presentation/hooks/useIsMounted';
import { useState, useEffect } from 'react';

export const useImageUrls = (locationEvent: LocationEvent) => {
  const [imageUrls, setImageUrls] = useState({ back: '', front: '' });
  const isMounted = useIsMounted();
  useEffect(() => {
    getLocationEventImagesUseCase(locationEvent)
      .then(({ frontImage, backImage }) => {
        if (isMounted.current) setImageUrls({ front: frontImage, back: backImage });
      })
      .catch(genericErrorLog);
  }, [locationEvent]);
  return imageUrls;
};

const getWaleEventSearchString = (waleEvent: TripWaleEvent | null) => {
  if (!waleEvent) {
    return '';
  }
  const searchParams = new URLSearchParams();
  searchParams.set('id', waleEvent.eventId);
  return searchParams.toString();
};
const getLocationEventSearchStrings = (locationEvent: LocationEvent) => ({
  front: getWaleEventSearchString(locationEvent.front),
  back: getWaleEventSearchString(locationEvent.back)
});

export const useSearchStrings = (locationEvent: LocationEvent) => {
  const [searchStrings, setSearchStrings] = useState({ back: '', front: '' });
  useEffect(() => setSearchStrings(getLocationEventSearchStrings(locationEvent)), [locationEvent]);
  return searchStrings;
};
