import { useUser } from 'presentation/hooks/data/useUser';
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { BackToHomeBtn, LinkBtn } from '../../components';
import { roleName, UserRole } from '../Users/userRoles';

const mdItemName = 6;
const conditionalAdminDetails = (demo: any, demoDate: any, demoHour: any) => (
  <>
    <Row data-cy="is_demo_row">
      <Col md={mdItemName}>
        <p className="text-white">Demo</p>
      </Col>
      <Col>
        <p className="text-slim" data-cy="is_demo_value">
          {demo ? 'Yes' : 'No'}
        </p>
      </Col>
    </Row>
    <hr className="divider" />
    {demoDate && (
      <Row data-cy="demo_date_row">
        <Col md={mdItemName}>
          <p className="text-white">Demo Date</p>
        </Col>
        <Col>
          <p className="text-slim" data-cy="demo_date_value">
            {demoDate}
          </p>
        </Col>
      </Row>
    )}
    <hr className="divider" />
    {demo && (
      <>
        <Row data-cy="demo_hour_row">
          <Col md={mdItemName}>
            <p className="text-white">Demo Hour (24 hours format)</p>
          </Col>
          <Col>
            <p className="text-slim" data-cy="demo_hour_value">
              {demoHour}
            </p>
          </Col>
        </Row>
        <hr className="divider" />
      </>
    )}
  </>
);

const Profile = () => {
  const user = useUser();
  const { firstName, lastName, email, demo, demoDate, demoHour, admin, role, id } = user;
  const isAgency = role === UserRole.Agency;
  return (
    <Row className="full-height">
      <Col className="bliss-card m-3">
        <Row>
          <Col md={3}>
            <BackToHomeBtn />
          </Col>
          <Col lg={{ span: 4, offset: 1 }} md={5}>
            <Row className="mb-3">
              <Col>
                <h2>
                  <span className="text-blue text-slim text-uppercase">Profile</span>
                </h2>
              </Col>
            </Row>
            <Row data-cy="name_row">
              <Col md={mdItemName}>
                <p className="text-white">Name </p>
              </Col>
              <Col>
                <p className="text-slim" data-cy="name_value">
                  {firstName} {lastName}
                </p>
              </Col>
            </Row>
            <hr className="divider" />
            {role === UserRole.Admin && conditionalAdminDetails(demo, demoDate, demoHour)}
            <Row data-cy="email_row">
              <Col md={mdItemName}>
                <p className="text-white">Email</p>
              </Col>
              <Col>
                <p className="text-slim" data-cy="email_value">
                  {email}
                </p>
              </Col>
            </Row>
            <hr className="divider" />
            <Row data-cy="role_row">
              <Col md={mdItemName}>
                <p className="text-white">Role</p>
              </Col>
              <Col>
                <p className="text-slim" data-cy="role_value">
                  {roleName(role)}
                </p>
              </Col>
            </Row>
            <hr className="divider" />
            <Row>
              <Col md={6}>
                {!isAgency && (
                  <LinkBtn
                    variant="dark"
                    block
                    to="/tokens"
                    className="mt-4"
                    data-cy="tokens_button"
                  >
                    Tokens
                  </LinkBtn>
                )}
              </Col>
              <Col md={6}>
                <LinkBtn
                  variant="dark"
                  block
                  to="/profile/change_password"
                  className="mt-4"
                  data-cy="change_password_button"
                >
                  Change Password
                </LinkBtn>
              </Col>
              {admin && (
                <Col>
                  <LinkBtn
                    variant="dark"
                    block
                    to={`/users/${id}/edit`}
                    className="mt-4"
                    data-cy="edit_user_button"
                  >
                    Edit User
                  </LinkBtn>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Profile;
