import React, { ChangeEvent, useState } from 'react';
import { Button, Col, Container, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import Select from 'components/Select';
import customStyles from 'components/Select/themes';
import { RoadDateTimePicker } from 'components/RoadDateTimePicker/RoadDateTimePicker';
import StateSelect from 'components/StateSelect';
import { PlateState } from 'domain/entities/waleDetection.entity';
import { getFilteredStructuredCSVTripsUseCase } from 'domain/use-cases/trip/getFilteredStructuredCSVTrips.use-case';
import { downloadCsv, setTooltipMessage } from 'services/generalUtils';

export const TripSearchAndDownloadBar = ({
  endDate,
  setEndDate,
  startDate,
  setStartDate,
  plateNumber,
  setPlateNumber,
  plateState,
  setPlateState,
  buttonState,
  onButtonClick,
  elemsPerPage,
  setElemsPerPage,
  page,
  setPage,
  tripsLength,
  fetchTrips
}: {
  endDate: Date | null;
  setEndDate: (a: Date | null) => void;
  startDate: Date | null;
  setStartDate: (a: Date | null) => void;
  plateNumber: string | null;
  setPlateNumber: (a: string | null) => void;
  plateState: PlateState | null;
  setPlateState: (a: PlateState | null) => void;
  buttonState: boolean;
  onButtonClick: () => void;
  elemsPerPage: number;
  setElemsPerPage: (a: number) => void;
  page: number;
  setPage: (a: number) => void;
  tripsLength: number;
  fetchTrips: () => void;
}) => {
  const [csvTooltipMessage, setCSVTooltipMessage] = useState('');
  const elemStyle = {
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    flex: '1 0 0',
    marginLeft: '5px',
    marginRight: '5px',
    marginTop: '5px',
    minWidth: '200px'
  };
  const inputStyle = { maxWidth: '12em' };
  const centerStyle = { display: 'flex', alignItems: 'center', justifyContent: 'center' };

  const handlePageElemsChange = (event: { value: number }) => {
    setElemsPerPage(Math.max(1, Math.min(event.value, 100)));
    setPage(0);
    fetchTrips();
  };

  const handleEventLPChange = (event: ChangeEvent<HTMLInputElement>) => {
    const trimmedPlate = event.target.value.trim();
    if (trimmedPlate) {
      setPlateNumber(trimmedPlate);
    } else {
      setPlateNumber(null);
    }
  };

  const handleCSVClick = () =>
    getFilteredStructuredCSVTripsUseCase(startDate, endDate, plateNumber, plateState)
      .then((response) => {
        if (response.data.size) {
          downloadCsv(response.data, 'trips.csv');
        } else {
          setCSVMessage('No trips were found.');
        }
      })
      .catch(() => setCSVMessage('There was a problem with the download.'));

  const setCSVMessage = (message: string) => setTooltipMessage(message, setCSVTooltipMessage);

  const prevPage = () => {
    if (page > 0) {
      setPage(page - 1);
      fetchTrips();
    }
  };
  const nextPage = () => {
    if (tripsLength) {
      setPage(page + 1);
      fetchTrips();
    }
  };

  const valueToSelectValue = (opt: number) => ({ label: opt, value: opt });

  const pageOptions = [10, 25, 50, 100];

  return (
    <Container fluid className="bliss-card my-3 px-3">
      <Row style={{ margin: 0 }}>
        <Col style={elemStyle}>
          <input
            onChange={handleEventLPChange}
            type="text"
            value={plateNumber || ''}
            name="license_plate_filter"
            placeholder="Plate Number..."
            className="bliss-input-field px-2 text-white"
            style={{
              width: '10em',
              minHeight: '38px'
            }}
          />
        </Col>
        <Col style={elemStyle}>
          <StateSelect setSelectedState={setPlateState} defaultValue={plateState} />
        </Col>
        <Col className="datetime" style={elemStyle}>
          <RoadDateTimePicker
            onChange={setStartDate}
            inputProps={{ placeholder: 'From (MT)', style: inputStyle }}
          />
        </Col>
        <Col className="datetime" style={elemStyle}>
          <RoadDateTimePicker
            onChange={setEndDate}
            inputProps={{ placeholder: 'To (MT)', style: inputStyle }}
          />
        </Col>
        <Col style={elemStyle}>
          <Button onClick={onButtonClick} disabled={buttonState} className="mx-2">
            Filter
          </Button>
          <OverlayTrigger
            placement="bottom"
            show={csvTooltipMessage.length > 0}
            overlay={<Tooltip id="csv-tooltip">{csvTooltipMessage}</Tooltip>}
          >
            <Button onClick={handleCSVClick} disabled={buttonState} className="mx-2">
              Export to CSV
            </Button>
          </OverlayTrigger>
        </Col>
        <Col className="rows-select" style={centerStyle}>
          Rows:
          <Select
            styles={customStyles}
            defaultValue={valueToSelectValue(elemsPerPage)}
            isDisabled={false}
            isSearchable={false}
            options={pageOptions.map(valueToSelectValue)}
            onChange={handlePageElemsChange}
          />
        </Col>
        <Col>
          <Row style={centerStyle}>
            <Col xs={2} className="pagination-elem">
              <p>Page:</p>
            </Col>
            <Col xs={1} className="pagination-elem">
              <Button disabled={page === 0} onClick={prevPage}>
                {'<'}
              </Button>
            </Col>
            <Col xs={1} className="pagination-elem">
              <p className="page-number">{page + 1}</p>
            </Col>
            <Col xs={1} className="pagination-elem">
              <Button disabled={buttonState} onClick={nextPage}>
                {'>'}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
