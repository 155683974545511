import { DailyMetrics } from 'domain/entities/dailyMetrics.entity';
import { API } from './api';

enum Path {
  DAILY_METRICS = '/daily_metrics'
}

/** Gets a SINGLE daily metrics document for the WALE */
export const getDailyMetrics = (
  startDate: Date,
  endDate: Date,
  waleId?: string,
  limit?: number
): Promise<DailyMetrics[]> =>
  API.get<DailyMetrics[]>(Path.DAILY_METRICS, {
    params: {
      start_date: startDate.valueOf(),
      end_date: endDate.valueOf(),
      ...(waleId && { wale_id: waleId }),
      ...(limit && { limit })
    }
  }).then((response) => response.data);
