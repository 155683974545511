import { Trip } from 'domain/entities/trip.entity';
import { PlateState } from 'domain/entities/waleDetection.entity';
import { getFilteredStructuredTripsUseCase } from 'domain/use-cases/trip/getFilteredStructuredTrips.use-case';
import { useAlert } from 'presentation/hooks/useAlert';
import { useIsMounted } from 'presentation/hooks/useIsMounted';
import { useEffect, useState } from 'react';

export const useTrips = (
  startDate: Date | null,
  endDate: Date | null,
  userEndDate: Date,
  plateNumber: string | null,
  plateState: PlateState | null,
  filter: { fetch: boolean; loading: boolean },
  setFilter: (a: { fetch: boolean; loading: boolean }) => void,
  elemsPerPage: number,
  page: number,
  id: string | null
) => {
  const [trips, setTrips] = useState<Trip[]>([]);
  const isMounted = useIsMounted();
  const { sendAlert } = useAlert();
  const realEndDate = endDate === null || userEndDate < endDate ? userEndDate : endDate;

  useEffect(() => {
    if (filter.fetch) {
      setFilter({ fetch: false, loading: true });
      getFilteredStructuredTripsUseCase(
        startDate,
        realEndDate,
        plateNumber,
        plateState,
        elemsPerPage,
        page,
        id
      )
        .then((data) => {
          setTrips(data);
          setFilter({ fetch: false, loading: false });
        })
        .catch((error) => {
          if (isMounted.current) {
            setTrips([]);
            setFilter({ fetch: false, loading: false });
          }
          sendAlert({
            type: 'error',
            message: error?.response?.data?.error || 'Something went wrong. Please try again'
          });
        });
    }
  }, [startDate, endDate, plateNumber, plateState, filter]);
  return trips;
};
