import axios from 'axios';
import { refreshAuthToken } from 'data/refreshSession.repository';
import store from 'services/store';
import { requestSignOut } from 'store/actions/auth';
import API_CONFIG from './config';
import { warningMessage } from './genericErrorMessage';
import { handleDates } from './handleDates';

export const API = axios.create({
  baseURL: API_CONFIG.url(),
  timeout: API_CONFIG.globalTimeout
});

API.interceptors.response.use((response) => {
  handleDates(response);
  return response;
});

API.interceptors.request.use(
  (request) => {
    const customRequest = request;
    if (localStorage.jwt) {
      customRequest.headers.Authorization = `Bearer ${localStorage.jwt}`;
    }
    return customRequest;
  },
  (error) => error
);

API.interceptors.request.use(
  (request) => {
    const project = sessionStorage.getItem('project');
    if (project && project !== 'All') request.headers.Road = project;
    return request;
  },
  (error) => error
);

// Interceptor that checks if a request failed because of
// an Unauthorized error, attempting to get a new accessToken
// and retrying the request. If it fails it logs out the user.
API.interceptors.response.use(
  (response) => response,
  async (error) => {
    // Warn the user about timeouts
    if (error?.code === 'ECONNABORTED') warningMessage(API_CONFIG.timeoutMessage);
    if (error?.config.url.includes('/auth/refresh')) {
      store.dispatch(requestSignOut());
      throw error;
    }
    if (
      error?.response?.status !== 401 ||
      error?.config.url.includes('/auth/login') ||
      error?.config.url.includes('/auth/long_refresh') ||
      error?.config.url.includes('/auth/invalidate_tokens')
    ) {
      throw error;
    }
    try {
      const data = await refreshAuthToken();
      if (!data) {
        throw error;
      }
      localStorage.setItem('jwtRefresh', data.refreshToken);
      localStorage.setItem('jwt', data.accessToken);
      const newRequest = error.config;
      newRequest.headers.Authorization = `Bearer ${localStorage.jwt}`;
      return await API(newRequest);
    } catch (err: any) {
      if (err?.response?.status === 401) {
        store.dispatch(requestSignOut());
        warningMessage('Session expired. Please log in again.');
      }
      throw err;
    }
  }
);
