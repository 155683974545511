import { SendAlertInput } from 'presentation/hooks/useAlert';
import React, { useState } from 'react';
import { Container, Table } from 'react-bootstrap';
import EboxWaleHeader from './EboxWaleHeader';
import EboxWaleModal from './EboxWaleModal';
import { EboxWalePairElement } from './EboxWalePairElement';
import { EboxWalePair, RequiredEboxWalePair, SortParams } from '../EboxWalePairTypes';

const LinkedPairs = ({
  sortParams,
  setSortParams,
  completeEboxWalePairs,
  triggerFetch,
  sendAlert
}: {
  sortParams: SortParams;
  setSortParams: (a: SortParams) => void;
  completeEboxWalePairs: RequiredEboxWalePair[];
  triggerFetch: () => void;
  sendAlert: (a: SendAlertInput) => void;
}) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedUnlinkPair, setSelectedUnlinkPair] = useState<EboxWalePair>({
    eboxId: null,
    eboxName: null,
    waleId: null
  });
  return (
    <>
      <h2 className="text-blue text-slim">Linked Eboxes and WALEs</h2>
      <Container fluid className="bliss-card">
        <div>
          <Table className="metrics-table bliss-card" variant="dark" hover size="sm">
            <EboxWaleHeader sortParams={sortParams} setSortParams={setSortParams} />
            <tbody>
              {completeEboxWalePairs.map((eboxWalePair) => (
                <EboxWalePairElement
                  key={`${eboxWalePair.waleId}-${eboxWalePair.eboxName}-${eboxWalePair.eboxId}`}
                  eboxWalePair={eboxWalePair}
                  setUnlinkPair={setSelectedUnlinkPair}
                  setShowModal={setShowModal}
                />
              ))}
            </tbody>
          </Table>
        </div>
      </Container>
      <EboxWaleModal
        selectedUnlinkPair={selectedUnlinkPair}
        showModal={showModal}
        setShowModal={setShowModal}
        triggerFetch={triggerFetch}
        sendAlert={sendAlert}
      />
    </>
  );
};

export default LinkedPairs;
