import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { ConfigChange } from 'domain/entities/changeHistory.entity';
import { Wale } from 'domain/entities/wale.entity';
import { API } from './api';

enum Path {
  WALES = '/wales',
  WALES_SETTINGS = '/wales/settings',
  UPDATE_WALE = '/wales/update',
  CREATE_WALE = '/wales',
  WALES_CHANGE_HISTORY = '/wales/settings_history'
}

export const getWales = (): Promise<Wale[]> =>
  API.get<Wale[]>(Path.WALES).then((response) => response.data);

export const getWalesSettings = (): Promise<Wale[]> =>
  API.get<Wale[]>(Path.WALES_SETTINGS).then((response) => response.data);

export const debounceGetWalesSettings = AwesomeDebouncePromise(getWalesSettings, 300);

export const getWaleChangeHistory = (waleId: string): Promise<ConfigChange[]> =>
  API.get<ConfigChange[]>(Path.WALES_CHANGE_HISTORY, {params: { wale_id: waleId }}).then((response) => response.data);

export const updateWale = (wale: Wale) => API.put(Path.UPDATE_WALE, wale);

export const createWale = (wale: Wale) => API.post(Path.CREATE_WALE, wale);
