import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { history } from './services/store';
import routes from './routes';
import { Alert } from './components';
import GlobalErrorBoundary from './pages/Error/GlobalErrorBoundary';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

const App = () => (
  <GlobalErrorBoundary>
    <QueryClientProvider client={queryClient}>
      <ConnectedRouter history={history}>{routes}</ConnectedRouter>
      <Alert />
    </QueryClientProvider>
  </GlobalErrorBoundary>
);

export default App;
