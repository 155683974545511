import React from 'react';
import { WALE_DETECTION_EMPTY } from 'domain/entities/samples/waleDetection.sample';
import DisplayWaleMonitor from './DisplayWaleMonitor';
import { useVehicleEventMetrics, useWaleDetection } from './SingleWaleMonitor.view-model';
import { WaleStatusMonitor } from './StatusTable';

export interface SingleWaleMonitorDrilledProps {
  index: number;
  pause: boolean;
  warningMinutes: number;
  dangerMinutes: number;
  hideWaleLane: (waleId?: string, lane?: number) => void;
  showHideWaleButton: boolean;
}
interface SingleWaleMonitorInterface extends SingleWaleMonitorDrilledProps {
  waleId: string;
  lane?: number;
  status: WaleStatusMonitor;
}

const OFFSET_LENGTH = 250;

const SingleWaleMonitor = ({
  waleId,
  index,
  pause,
  warningMinutes,
  dangerMinutes,
  lane,
  hideWaleLane,
  showHideWaleButton,
  status
}: SingleWaleMonitorInterface) => {
  const waleDetection = useWaleDetection(waleId, index * OFFSET_LENGTH, pause, lane);
  const metrics = useVehicleEventMetrics(waleId, lane);

  return (
    <DisplayWaleMonitor
      waleId={waleId}
      captureData={waleId ? waleDetection : WALE_DETECTION_EMPTY}
      statusData={status}
      metricsData={metrics}
      pause={pause}
      warningMinutes={warningMinutes}
      dangerMinutes={dangerMinutes}
      lane={lane}
      index={index}
      hideWaleLane={() => hideWaleLane(waleId, lane)}
      showHideWaleButton={showHideWaleButton}
    />
  );
};

export default SingleWaleMonitor;
