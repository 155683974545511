import { IndexScore } from './indexScore.entity';
import { LocationEvent } from './locationEvent.entity';

export type ZoneEvent = {
  zoneId: string;
  fare: number;
  startedAt: Date;
  endedAt: Date;
  bestLocationEvent: IndexScore;
  locationEvents: LocationEvent[];
};

export const getZonesList = (zones: ZoneEvent[]): string =>
  zones.map((zone: ZoneEvent) => zone.zoneId.split('-').pop()).join('-');
