/* eslint-disable no-underscore-dangle */
import {
  WaleDetection,
  HumanReview,
  VehicleEventMetrics
} from 'domain/entities/waleDetection.entity';
import { LabeledDetectionsParams } from 'pages/LabelSearch/SearchLabelsBar';
import { API } from './api';
import { paramsGetRequest } from './api/getRequestParams';

enum Path {
  WALE_DETECTION = '/vehicle_events',
  HUMAN_REVIEW = '/vehicle_events/human_review',
  METRICS = '/vehicle_events/metrics_summary'
}

type ApiWaleDetection = Omit<WaleDetection, 'eventId'> & { _id: string };

const defaultParams = { alpr: true, all_captures: true, limit: 1 };

const dataToWaleDetection = (data: ApiWaleDetection[]): WaleDetection | null =>
  data[0] ? { ...data[0], eventId: data[0]._id } : null;

export const getWaleDetection = async (
  waleId: string,
  endDate: Date,
  lane?: number,
  alpr = true
): Promise<WaleDetection | null> =>
  API.get<ApiWaleDetection[]>(Path.WALE_DETECTION, {
    params: {
      end_date: endDate.getTime(),
      wale_id: waleId,
      ...(lane && { lane }),
      limit: 1,
      alpr
    }
  }).then((response) => dataToWaleDetection(response.data));

export const getWaleDetectionById = (id: string): Promise<WaleDetection | null> =>
  API.get(Path.WALE_DETECTION, { params: { ...defaultParams, id } })
    .then((response) => dataToWaleDetection(response.data))
    .catch(() => null);

export enum GetWaleDetectionMode {
  NEXT = 'next',
  PREV = 'previous'
}

export const getWaleDetectionByDate =
  (mode: GetWaleDetectionMode) =>
  async (
    waleId: string,
    date: Date,
    lane?: number,
    videoSource = false
  ): Promise<WaleDetection | null> => {
    const request = await API.get(Path.WALE_DETECTION, {
      params: {
        ...defaultParams,
        wale_ids: [waleId],
        mode,
        ...(lane && { lane }),
        ...(mode === GetWaleDetectionMode.NEXT && { start_date: date.getTime() }),
        ...(mode === GetWaleDetectionMode.PREV && { end_date: date.getTime() }),
        ...(videoSource && { source: 'video-manual' })
      }
    });
    return dataToWaleDetection(request.data);
  };

export const updateWaleDetectionHumanReview = (
  id: string,
  humanReview: HumanReview
): Promise<WaleDetection | null> =>
  API.put(Path.HUMAN_REVIEW, { _id: id, humanReview }).then((response) => response.data);

export const getWaleDetectionWithLabels = (params: LabeledDetectionsParams) =>
  API.get(Path.WALE_DETECTION, {
    params: {
      ...defaultParams,
      ...params
    },
    paramsSerializer: (pr) => paramsGetRequest(pr, true)
  }).then((response) => response.data);

export const getVehicleEventMetrics = (
  waleId: string,
  date: Date,
  lane?: number,
  eventCount?: number
): Promise<VehicleEventMetrics> =>
  API.get(Path.METRICS, {
    params: {
      waleId,
      date: date.getTime(),
      ...(lane && { lane }),
      ...(eventCount && { eventCount })
    }
  }).then((response) => response.data);
