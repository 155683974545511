import React from 'react';
import { LocationEvent } from 'domain/entities/locationEvent.entity';
import { ZoneEvent } from 'domain/entities/zoneEvent.entity';
import { Trip } from 'domain/entities/trip.entity';
import ZoneEventElement from './ZoneEventElement';
import TripInfoBlock from './TripInfoBlock';

const ZoneVisualizer = ({
  zoneEvents,
  handleBack,
  setLocationEventData,
  selectedTrip,
  setSelectedZone
}: {
  zoneEvents: ZoneEvent[];
  handleBack: () => void;
  setLocationEventData: (a: LocationEvent[]) => void;
  selectedTrip: Trip;
  setSelectedZone: (a: ZoneEvent) => void;
}) => (
  <>
    <TripInfoBlock handleBack={handleBack} trip={selectedTrip} />
    {zoneEvents.map((zoneEvent) => (
      <ZoneEventElement
        key={zoneEvent.zoneId}
        zoneEvent={zoneEvent}
        setLocationEventData={setLocationEventData}
        setSelectedZone={setSelectedZone}
      />
    ))}
  </>
);

export default ZoneVisualizer;
