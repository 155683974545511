import React from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useAlert } from 'presentation/hooks/useAlert';
import { updatePasswordUseCase } from 'domain/use-cases/auth/updatePassword.use-case';
import { BackToHomeBtn } from '../../components';
import { validateToken } from '../../store/actions/auth';
import ChangePasswordForm from './ChangePasswordForm';

export interface NewPasswordRequest {
  currentPassword: string;
  newPassword: string;
  passwordConfirmation: string;
}
const basicUser: NewPasswordRequest = {
  currentPassword: '',
  newPassword: '',
  passwordConfirmation: ''
};

const ChangePassword = () => {
  const { sendAlert } = useAlert();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleFailureRequest = (error: any) => {
    const errorMessage = error?.response?.data?.error || 'Something went wrong. Please try again';
    sendAlert({ type: 'error', message: errorMessage });
  };

  const handleSuccessUpdate = () => {
    dispatch(validateToken());
    sendAlert({ type: 'success', message: 'Password updated successfully' });
    return history.push('/profile');
  };

  const handleUpdatePasswordRequest = (values: { user: NewPasswordRequest }) => {
    updatePasswordUseCase(values).then(handleSuccessUpdate).catch(handleFailureRequest);
  };

  return (
    <Row className="full-height">
      <Col className="bliss-card m-3">
        <Row>
          <Col md={4}>
            <BackToHomeBtn route="/profile" text="Back to Profile" />
          </Col>
          <Col md={4}>
            <h2 className="mb-5">
              <span className="text-blue text-slim text-uppercase">Change Password</span>
            </h2>
            <ChangePasswordForm
              // @ts-ignore ts-migrate(2322) FIXME: Type '{ user: { currentPassword: string; newPasswo... Remove this comment to see the full error message
              user={basicUser}
              formRequest={handleUpdatePasswordRequest}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ChangePassword;
