import React from 'react';
import { Col, Container, Row, Image } from 'react-bootstrap';
import defaultImage from 'assets/images/blissway_default.png';
import { Link } from 'react-router-dom';
import { getRoadDate, getRoadTime } from 'services/timeUtils';
import { getWaleLocation } from 'presentation/helpers/waleId';
import { useUser } from 'presentation/hooks/data/useUser';

const oldLocationPairs: Record<string, number> = {
  'CO-I70W-242_0': 1,
  'CO-I70W-238_5': 2,
  'CO-I70W-238_1': 3,
  'CO-I70W-237_0': 4,
  'CO-I70W-235_8': 5,
  'CO-I70W-234_6': 6,
  'CO-I70W-232_9': 7,
  'CO-I70W-232_7': 8,
  'CO-I70W-232_6': 9,
  'CO-I70W-231_9': 10,
  'CO-I70E-232_8': 3,
  'CO-I70E-232_7': 2,
  'CO-I70E-232_6': 1
};

const parseText = (cardText: string, isAgency?: boolean): string => {
  if (cardText.includes('Z')) {
    const placeNumber = cardText.split('-')[2].slice(1);
    return `Zone ${placeNumber}`;
  }
  let locationName = cardText;
  if (isAgency && locationName in oldLocationPairs)
    locationName = String(oldLocationPairs[locationName]);
  return `Location ${locationName}`;
};

export const EventCard = ({
  cardText,
  startedAt,
  leftImageUrl,
  rightImageUrl,
  onImageClick,
  leftImageLink,
  rightImageLink,
  leftImageText,
  rightImageText
}: {
  cardText: string;
  startedAt?: Date;
  leftImageUrl: string;
  rightImageUrl: string;
  onImageClick?: () => void;
  leftImageLink?: string;
  rightImageLink?: string;
  leftImageText?: string;
  rightImageText?: string;
}) => {
  const imageStyle = 'wale-img fade-in my-1 mb-2 trip-image';
  const textStyle = 'd-flex justify-content-center align-items-center text-center';

  const user = useUser();
  const isAgency = user.role === 3;

  const imageClick = (!isAgency && onImageClick) || (() => null);

  return (
    <Container className="bliss-card mb-3 p-3" key={cardText} style={{ maxWidth: '95%' }}>
      <Row>
        <Col xs={12} md={12} xl={2} className={textStyle}>
          {parseText(cardText, isAgency)}
          {startedAt && (
            <>
              <br />
              {getRoadDate(startedAt)} {getRoadTime(startedAt)}
            </>
          )}
        </Col>

        <Col xs={12} md={6} xl={5}>
          {leftImageUrl !== 'no-image' &&
            (!isAgency && leftImageLink ? (
              <Link to={leftImageLink}>
                <Image
                  className={imageStyle}
                  style={{ cursor: 'pointer' }}
                  src={leftImageUrl || defaultImage}
                />
              </Link>
            ) : (
              <Image
                className={imageStyle}
                style={{ cursor: !isAgency && onImageClick ? 'pointer' : undefined }}
                src={leftImageUrl || defaultImage}
                onClick={imageClick}
              />
            ))}
          {leftImageText && (
            <div className={textStyle}>{parseText(getWaleLocation(leftImageText), isAgency)}</div>
          )}
        </Col>
        <Col xs={12} md={6} xl={5}>
          {rightImageUrl !== 'no-image' &&
            (!isAgency && rightImageLink ? (
              <Link to={rightImageLink}>
                <Image
                  className={imageStyle}
                  style={{ cursor: 'pointer' }}
                  src={rightImageUrl || defaultImage}
                />
              </Link>
            ) : (
              <Image
                className={imageStyle}
                style={{ cursor: !isAgency && onImageClick ? 'pointer' : undefined }}
                src={rightImageUrl || defaultImage}
                onClick={imageClick}
              />
            ))}
          {rightImageText && (
            <div className={textStyle}>{parseText(getWaleLocation(rightImageText), isAgency)}</div>
          )}
        </Col>
      </Row>
    </Container>
  );
};

const defaultProps = {
  onImageClick: null,
  leftImageLink: '',
  rightImageLink: ''
};

EventCard.defaultProps = defaultProps;
