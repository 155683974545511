import React from 'react';

const ThroughputBar = ({ throughput, maxThroughput, isOffline }: any) => {
  const percentage = throughput / maxThroughput;
  // Saturate below 0% and above 100%
  let adjPercentage = percentage ? Math.min(1, Math.max(0, percentage)) : 0;
  if (isOffline) adjPercentage = 0;

  const activeBlocks = Math.round((adjPercentage * 100) / 10);

  return (
    <div className="d-flex flex-column align-items-center">
      <p className="text-small">{maxThroughput.toLocaleString('en')} vlh</p>
      <div className="filling-bar">
        {[10, 9, 8, 7, 6, 5, 4, 3, 2, 1].map((index) => {
          const barClass = index <= activeBlocks ? 'bar-full' : 'bar-empty';
          return <div key={index} className={barClass} />;
        })}
        <p
          className={isOffline ? 'text-medium text-gray' : 'text-medium text-white'}
          style={{ fontWeight: '400' }}
        >
          {isOffline ? '-' : `${Math.round(adjPercentage * 100)}%`}
        </p>
      </div>
      <p className="text-small">0 vlh</p>
    </div>
  );
};

export default ThroughputBar;
