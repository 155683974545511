import React, { useState, useEffect } from 'react';
import { Row, Col, FormControl, Form, Spinner } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
// @ts-ignore ts-migrate(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import DatePicker from 'react-datepicker';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import './themes';

const Debounce = AwesomeDebouncePromise((value) => value, 500);

type OwnProps = {
  customQuery?: string;
  onRequest?: boolean;
  totalRows?: number;
  RowsPage?: number;
  expandable?: boolean;
  moreData?: boolean;
  resourceRequest?: (...args: any[]) => any;
};

// @ts-ignore ts-migrate(2456) FIXME: Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof ComponentDataTable.defaultProps;

// @ts-ignore ts-migrate(7022) FIXME: 'ComponentDataTable' implicitly has type 'any' bec... Remove this comment to see the full error message
const ComponentDataTable = (props: Props) => {
  const {
    columns,
    onRequest,
    data,
    totalRows,
    RowsPage: propsRowsPage,
    expandable,
    withDate,
    conditionalRowStyles,
    customSearch,
    firstCustomMediumSearch,
    secondCustomMediumSearch,
    thirdCustomMediumSearch,
    customSearchLong,
    noSearch,
    handleSortCase,
    resourceRequest,
    moreData,
    customQuery,
    customStyles,
    subHeader,
    striped
  } = props;
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [textInput, setTextInput] = useState('');
  const [page, setPage] = useState(0);
  const [RowsPage, setRowsPage] = useState(propsRowsPage);
  const [column, setColumn] = useState('');
  const [direction, setDirection] = useState('desc');

  const setRequest = (params?: any) => {
    let sortColumn = { sort_column: column };
    if (handleSortCase) {
      sortColumn = handleSortCase(column);
    }
    resourceRequest({
      query: textInput,
      start_date: startDate,
      end_date: endDate,
      display_start: page * RowsPage,
      limit: RowsPage,
      sort_direction: direction,
      ...sortColumn,
      ...moreData,
      ...params
    });
  };

  const onChangePage = (inputPage: any) => {
    setPage(inputPage - 1);
    setRequest({
      display_start: (inputPage - 1) * RowsPage
    });
  };

  const onChangeRowsPage = (inputRowsPage: any) => {
    setRowsPage(inputRowsPage);
    setRequest({
      display_start: 0,
      limit: inputRowsPage
    });
  };

  const handleDatepickerChange = async (value: any, name: any) => {
    const formattedDate = value.getTime();
    setPage(0);
    if (name === 'startDate') {
      setStartDate(formattedDate || '');
    }
    if (name === 'endDate') {
      setEndDate(formattedDate || '');
    }
  };

  const handleInputChange = async (event: any) => {
    const { value } = event.target;
    setPage(0);
    setTextInput(value || '');
    await Debounce(value);
    setRequest();
  };

  const handleSortDirection = () => {
    setRequest({
      display_start: 0,
      sort_direction: direction
    });
  };

  const handleSort = (inputColumn: any, inputDirection: any) => {
    setDirection(inputDirection);
    setColumn(inputColumn.selector);
    setPage(0);
  };

  useEffect(() => {
    async function handleInitialQuery() {
      setTextInput(customQuery);
      await Debounce(customQuery);
      setRequest({
        display_start: 0,
        query: customQuery
      });
    }
    handleInitialQuery();
  }, [customQuery]);

  useEffect(handleSortDirection, [column, direction]);
  // @ts-ignore ts-migrate(2345) FIXME: Argument of type '(params: any) => void' is not as... Remove this comment to see the full error message
  useEffect(setRequest, [moreData, startDate, endDate, customQuery]);

  return (
    <div className={noSearch ? 'no-search-data-table' : 'mt-4'} style={{ position: 'relative' }}>
      {onRequest && (
        <div className="containerSpinnerLoad" style={{ position: 'absolute', height: '100%' }}>
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      <DataTable
        theme="blissway"
        className="bliss-card mb-4"
        pagination
        conditionalRowStyles={conditionalRowStyles}
        noDataComponent={onRequest ? '' : 'No data found.'}
        paginationServer
        expandableRows={expandable}
        paginationTotalRows={totalRows}
        paginationPerPage={RowsPage}
        paginationRowsPerPageOptions={[10, 25, 50]}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPage}
        paginationComponentOptions={{
          rowsPerPageText: 'Rows per page:',
          rangeSeparatorText: 'of'
        }}
        sortServer
        fixedHeader
        customStyles={customStyles}
        onSort={(inputColumnSort, inputDirectionSort) =>
          handleSort(inputColumnSort, inputDirectionSort)
        }
        sortFunction={(a) => a}
        noHeader
        subHeader={subHeader}
        disabled={onRequest}
        columns={columns}
        data={data}
        striped={striped}
        subHeaderComponent={
          <Row>
            {withDate && (
              <>
                <Col xs={customSearch ? 3 : 4}>
                  <Form.Group>
                    <DatePicker
                      placeholderText="From"
                      dateFormat="dd-MM-yyyy"
                      className="form-control"
                      showYearDropdown
                      selected={startDate}
                      onChange={(date: any) => handleDatepickerChange(date, 'startDate')}
                    />
                  </Form.Group>
                </Col>
                <Col xs={customSearch ? 3 : 4}>
                  <Form.Group>
                    <DatePicker
                      placeholderText="Until"
                      dateFormat="dd-MM-yyyy"
                      className="form-control"
                      showYearDropdown
                      selected={endDate}
                      onChange={(date: any) => handleDatepickerChange(date, 'endDate')}
                    />
                  </Form.Group>
                </Col>
              </>
            )}
            <Col>
              <Form.Group>
                <FormControl
                  className="form-input text-blue-gray border-bottom-light"
                  placeholder="Search"
                  value={textInput}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            {customSearch && <Col>{customSearch}</Col>}
            {firstCustomMediumSearch && (
              <Col md={4} className="w-100">
                {firstCustomMediumSearch}
              </Col>
            )}
            {secondCustomMediumSearch && (
              <Col md={4} className="w-100">
                {secondCustomMediumSearch}
              </Col>
            )}
            {thirdCustomMediumSearch && (
              <Col md={4} className="w-100">
                {thirdCustomMediumSearch}
              </Col>
            )}
            {customSearchLong && <Col md={12}>{customSearchLong}</Col>}
          </Row>
        }
      />
    </div>
  );
};

ComponentDataTable.defaultProps = {
  customQuery: '',
  onRequest: false,
  totalRows: 0,
  RowsPage: 10,
  expandable: false,
  moreData: '',
  resourceRequest: () => null
};

export default ComponentDataTable;
