import { utilsTypes } from '../actions/utils';

const initialState = {
  isOffline: false,
  settings: {
    mainContactEmail: ''
  },
  alert: {
    show: false,
    title: '',
    kind: '',
    timeout: 3000,
    message: ''
  },
  ongoingRequest: {
    getSettings: false
  }
};

const reducer = (state = initialState, action: any = '') => {
  switch (action.type) {
    case utilsTypes.SET_ALERT:
      return {
        ...state,
        alert: {
          show: true,
          kind: action.kind,
          timeout: action.timeout || initialState.alert.timeout,
          message: action.message,
          title: action.title
        }
      };
    case utilsTypes.CLOSE_ALERT:
      return {
        ...state,
        alert: initialState.alert
      };
    default:
      return state;
  }
};

export default reducer;
