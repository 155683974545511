import React from 'react';
import { Button } from 'react-bootstrap';
import { RequiredEboxWalePair } from '../EboxWalePairTypes';

export const EboxWalePairElement = ({
  eboxWalePair,
  setUnlinkPair,
  setShowModal
}: {
  eboxWalePair: RequiredEboxWalePair;
  setUnlinkPair: ({
    eboxId,
    eboxName,
    waleId
  }: {
    eboxId: string;
    eboxName: string;
    waleId: string;
  }) => void;
  setShowModal: (a: boolean) => void;
}) => {
  const { eboxId, eboxName, waleId } = eboxWalePair;
  const normalFontStyle = { fontWeight: 'normal' };

  const handleClick = () => {
    setUnlinkPair({ eboxId, eboxName, waleId });
    setShowModal(true);
  };

  return (
    <tr className="text-center justify-content-between center-row-columns">
      <th style={normalFontStyle}>{waleId}</th>
      <th style={normalFontStyle}>{eboxName}</th>
      <th style={normalFontStyle}>{eboxId}</th>
      <th>
        <Button onClick={handleClick}>Unlink</Button>
      </th>
    </tr>
  );
};
