import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { getRoadProject, getWaleLocation } from 'presentation/helpers/waleId';
import OperationStatusIndicator from './OperationStatusIndicator';
import WALE from './WALE';
import AlertCount from './AlertCount';
import { CorridorOverview, operationStatusToPretty } from './Overview.view-model';

type CorridorStatusProps = CorridorOverview;

const CorridorStatus = ({ value, label, status, wales, alertCounts }: CorridorStatusProps) => {
  const params = new URLSearchParams({
    locations: wales.length > 0 ? wales.map((wale) => getWaleLocation(wale.id)).join(',') : '',
    project: getRoadProject(value),
    waleType: 'ALL',
    maxWalesPerRow: '2'
  });

  return (
    <div className="rounded-xl overflow-hidden bliss-card-bg mb-2.5" key={value}>
      <div className={clsx('flex items-center justify-between p-3 w-full')}>
        <div className={clsx('flex items-center gap-2', status !== 'operational' && 'mt-1')}>
          <OperationStatusIndicator status={status} />
          {status === 'operational' ? (
            <h3 className="text-xl ml-1 font-semibold text-white m-0">{label}</h3>
          ) : (
            <Link
              className="text-xl ml-1 text-white m-0 font-semibold"
              to={`/wale/monitor?${params.toString()}`}
              target="_blank"
              rel="noreferrer"
            >
              {label}
            </Link>
          )}
          <AlertCount alertCounts={alertCounts} />
        </div>
        <div className="flex items-center gap-2">
          <p
            className={clsx(
              'text-base m-0',
              status === 'operational' && 'text-green-400',
              status === 'underperforming-metrics' && 'text-purple-400',
              status === 'degraded-metrics' && 'text-yellow-400',
              status === 'degraded-system' && 'text-red-400'
            )}
          >
            {operationStatusToPretty[status]}
          </p>
        </div>
      </div>

      {wales.length > 0 ? (
        <div className="px-4 pb-4 grid grid-cols-2 md:grid-cols-3 xl:grid-cols-2 2xl:grid-cols-3 3xl:grid-cols-4">
          {wales.map(({ id, status: waleStatus, statusMessages, maintenanceStatus }) => (
            <WALE
              key={id}
              id={id}
              maintenanceStatus={maintenanceStatus}
              status={waleStatus}
              statusMessages={statusMessages}
            />
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default CorridorStatus;
