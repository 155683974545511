import React from 'react';
import moment from 'moment';
import Datetime from 'react-datetime';
import { fromRoadToUnix, roadTimeObject } from 'services/timeUtils';

/** Wrapper for react-datetime component, that will handle Date in the Road's time */
export const RoadDateTimePicker = ({
  onChange,
  inputProps,
  initialValue
}: {
  onChange: (d: Date | null) => void;
  inputProps: any;
  initialValue?: Date | null;
}) => {
  const getInputDateFormatted = (inputDate: moment.MomentInput): Date | null => {
    const momentInputDate = moment(inputDate, 'yyyy-MM-DD HH:mm:ss', true);
    if (!momentInputDate.isValid()) return null;
    const timestamp = fromRoadToUnix(momentInputDate);
    return new Date(timestamp);
  };

  return (
    <Datetime
      timeFormat="HH:mm:ss"
      onChange={(i) => onChange(getInputDateFormatted(i))}
      inputProps={inputProps}
      initialValue={
        initialValue ? roadTimeObject(initialValue).format('MM/DD/yyyy HH:mm:ss') : undefined
      }
    />
  );
};
