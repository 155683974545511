import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { GraphSection } from './components/Graphs/GraphSection';
import { Lane } from './components/Lanes/Lane';
import { useTrafficTimeSeries, useTrafficUpdatedInformation } from './TollingAndTraffic.view-model';
import { TripsSummary } from './components/TripsSummary/TripsSummary';

export const TollingAndTraffic = () => {
  const trafficInformation = useTrafficUpdatedInformation();
  const trafficGraphData = useTrafficTimeSeries();

  return (
    <>
      <Row className="my-3 gap-4 px-3">
        <Col className="p-0">
          <div className="bliss-card py-3 px-4" data-cy="fast_lane">
            <Lane
              trafficInformation={trafficInformation?.fastLane}
              title="Fast Lane"
              numberOfLines={1}
            />
          </div>
        </Col>
        <Col className="p-0">
          <div className="bliss-card py-3 px-4" data-cy="regular_lane">
            <Lane
              trafficInformation={trafficInformation?.regularLane}
              title="Regular Lane"
              numberOfLines={2}
            />
          </div>
        </Col>
        <Col lg={8} md={12} xs={12} className="p-0">
          <div className="bliss-card py-3 px-4">
            <TripsSummary />
          </div>
        </Col>
      </Row>
      <GraphSection trafficGraphData={trafficGraphData} />
    </>
  );
};
