import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { LinkBtn, BackToHomeBtn } from '../../components';
import UserDataTable from './UserDataTable';
import columns from './Columns';
import { Can } from '../../services/abilityContext';

const UserIndex = () => (
  <Row className="full-height">
    <Col md={12} className="bliss-card my-3">
      <Row>
        <Col md={1} xs={4}>
          <BackToHomeBtn />
        </Col>
        <Col md={9} xs={4} className="text-center">
          <h2>
            <span className="text-blue text-slim text-uppercase">Users</span>
          </h2>
        </Col>
        <Can I="manage" a="all">
          <Col md={2} xs={4}>
            <LinkBtn variant="primary" block to="/users/new">
              New User
            </LinkBtn>
          </Col>
        </Can>
        <Col md={12} xs={12}>
          <UserDataTable columns={columns} />
        </Col>
      </Row>
    </Col>
  </Row>
);

export default UserIndex;
