export const utilsTypes = {
  // ALERT
  SET_ALERT: 'SET_ALERT',
  CLOSE_ALERT: 'CLOSE_ALERT',
  SET_ALERT_ERROR: 'SET_ALERT_ERROR',
  // SET_HEADERS
  SET_HEADERS: 'SET_HEADERS',
};

// ALERT
export const closeAlert = (params?: any) => ({
  type: utilsTypes.CLOSE_ALERT,
  params
});
export const sendAlert = (params: any) => ({
  type: utilsTypes.SET_ALERT,
  ...params
});

export const setHeaders = (params: any) => ({
  type: utilsTypes.SET_HEADERS,
  ...params
});
