/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react';
import { GasCode } from 'domain/entities/waleDetection.entity';

export const errorCodeLookup = {
  [GasCode.DisabledVeteranPurpleHeartLegionOfValor]:
    'Disabled Veteran/Purple Heart/Legion of Valor',
  [GasCode.PaperPlate]: 'Paper Plate',
  [GasCode.EmergencyVehicleSchoolBusGovernmentDiplomat]:
    'Emergency Vehicle/School bus/Government/Diplomat',
  [GasCode.Trailer]: 'Trailer',
  [GasCode.OutOfCountry]: 'Out of country',
  [GasCode.NoImageOrNoVehicle]: 'No vehicle',
  [GasCode.CameraOutOfAlignment]: 'Camera out of alignment',
  [GasCode.BadImage]: 'Bad image', // blurry / bright / dark / corrupted
  [GasCode.ObstructedPlate]: 'Obstructed plate', // (or partial view of plate)
  [GasCode.NoLicensePlate]: 'No license plate'
};

export const errorCodeToLabel = (code: any) => {
  // @ts-ignore ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  if (code in errorCodeLookup) return errorCodeLookup[code];
  return '';
};

const ErrorCodeSelect = React.forwardRef(
  // @ts-ignore ts-migrate(2339) FIXME: Property 'selectedErrorCode' does not exist on typ... Remove this comment to see the full error message
  ({ selectedErrorCode, ...props }, ref) => {
    const errorOptions = Object.keys(errorCodeLookup).map((code) =>
      code === selectedErrorCode ? (
        <option value={code} key={code} selected>
          {/* @ts-ignore ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
          {errorCodeLookup[code]}
        </option>
      ) : (
        <option value={code} key={code}>
          {/* @ts-ignore ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
          {errorCodeLookup[code]}
        </option>
      )
    );

    return (
      // @ts-ignore ts-migrate(2322) FIXME: Type 'ForwardedRef<unknown>' is not assignable to ... Remove this comment to see the full error message
      <select ref={ref} {...props}>
        <option disabled>Choose error</option>
        <option value="">{'<none>'}</option>
        {errorOptions}
      </select>
    );
  }
);

export default ErrorCodeSelect;
