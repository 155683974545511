import { PlateState } from 'domain/entities/waleDetection.entity';
import React from 'react';
import { getStateFullname } from 'services/statePlateStyles';
import FormikSelect from 'components/Select/FormikSelect';

export const StateSelect = ({
  setSelectedState,
  defaultValue
}: {
  setSelectedState: (location: PlateState | null) => void;
  defaultValue: string | null;
}) => {
  const states = Object.values(PlateState);
  const stateOptions = states.map((opt) => ({ value: opt, label: getStateFullname(opt) }));

  const handleChange = (event: { value: PlateState }) => {
    if (!event) {
      setSelectedState(null);
    } else {
      const state = event.value;
      if (states.includes(state)) {
        setSelectedState(state);
      } else {
        setSelectedState(null);
      }
    }
  };
  const placeholder = 'Plate State...';
  // TODO: Clean this logic, only difference is value and deafultValue
  return (
    <div className="state-select">
      {defaultValue ? (
        <FormikSelect
          isClearable
          key="stateSelectWithDefault"
          placeholder={placeholder}
          options={stateOptions}
          onChange={handleChange}
          value={defaultValue}
          defaultValue={defaultValue}
        />
      ) : (
        <FormikSelect
          isClearable
          key="stateSelectPlate WithNoDefault"
          placeholder={placeholder}
          options={stateOptions}
          onChange={handleChange}
        />
      )}
    </div>
  );
};
