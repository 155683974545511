import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { CustomXAxis, Graph, GraphData } from './Graph';

export const GraphSection = ({
  trafficGraphData: { vehiclesPerHourChartData, speedChartData, chartLabels }
}: {
  trafficGraphData: {
    vehiclesPerHourChartData: GraphData[];
    speedChartData: GraphData[];
    chartLabels: Pick<GraphData, 'displayHour'>[];
  };
}) => (
  <Row>
    <Col md={12}>
      <div className="blue-gradient-inverse rounded-corners-top">
        <Graph data={vehiclesPerHourChartData} title="Traffic Volume" />
      </div>
      <div className="blue-gradient">
        <Graph data={speedChartData} title="Speed" />
      </div>
      <div className="blue-gradient rounded-corners-bottom">
        <CustomXAxis data={chartLabels} />
      </div>
    </Col>
  </Row>
);
