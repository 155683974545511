import React, { useEffect } from 'react';
import { Row, Col, Button, Image } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withFormik, Field, Form } from 'formik';
// @ts-ignore ts-migrate(7016) FIXME: Could not find a declaration file for module 'yup'... Remove this comment to see the full error message
import * as Yup from 'yup';
import { resetPasswordUseCase } from 'domain/use-cases/auth/resetPassword.use-case';
import { FormikInput } from '../../components';
import { sendAlert } from '../../store/actions/utils';
import Logo from '../../assets/images/LogoText.png';
import './style.scss';

const ResetPassword = (props: any) => {
  const { onHide, isValid, submitVariant, errors, touched } = props;
  useEffect(() => {
    document.body.className = 'login-bg';
  }, []);
  return (
    <Row className="full-height login">
      <Col md={3} className="text-center">
        <Image src={Logo} className="img-fluid" />
      </Col>
      <Col md={9}>
        <Row>
          <Col md={{ span: 6, offset: 3 }}>
            <h4 className="text-white mb-4">Change password</h4>
            <Form>
              <Field name="password">
                {({ field }: any) => (
                  <FormikInput
                    {...field}
                    inputType="password"
                    label="New"
                    placeholder="Create your new password"
                    error={errors[field.name]}
                    touched={touched[field.name]}
                  />
                )}
              </Field>
              <Field name="passwordConfirmation">
                {({ field }: any) => (
                  <FormikInput
                    {...field}
                    inputType="password"
                    label="Confirm new"
                    placeholder="Create your new password"
                    error={errors[field.name]}
                    touched={touched[field.name]}
                  />
                )}
              </Field>
              <Row>
                <Col md={{ span: 6, offset: 6 }}>
                  <Button
                    variant={submitVariant}
                    // @ts-ignore ts-migrate(2322) FIXME: Type '"md"' is not assignable to type '"sm" | "lg"... Remove this comment to see the full error message
                    size="md"
                    className="mt-4 btn-dark"
                    block
                    type="submit"
                    disabled={!isValid}
                    onClick={onHide}
                  >
                    Change
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state: any) => {
  const { ongoingRequest, signedIn } = state.auth;
  return {
    ongoingRequest,
    signedIn
  };
};

const initialValues = {
  password: '',
  passwordConfirmation: ''
};

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required('This field is mandatory')
    .min(6, 'password must be at least 6 characters'),
  passwordConfirmation: Yup.string()
    .required('This field is mandatory')
    .min(6, 'password must be at least 6 characters')
    .test(
      'password-confirmation-test',
      "passwords don't match",
      function equalTo(this: any, value: any) {
        if (value && value === this.parent.password) {
          return true;
        }
        return false;
      }
    )
});

const handleSubmit = (values: any, { props }: any) => {
  const { dispatch, match, history } = props;
  const { token } = match.params;

  resetPasswordUseCase(values.password, token)
    .then(() => {
      dispatch(sendAlert({ kind: 'success', message: 'Password Updated' }));
      history.push('/login');
    })
    .catch((result: any) => {
      dispatch(sendAlert({ kind: 'error', message: result || 'Token expired' }));
    });
};

export default withRouter(
  connect(mapStateToProps)(
    withFormik({
      mapPropsToValues: () => initialValues,
      validationSchema,
      handleSubmit
    })(ResetPassword)
  )
);
