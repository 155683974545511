import { WaleDetection } from '../waleDetection.entity';

/**
 These values are only used as sample.
 */
export const WALE_DETECTION_EMPTY: WaleDetection = {
  eventId: '',
  waleId: '',
  eboxId: '',
  imageUrl: '',
  capturedAt: '',
  plateState: '',
  plateNumber: '',
  makeModel: '',
  color: '',
  id: '',
  _id: ''
};
