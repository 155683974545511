import { LidarLog } from 'domain/entities/lidarLog.entity';
import { API } from './api';

enum Path {
  LIDAR_LOGS = '/lidar_logs'
}

export const getLidarLogs = (startDate: Date, endDate: Date, waleId: string): Promise<LidarLog[]> =>
  API.get<LidarLog[]>(Path.LIDAR_LOGS, {
    params: {
      start_date: startDate.valueOf(),
      end_date: endDate.valueOf(),
      wale_id: waleId,
      limit: 1000
    }
  }).then((response) => response.data);
