import { DefaultModal } from 'components';
import { genericErrorMessage } from 'data/api/genericErrorMessage';
import { unlinkEboxWalePairUseCase } from 'domain/use-cases/ebox/unlinkEboxWalePair.use-case';
import { SendAlertInput } from 'presentation/hooks/useAlert';
import React, { ChangeEvent, useState } from 'react';
import { EboxWalePair } from '../EboxWalePairTypes';

const ModalBody = ({
  selectedUnlinkPair,
  setInputValue
}: {
  selectedUnlinkPair: EboxWalePair;
  setInputValue: (a: string) => void;
}) => (
  <>
    Are you sure you want to unlink WALE {selectedUnlinkPair.waleId} from Ebox{' '}
    {selectedUnlinkPair.eboxName} / {selectedUnlinkPair.eboxId}?
    <br />
    Write &apos;YES&apos; to confirm.
    <br />
    <input
      className="bliss-input-field px-2 bliss-input-common-style bliss-input-set-width"
      placeholder="Confirm action..."
      onChange={(event: ChangeEvent<HTMLInputElement>) => setInputValue(event.target.value)}
    />
  </>
);

const EboxWaleModal = ({
  selectedUnlinkPair,
  showModal,
  setShowModal,
  triggerFetch,
  sendAlert
}: {
  selectedUnlinkPair: EboxWalePair;
  showModal: boolean;
  setShowModal: (a: boolean) => void;
  triggerFetch: () => void;
  sendAlert: (a: SendAlertInput) => void;
}) => {
  const [inputValue, setInputValue] = useState('');

  const handleModalClose = () => {
    setShowModal(false);
    setInputValue('');
  };

  const handleConfirm = () => {
    if (inputValue !== 'YES') {
      sendAlert({
        type: 'warning',
        message: "Input value is not 'YES'",
        timeout: 3000
      });
      return;
    }
    if (!selectedUnlinkPair.eboxId || !selectedUnlinkPair.waleId) {
      sendAlert({
        type: 'error',
        message: 'Wale ID or Ebox ID not set.',
        timeout: 3000
      });
      return;
    }
    unlinkEboxWalePairUseCase(selectedUnlinkPair.eboxId, selectedUnlinkPair.waleId)
      .then(() => {
        sendAlert({
          type: 'success',
          message: 'Devices unlinked!',
          timeout: 3000
        });
        triggerFetch();
        handleModalClose();
      })
      .catch(genericErrorMessage(false));
  };
  return (
    <DefaultModal
      title="Unlink WALE and Ebox"
      body={<ModalBody selectedUnlinkPair={selectedUnlinkPair} setInputValue={setInputValue} />}
      show={showModal}
      handleClose={handleModalClose}
      handleConfirm={handleConfirm}
      titleBtnClose="Cancel"
      titleBtnSave="Confirm"
    />
  );
};

export default EboxWaleModal;
