import { UseQueryResult } from '@tanstack/react-query';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { RefreshCw } from 'react-feather';
import { formatDistance } from 'date-fns';
import { CorridorOverview } from './Overview.view-model';

type LastUpdatedButtonProps = {
  corridorOverviewsQuery: UseQueryResult<CorridorOverview[], unknown>;
};

const LastUpdatedButton = ({ corridorOverviewsQuery }: LastUpdatedButtonProps) => {
  const [currentDate, setCurrentDate] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDate(new Date());
    }, 10000);

    return () => clearInterval(intervalId);
  }, [corridorOverviewsQuery.dataUpdatedAt]);

  const lastUpdatedText = corridorOverviewsQuery.isLoading
    ? '-'
    : `${formatDistance(currentDate, corridorOverviewsQuery.dataUpdatedAt)} ago`;

  return (
    <div className="flex items-center gap-2">
      <button
        type="button"
        className={clsx(
          'm-0 text-sm flex items-center gap-2 text-gray-400 hover:text-white transition-all duration-100',
          corridorOverviewsQuery.isFetching ? 'text-white cursor-default' : 'cursor-pointer'
        )}
        onClick={() => corridorOverviewsQuery.refetch()}
        disabled={corridorOverviewsQuery.isFetching}
      >
        <RefreshCw
          className={clsx('size-3', corridorOverviewsQuery.isFetching && 'animate-spin')}
        />
        Last updated: {lastUpdatedText}
      </button>
    </div>
  );
};

export default LastUpdatedButton;
