import { getStructuredCSVTrips } from 'data/trip.repository';
import { PlateState } from 'domain/entities/waleDetection.entity';
import { subtractDays } from 'services/timeUtils';

export const getFilteredStructuredCSVTripsUseCase = async (
  startDate: Date | null,
  endDate: Date | null,
  plateNumber: string | null,
  plateState: PlateState | null
) => {
  const realEndDate = endDate ?? new Date();
  const realStartDate = startDate ?? new Date(subtractDays(realEndDate, 365));
  return getStructuredCSVTrips(realStartDate, realEndDate, plateNumber, plateState);
};
