import { TrafficData } from 'domain/entities/trafficData.entity';
import { API } from './api';

enum Path {
  TRAFFIC = '/traffic',
  TRAFFIC_CHART = '/traffic/chart'
}

export const getTraffic = (startDate: Date, endDate: Date): Promise<TrafficData> =>
  API.get<TrafficData>(Path.TRAFFIC, {
    params: {
      start_date: startDate.valueOf(),
      end_date: endDate.valueOf()
    }
  }).then((response) => response.data);

export const getTrafficChart = (startDate?: Date, endDate?: Date): Promise<TrafficData[]> =>
  API.get<TrafficData[]>(
    Path.TRAFFIC_CHART,
    startDate &&
      endDate && {
        params: {
          start_date: startDate.valueOf(),
          end_date: endDate.valueOf()
        }
      }
  ).then((response) => response.data);
