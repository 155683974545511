import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { API } from './api';

enum Path {
  USERS = '/users'
}

export const getUsers = (queryParams: Record<string, unknown>) =>
  API.get(Path.USERS, { params: queryParams }).then((response) => response.data);

export const debounceGetUsers = AwesomeDebouncePromise(getUsers, 300);

export const getUserById = (id: string) =>
  API.get(`${Path.USERS}/${id}`).then((response) => response.data);

export const createUser = (user: Record<string, unknown>) =>
  API.post(Path.USERS, user).then((response) => response.data);

export const updateUser = (id: string, user: Record<string, unknown>) =>
  API.put(`${Path.USERS}/${id}`, user).then((response) => response.data);

export const deleteUser = (id: string) => API.delete(`${Path.USERS}/${id}`);
