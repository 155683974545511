import { NOT_SET_DEMO_PARAM, toIsoDateString } from 'services/timeUtils';
import { authTypes } from '../actions/auth';

const initialState = {
  user: {
    id: 0,
    email: '',
    uid: '',
    role: '',
    demo: localStorage.getItem('demo') === 'true',
    demoDate: localStorage.getItem('demoDate') ?? toIsoDateString(new Date()),
    demoHour: parseInt(localStorage.getItem('demoHour') ?? NOT_SET_DEMO_PARAM.toString(), 10),
    demoMinute: parseInt(localStorage.getItem('demoMinute') ?? NOT_SET_DEMO_PARAM.toString(), 10)
  },
  headers: {
    accessToken: ''
  },
  signedIn: localStorage.jwt !== undefined,
  ongoingRequest: {
    signIn: false,
    signOut: false,
    updateUser: false
  }
};

const setHeaders = (action: any) => ({
  accessToken: action.response.data.accessToken
});

const reducer = (state = initialState, action: any = '') => {
  switch (action.type) {
    case authTypes.SIGN_IN_REQUEST:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, signIn: true }
      };
    case authTypes.SIGN_IN_SUCCESS:
      return {
        ...state,
        user: action.result,
        headers: setHeaders(action),
        ongoingRequest: { ...state.ongoingRequest, signIn: false },
        signedIn: true
      };
    case authTypes.SIGN_IN_FAILURE:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, signIn: false }
      };
    case authTypes.SIGN_OUT_REQUEST:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, signOut: true }
      };
    case authTypes.SIGN_OUT_SUCCESS:
      return {
        ...initialState,
        ongoingRequest: { ...state.ongoingRequest, signOut: false },
        signedIn: false
      };
    case authTypes.SIGN_OUT_FAILURE:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, signOut: false }
      };
    case authTypes.VALIDATE_TOKEN_REQUEST:
      return {
        ...state
      };
    case authTypes.VALIDATE_TOKEN_SUCCESS:
      return {
        ...state,
        user: action.user,
        headers: setHeaders(action)
      };
    case authTypes.RESTORE_AUTH_INFO:
      return {
        ...state,
        headers: action.auth,
        signedIn: true
      };
    case authTypes.CLEAR_AUTH_INFO:
      localStorage.removeItem('jwt');
      localStorage.removeItem('jwtRefresh');
      return {
        ...state,
        user: initialState.user,
        headers: initialState.headers,
        signedIn: false
      };
    default:
      return state;
  }
};

export default reducer;
