import React, { Component, ReactNode } from 'react';
import { Button } from 'react-bootstrap';

// Define types for the ErrorBoundary props and state
interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  countdown: number;
  countdownStopped: boolean;
}

class GlobalErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  intervalId?: NodeJS.Timeout;

  timeoutId?: NodeJS.Timeout;

  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, countdown: 10, countdownStopped: false };
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    // Update state so the next render will show the fallback UI.
    console.log(error);
    return { hasError: true, countdown: 10, countdownStopped: false };
  }

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    console.log(error, info);

    // Start the countdown for reloading the page
    this.intervalId = setInterval(() => {
      this.setState((prevState) => ({ countdown: prevState.countdown - 1 }));
    }, 1000);

    // Reload the page after 10 seconds
    this.timeoutId = setTimeout(() => {
      window.location.reload();
    }, 10000);
  }

  componentWillUnmount() {
    this.clearTimers();
  }

  stopCountdown = () => {
    this.clearTimers();
    this.setState({ countdownStopped: true });
  };

  reloadNow = () => {
    this.clearTimers();
    window.location.reload();
  };

  clearTimers() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  render() {
    const { hasError, countdown, countdownStopped } = this.state;
    const { children } = this.props;
    if (hasError) {
      return (
        <div className="p-12">
          <h1>Oops... something went wrong!</h1>
          <p>
            {countdownStopped
              ? 'Automatic reloading stopped. '
              : `Reloading in ${countdown} seconds... `}
            Click
            <span
              role="link"
              tabIndex={0}
              style={{ cursor: 'pointer', color: 'blue' }}
              onClick={this.reloadNow}
              onKeyDown={this.reloadNow}
            >
              {' '}
              here{' '}
            </span>
            to reload now.
          </p>
          {!countdownStopped && (
            <Button onClick={this.stopCountdown} disabled={countdownStopped}>
              Stop Reload
            </Button>
          )}
        </div>
      );
    }

    return children;
  }
}

export default GlobalErrorBoundary;
