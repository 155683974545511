/* eslint-disable react/jsx-props-no-spreading */
import { useContainerDimensions } from 'presentation/hooks/useContainerDimensions';
import React, { Fragment, useRef } from 'react';
import { LabelList, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts';
import { first, last } from 'lodash';
import { CorridorDisplayValues, MetroLocationInfo } from './CorridorDisplay.view-model';
import { CorridorHeader } from './CorridorHeader';
import { LocationLaneDisplay } from './LocationLaneDisplay';
import { LocationTick } from './LocationTick';

const HARDCODED_HEIGHT = 700;

const laneNameXAxisFormatter = (
  laneIndex: number,
  lanes: number[],
  laneWidth: number,
  gplStart: number
) => {
  if (!lanes.includes(laneIndex + 1)) return '';
  if (laneIndex + 1 < gplStart) return 'EL';

  let gplWord;

  if (laneWidth > 40) gplWord = 'GPL';
  else if (laneWidth > 30) gplWord = 'GP';
  else gplWord = 'G';

  return `${gplWord}${laneIndex + 2 - gplStart}`;
};

const xAxisTickFontSize = (laneWidth: number) => {
  if (laneWidth < 45) return 13;
  if (laneWidth < 40) return 11;
  return undefined;
};
const xAxisArrowFontSize = (lanes: number[]) => (lanes.length > 2 ? 35 : 55);

const paddingYAxisWidth = (lanes: number[]) => (lanes.length > 2 ? 10 : 15);
const laneDirectionXAxisFormatter = (
  laneIndex: number,
  lanes: number[],
  isAscendingMileage: boolean,
  firstLaneAtEnd: number,
  lastLaneAtEnd: number
) => {
  if (
    !lanes.includes(laneIndex + 1) ||
    laneIndex + 1 < firstLaneAtEnd ||
    laneIndex + 1 > lastLaneAtEnd
  )
    return '';
  if (!isAscendingMileage) return '▼';
  return '▲';
};

const yAxisSharedFormatting = {
  dataKey: 'name',
  type: 'category' as const,
  interval: 0,
  strokeWidth: 0,
  minTickGap: 0,
  tickMargin: -18
};

const sharedLineFormatting = { isAnimationActive: false, dot: false };

export const CorridorDisplay = (props: {
  roadTitle: string;
  roadColor: string;
  corridorLocations: MetroLocationInfo[];
  setCorridorLocations: (a: MetroLocationInfo[]) => void;
  lanes: number[];
  isCorridorAscending: boolean;
  humanNameMap: Record<string, string>;
  carouselLocations: string[] | null;
  gplStart: number;
}) => {
  const {
    roadTitle,
    roadColor,
    corridorLocations,
    setCorridorLocations,
    lanes,
    isCorridorAscending,
    humanNameMap,
    carouselLocations,
    gplStart
  } = props;

  const ref = useRef(null);
  const divDimensions = useContainerDimensions(ref, props);
  const lineChartWidth = Math.min(divDimensions.width, 40 + lanes.length * 50);
  const laneWidth = (lineChartWidth - 40) / lanes.length;
  const xAxisSharedFormatting = {
    type: 'number' as const,
    tickCount: Math.max(...lanes) + 1,
    strokeWidth: 0,
    interval: 0,
    allowDecimals: false,
    domain: [Math.min(...lanes) - 1, Math.max(...lanes)],
    tickMargin: 20,
    height: 50,
    reversed: !isCorridorAscending
  };

  const elementBeforeArrows = isCorridorAscending
    ? first(corridorLocations)
    : last(corridorLocations);
  const lastLaneInLastLocation = elementBeforeArrows?.lastLaneInLocation ?? 0;
  const firstLaneInLastLocation = (elementBeforeArrows?.laneOffset ?? 0) + 1;

  return corridorLocations.length > 0 && lanes.length > 0 ? (
    <div ref={ref}>
      {/* Header with the direction of the corridor, clickable to enable/disable all WALEs in it */}
      <CorridorHeader
        roadTitle={roadTitle}
        corridorLocations={corridorLocations}
        setCorridorLocations={setCorridorLocations}
      />
      {/* The corridor display is a customized line chart. Sadly, Recharts doesn't allow all the
      elements to be in different files so they all need to be here. */}
      <LineChart
        width={lineChartWidth}
        height={HARDCODED_HEIGHT}
        data={corridorLocations}
        layout="vertical"
      >
        {/* Tooltip that shows up on location hover, says the location name in human terms,
        and the custom formatter gets rid of the actual value display. */}
        <Tooltip
          formatter={() => null}
          labelFormatter={(a: any, prop: any[]) => {
            if (prop.length === 0) return '';
            return humanNameMap[prop[0].payload.locationId];
          }}
          isAnimationActive={false}
          cursor={false}
        />

        {/* Each axis' side depends on the orientation of the road. */}

        {/* This axis displays the mileage of the locations. */}
        <YAxis
          yAxisId="mileNumbers"
          orientation={isCorridorAscending ? 'right' : 'left'}
          width={40}
          {...yAxisSharedFormatting}
          tick={<LocationTick />}
        />
        {/* This axis is empty and is only used for spacing. */}
        <YAxis
          yAxisId="empty"
          orientation={isCorridorAscending ? 'left' : 'right'}
          tickFormatter={() => ''}
          width={paddingYAxisWidth(lanes)}
          {...yAxisSharedFormatting}
        />
        {/* This axis displays the name of the lane. */}
        <XAxis
          xAxisId="laneName"
          orientation={isCorridorAscending ? 'bottom' : 'top'}
          tickFormatter={(val) => laneNameXAxisFormatter(val, lanes, laneWidth, gplStart)}
          {...xAxisSharedFormatting}
          fontSize={xAxisTickFontSize(laneWidth)}
        />
        {/* This axis displays the arrowhead of the road direction. */}
        <XAxis
          xAxisId="laneDirection"
          orientation={isCorridorAscending ? 'top' : 'bottom'}
          tickFormatter={(val) =>
            laneDirectionXAxisFormatter(
              val,
              lanes,
              isCorridorAscending,
              firstLaneInLastLocation,
              lastLaneInLastLocation
            )
          }
          stroke={roadColor}
          fontSize={xAxisArrowFontSize(lanes)}
          dy={isCorridorAscending ? 26 : -30}
          {...xAxisSharedFormatting}
        />
        {/* For each lane we generate two line charts */}
        {lanes.map((lane) => (
          <Fragment key={lane}>
            {/* The first line chart is a straight line that goes across the entire lane.
                The line will get cut off when the lane no longer exists.  */}
            <Line
              xAxisId="laneDirection"
              yAxisId="mileNumbers"
              dataKey={(obj) => {
                if (lane <= obj.laneOffset || lane > obj.lastLaneInLocation) return null;
                return lane - 1;
              }}
              strokeDasharray={lane >= gplStart ? '3  3' : '8  1'}
              stroke={roadColor}
              strokeWidth={16}
              {...sharedLineFormatting}
            />
            {/* The second line chart is just the symbols representing the WALEs, which are clickable. */}
            <Line
              xAxisId="laneName"
              yAxisId="mileNumbers"
              dataKey={(obj: MetroLocationInfo) =>
                obj.chartValues[lane - 1] !== CorridorDisplayValues.NOT_PRESENT ? lane - 1 : null
              }
              stroke="none"
              strokeWidth={0}
              {...sharedLineFormatting}
            >
              {/* Each dot has a 'label', which is a triangle for each existing WALE */}
              <LabelList
                // This is what the next element receives as 'value'.
                dataKey={(metroLocationInfo) => metroLocationInfo}
                content={
                  <LocationLaneDisplay
                    isAscendingMileage={isCorridorAscending}
                    baseStroke="white"
                    selectedStroke="#1ba3dc"
                    carouselStroke="#32b122"
                    firstCarouselStroke="#227916"
                    lane={lane}
                    lanes={lanes}
                    corridorLocations={corridorLocations}
                    setCorridorLocations={setCorridorLocations}
                    carouselLocations={carouselLocations}
                  />
                }
              />
            </Line>
          </Fragment>
        ))}
      </LineChart>
    </div>
  ) : null;
};
