import React from 'react';
import { EnhancedUptime, SingleEnhancedUptime } from 'domain/use-cases/processUptime.use-case';
import { UptimeMetrics, DisplayUptimeMetrics } from './DisplayUptimeMetrics';

const SingleUptimeMetricRow = ({
  uptimeData,
  metric
}: {
  uptimeData: EnhancedUptime[];
  metric: UptimeMetrics;
}) => (
  <>
    {uptimeData.map((waleUptime, index) => {
      const { waleId } = waleUptime;
      const displayWaleId = <td>{waleId}</td>;

      const displayMetricName = index === 0 ? <td rowSpan={uptimeData.length}>{metric}</td> : null;

      const displayMetric: (slotUptime: SingleEnhancedUptime) => JSX.Element =
        metric === UptimeMetrics.LidarMinutes || metric === UptimeMetrics.VideoMinutes
          ? DisplayUptimeMetrics[metric](waleId)
          : DisplayUptimeMetrics[metric];

      return (
        <tr key={waleId}>
          {displayMetricName}
          {displayWaleId}
          {waleUptime.timeSlots.map(displayMetric)}
        </tr>
      );
    })}
  </>
);

export const UptimeDataByMetric = ({ uptimeData }: { uptimeData: EnhancedUptime[] }) => {
  const numberOfWales = uptimeData.length;
  if (!numberOfWales) return null;

  return (
    <>
      <SingleUptimeMetricRow uptimeData={uptimeData} metric={UptimeMetrics.DetectionsCount} />
      <SingleUptimeMetricRow uptimeData={uptimeData} metric={UptimeMetrics.LidarNormal} />
      <SingleUptimeMetricRow uptimeData={uptimeData} metric={UptimeMetrics.LidarMinutes} />
      <SingleUptimeMetricRow uptimeData={uptimeData} metric={UptimeMetrics.VideoMinutes} />
      <SingleUptimeMetricRow uptimeData={uptimeData} metric={UptimeMetrics.StatusesCount} />
    </>
  );
};
