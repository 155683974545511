import jszip from 'jszip';
import saveAs from 'file-saver';

export interface DownloadInfo {
  url: string;
  fileName: string;
}

const S3_REQUEST_HEADER: RequestInit = {
  // solve CORS problem
  method: 'GET',
  mode: 'cors',
  cache: 'no-cache',
  credentials: 'same-origin'
};

export const downloadLinksAsZip = async (downloadList: DownloadInfo[], folderName: string) => {
  if (downloadList.length === 0) return;
  const zip = jszip();

  const folder = zip.folder(folderName);
  if (!folder) return;

  // fetch every item to download
  const blobs = await Promise.all(
    downloadList.map((item) => fetch(item.url, S3_REQUEST_HEADER).then((res) => res.blob()))
  );

  // Store downloaded items in folder
  await Promise.all(
    blobs.map(async (blob, index) => {
      const { fileName } = downloadList[index];
      folder.file(fileName, blob);
    })
  );

  // Download zip
  zip.generateAsync({ type: 'blob' }).then((content) => {
    saveAs(content, `${folderName}.zip`);
  });
};
