import moment from 'moment';
import { getDailyMetrics } from 'data/dailyMetrics.repository';
import { getLidarLogs } from 'data/lidarLog.repository';
import { getVideos } from 'data/video.repository';
import { getWaleStatusesCount } from 'data/waleStatus.repository';
import { LidarLog } from 'domain/entities/lidarLog.entity';
import { DailyMetrics } from 'domain/entities/dailyMetrics.entity';
import { Video } from 'domain/entities/video.entity';
import { WaleStatusUptime } from 'domain/entities/waleStatus.entity';

export interface RawUptimeData {
  waleId: string;
  dailyMetrics: DailyMetrics | null;
  lidarLogs: LidarLog[];
  videos: Video[];
  statuses: WaleStatusUptime[];
}

const UPTIME_MAX_CONCURRENT_CALLS = 10;

export const getUptimeDataUseCase = async (waleId: string, date: Date): Promise<RawUptimeData> => {
  const endDate = moment(date).add(23, 'hours').add(59, 'minutes').add(59, 'seconds').toDate();
  const [dailyMetrics, lidarLogs, videos, statuses] = await Promise.all([
    getDailyMetrics(date, endDate, waleId, 1).then((data) => (data.length ? data[0] : null)),
    getLidarLogs(date, endDate, waleId),
    getVideos(date, endDate, waleId),
    getWaleStatusesCount(date, endDate, waleId)
  ]);

  return {
    waleId,
    dailyMetrics,
    lidarLogs,
    videos,
    statuses
  };
};

export const getUptimeDataMultipleWALEs = async (waleIds: string[], date: Date) => {
  const returnList: RawUptimeData[] = [];
  const waleIdsClone = [...waleIds];
  while (waleIdsClone.length) {
    // eslint-disable-next-line no-await-in-loop
    const tempUptimeData = await Promise.all(
      waleIdsClone
        .splice(0, UPTIME_MAX_CONCURRENT_CALLS)
        .map((waleId) => getUptimeDataUseCase(waleId, date))
    );
    returnList.push(...tempUptimeData);
  }
  return returnList;
};
