import { VehicleEventMetrics } from '../waleDetection.entity';

export const VEHICLE_EVENT_METRICS_SAMPLE: VehicleEventMetrics = {
  alprAvg: 100,
  alprStd: 0,
  blurAvg: 0,
  blurStd: 0,
  laplaceBlurry: 0,
  emptyCaptures: 0,
  emptyEvents: 0,
  bright: 0,
  dark: 0
};
