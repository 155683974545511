
import snakeCaseKeys from 'snakecase-keys';

const serializeJsonToString = (obj: any) => {
  if (!obj) return '';
  return Object.keys(obj)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
    .join('&');
};

export const paramsGetRequest = (params: any, omitStartSymbol = false) => {
  const paramsUrl = serializeJsonToString(snakeCaseKeys(params));
  return paramsUrl !== '' ? `${omitStartSymbol ? '' : '?'}${paramsUrl}` : '';
};
