/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import { DownloadInfo, downloadLinksAsZip } from 'presentation/helpers/download';

export enum Color {
  Up = '#32b122', // green
  Down = 'red',
  Out = 'orange',
  Default = 'transparent'
}

export const GenericMetricDisplay = (
  text: number | string,
  color: Color,
  start: Date,
  zipName?: string,
  downloadUrls?: DownloadInfo[]
) => {
  const key = start.toISOString();

  if (!downloadUrls || downloadUrls.length === 0) {
    return (
      <td style={{ background: color }} key={key}>
        {text}
      </td>
    );
  }

  return (
    <td style={{ background: color }} key={key}>
      <Link to="#" onClick={() => downloadLinksAsZip(downloadUrls, zipName ?? 'zip')}>
        {text}
      </Link>
    </td>
  );
};
