import moment from 'moment';
import { useEffect, useState } from 'react';

import { TrafficData } from 'domain/entities/trafficData.entity';
import { getTrafficChartUseCase } from 'domain/use-cases/traffic/getTraffic.use-case';
import { useEndDate } from 'presentation/hooks/useEndDate';
import useInterval from 'presentation/hooks/useInterval';
import { roadTimeObject, getRoadTimeMins, getStartOfDay } from 'services/timeUtils';
import { genericErrorLog } from 'data/api/genericErrorMessage';
import { convertedSpeed } from '../components/Lanes/Lane';

const TRAFFIC_SERIES_INTERVAL = 30 * 60 * 1000;
// TODO dont give a time range to the server, but have the server decide it instead?
const START_TIME = 7;
const END_TIME = 19;
const DEFAULT_SPEED = 65;

interface ChartData {
  fastLane: number;
  regularLane?: number;
  displayHour: string;
}

export const useTrafficTimeSeries = () => {
  const [trafficTimeSeries, setTrafficTimeSeries] = useState<TrafficData[]>([]);
  const [vehiclesPerHourChartData, setVehiclesPerHourChartData] =
    useState<ChartData[]>(emptyChartData);
  const [speedChartData, setSpeedChartData] = useState<ChartData[]>(emptyChartData);
  const [tollRateChartData, setTollRateChartData] = useState<ChartData[]>(emptyChartData);
  const [chartLabels, setChartLabels] = useState<{ displayHour: string }[]>(emptyChartData);
  const endDate = useEndDate();

  useInterval(() => {
    let start = moment(getStartOfDay(endDate)).add(START_TIME, 'hours').toDate();
    let end = moment(getStartOfDay(endDate)).add(END_TIME, 'hours').toDate();

    if (moment(getStartOfDay(start)).add(START_TIME, 'hours').isAfter(moment(endDate))) {
      start = moment(start).subtract(1, 'days').toDate();
      end = moment(end).subtract(1, 'days').toDate();
    }

    getTrafficChartUseCase(start, end)
      .then((data) => {
        const realData = data.filter(
          (item) => roadTimeObject(item.intervalEnd) <= roadTimeObject(endDate)
        );
        return setTrafficTimeSeries(realData);
      })
      .catch(genericErrorLog);
  }, TRAFFIC_SERIES_INTERVAL);

  useEffect(() => {
    if (trafficTimeSeries.length === 0) return;

    const labels = trafficTimeSeries.map(mapToChartLabels);
    setChartLabels(labels);

    const addLabels = zipObjectArrays<ChartData>(labels);

    const vehiclesPerHour = trafficTimeSeries
      .map((data) => ({
        fastLane: Math.round(data.fastLane.vehiclesPerHour),
        regularLane: Math.round(data.regularLane.vehiclesPerHour)
      }))
      .map(addLabels);

    const speed = trafficTimeSeries
      .map((data) => ({
        fastLane: convertedSpeed(data.fastLane.speedKmHour) || DEFAULT_SPEED,
        regularLane: convertedSpeed(data.regularLane.speedKmHour) || DEFAULT_SPEED
      }))
      .map(addLabels);

    const tollRate = trafficTimeSeries.map((data) => ({ fastLane: data.tollRate })).map(addLabels);

    setVehiclesPerHourChartData(vehiclesPerHour);
    setSpeedChartData(speed);
    setTollRateChartData(tollRate);
  }, [trafficTimeSeries]);

  return { vehiclesPerHourChartData, speedChartData, tollRateChartData, chartLabels };
};

const mapToChartLabels = (data: TrafficData): Pick<ChartData, 'displayHour'> => ({
  displayHour: getRoadTimeMins(data.intervalStart)
});

const zipObjectArrays =
  <T>(firstArray: Record<string, unknown>[]) =>
  (second: Record<string, unknown>, index: number) =>
    ({
      ...second,
      ...firstArray[index]
    } as unknown as T);

const emptyChartData: { displayHour: string; fastLane: number; regularLane: number }[] = [];
