import React from 'react';
import { Switch } from 'react-router-dom';
import { WaleMonitor } from 'presentation/WaleMonitor/WaleMonitor.view';
import { WaleUptimeView } from 'presentation/WaleUptime/WaleUptime.view';
import { WaleEvents } from 'presentation/WaleEvents/WaleEvents';
import { TollingAndTraffic } from 'presentation/TollingAndTraffic/TollingAndTraffic';
import TripVisualizer from 'presentation/TripVisualizer/TripVisualizer';
import { Tokens } from 'presentation/TokenGenerator/Tokens';
import EboxWaleLinking from 'presentation/EboxWalePairing/EboxWaleLinking';
import CreateWale from 'presentation/CreateWale/CreateWale';
import Overview from 'presentation/Overview/Overview';
import { LabelingMain } from 'presentation/LabelWindow/LabelingMain';
import { LoggedLayout, NavbarLess } from './services/layouts';
import {
  ChangePassword,
  Login,
  RecoverPassword,
  ResetPassword,
  Profile,
  UserEdit,
  UserIndex,
  UserNew,
  WaleSettings,
  LabelsIndex
} from './pages';

const routes = (
  <Switch>
    <LoggedLayout exact path="/" component={Overview} title="Roadside" />
    <NavbarLess exact path="/login" component={Login} />
    <LoggedLayout exact path="/profile" component={Profile} title="Profile" />
    <LoggedLayout
      exact
      path="/profile/change_password"
      component={ChangePassword}
      title="Change Password"
    />
    <NavbarLess exact path="/recover_password" component={RecoverPassword} />
    <NavbarLess exact path="/reset_password/:token" component={ResetPassword} />
    <LoggedLayout exact path="/roadside" component={Overview} title="Roadside" />
    <LoggedLayout
      exact
      path="/traffic_metrics"
      component={TollingAndTraffic}
      title="Traffic Metrics"
    />
    <LoggedLayout exact path="/users" component={UserIndex} title="Users" />
    <LoggedLayout exact path="/users/:id/edit" component={UserEdit} title="Edit User" />
    <LoggedLayout exact path="/users/new" component={UserNew} title="Create User" />
    <LoggedLayout exact path="/wale/events" component={WaleEvents} title="WALE Events" />
    <LoggedLayout exact path="/wale/labels" component={LabelsIndex} title="Labels" />
    <LoggedLayout exact path="/wale/monitor" component={WaleMonitor} title="WALE Monitor" />
    <LoggedLayout exact path="/wale/settings" component={WaleSettings} title="WALE Settings" />
    <LoggedLayout exact path="/wale/uptime" component={WaleUptimeView} title="WALE Uptime" />
    <LoggedLayout exact path="/wale/create" component={CreateWale} title="WALE Creation" />
    <LoggedLayout exact path="/events/label_batch" component={LabelingMain} title="Event Labeling" />
    <LoggedLayout exact path="/trips" component={TripVisualizer} title="Trip Building" />
    <LoggedLayout exact path="/tokens" component={Tokens} title="Tokens" />
    <LoggedLayout
      exact
      path="/ebox/linking"
      component={EboxWaleLinking}
      title="Ebox WALE Linking"
    />
  </Switch>
);

export default routes;
