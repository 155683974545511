import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAlert } from 'presentation/hooks/useAlert';
import { getUsersIndexUseCase } from 'domain/use-cases/users/getUsersIndex.use-case';
import { deleteUserUseCase } from 'domain/use-cases/users/deleteUser.use-case';
import ComponentDataTable from '../../components/DataTable';
import { DefaultModal } from '../../components';

const UserDataTable = ({ columns }: any) => {
  const [amount, setAmount] = useState(0);
  const [moreData, setMoreData] = useState(false);
  const [onRequest, setOnRequest] = useState(false);
  const [users, setUsers] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [modalItem, setModalItem] = useState<any>(null);
  const [modalBody, setModalBody] = useState('');
  const history = useHistory();
  const { sendAlert } = useAlert();

  const handleFailureRequest = (error: any) => {
    setOnRequest(false);
    sendAlert({ type: 'error', message: error?.response?.data?.error });
  };

  const handleSuccessRequest = (data: any) => {
    setUsers(data.docs);
    setAmount(data.totalDocs);
    setOnRequest(false);
  };

  const handleIndexRequest = (params: any) => {
    setOnRequest(true);
    getUsersIndexUseCase(params).then(handleSuccessRequest).catch(handleFailureRequest);
  };

  const handleModalClose = () => {
    setModalShow(false);
  };

  const handleSuccessRemove = () => {
    handleModalClose();
    sendAlert({ type: 'success', message: 'User deleted successfully' });
    setMoreData(!moreData);
  };

  const handleModalConfirm = () => {
    if (!modalItem) return;
    deleteUserUseCase(modalItem._id).then(handleSuccessRemove).catch(handleFailureRequest);
  };

  const handleButtonClick = (item: any, action: any) => {
    switch (action) {
      case 'edit':
        history.push(`/users/${item._id}/edit`);
        break;
      case 'destroy':
        setModalShow(true);
        setModalItem(item);
        setModalBody('You are about to delete this user. Are you sure?');
        break;
      default:
        sendAlert({ type: 'error', message: 'Action not found' });
    }
  };

  return (
    <>
      <ComponentDataTable
        onRequest={onRequest}
        columns={columns(handleButtonClick)}
        data={users}
        totalRows={amount}
        moreData={moreData}
        resourceRequest={(response: any) => {
          if (!onRequest) handleIndexRequest(response);
        }}
      />
      <DefaultModal
        title="Delete User"
        body={modalBody}
        show={modalShow}
        handleClose={handleModalClose}
        handleConfirm={handleModalConfirm}
        titleBtnClose="Cancel"
        titleBtnSave="Confirm"
      />
    </>
  );
};

export default UserDataTable;
