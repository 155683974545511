export enum UserRole {
  Admin = 1,
  Basic = 2,
  Agency = 3,
  Reviewer = 5
}

export const roleName = (role: number): string => {
  if (role === UserRole.Admin) return 'Admin';
  if (role === UserRole.Basic) return 'Basic';
  if (role === UserRole.Agency) return 'Agency';
  if (role === UserRole.Reviewer) return 'Reviewer';
  return 'Unknown Role';
};

export const isInternal = (role?: number) => [UserRole.Admin, UserRole.Basic].includes(role ?? 0);
