import React, { useState } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { copyToClipboard, setTooltipMessage } from 'services/generalUtils';

const CopyToClipboardButton = ({ content }: { content: string }) => {
  const [tooltipText, setTooltipText] = useState('');
  const onCopyButtonClick = () =>
    copyToClipboard(
      content,
      () => setTooltipMessage('Copied!', setTooltipText),
      () => setTooltipMessage('Failed to copy, please copy the content manually.', setTooltipText)
    );

  return (
    <OverlayTrigger
      placement="bottom"
      show={tooltipText.length > 0}
      overlay={<Tooltip id="token-tooltip">{tooltipText}</Tooltip>}
    >
      <Button onClick={onCopyButtonClick} className="mt-4 btn-dark">
        Copy to Clipboard
      </Button>
    </OverlayTrigger>
  );
};

export default CopyToClipboardButton;
