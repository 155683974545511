import { useEffect, useLayoutEffect, useRef } from 'react';

/**
 * declarative set interval
 * @see  https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 */
export const useInterval = (callback: () => void, delay: number) => {
  const savedCallback = useRef(callback);

  // Remember the latest callback if it changes.
  useLayoutEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    // Note: 0 is a valid value for delay.
    if (!delay && delay !== 0) {
      return;
    }

    const id = setInterval(() => savedCallback.current(), delay);

    // eslint-disable-next-line consistent-return
    return () => clearInterval(id);
  }, [delay]);

  useEffect(() => {
    // run the callback at the first render
    callback();
  }, []);
};

export default useInterval;
