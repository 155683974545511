import { API } from './api';

enum Path {
  REFRESH_TOKEN = '/auth/refresh'
}

export const refreshAuthToken = () =>
  API.post<{ accessToken: string; refreshToken: string }>(Path.REFRESH_TOKEN, {
    params: {
      refreshToken: localStorage.getItem('jwtRefresh')
    }
  })
    .then((response) => response.data)
    .catch(() => undefined);
