import { createTheme } from 'react-data-table-component';

createTheme('blissway', {
  text: {
    primary: '#eee',
    secondary: '#737386'
  },
  background: {
    default: '#2c2c3d'
  },
  striped: {
    default: '#33334a',
    text: 'white'
  },
  context: {
    background: '#fff',
    text: '#737386'
  },
  divider: {
    default: '#16161b'
  },
  button: {
    default: '#fff',
    focus: '#1ba3dc',
    hover: '#1ba3dc',
    disabled: '#9696a0'
  },
  action: {
    button: '#1ba3dc',
    hover: '#1ba3dc',
    disabled: '#9696a0'
  }
});
