/* eslint-disable react/jsx-props-no-spreading */
import { VehicleEventMetrics } from 'domain/entities/waleDetection.entity';
import { eventCountForMetrics } from 'domain/use-cases/waleDetection/getVehicleEventMetrics.use-case';
import { range } from 'lodash';
import React from 'react';
import {
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  LabelList
} from 'recharts';

const DESCRIPTIONS: Record<string, (a: number) => string> = {
  ALPR: (eventCount: number) =>
    `Average ALPR confidence score of all non-empty captures of the past ${eventCount} events`,
  Bright: (eventCount: number) =>
    `% of events in the past ${eventCount} events whose darkest plate is not too bright (bright_cvar < 220)`,
  Dark: (eventCount: number) =>
    `% of events in the past ${eventCount} events whose brightest plate is not too dark (bright_cvar > 75)`,
  Blur: (eventCount: number) =>
    `% of events in the past ${eventCount} events whose sharpest plate is not blurry (laplace_blur < 0.4)`,
  Empty: (eventCount: number) =>
    `% of captures and events in the past ${eventCount} events that have a plate reading`
};

const RANGES: Record<string, string[]> = {
  ALPR: ['0', '100'],
  Bright: ['0', '100'],
  Dark: ['0', '100'],
  Blur: ['0', '100'],
  Empty: ['0', '100']
};

const commonYAxisProps = {
  type: 'category' as const,
  dataKey: 'name',
  interval: 0,
  tickMargin: -3,
  tickLine: false,
  axisLine: false,
  fontSize: 11
};

type ChartValueFields = {
  name: string;
  firstBar: number;
  secondBar: number;
  thirdBar: number;
  realAvg?: number;
  realStd?: number;
};

const leftYAxisWidth = 5 + 10 * Math.max(...Object.values(RANGES).map((item) => item[0].length));
const rightYAxisWidth = 5 + 10 * Math.max(...Object.values(RANGES).map((item) => item[1].length));

// Functions used for the blurriness custom ranges
const normalizedSlope = (initRange: number, endRange: number) => 100 / (endRange - initRange);
const normalizeValue = (value: number, initRange: number, endRange: number) =>
  normalizedSlope(initRange, endRange) * (value - initRange);

/** Takes the data from the endpoints and formats it for the charts to be displayed */
const adaptMetricsDataForCharts = (metricsData: VehicleEventMetrics): ChartValueFields[] => {
  const alprChartData = {
    name: 'ALPR',
    firstBar: Math.round(metricsData.alprAvg) - Math.round(metricsData.alprStd),
    secondBar: Math.round(metricsData.alprStd) * 2,
    thirdBar: 0,
    realAvg: Math.round(metricsData.alprAvg),
    realStd: Math.round(metricsData.alprStd)
  };
  const blurrinessChartData = {
    name: 'Blur',
    firstBar: Math.round((1 - metricsData.laplaceBlurry) * 100),
    secondBar: 0,
    thirdBar: 0
  };

  const alprEmptyEventsData = {
    name: 'Empty',
    firstBar: Math.round((1 - metricsData.emptyCaptures) * 100),
    secondBar: 0,
    thirdBar: Math.floor(Math.max(0, metricsData.emptyCaptures - metricsData.emptyEvents) * 100)
  };

  const brightChartData = {
    name: 'Bright',
    firstBar: Math.round((1 - metricsData.bright) * 100),
    secondBar: 0,
    thirdBar: 0
  };
  const darkChartData = {
    name: 'Dark',
    firstBar: Math.round((1 - metricsData.dark) * 100),
    secondBar: 0,
    thirdBar: 0
  };

  const metrics = [
    alprChartData,
    alprEmptyEventsData,
    blurrinessChartData,
    brightChartData,
    darkChartData
  ];

  // Recharts doesn't allow having bars next to each other with different ranges, so
  // we have to normalize all metrics so their range goes from 0 to 100 for everything.
  return metrics.map((chartData) => ({
    ...chartData,
    firstBar: normalizeValue(
      chartData.firstBar,
      Number(RANGES[chartData.name][0]),
      Number(RANGES[chartData.name][1])
    ),
    secondBar:
      chartData.secondBar *
      normalizedSlope(Number(RANGES[chartData.name][0]), Number(RANGES[chartData.name][1])),
    thirdBar:
      chartData.thirdBar *
      normalizedSlope(Number(RANGES[chartData.name][0]), Number(RANGES[chartData.name][1]))
  }));
};

export const CaptureMetricsChart = ({
  metricsData,
  waleId,
  lane
}: {
  metricsData: VehicleEventMetrics;
  waleId: string;
  lane?: number;
}) => {
  const data = adaptMetricsDataForCharts(metricsData);
  const eventCount = eventCountForMetrics(waleId, lane);
  return (
    <ResponsiveContainer width="100%" height={55}>
      <BarChart
        data={data}
        {...(data.length === 0 && { style: { display: 'none' } })}
        layout="vertical"
        margin={{
          top: 0,
          right: 0,
          left: 0,
          bottom: 0
        }}
      >
        <CartesianGrid
          strokeDasharray="1 1"
          horizontal={false}
          verticalCoordinatesGenerator={({
            offset: { width, left }
          }: {
            offset: { width: number; left: number };
          }) =>
            // Sets three vertical grid lines: at 25%, 50% and 75%
            range(left, width, width / 4).filter((x) => x !== left)
          }
        />
        <XAxis type="number" domain={[0, 100]} allowDataOverflow hide />
        <YAxis
          yAxisId="low"
          tickFormatter={(value: string) => {
            if (value in RANGES) return RANGES[value][0];
            return value;
          }}
          width={leftYAxisWidth}
          {...commonYAxisProps}
        />
        <YAxis
          orientation="right"
          yAxisId="high"
          tickFormatter={(value: string) => {
            if (value in RANGES) return RANGES[value][1];
            return value;
          }}
          width={rightYAxisWidth}
          {...commonYAxisProps}
        />
        <Tooltip
          contentStyle={{ border: 'none', background: '#363646' }}
          itemStyle={{ color: '#9696a0' }}
          formatter={(
            value: number,
            name: string,
            {
              payload: { realAvg, realStd, secondBar, name: fieldName, firstBar, thirdBar }
            }: {
              payload: ChartValueFields;
            }
          ) => {
            if (fieldName === 'Empty') {
              if (name === 'firstBar') return [`${firstBar}%`, 'Captures with a plate'];
              // still use second bar because otherwise there's empty space between lines
              if (name === 'secondBar') return [`${firstBar + thirdBar}%`, 'Events with a plate'];
            }
            if (name === 'firstBar') return [realAvg ?? value + secondBar / 2, 'Average'];
            if (name === 'secondBar' && (realStd || value))
              return [realStd ?? value / 2, 'Standard Deviation'];
            return [null, null];
          }}
          labelFormatter={(label: string) => DESCRIPTIONS[label](eventCount) ?? label}
          wrapperStyle={{ zIndex: 20 }}
          cursor={false}
        />
        <Bar
          dataKey="firstBar"
          stackId="a"
          fill="#053c5e"
          yAxisId="low"
          background={{ fill: '#db222a' }}
          isAnimationActive={false}
        >
          <LabelList
            fill="white"
            valueAccessor={(elem: ChartValueFields) => {
              if (elem.name === 'Empty') return elem.firstBar;
              return null;
            }}
            fontSize={11}
          />
        </Bar>
        <Bar dataKey="secondBar" stackId="a" fill="#1f7a8c" yAxisId="low" isAnimationActive={false}>
          <LabelList
            fill="white"
            valueAccessor={(elem: ChartValueFields) => {
              if (elem.name === 'Empty') return null;
              return elem.realAvg ?? elem.firstBar + elem.secondBar / 2;
            }}
            fontSize={11}
          />
        </Bar>
        <Bar dataKey="thirdBar" stackId="a" fill="#A25D97" yAxisId="low" isAnimationActive={false}>
          <LabelList
            fill="white"
            valueAccessor={(elem: ChartValueFields) => {
              if (elem.name === 'Empty') return elem.firstBar + elem.thirdBar;
              return null;
            }}
            fontSize={11}
          />
        </Bar>
        <YAxis yAxisId="labels" {...commonYAxisProps} mirror style={{ fill: 'white' }} />
      </BarChart>
    </ResponsiveContainer>
  );
};
