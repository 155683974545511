import React from 'react';
import CorridorStatus from './CorridorStatus';
import { CorridorOverview } from './Overview.view-model';

type CorridorAlertsProps = {
  corridors: Array<CorridorOverview>;
};

const CorridorAlerts = ({ corridors }: CorridorAlertsProps) => (
  <>
    {corridors.map(({ value, label, status, wales, alertCounts }) => (
      <CorridorStatus
        key={value}
        value={value}
        label={label}
        status={status}
        wales={wales}
        alertCounts={alertCounts}
      />
    ))}
  </>
);

export default CorridorAlerts;
