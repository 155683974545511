import React from 'react';
import { OperationStatus } from './OperationStatusIndicator';

type AlertCountProps = {
  alertCounts: Record<OperationStatus, number>;
};

const AlertCount = ({ alertCounts }: AlertCountProps) => {
  const alertTextComponents = [
    alertCounts['degraded-system'] > 0 ? (
      <span key="degraded-system" className="text-red-400">
        {alertCounts['degraded-system']}
      </span>
    ) : null,
    alertCounts['degraded-metrics'] > 0 ? (
      <span key="degraded-metrics" className="text-yellow-400">
        {alertCounts['degraded-metrics']}
      </span>
    ) : null,
    alertCounts['underperforming-metrics'] > 0 ? (
      <span key="underperforming-metrics" className="text-purple-400">
        {alertCounts['underperforming-metrics']}
      </span>
    ) : null,
    alertCounts.operational > 0 ? (
      <span key="operational" className="text-green-400">
        {alertCounts.operational}
      </span>
    ) : null
  ].filter(Boolean);

  return (
    <p className="m-0 text-base">
      <span>(&nbsp;</span>
      {alertTextComponents.map((text, index) => (
        <React.Fragment key={text?.key}>
          {text}
          {index < alertTextComponents.length - 1 ? ' / ' : null}
        </React.Fragment>
      ))}
      <span>&nbsp;)</span>
    </p>
  );
};

export default AlertCount;
