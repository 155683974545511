import { Video } from 'domain/entities/video.entity';
import { API } from './api';

enum Path {
  VIDEOS = '/videos'
}

export const getVideos = (startDate: Date, endDate: Date, waleId: string): Promise<Video[]> =>
  API.get<Video[]>(Path.VIDEOS, {
    params: {
      start_date: startDate.valueOf(),
      end_date: endDate.valueOf(),
      wale_id: waleId,
      limit: 1000
    }
  }).then((response) => response.data);
