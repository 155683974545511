import moment from 'moment';

const MIN_AVERAGE_SAMPLE_FREQ_HZ = 97;
const MAX_WORST_HICCUP_SECS = 5;

export type BasicStats = {
  minValues: number[];
  maxValues: number[];
  mean: number;
  std: number;
};

export type LidarMetrics = {
  distance: BasicStats & {
    belowThreshold: number;
    aboveThreshold: number;
  };
  strength: BasicStats;
  triggers: {
    count: number;
    timeDiffSecs?: BasicStats;
  };
  sampling: {
    sampleCount: number;
    logDurationSecs: number;
    averageSampleFreqHz: number;
  };
  worstHiccup: {
    durationSecs: number;
    sampleNumber: number;
    at: Date;
  };
};

export type LidarConfig = {
  triggerOn: boolean;
  logging: boolean;
  logFileTime: number;
  winsize: number;
  posedge: number;
  negedge: number;
  mindist: number;
  maxdist: number;
  lowerThreshold: number;
  upperThreshold: number;
};

export interface LidarLog {
  waleId: string;
  eboxId: string;
  startedAt: Date;
  endedAt?: Date;
  lidarConfig: LidarConfig;
  lidarMetrics?: LidarMetrics;
  filename: string;
  downloadUrl: string;
}

export const getLidarLogDurationMins = (lidarLog: LidarLog): number =>
  (lidarLog.lidarMetrics?.sampling.logDurationSecs ??
    (lidarLog.endedAt
      ? moment.duration(moment(lidarLog.endedAt).diff(moment(lidarLog.startedAt))).asSeconds()
      : 0)) / 60;

export const isLidarLogSignalNormal = (lidarLog: LidarLog): boolean =>
  lidarLog.lidarMetrics
    ? lidarLog.lidarMetrics.sampling.averageSampleFreqHz >= MIN_AVERAGE_SAMPLE_FREQ_HZ && // good sampling
      lidarLog.lidarMetrics.worstHiccup.durationSecs <= MAX_WORST_HICCUP_SECS && // short hiccups
      lidarLog.lidarMetrics.distance.maxValues[0] > lidarLog.lidarConfig.mindist && // lidar not dirty
      lidarLog.lidarMetrics.distance.minValues[0] < lidarLog.lidarConfig.maxdist && // lidar positioned correctly
      lidarLog.lidarMetrics.distance.minValues[0] !== -1 && // no -1 ('corrupted') samples
      lidarLog.lidarMetrics.distance.std > 0 // at least 2 different distance samples
    : false;
