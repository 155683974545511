import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useAlert } from 'presentation/hooks/useAlert';
import { SortParams } from './EboxWalePairTypes';
import { useEboxWalePairs } from './EboxWaleLinking.view-model';
import LinkedPairs from './LinkedPairs/LinkedPairs';
import LinkEboxAndWale from './LinkEboxAndWale';

const EboxWaleLinking = () => {
  const [reFetch, setReFetch] = useState(0);
  const [sortParams, setSortParams] = useState<SortParams>({ field: 'eboxName', ascending: true });
  const { completeEboxWalePairs, looseEboxes, looseWales } = useEboxWalePairs(reFetch, sortParams);
  const { sendAlert } = useAlert();
  const triggerFetch = () => {
    setReFetch(reFetch + 1);
  };

  return (
    <Row className="full-height">
      <Col className="bliss-card m-3 tokens-overscroll">
        <Col lg={{ span: 10, offset: 1 }}>
          <LinkEboxAndWale
            looseEboxes={looseEboxes}
            looseWales={looseWales}
            triggerFetch={triggerFetch}
            sendAlert={sendAlert}
          />
          <LinkedPairs
            sortParams={sortParams}
            setSortParams={setSortParams}
            completeEboxWalePairs={completeEboxWalePairs}
            triggerFetch={triggerFetch}
            sendAlert={sendAlert}
          />
        </Col>
      </Col>
    </Row>
  );
};

export default EboxWaleLinking;
