import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { ArrowLeft } from 'react-feather';

type OwnProps = {
  route?: string;
  text?: string;
};

// @ts-ignore ts-migrate(2456) FIXME: Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof BackToHomeBtn.defaultProps;

// @ts-ignore ts-migrate(7022) FIXME: 'BackToHomeBtn' implicitly has type 'any' because ... Remove this comment to see the full error message
const BackToHomeBtn = ({ route, text }: Props) => (
  <Link to={route} className="text-blue text-medium flex items-center">
    <Button size="sm" className="btn-circle" type="button">
      <ArrowLeft size={15} />
    </Button>
    {text}
  </Link>
);

BackToHomeBtn.defaultProps = {
  route: '/',
  text: 'Home'
};
export default BackToHomeBtn;
