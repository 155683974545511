/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { getWaleLanes } from 'presentation/helpers/waleId';
import { union } from 'lodash';
import { useWaleStatus } from './SingleWaleMonitor.view-model';
import FrontBackMonitor, {
  AscendingMileage,
  FrontBackPairDrilledProps,
  SingleWaleMonitorPropsObject
} from './FrontBackMonitor';

type SingleLocationMonitorProps = AscendingMileage & DisplayFrontBackMonitorPropsObject;

type DisplayFrontBackMonitorPropsObject = {
  frontBackProps: FrontBackPairDrilledProps;
};
interface LaneAndWaleFilteringAndSplittingParams {
  front?: string;
  back?: string;
  hiddenWaleLanes: { waleId: string; lane: number }[];
  noEmptySpace: boolean;
  splitLanes: boolean;
}

type SingleLocationMonitorInterface = LaneAndWaleFilteringAndSplittingParams &
  SingleWaleMonitorPropsObject &
  SingleLocationMonitorProps;

const filterAndSplitLanesAndWales = ({
  splitLanes,
  hiddenWaleLanes,
  front,
  back,
  isAscendingMileage,
  noEmptySpace
}: LaneAndWaleFilteringAndSplittingParams & AscendingMileage) => {
  if (!splitLanes) return [{ front, back }];

  const realWaleLanePairs: {
    lane?: number;
    front?: string;
    back?: string;
  }[] = [];
  const frontHiddenLanes = hiddenWaleLanes
    .filter((waleLane) => waleLane.waleId === front)
    .map((waleLane) => waleLane.lane);
  const backHiddenLanes = hiddenWaleLanes
    .filter((waleLane) => waleLane.waleId === back)
    .map((waleLane) => waleLane.lane);

  const frontWaleLanes = getWaleLanes(front).filter(
    (waleLane) => !frontHiddenLanes.includes(waleLane)
  );
  const backWaleLanes = getWaleLanes(back).filter(
    (waleLane) => !backHiddenLanes.includes(waleLane)
  );

  const allReadLanes = union(frontWaleLanes, backWaleLanes);
  allReadLanes.sort();

  if (!isAscendingMileage) {
    frontWaleLanes.reverse();
    backWaleLanes.reverse();
    allReadLanes.reverse();
  }

  if (noEmptySpace) {
    if (isAscendingMileage)
      frontWaleLanes.forEach((lane) => realWaleLanePairs.push({ lane, front }));
    backWaleLanes.forEach((lane) => realWaleLanePairs.push({ lane, back }));
    if (!isAscendingMileage)
      frontWaleLanes.forEach((lane) => realWaleLanePairs.push({ lane, front }));
  } else {
    allReadLanes.forEach((lane) => {
      realWaleLanePairs.push({
        lane,
        ...(frontWaleLanes.includes(lane) && { front }),
        ...(backWaleLanes.includes(lane) && { back })
      });
    });
  }
  return realWaleLanePairs;
};

const SingleLocationMonitor = ({
  front,
  back,
  isAscendingMileage,
  noEmptySpace,
  splitLanes,
  hiddenWaleLanes,
  frontBackProps,
  singleMonitorProps
}: SingleLocationMonitorInterface) => {
  const frontStatus = useWaleStatus(front);
  const backStatus = useWaleStatus(back);

  const realWaleLanePairs = filterAndSplitLanesAndWales({
    splitLanes,
    hiddenWaleLanes,
    front,
    back,
    isAscendingMileage,
    noEmptySpace
  });

  return (
    <>
      {realWaleLanePairs.map((pair) => {
        const newSingleMonitorProps = { ...singleMonitorProps };
        if (
          noEmptySpace &&
          front &&
          back &&
          ((!isAscendingMileage && pair.front) || (isAscendingMileage && pair.back))
        )
          newSingleMonitorProps.index += 1;

        return (
          <FrontBackMonitor
            key={`front-back-${pair.back}-${pair.front}-${pair.lane}`}
            isAscendingMileage={isAscendingMileage}
            pair={pair}
            frontStatus={frontStatus}
            backStatus={backStatus}
            singleMonitorProps={newSingleMonitorProps}
            {...frontBackProps}
          />
        );
      })}
    </>
  );
};
export default SingleLocationMonitor;
