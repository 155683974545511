export enum PlateState {
  // US States
  Alabama = 'us-al',
  Alaska = 'us-ak',
  Arizona = 'us-az',
  Arkansas = 'us-ar',
  California = 'us-ca',
  Colorado = 'us-co',
  Connecticut = 'us-ct',
  Delaware = 'us-de',
  DC = 'us-dc',
  Florida = 'us-fl',
  Georgia = 'us-ga',
  Hawaii = 'us-hi',
  Idaho = 'us-id',
  Illinois = 'us-il',
  Indiana = 'us-in',
  Iowa = 'us-ia',
  Kansas = 'us-ks',
  Kentucky = 'us-ky',
  Louisiana = 'us-la',
  Maine = 'us-me',
  Maryland = 'us-md',
  Massachusetts = 'us-ma',
  Michigan = 'us-mi',
  Minnesota = 'us-mn',
  Mississippi = 'us-ms',
  Missouri = 'us-mo',
  Montana = 'us-mt',
  Nebraska = 'us-ne',
  Nevada = 'us-nv',
  NewHampshire = 'us-nh',
  NewJersey = 'us-nj',
  NewMexico = 'us-nm',
  NewYork = 'us-ny',
  NorthCarolina = 'us-nc',
  NorthDakota = 'us-nd',
  Ohio = 'us-oh',
  Oklahoma = 'us-ok',
  Oregon = 'us-or',
  Pennsylvania = 'us-pa',
  RhodeIsland = 'us-ri',
  SouthCarolina = 'us-sc',
  SouthDakota = 'us-sd',
  Tennessee = 'us-tn',
  Texas = 'us-tx',
  Utah = 'us-ut',
  Vermont = 'us-vt',
  Virginia = 'us-va',
  Washington = 'us-wa',
  WestVirginia = 'us-wv',
  Wisconsin = 'us-wi',
  Wyoming = 'us-wy'
}

enum ExternalReviewStatus {
  Inactive = 'inactive',
  Selected = 'selected',
  Uploaded = 'uploaded',
  Completed = 'completed'
}

export enum GasCode {
  NoError = 0,
  DisabledVeteranPurpleHeartLegionOfValor = 60,
  PaperPlate = 61,
  EmergencyVehicleSchoolBusGovernmentDiplomat = 62,
  Trailer = 63,
  OutOfCountry = 64,
  NoImageOrNoVehicle = 65,
  CameraOutOfAlignment = 67,
  BadImage = 72,
  ObstructedPlate = 74,
  NoLicensePlate = 84
}

interface BaseHumanReview {
  labeledBy: string;
  plateNumber?: string;
  makeModel?: string;
  errorCode?: GasCode;
}

export interface InternalHumanReview extends BaseHumanReview {
  plateState?: PlateState;
  completedAt: Date;
  labels?: Record<string, string[]>;
  eventLabels?: string[];
}

interface ExternalHumanReview extends BaseHumanReview {
  status: ExternalReviewStatus;
  plateState?: string;
  selectedAt?: Date;
  uploadedAt?: Date;
  completedAt?: Date;
}

export interface HumanReview {
  internal?: InternalHumanReview;
  external?: ExternalHumanReview;
}

interface PredictionScore {
  prediction: string;
  score: number;
}

interface Coordinate {
  x: number;
  y: number;
}

type PredictionScoreKey =
  | 'plateNumber'
  | 'makeModel'
  | 'bodyType'
  | 'missingPlate'
  | 'isVehicle'
  | 'year'
  | 'make'
  | 'color';

export type EngineData = {
  [key in PredictionScoreKey]: PredictionScore[];
} & {
  plateState: PredictionScore;
  coordinates: Coordinate[];
};

interface Alpr {
  engine: string;
  data: EngineData;
  raw: Record<string, unknown>;
}

interface Capture {
  _id: unknown;
  filename: string;
  alpr?: Alpr[];
  cameraParams?: Record<string, number>;
  capturedAt: Date;
  triggeredAt: Date;
  laneNumber: number;
}

export interface WaleDetection {
  triggeredAt?: Date;
  laneNumber?: number;
  createdAt?: Date;
  eventId: string;
  waleId: string;
  eboxId: string;
  imageUrl?: string;
  imageUrls?: string[];
  capturedAt: string;
  plateState?: string;
  plateNumber?: string;
  makeModel?: string;
  color?: string;
  captures?: Capture[];
  humanReview?: HumanReview;
  _id: string;
  id: string;
  source?: string;
}

export type VehicleEventMetrics = {
  alprAvg: number;
  alprStd: number;
  blurAvg: number;
  blurStd: number;
  laplaceBlurry: number;
  emptyCaptures: number;
  emptyEvents: number;
  bright: number;
  dark: number;
  maxDate?: Date;
  minDate?: Date;
};
