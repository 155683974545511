/* eslint-disable @typescript-eslint/no-explicit-any */
// Source: https://weblog.west-wind.com/posts/2014/jan/06/javascript-json-date-parsing-and-real-dates
const RE_ISO = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/;

function isIsoDateString(value: any): boolean {
  return value && typeof value === 'string' && RE_ISO.test(value);
}

export const handleDates = (data: any) => {
  if (data === null || data === undefined || typeof data !== 'object') return;

  Object.keys(data).forEach((key) => {
    const value = data[key];
    // eslint-disable-next-line no-param-reassign
    if (isIsoDateString(value)) data[key] = new Date(value);
    else if (typeof value === 'object') handleDates(value);
  });
};
