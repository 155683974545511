import React from 'react';

export const TripHeader = () => (
  <thead>
    <tr
      className="text-center justify-content-between center-row-columns"
      style={{ borderBottom: '1px solid', margin: '10px', marginTop: 0 }}
      key="top-row-trips"
    >
      <th>Trip ID</th>
      <th>Plate Number</th>
      <th>Plate State</th>
      <th>Date</th>
      <th>Time</th>
      <th>Total Fare</th>
      <th>Timeout</th>
      <th>Zones</th>
      <th>Zone Lookup</th>
    </tr>
  </thead>
);
