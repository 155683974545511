import { DefaultModal } from 'components';
import { RoadDateTimePicker } from 'components/RoadDateTimePicker/RoadDateTimePicker';
import { updateUserUseCase } from 'domain/use-cases/users/updateUser.use-case';
import { useUser } from 'presentation/hooks/data/useUser';
import { useEndDate } from 'presentation/hooks/useEndDate';
import React, { useState } from 'react';
import { Button, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { NOT_SET_DEMO_PARAM, roadTimeObject } from 'services/timeUtils';
import { validateToken } from 'store/actions/auth';

export const ChangeDemoDateModal = ({
  showModal,
  closeModal
}: {
  showModal: boolean;
  closeModal: () => void;
}) => {
  const endDate = useEndDate();
  const user = useUser();

  const userIsDemo = user.demo || sessionStorage.getItem('demoDate') !== null;
  const hourIgnoredOnStart =
    !userIsDemo ||
    (user.demoHour === NOT_SET_DEMO_PARAM && sessionStorage.getItem('demoHour') === null) ||
    sessionStorage.getItem('demoHour') === NOT_SET_DEMO_PARAM.toString();
  const minuteIgnoredOnStart =
    !userIsDemo ||
    (user.demoMinute === NOT_SET_DEMO_PARAM &&
      sessionStorage.getItem('demoMinute') === null) ||
    sessionStorage.getItem('demoMinute') === NOT_SET_DEMO_PARAM.toString();

  const [selectedDate, setSelectedDate] = useState<Date | null>(userIsDemo ? endDate : null);
  const [ignoreTime, setIgnoreTime] = useState(hourIgnoredOnStart);
  const [ignoreMinute, setIgnoreMinute] = useState(minuteIgnoredOnStart);
  const [persistChanges, setPersistChanges] = useState(false);
  const dispatch = useDispatch();

  const onConfirm = () => {
    const demo = selectedDate !== null;
    const savedDate = roadTimeObject(selectedDate);
    const demoDate = demo ? savedDate.format('YYYY-MM-DD') : '';
    const demoHour = demo && !ignoreTime ? savedDate.hour() : NOT_SET_DEMO_PARAM;
    const demoMinute = demo && !ignoreMinute ? savedDate.minute() : NOT_SET_DEMO_PARAM;

    const saveFunction = persistChanges ? saveToServer : saveToSessionStorage;
    saveFunction(demo, demoDate, demoHour, demoMinute).then(() => {
      closeModal();
    });
  };

  const clearDemoFromLocalStorage = () => {
    sessionStorage.removeItem('demoDate');
    sessionStorage.removeItem('demoHour');
    sessionStorage.removeItem('demoMinute');
  };

  const saveToServer = async (
    demo: boolean,
    demoDate: string,
    demoHour: number,
    demoMinute: number
  ) => {
    clearDemoFromLocalStorage();
    await updateUserUseCase(user.id, { user: { demo, demoDate, demoHour, demoMinute } });
    dispatch(validateToken());
  };

  const saveToSessionStorage = async (
    demo: boolean,
    demoDate: string,
    demoHour: number,
    demoMinute: number
  ) => {
    if (demo) {
      sessionStorage.setItem('demoDate', demoDate);
      sessionStorage.setItem('demoHour', demoHour.toString());
      sessionStorage.setItem('demoMinute', demoMinute.toString());
    } else {
      clearDemoFromLocalStorage();
    }
  };

  return (
    <DefaultModal
      title="Change Dashboard Date"
      body={
        <ModalBody
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          ignoreTime={ignoreTime}
          setIgnoreTime={setIgnoreTime}
          ignoreMinute={ignoreMinute}
          setIgnoreMinute={setIgnoreMinute}
          persistChanges={persistChanges}
          setPersistChanges={setPersistChanges}
        />
      }
      show={showModal}
      handleClose={closeModal}
      handleConfirm={onConfirm}
      titleBtnClose="Close"
      titleBtnSave="Save"
    />
  );
};

const ModalBody = ({
  selectedDate,
  setSelectedDate,
  ignoreTime,
  setIgnoreTime,
  ignoreMinute,
  setIgnoreMinute,
  persistChanges,
  setPersistChanges
}: {
  selectedDate: Date | null;
  setSelectedDate: React.Dispatch<React.SetStateAction<Date | null>>;
  ignoreTime: boolean;
  setIgnoreTime: React.Dispatch<React.SetStateAction<boolean>>;
  ignoreMinute: boolean;
  setIgnoreMinute: React.Dispatch<React.SetStateAction<boolean>>;
  persistChanges: boolean;
  setPersistChanges: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [dtpKey, setDtpKey] = useState(0);
  const addMinutesToSelectedDate = (minutes: number) => () => {
    setSelectedDate(roadTimeObject(selectedDate).add(minutes, 'minutes').toDate());
    setIgnoreTime(false);
    setIgnoreMinute(false);
    setDtpKey((val) => val + 1);
  };
  return (
    <>
      <p>Set the Dashboard to fetch data from the past for this tab.</p>
      <p>If a parameter is &quot;ignored&quot; then the current clock value (MT) will be used.</p>
      <div className="datetime">
        <RoadDateTimePicker
          key={dtpKey}
          onChange={(date) => {
            setSelectedDate(date);
            const roadTime = roadTimeObject(date);
            const setToDefaultTime = roadTime.hour() === 0 && roadTime.minute() === 0;
            setIgnoreTime(setToDefaultTime);
            setIgnoreMinute(setToDefaultTime);
          }}
          inputProps={{ placeholder: 'Select a date (MT), leave blank to reset' }}
          initialValue={selectedDate}
        />
        <div>
          {selectedDate && (
            <Row
              className="justify-content-center"
              style={{ gap: '0.5em', padding: '1em 0 0.5em 0' }}
            >
              <Button onClick={addMinutesToSelectedDate(-30)}>-30m</Button>
              <Button onClick={addMinutesToSelectedDate(-15)}>-15m</Button>
              <Button onClick={addMinutesToSelectedDate(15)}>+15m</Button>
              <Button onClick={addMinutesToSelectedDate(30)}>+30m</Button>
            </Row>
          )}
          <input
            type="checkbox"
            style={{ margin: 10 }}
            checked={ignoreTime}
            onChange={() => setIgnoreTime((ignHour) => !ignHour)}
          />
          Ignore selected time
        </div>
        <div>
          <input
            type="checkbox"
            style={{ margin: 10 }}
            checked={ignoreMinute}
            onChange={() => setIgnoreMinute((ignMinute) => !ignMinute)}
          />
          Ignore selected minute
        </div>
        <div>
          <input
            type="checkbox"
            style={{ margin: 10 }}
            defaultChecked={persistChanges}
            onChange={() => setPersistChanges((reload) => !reload)}
          />
          Persist changes across sessions
        </div>
      </div>
    </>
  );
};
