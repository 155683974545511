import { Corridor, CorridorLaneData } from 'domain/entities/corridor.entity';
import { getCorridorFromName } from 'domain/use-cases/corridor/getCorridorWithName.use-case';
import { range } from 'lodash';
import {
  getEgressLocationName,
  getIngressLocationName,
  getLocationSubcorridorName,
  getWaleLanes,
  getWaleLocation,
  isLocationPartOfMainCorridor,
  isWaleReadingExpressLane,
  isWaleReadingGeneralPurposeLane,
  islocationIngress,
  parseLocationMileFromLocation
} from 'presentation/helpers/waleId';
import { useEffect, useState } from 'react';
import { useQueryParam } from 'presentation/hooks/useQueryParam';
import { useSetQueryParam } from 'presentation/hooks/useSetQueryParam';

export interface MetroLocationInfo {
  name: string;
  // This is a collapsed location, for a main corridor's locations it's the same
  // as a regular location, but for entry/exit ramps it's a special ID that covers
  // all locations in that ramp.
  locationId: string;
  // the element of index N in this list represents the value of the
  // location in the chart of the N+1th lane (not present, unmarked, marked)
  chartValues: CorridorDisplayValues[];
  laneOffset: number;
  lastLaneInLocation: number;
  walesInLocation: string[];
}

export enum CorridorDisplayValues {
  NOT_PRESENT = 0,
  BASE = 1,
  SELECTED = 2
}

const INITIAL_LOCATIONS: string[] = [];

const getLanesInCorridor = (corridor: Corridor) => {
  let highestReadEL = 0;
  let highestReadGPL = 0;
  corridor.locations.forEach((corridorLocation) => {
    const expressLanes = corridorLocation.lanes.filter((laneData) => laneData.laneType === 'EL');
    const gpls = corridorLocation.lanes.filter((laneData) => laneData.laneType === 'GPL');
    const highestReadELinLocation = Math.max(
      ...expressLanes.map((laneData) => Number(laneData.name.replace('EL', '')))
    );
    const highestReadGPLinLocation = Math.max(
      ...gpls.map((laneData) => Number(laneData.name.replace('GPL', '')))
    );
    highestReadEL = Math.max(highestReadEL, highestReadELinLocation);
    highestReadGPL = Math.max(highestReadGPL, highestReadGPLinLocation);
  });
  return {
    lanesInCorridor: range(1, 1 + highestReadEL + highestReadGPL),
    gplStart: highestReadEL + 1
  };
};

const getBaseChartValuesForConnectingLocation = (
  associatedLocation: MetroLocationInfo,
  walesInLocation: string[],
  shouldMarkAsSelected: boolean
) => {
  const lastIndex = associatedLocation.chartValues.length - 1;
  return associatedLocation.chartValues.map((corridorDisplayValue, idx) => {
    if (idx !== 0 && idx !== lastIndex) return CorridorDisplayValues.NOT_PRESENT;
    if (idx === 0 && walesInLocation.filter(isWaleReadingExpressLane).length > 0)
      return shouldMarkAsSelected ? CorridorDisplayValues.SELECTED : CorridorDisplayValues.BASE;

    if (idx === lastIndex && walesInLocation.filter(isWaleReadingGeneralPurposeLane).length > 0)
      return shouldMarkAsSelected ? CorridorDisplayValues.SELECTED : CorridorDisplayValues.BASE;

    return CorridorDisplayValues.NOT_PRESENT;
  });
};

/** Returns the initial chartValues array for the given location. * */
const parseLanesAndWALEsInLocation = (
  locationId: string,
  waleIds: string[],
  lanesInCorridor: number[],
  laneData: CorridorLaneData[],
  gplStart: number,
  initialLocations?: string[]
) => {
  const chartValues: CorridorDisplayValues[] = [];
  const waleIdsInLocation = waleIds.filter((waleId) => getWaleLocation(waleId) === locationId);
  const lastELInLocation = Math.max(
    0,
    ...laneData
      .filter((value) => value.laneType === 'EL')
      .map((value) => Number(value.name.replace('EL', '')))
  );
  const firstGplLaneNumberInLocation =
    laneData.find((value) => value.name === 'GPL1')?.laneNumber ?? lastELInLocation + 1;

  const lastGplInLocation = Math.max(
    0,
    ...laneData
      .filter((value) => value.laneType === 'GPL')
      .map((value) => Number(value.name.replace('GPL', '')))
  );

  const laneOffset = gplStart - firstGplLaneNumberInLocation;
  const lastLaneInLocation = gplStart + lastGplInLocation - 1;

  lanesInCorridor.forEach((lane) => {
    if (lane <= laneOffset) {
      chartValues.push(CorridorDisplayValues.NOT_PRESENT);
      return;
    }
    const foundWale = waleIdsInLocation.find((waleId) =>
      getWaleLanes(waleId).includes(lane - laneOffset)
    );
    if (foundWale) {
      const locations =
        initialLocations && initialLocations.length > 0 ? initialLocations : INITIAL_LOCATIONS;
      chartValues.push(
        locations.includes(locationId) ? CorridorDisplayValues.SELECTED : CorridorDisplayValues.BASE
      );
    } else {
      chartValues.push(CorridorDisplayValues.NOT_PRESENT);
    }
  });
  return { chartValues, laneOffset, lastLaneInLocation };
};

export const useCorridorData = (
  road: string,
  waleIds: string[],
  carouselIndex: number | null,
  setCarouselIndex: (a: number | null) => void,
  initialLocations?: string[]
) => {
  const [corridorLocations, setCorridorLocations] = useState<MetroLocationInfo[]>([]);
  const [isCorridorAscending, setIsCorridorAscending] = useState(false);
  const [lanes, setLanes] = useState<number[]>([]);
  const [gplStartLane, setGplStartLane] = useState(1);
  const project = sessionStorage.getItem('project');
  const locations = useQueryParam('locations');
  const setQueryParam = useSetQueryParam();
  useEffect(() => {
    if (waleIds.length === 0) return;
    getCorridorFromName(road).then((corridor) => {
      const { lanesInCorridor, gplStart } = getLanesInCorridor(corridor);
      setGplStartLane(gplStart);
      const fetchedCorridorLocations: MetroLocationInfo[] = corridor.locations
        .map((loc) => {
          const walesInLocation = waleIds.filter(
            (waleId) => getWaleLocation(waleId) === loc.locationId
          );
          if (walesInLocation.length === 0) return null;
          const { chartValues, laneOffset, lastLaneInLocation } = parseLanesAndWALEsInLocation(
            loc.locationId,
            waleIds,
            lanesInCorridor,
            loc.lanes,
            gplStart,
            initialLocations
          );
          return {
            name: isLocationPartOfMainCorridor(loc.locationId)
              ? parseLocationMileFromLocation(loc.locationId).toFixed(1)
              : getLocationSubcorridorName(loc.locationId),
            chartValues,
            locationId: loc.locationId,
            walesInLocation,
            laneOffset,
            lastLaneInLocation
          };
        })
        .filter((res) => res) as MetroLocationInfo[];
      const corridorCorrectingFactor = corridor.isAscendingMileage ? 1 : -1;
      fetchedCorridorLocations.sort((a, b) => {
        const [firstNumber, secondNumber] = [a, b].map((elem) => Number(elem.name));
        let firstSpecialCase = 0;
        let secondSpecialCase = 0;
        if (Number.isNaN(firstNumber))
          // If the name doesn't parse to a number, it's an ingress or egress point.
          // Ingress points should be at the beginning of the list, while egress ones
          // should be at the end. This variable stores the value that the sort function
          // should return based on the ingress/egress check. The correcting factor
          // of the corridor is required because some corridors will be reverse sorted
          // eventually.
          firstSpecialCase = (islocationIngress(a.locationId) ? 1 : -1) * corridorCorrectingFactor;
        if (Number.isNaN(secondNumber))
          secondSpecialCase =
            -1 * (islocationIngress(b.locationId) ? 1 : -1) * corridorCorrectingFactor;

        // Special case where both locations are ingress or both are egress. In that case
        // they have the same value for sorting.
        if (
          firstSpecialCase !== 0 &&
          secondSpecialCase !== 0 &&
          secondSpecialCase !== firstSpecialCase
        )
          return 0;
        // If any of the variables have a special return value, we return it.
        if (firstSpecialCase !== 0) return firstSpecialCase;
        if (secondSpecialCase !== 0) return secondSpecialCase;

        // We ultimately return the difference between the numeric locations.
        return secondNumber - firstNumber;
      });

      const getConnectingLocationWales = (subArray: 'entries' | 'exits') => {
        const returnWales: Record<string, string[]> = {};
        corridor.locations.forEach((mainLocation) => {
          if (!(subArray in mainLocation) || mainLocation[subArray]?.length === 0) return;
          mainLocation[subArray].forEach((locationConnection) => {
            const walesInConnectingLocation = waleIds.filter(
              (waleId) => getWaleLocation(waleId) === locationConnection.locationId
            );
            if (walesInConnectingLocation.length === 0) return;
            if (mainLocation.locationId in returnWales)
              returnWales[mainLocation.locationId].push(...walesInConnectingLocation);
            else returnWales[mainLocation.locationId] = walesInConnectingLocation;
          });
        });
        return returnWales;
      };

      const addConnectingLocations = (
        conectingWales: Record<string, string[]>,
        connectingCharacter: 'I' | 'E'
      ) =>
        Object.keys(conectingWales).forEach((locIdWithConnectingPoint) => {
          const associatedLocationIndex = fetchedCorridorLocations.findIndex(
            (loc) => loc.locationId === locIdWithConnectingPoint
          );
          const associatedLocation = fetchedCorridorLocations[associatedLocationIndex];
          const walesInLocation = conectingWales[locIdWithConnectingPoint];
          const connectingLocationId =
            connectingCharacter === 'I'
              ? getIngressLocationName(locIdWithConnectingPoint)
              : getEgressLocationName(locIdWithConnectingPoint);
          const newChartValues = getBaseChartValuesForConnectingLocation(
            associatedLocation,
            walesInLocation,
            initialLocations?.includes(connectingLocationId) ?? false
          );
          // Determines if the connecting location should be inserted before or
          // after the original location in the list.
          const insertionOffset =
            (connectingCharacter === 'I' && !corridor.isAscendingMileage) ||
            (connectingCharacter === 'E' && corridor.isAscendingMileage)
              ? 0
              : 1;

          fetchedCorridorLocations.splice(associatedLocationIndex + insertionOffset, 0, {
            name: `${connectingCharacter}_${associatedLocation.name}`,
            locationId: connectingLocationId,
            walesInLocation,
            chartValues: newChartValues,
            laneOffset: associatedLocation.laneOffset,
            lastLaneInLocation: associatedLocation.lastLaneInLocation
          });
        });
      const entryWales = getConnectingLocationWales('entries');
      const exitWales = getConnectingLocationWales('exits');

      addConnectingLocations(entryWales, 'I');
      addConnectingLocations(exitWales, 'E');

      setCorridorLocations(fetchedCorridorLocations);
      setIsCorridorAscending(corridor.isAscendingMileage);
      setLanes(lanesInCorridor);
    });
  }, [waleIds, road]);

  useEffect(() => {
    if (carouselIndex !== null) setCarouselIndex(0);
  }, [corridorLocations]);

  useEffect(() => {
    let selected = corridorLocations
      .filter((location) =>
        location.chartValues.some((val) => val === CorridorDisplayValues.SELECTED)
      )
      .map((location) => location.locationId);
    if (locations) {
      const current = locations?.split(',') ?? [];
      selected = [
        ...selected,
        ...current.filter(
          (loc) => !corridorLocations.map((location) => location.locationId).includes(loc)
        ) // append only locations from other corridor
      ].filter((loc) => loc.includes(project ?? '')); // Only from selected project
    }
    setQueryParam('locations', selected.join());
  }, [corridorLocations]);

  return {
    corridorLocations,
    setCorridorLocations,
    isCorridorAscending,
    lanes,
    gplStart: gplStartLane
  };
};
