import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

type OwnProps = {
  to?: string;
  variant?: string;
};

// @ts-ignore ts-migrate(2456) FIXME: Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof LinkBtn.defaultProps;

// @ts-ignore ts-migrate(7022) FIXME: 'LinkBtn' implicitly has type 'any' because it doe... Remove this comment to see the full error message
const LinkBtn = ({ children, variant, to, ...props }: Props) => (
  <Button as={Link} to={to} {...props} variant={variant}>
    {children}
  </Button>
);

LinkBtn.defaultProps = {
  to: null,
  variant: 'link'
};

export default LinkBtn;
