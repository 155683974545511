/* eslint-disable react/jsx-props-no-spreading */
import { useQuery } from '@tanstack/react-query';
import { RoadDateTimePicker } from 'components/RoadDateTimePicker/RoadDateTimePicker';
import FormikSelect, { mapToOptions } from 'components/Select/FormikSelect';
import { getEventLabelsUseCase } from 'domain/use-cases/eventLabels/getEventLabels.use-case';
import { sleep } from 'presentation/helpers/sleep';
import { useAlert } from 'presentation/hooks/useAlert';
import { useWales } from 'presentation/hooks/useWaleList';
import React, { ChangeEvent, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';

const MAX_BATCH_SIZE = 2000;

export const InitialSelector = ({
  setParams
}: {
  setParams: (
    waleId: string,
    startDate: Date,
    endDate: Date | null,
    batchSize: number | null,
    quickLabels: string[],
    labels: string[]
  ) => void;
}) => {
  const waleIds = useWales()
    .filter((wale) => wale.active)
    .map((wale) => wale.waleId);

  const [selectedWale, setSelectedWale] = useState<string | null>(null);
  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(null);
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null);
  const { isFetching, isLoading, data } = useQuery({
    queryKey: ['labels'],
    queryFn: getEventLabelsUseCase
  });

  const [selectedQuickLabels, setSelectedQuickLabels] = useState<string[]>([]);
  const [selectedLabels, setSelectedLabels] = useState<string[]>([]);
  const [batchSize, setBatchSize] = useState<number | null>(null);
  const [autoFillUsed, setAutoFillUsed] = useState(0);
  const { sendAlert } = useAlert();

  const handleBatchSizeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const trimmedInput = event.target.value.trim();
    if (trimmedInput === '') {
      setBatchSize(null);
      return;
    }
    const parsedInput = Number.parseInt(trimmedInput, 10);
    if (Number.isNaN(parsedInput)) {
      setBatchSize(batchSize);
    } else if (parsedInput > MAX_BATCH_SIZE) {
      setBatchSize(MAX_BATCH_SIZE);
    } else {
      setBatchSize(parsedInput);
    }
  };
  if (isFetching || isLoading) return null;

  const centeredColumn = { md: { offset: 2, span: 8 }, xl: { offset: 4, span: 4 } };

  const sendError = async (message: string) => {
    sendAlert({ type: 'error', message });
    // Waiting helps with rapid fire messages
    await sleep(50);
  };

  const onStartButtonClick = async () => {
    let error = false;
    if (!selectedWale) {
      error = true;
      await sendError('A WALE must be selected.');
    }
    if (!selectedStartDate) {
      error = true;
      await sendError('A Start Date must be selected.');
    }
    if (!selectedEndDate && !batchSize) {
      error = true;
      await sendError('An End Date or a Batch Size must be selected.');
    }
    if (selectedEndDate && batchSize) {
      error = true;
      await sendError('Either End Date or Batch Size must be selected, not both.');
    }
    if (batchSize && batchSize > MAX_BATCH_SIZE) {
      error = true;
      await sendError(`Batch Size can't be more than ${MAX_BATCH_SIZE}.`);
    }
    if (selectedEndDate && selectedStartDate && selectedEndDate < selectedStartDate) {
      error = true;
      await sendError('End date must be after Start date.');
    }
    if (selectedQuickLabels.length !== 5) {
      error = true;
      await sendError('Five quick labels must be selected.');
    }
    if (selectedLabels.length > 10) {
      error = true;
      await sendError("Can't have more than 10 other labels.");
    }
    if (error) return;
    setParams(
      selectedWale!,
      selectedStartDate!,
      selectedEndDate,
      batchSize,
      selectedQuickLabels,
      selectedLabels
    );
  };

  const autoFill = () => {
    const localStoreProgress = localStorage.getItem('lastLabelReview');
    if (localStoreProgress === null) return;
    const parsedStoredProgress: Record<string, any> = JSON.parse(localStoreProgress);
    if (parsedStoredProgress.waleId) setSelectedWale(parsedStoredProgress.waleId);
    if (parsedStoredProgress.lastReviewedDate)
      setSelectedStartDate(new Date(parsedStoredProgress.lastReviewedDate));
    if (parsedStoredProgress.quickLabels) setSelectedQuickLabels(parsedStoredProgress.quickLabels);
    if (parsedStoredProgress.otherLabels) setSelectedLabels(parsedStoredProgress.otherLabels);
    if (parsedStoredProgress.endDate) setSelectedEndDate(new Date(parsedStoredProgress.endDate));
    else if (parsedStoredProgress.batchSize)
      setBatchSize(parsedStoredProgress.batchSize - (parsedStoredProgress.reviewedTotal ?? 0));
    setAutoFillUsed((val) => val + 1);
  };
  return (
    <Col>
      <Row>
        <Col {...centeredColumn}>
          <h4>Select parameters</h4>
        </Col>
      </Row>
      <Row>
        <Col {...centeredColumn}>WALE for review</Col>
      </Row>
      <Row>
        <Col {...centeredColumn}>
          <FormikSelect
            key={`waleSelect${waleIds.length}`}
            placeholder="Select WALE"
            options={mapToOptions(waleIds)}
            value={selectedWale}
            defaultValue={selectedWale}
            onChange={(elem: { value: string }) => setSelectedWale(elem.value)}
          />
        </Col>
      </Row>
      <Row>
        <Col {...centeredColumn}>Batch start time</Col>
      </Row>
      <Row>
        <Col {...centeredColumn} className="datetime">
          <RoadDateTimePicker
            key={`rdtPickerStart-${autoFillUsed}`}
            onChange={setSelectedStartDate}
            inputProps={{ placeholder: 'Select a start date (MT)' }}
            initialValue={selectedStartDate}
          />
        </Col>
      </Row>
      <Row>
        <Col {...centeredColumn}>
          <Row>
            <Col xs={5}>
              <Row>
                <Col>Batch end time</Col>
              </Row>
              <Row>
                <Col className="datetime">
                  <RoadDateTimePicker
                    key={`rdtPickerEnd-${autoFillUsed}`}
                    onChange={setSelectedEndDate}
                    inputProps={{ placeholder: 'Select an end date (MT)' }}
                    initialValue={selectedEndDate}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={1}>OR</Col>
            <Col xs={6}>
              <Row>
                <Col>Number of events</Col>
              </Row>
              <Row>
                <input
                  onChange={handleBatchSizeChange}
                  type="text"
                  value={batchSize || ''}
                  name="batch_size"
                  placeholder="Batch Size"
                  className="bliss-input-field px-2 text-white"
                  style={{
                    width: '15em',
                    minHeight: '38px'
                  }}
                />
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col {...centeredColumn}>Select quick labels (must select 5)</Col>
      </Row>
      <Row>
        <Col {...centeredColumn}>
          <FormikSelect
            key={`quicklabelselect${autoFillUsed}`}
            isMulti
            placeholder="Quick Labels"
            options={mapToOptions(data?.labels ?? [])}
            onChange={(selected: { value: string }[] | null) => {
              setSelectedQuickLabels((selected ?? []).map((sel) => sel.value));
            }}
            defaultValue={mapToOptions(selectedQuickLabels)}
            isOptionDisabled={() => selectedQuickLabels.length >= 5}
          />
        </Col>
      </Row>
      <Row>
        <Col {...centeredColumn}>Select other labels (Optional, up to 10)</Col>
      </Row>
      <Row>
        <Col {...centeredColumn}>
          <FormikSelect
            key={`labelselect${autoFillUsed}`}
            isMulti
            placeholder="Labels"
            options={mapToOptions(data?.labels ?? [])}
            onChange={(selected: { value: string }[] | null) => {
              setSelectedLabels((selected ?? []).map((sel) => sel.value));
            }}
            defaultValue={mapToOptions(selectedLabels)}
            isOptionDisabled={() => selectedLabels.length >= 10}
          />
        </Col>
      </Row>
      <Row>
        <Col {...centeredColumn}>
          <Row>
            <Col>
              <Button onClick={onStartButtonClick}>Start Batch</Button>
            </Col>
            <Col>
              <Button onClick={autoFill}>Fill with last review&apos;s progress</Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};
