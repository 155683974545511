import React from 'react';
import { Form, ButtonToolbar, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { HelpCircle } from 'react-feather';

type OwnProps = {
  direction?: string;
  tooltipText?: string;
  delayShow?: number;
  delayHide?: number;
  toolbarVariant?: string;
};

// @ts-ignore ts-migrate(2456) FIXME: Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof FormikCheckBox.defaultProps;

// @ts-ignore ts-migrate(7022) FIXME: 'FormikCheckBox' implicitly has type 'any' because... Remove this comment to see the full error message
const FormikCheckBox = ({
  label,
  margin,
  field,
  toolbarVariant,
  direction,
  tooltipText,
  delayShow,
  delayHide,
  ...props
}: Props) => (
  <Form.Group className={`d-flex align-items-center ${margin}`}>
    <Form.Check
      {...field}
      custom
      type="checkbox"
      checked={field.value}
      label={label}
      id={field.name}
      {...props}
    />
    {tooltipText && (
      <ButtonToolbar className="ml-2">
        <OverlayTrigger
          key={direction}
          placement={direction}
          delay={{ show: delayShow, hide: delayHide }}
          // @ts-ignore ts-migrate(2741) FIXME: Property 'id' is missing in type '{ children: any;... Remove this comment to see the full error message
          overlay={<Tooltip>{tooltipText}</Tooltip>}
        >
          <HelpCircle />
        </OverlayTrigger>
      </ButtonToolbar>
    )}
  </Form.Group>
);

FormikCheckBox.defaultProps = {
  direction: 'top',
  delayShow: 250,
  tooltipText: '',
  delayHide: 0,
  toolbarVariant: ''
};
export default FormikCheckBox;
