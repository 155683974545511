import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { useAlert } from 'presentation/hooks/useAlert';
import { createUserUseCase } from 'domain/use-cases/users/createUser.use-case';
import { BackToHomeBtn } from '../../components';
import setInitialMe from '../../services/userUtils';
import UserForm from './UserForm';

const UserNew = () => {
  const emptyUser = setInitialMe();
  const [user, setUser] = useState(emptyUser);
  const history = useHistory();
  const { sendAlert } = useAlert();
  const handleSuccessCreate = (data: any) => {
    setUser(data);
    sendAlert({ type: 'success', message: 'User created successfully' });
    history.push('/users');
  };

  const handleFailureRequest = (error: any) => {
    sendAlert({ type: 'error', message: error?.response?.data?.error });
  };

  const handleCreateRequest = (values: any) => {
    createUserUseCase(values).then(handleSuccessCreate).catch(handleFailureRequest);
  };

  return (
    <Row>
      <Col md={12} className="bliss-card my-3">
        <Row>
          <Col md={2}>
            <BackToHomeBtn route="/users" text="Back to Users" />
          </Col>
          <Col md={{ span: 4, offset: 2 }}>
            <h2 className="text-blue text-slim text-uppercase">New User</h2>
          </Col>
          <Col md={{ span: 4, offset: 4 }} className="mt-5">
            <UserForm
              // @ts-ignore ts-migrate(2322) FIXME: Type '{ user: { email: string; demo: boolean; demo... Remove this comment to see the full error message
              user={user}
              action="new"
              submitVariant="success"
              formRequest={handleCreateRequest}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default UserNew;
