import { createUser } from 'data/users.repository';

const parseValues = (values: any): any => {
    const res = { ...values };
    if (!res?.user?.demo) {
      res.user.demoHour = '';
      res.user.demoMinute = '';
      res.user.demoDate = null;
    }
    return res;
  };

export const createUserUseCase = (user: Record<string, unknown>) => createUser(parseValues(user));
