import moment from 'moment-timezone';

export const tz = process.env.REACT_APP_TIMEZONE || 'America/Denver';

export function toIsoDateString(date?: any) {
  let standardDate = date?.toString() || new Date().toISOString();
  if (standardDate.includes('GMT')) {
    standardDate = new Date(date).toISOString();
  }
  if (standardDate.includes('T')) {
    [standardDate] = standardDate.split('T');
  }
  return standardDate;
}

/** Ignores timezone of the given date (treats it as naive), sets zone to timeZone, and converts to Unix */
function fromZoneToUnix(date: Date | moment.Moment, timeZone: string) {
  const stringDate = moment(date).format('yyyy-MM-DD HH:mm:ss');
  const dateWithZone = moment.tz(stringDate, timeZone);
  return dateWithZone.valueOf();
}

/** Ignores timezone of the given date (treats it as naive), sets zone to the Road's, and converts to Unix */
export const fromRoadToUnix = (date: Date | moment.Moment) => fromZoneToUnix(date, tz);

export function ignoreTimeZone(date: any) {
  let standardDate = toIsoDateString(date);
  standardDate = standardDate.replaceAll('-', '/');
  return new Date(standardDate);
}

export const toUnixTimeStamp = (date: any) => {
  let unixDate;
  if (date) {
    unixDate = ignoreTimeZone(date).getTime();
  }
  return unixDate;
};

export const calcBlockStartTimeRegularIntervals = (date: any, timeMultiple: any) => {
  /* Return start time of current time block if want to keep a schedule with regular time intervals.

    An operating schedule that needs to be split into regular time blocks such that the end time of
    each time block matches a round time (i.e. a multiple of timeMultiple).
  */
  const curTime = moment(date);

  const elapsedTimeInBlock = (60 * curTime.minutes() + curTime.seconds()) % timeMultiple;
  return curTime.subtract({ seconds: elapsedTimeInBlock }).unix() * 1000;
};

export const NOT_SET_DEMO_PARAM = -1;
/**
 *
 * @param originalDate: string of type "YYYY-MM-DD"
 * @param fixedHour: If provided the time will be changed such that the hour component is equal to fixedHour
 * @param fixedMinute: If provided the time will be changed such that the minute component is fixedMinute + (curMinute % 10).
 * This means that the minute is changing for 10 minutes and then starting all over from fixedMinute.
 * @returns a UNIX timestamp calculated by combining the originalDate (without time) and the current time.
 */
export const addCurrentTime = (
  originalDate: string | Date,
  fixedHour = NOT_SET_DEMO_PARAM,
  fixedMinute = NOT_SET_DEMO_PARAM
) => {
  let adjustedDate = new Date();

  if (originalDate) {
    const curRoadTime = moment().tz(tz);

    const dateSplit = new Date(originalDate).toISOString().split('-');
    const year = parseInt(dateSplit[0], 10);
    const month = parseInt(dateSplit[1], 10) - 1;
    const day = parseInt(dateSplit[2], 10);

    let newRoadTime = curRoadTime.year(year).month(month).date(day);

    if (fixedHour !== NOT_SET_DEMO_PARAM) {
      newRoadTime = newRoadTime.hour(fixedHour);
      if (fixedMinute !== NOT_SET_DEMO_PARAM) {
        newRoadTime = newRoadTime.minute(fixedMinute);
        newRoadTime = newRoadTime.second(0);
      }
    }

    adjustedDate = new Date(newRoadTime.format());
  }
  return adjustedDate.getTime();
};

export const addGivenTime = (targetTime: any, date: any) => {
  const dateWithTargetTime = new Date(targetTime);
  const adjustedDate = new Date(date);

  adjustedDate.setDate(adjustedDate.getUTCDate());
  adjustedDate.setHours(dateWithTargetTime.getHours());
  adjustedDate.setMinutes(dateWithTargetTime.getMinutes());
  adjustedDate.setSeconds(dateWithTargetTime.getSeconds());

  return adjustedDate.getTime();
};

export const subtractDays = (date: any, days: any) => {
  const resultingDate = new Date(date);
  resultingDate.setDate(resultingDate.getDate() - days);

  return resultingDate.getTime();
};

const decomposeTime = (time: any) => time.split(':').map((string: any) => parseFloat(string));

const timeToString = (time: any) =>
  time.map((unit: any) => (unit < 10 ? `0${unit}` : `${unit}`)).join(':');

export const formatTime = (dateTime: any) => {
  const timeList = new Date(dateTime).toLocaleTimeString('en-US', { timeZone: tz }).split(' ');
  const time = decomposeTime(timeList[0]);
  const qualifier = timeList[1];
  if (qualifier === 'PM' && time[0] !== 12) {
    time[0] += 12;
  } else if (time[0] === 12 && qualifier === 'AM') {
    time[0] -= 12;
  }
  return timeToString(time);
};

export const parseSchedule = (schedule: any) =>
  schedule.split(', ').map((range: any) => range.split('-'));

export const timeToNumber = (time: any) => parseFloat(time.replace(':', ''));

export const formatDisplayTime = (time: any) => {
  const t = decomposeTime(time);
  if (t[0] > 12) t[0] -= 12;
  return `${t[0]}:${t[1] < 10 ? `0${t[1]}` : t[1]}`;
};

/**
 * Returns true if time1 is before time2
 */
const isBefore = (time1: any, time2: any) => timeToNumber(time1) - timeToNumber(time2) < 0;

export const findInterval = (schedule: any, time: any) =>
  schedule.filter((range: any) => isBefore(range[1], formatTime(time)));

export const getDashboardSchedule = (schedule: any) =>
  schedule.reduce((list: any, range: any) => [...list, { hour: formatDisplayTime(range[1]) }], []);

export const getBuffer = (schedule: any) => {
  const dashboardSchedule = getDashboardSchedule(schedule);
  const lastIndex = findInterval(schedule, Date.now()).length;

  return dashboardSchedule.reduce((list: any, data: any, index: any) => {
    if (index >= lastIndex)
      return [
        ...list,
        {
          displayHour: data.hour,
          inactiveRegular: 0,
          inactiveFast: 0
        }
      ];
    return list;
  }, []);
};

export const getNullData = (schedule: any) => {
  if (!schedule || schedule.length === 0) return null;

  const dashboardSchedule = getDashboardSchedule(schedule);
  const lastIndex = findInterval(schedule, Date.now()).length;

  const nullData = dashboardSchedule.reduce((list: any, data: any, index: any) => {
    if (index < lastIndex)
      return [
        ...list,
        {
          displayHour: data.hour,
          fast: 0,
          regular: 0
        }
      ];
    return list;
  }, []);

  return nullData.concat(getBuffer(schedule));
};

export const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

export function roadTimeObject(date?: any) {
  if (date) return moment(date).tz(tz);
  return moment().tz(tz);
}
export function roadTime() {
  return moment().tz(tz).format('LTS');
}

export function openRoadTime() {
  let openTimestamp = moment.tz(tz).set({ hour: 8, minute: 0 });
  openTimestamp = moment.tz(openTimestamp.format('YYYY-MM-DD HH:mm'), tz);
  return openTimestamp.unix() * 1000;
}

export function closeRoadTime() {
  let closeTimestamp = moment.tz(tz).set({ hour: 18, minute: 0 });
  closeTimestamp = moment.tz(closeTimestamp.format('YYYY-MM-DD HH:mm'), tz);
  return closeTimestamp.unix() * 1000;
}

export function compareHours(roadDate: any, openDateTime: any, closeDateTime: any) {
  const currentDateTime = new Date(roadDate);
  return currentDateTime <= closeDateTime && currentDateTime >= openDateTime;
}

export function getRoadDateTime(date: any) {
  return moment.tz(date, tz).format('MMMM Do YYYY, h:mm:ss a');
}

export function getRoadShortDateTime(date: any) {
  return moment.tz(date, tz).format('MMM DD, HH:mm:ss');
}

export function getRoadVeryShortDateTime(date: any) {
  return moment.tz(date, tz).format('MMM DD, HH:mm');
}

export function getRoadDate(date: any) {
  return moment.tz(date, tz).format('MMMM Do YYYY');
}

export function getRoadTime(date: any) {
  return moment.tz(date, tz).format('h:mm:ss a');
}

export function getRoadTimeMins(date: any) {
  return moment.tz(date, tz).format('h:mm a');
}

export function getRoadDateTimeMilli(date: any) {
  return moment.tz(date, tz).format('MMMM Do YYYY, HH:mm:ss.SSS');
}

/**
 * @param {String} interval String representation of a time interval
 * @returns {Object} Start time and end time of an interval in Moment()
 */
export function parseIntervals(interval: string) {
  const splittedInterval = interval.split('-');
  const start = moment(splittedInterval[0], 'HH:mm:ss');
  const end = moment(splittedInterval[1], 'HH:mm:ss');
  return { start, end };
}

/**
 * Check if two intervals are intersecting
 * @param {moment} dt
 * @param {moment} s
 * @returns bool
 */
export function intersects(inter1: any, inter2: any) {
  return (
    inter1.start.isBetween(inter2.start, inter2.end, null, '()') ||
    inter1.end.isBetween(inter2.start, inter2.end, null, '()') ||
    inter2.start.isBetween(inter1.start, inter1.end, null, '()') ||
    inter2.end.isBetween(inter1.start, inter1.end, null, '()')
  );
}

export function getUtcDateTime(date: any) {
  return moment.utc(date).format('MMMM Do YYYY, H:mm:ss.SSS');
}

export const getRoadDateAndTimeWithoutYear = (time: Date): string =>
  getRoadDate(time).slice(0, -4) + getRoadTime(time);

export const formatDate = (date: Date, format: string) => moment(date).format(format);

export const getStartOfDay = (date?: Date) => {
  if (!date) return moment.tz(tz).startOf('day').toDate();

  const formatted = formatDate(date, 'yyyy-MM-DD');
  return moment.tz(formatted, tz).toDate();
};

export const forceLocalDateToMatchRoad = (date: Date) => {
  const roadDate = moment.tz(date, tz);
  const localTimeZone = moment.tz.guess();
  return roadDate.tz(localTimeZone, true).toDate();
};

export const getSecondsSince = (date?: Date | null, since?: Date) => {
  if (!date) return 0;

  return ((since ?? new Date()).getTime() - date.getTime()) / 1000;
};
