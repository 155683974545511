/* eslint-disable react/jsx-props-no-spreading */
import { Col } from 'react-bootstrap';
import React from 'react';
import SingleWaleMonitor, { SingleWaleMonitorDrilledProps } from './SingleWaleMonitor';
import { WaleStatusMonitor } from './StatusTable';

interface OtherFrontBackPairProps {
  pair: { front?: string; back?: string; lane?: number };
  frontStatus: WaleStatusMonitor;
  backStatus: WaleStatusMonitor;
}

export interface AscendingMileage {
  isAscendingMileage: boolean;
}

export interface FrontBackPairDrilledProps {
  ignoreEmptySpace: boolean;
  hideUnderMaintenance: boolean;
  hideVDOff: boolean;
  hideSystemOff: boolean;
}

export type SingleWaleMonitorPropsObject = {
  singleMonitorProps: SingleWaleMonitorDrilledProps;
};

type FrontBackMonitorInterface = FrontBackPairDrilledProps &
  AscendingMileage &
  OtherFrontBackPairProps &
  SingleWaleMonitorPropsObject;

const FrontBackMonitor = ({
  isAscendingMileage,
  ignoreEmptySpace,
  hideUnderMaintenance,
  hideVDOff,
  hideSystemOff,
  pair,
  frontStatus,
  backStatus,
  singleMonitorProps
}: FrontBackMonitorInterface) => {
  const { front, back, lane } = pair;

  const frontUnderMaintenance = frontStatus.maintenanceStatus.length > 0;
  const backUnderMaintenance = backStatus.maintenanceStatus.length > 0;

  const showFront =
    Boolean(front) &&
    !(hideUnderMaintenance && frontUnderMaintenance) &&
    !(hideVDOff && !frontStatus.vehicleScheduleOn) &&
    !(hideSystemOff && !frontStatus.systemScheduleOn);
  const renderFrontBoxSpace = !ignoreEmptySpace || showFront;

  const showBack =
    Boolean(back) &&
    !(hideUnderMaintenance && backUnderMaintenance) &&
    !(hideVDOff && !backStatus.vehicleScheduleOn) &&
    !(hideSystemOff && !backStatus.systemScheduleOn);

  const renderBackBoxSpace = !ignoreEmptySpace || showBack;

  if (!showFront && !showBack) return null;

  const backIndexOffset = showBack && showFront && isAscendingMileage ? 1 : 0;
  const frontIndexOffset = showBack && showFront && !isAscendingMileage ? 1 : 0;

  const frontDisplay = renderFrontBoxSpace ? (
    <div style={{ ...(!showFront && { visibility: 'hidden' }) }}>
      <SingleWaleMonitor
        waleId={front ?? ''}
        status={frontStatus}
        lane={lane}
        {...singleMonitorProps}
        index={singleMonitorProps.index + frontIndexOffset}
      />
    </div>
  ) : null;

  const backDisplay = renderBackBoxSpace ? (
    <div style={{ ...(!showBack && { visibility: 'hidden' }) }}>
      <SingleWaleMonitor
        waleId={back ?? ''}
        status={backStatus}
        lane={lane}
        {...singleMonitorProps}
        index={singleMonitorProps.index + backIndexOffset}
      />
    </div>
  ) : null;

  return (
    <Col
      style={{ padding: '0.1em' }}
      key={(front ?? '').concat(back ?? '').concat(String(lane) ?? '')}
    >
      <div>
        {/* The display order depends on the shape of the road. */}
        {isAscendingMileage ? frontDisplay : null}
        {backDisplay}
        {!isAscendingMileage ? frontDisplay : null}
      </div>
    </Col>
  );
};

export default FrontBackMonitor;
