import React from 'react';
import { AlertTriangle, AlertCircle, Bell, CheckCircle } from 'react-feather';

const AlertError = ({ message }: any) => (
  <div className="bliss-alert">
    <div className="icon error">
      <AlertCircle size={40} fill="#d72038" />
    </div>
    <div className="content">
      <p className="title">Error</p>
      <p>{message}</p>
    </div>
  </div>
);

const AlertWarning = ({ message }: any) => (
  <div className="bliss-alert" data-cy="alert_warning">
    <div className="icon warning">
      <AlertTriangle size={40} fill="#DED024" />
    </div>
    <div className="content">
      <p className="title">Warning</p>
      <p>{message}</p>
    </div>
  </div>
);

const AlertInfo = ({ message }: any) => (
  <div className="bliss-alert">
    <div className="icon info">
      <Bell size={40} fill="#bce9fc" />
    </div>
    <div className="content">
      <p className="title">Information</p>
      <p>{message}</p>
    </div>
  </div>
);

const AlertSuccess = ({ message }: any) => (
  <div className="bliss-alert">
    <div className="icon success">
      <CheckCircle size={40} fill="#cefdc8" />
    </div>
    <div className="content">
      <p className="title">Information</p>
      <p>{message}</p>
    </div>
  </div>
);

export { AlertWarning, AlertError, AlertInfo, AlertSuccess };
