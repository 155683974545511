/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { NavbarTop, NavbarLogged } from '../components';

// @ts-ignore ts-migrate(7031) FIXME: Binding element 'Component' implicitly has an 'any... Remove this comment to see the full error message
export const LoggedLayout = ({ title = 'BLISSWAY Dashboard', component: Component, ...rest }) => {
  document.title = title;
  const [showPage, setShowPage] = useState<boolean | null>(null);

  useEffect(() => {
    if (showPage === false) setShowPage(true);
  }, [showPage]);

  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem('jwtRefresh') ? (
          <>
            <NavbarLogged projectChanged={() => setShowPage(false)} />
            {showPage ? (
              <Container fluid key={String(showPage)}>
                <Component {...props} />
              </Container>
            ) : null}
          </>
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        )
      }
    />
  );
};

// @ts-ignore ts-migrate(7031) FIXME: Binding element 'Component' implicitly has an 'any... Remove this comment to see the full error message
export const NonLoggedLayout = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      localStorage.getItem('jwtRefresh') ? (
        <Redirect to={{ pathname: '/roadside', state: { from: props.location } }} />
      ) : (
        <>
          <NavbarTop />
          <Container fluid>
            <Component {...props} />
          </Container>
        </>
      )
    }
  />
);

// @ts-ignore ts-migrate(7031) FIXME: Binding element 'Component' implicitly has an 'any... Remove this comment to see the full error message
export const NavbarLess = ({ component: Component, ...rest }) => {
  document.title = 'BLISSWAY Dashboard';
  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem('jwtRefresh') ? (
          <Redirect to={{ pathname: '/roadside', state: { from: props.location } }} />
        ) : (
          <Container fluid>
            <Component {...props} />
          </Container>
        )
      }
    />
  );
};
