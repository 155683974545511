import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { useAlert } from 'presentation/hooks/useAlert';
import { getUserByIdUseCase } from 'domain/use-cases/users/getUserById.use-case';
import { updateUserUseCase } from 'domain/use-cases/users/updateUser.use-case';
import { useUser } from 'presentation/hooks/data/useUser';
import { BackToHomeBtn } from '../../components';
import { validateToken } from '../../store/actions/auth';
import setInitialMe from '../../services/userUtils';
import UserForm from './UserForm';
import { UserRole } from './userRoles';

const UserEdit = (props: any) => {
  const {
    match: { params }
  } = props;
  const { id: userId } = params;
  const emptyUser = setInitialMe();
  const [user, setUser] = useState(emptyUser);
  const reduxUser = useUser();
  const dispatch = useDispatch();
  const history = useHistory();
  const { sendAlert } = useAlert();

  const handleFailureRequest = (error: any) => {
    sendAlert({ type: 'error', message: error?.response?.data?.error });
  };

  const fetchUser = () => {
    getUserByIdUseCase(userId)
      .then((data) => setUser(data.user))
      .catch(handleFailureRequest);
  };

  useEffect(fetchUser, [userId]);

  const handleSuccessUpdate = (data: any) => {
    dispatch(validateToken());
    sendAlert({ type: 'success', message: 'User updated successfully' });
    const responseUser = data?.user;
    if (responseUser.role !== UserRole.Admin && responseUser.email === reduxUser.email)
      return history.push('/');
    return history.push('/users');
  };

  const handleUpdateRequest = (values: any) => {
    updateUserUseCase(userId, values).then(handleSuccessUpdate).catch(handleFailureRequest);
  };

  return (
    <Row>
      <Col md={12} className="bliss-card my-3">
        <Row>
          <Col md={2}>
            <BackToHomeBtn route="/users" text="Back to Users" />
          </Col>
          <Col md={{ span: 4, offset: 2 }}>
            <h2 className="text-blue text-slim text-uppercase mb-5">Edit User</h2>
            <UserForm
              // @ts-ignore ts-migrate(2322) FIXME: Type '{ user: { email: string; demo: boolean; demo... Remove this comment to see the full error message
              user={user}
              action="edit"
              submitVariant="dark"
              formRequest={handleUpdateRequest}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default UserEdit;
