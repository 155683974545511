/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Container, Table } from 'react-bootstrap';
import { LocationEvent } from 'domain/entities/locationEvent.entity';
import { PlateState } from 'domain/entities/waleDetection.entity';

import { Trip } from 'domain/entities/trip.entity';
import { ZoneEvent } from 'domain/entities/zoneEvent.entity';
import { useHistory } from 'react-router-dom';
import { useEndDate } from 'presentation/hooks/useEndDate';
import { useQueryParam } from 'presentation/hooks/useQueryParam';
import { useTrips } from './TripVisualizer.view-model';
import { TripVisualizerElement } from './components/TripVisualizer/TripVisualizerElement';
import LocationEventVisualizer from './components/LocationEvents/LocationEventVisualizer';
import { TripSearchAndDownloadBar } from './components/TripVisualizer/TripSearchAndDownloadBar';
import { TripHeader } from './components/TripVisualizer/TripHeader';
import ZoneVisualizer from './components/ZoneEvents/ZoneVisualizer';

const TripVisualizer = () => {
  // Filter parameters
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [plateNumberFilter, setPlateNumberFilter] = useState<string | null>(null);
  const [plateStateFilter, setPlateStateFilter] = useState<PlateState | null>(null);
  // Not fetching initially to avoid a race condition if the plate number is in the URL
  const [filter, setFilter] = useState({ fetch: false, loading: false });
  const [selectedTrip, setSelectedTrip] = useState<Trip | null>(null);
  const [selectedZone, setSelectedZone] = useState<ZoneEvent | null>(null);
  const [zoneEventList, setZoneEventList] = useState<ZoneEvent[]>([]);
  const [locationEventData, setLocationEventData] = useState<LocationEvent[]>([]);
  const userEndDate = useEndDate();

  const isFetching = filter.fetch || filter.loading;

  const history = useHistory();

  // URL params
  const tripId = useQueryParam('id');
  const plateNumber = useQueryParam('plate_number');

  // Pagination
  const [elemsPerPage, setElemsPerPage] = useState(25);
  const [page, setPage] = useState(0);

  const trips = useTrips(
    startDate,
    endDate,
    userEndDate,
    plateNumberFilter,
    plateStateFilter,
    filter,
    setFilter,
    elemsPerPage,
    page,
    tripId
  );

  const displayLocationEvents = locationEventData.length && selectedTrip && selectedZone;

  useEffect(() => {
    if (trips.length && tripId) {
      trips.forEach((trip) => {
        if (trip.tripId === tripId) {
          setSelectedTrip(trip);
          setZoneEventList(trip.zoneEvents);
        }
      });
    }
  }, [trips]);

  useEffect(() => {
    if (plateNumber) {
      setPlateNumberFilter(plateNumber);
      fetchTrips();
    } else if (tripId) {
      fetchTrips();
    } else if (!filter.fetch && !filter.loading) {
      fetchTrips();
      handleBackToTrips();
    }
  }, [tripId, plateNumber]);

  useEffect(() => {
    window.onpopstate = (e) => {
      if (selectedTrip) {
        e.preventDefault();
        history.go(1);
      }
    };
  }, [selectedTrip]);

  const goToZone = (zoneEvents: ZoneEvent[]) => {
    setZoneEventList(zoneEvents);
  };

  const fetchTrips = () => {
    setFilter({ fetch: true, loading: true });
  };

  const handleBackToTrips = () => {
    setZoneEventList([]);
    setSelectedTrip(null);
  };

  // TODO: Refactor this component, maybe good case for useContext
  return (
    <>
      {displayLocationEvents ? (
        <LocationEventVisualizer
          locationEvents={locationEventData}
          handleBack={() => setLocationEventData([])}
          selectedTrip={selectedTrip}
          selectedZone={selectedZone}
        />
      ) : selectedTrip ? (
        <ZoneVisualizer
          zoneEvents={zoneEventList}
          handleBack={handleBackToTrips}
          setLocationEventData={setLocationEventData}
          setSelectedZone={setSelectedZone}
          selectedTrip={selectedTrip}
        />
      ) : isFetching ? (
        <>
          <TripSearchAndDownloadBar
            endDate={endDate}
            setEndDate={setEndDate}
            startDate={startDate}
            setStartDate={setStartDate}
            plateNumber={plateNumberFilter}
            setPlateNumber={setPlateNumberFilter}
            plateState={plateStateFilter}
            setPlateState={setPlateStateFilter}
            buttonState={isFetching}
            onButtonClick={fetchTrips}
            page={page}
            setPage={setPage}
            elemsPerPage={elemsPerPage}
            setElemsPerPage={setElemsPerPage}
            tripsLength={trips.length}
            fetchTrips={fetchTrips}
          />
          <p>Loading trips...</p>
        </>
      ) : (
        <>
          <TripSearchAndDownloadBar
            endDate={endDate}
            setEndDate={setEndDate}
            startDate={startDate}
            setStartDate={setStartDate}
            plateNumber={plateNumberFilter}
            setPlateNumber={setPlateNumberFilter}
            plateState={plateStateFilter}
            setPlateState={setPlateStateFilter}
            buttonState={isFetching}
            onButtonClick={fetchTrips}
            page={page}
            setPage={setPage}
            elemsPerPage={elemsPerPage}
            setElemsPerPage={setElemsPerPage}
            tripsLength={trips.length}
            fetchTrips={fetchTrips}
          />
          <Container fluid className="bliss-card">
            <div>
              <Table className="metrics-table bliss-card" variant="dark" hover size="sm">
                <TripHeader />
                <tbody>
                  {trips.map((trip: Trip) => (
                    <TripVisualizerElement
                      key={`${trip.plateNumber}-${trip.plateState}-${trip.startedAt}-${trip.endedAt}`}
                      trip={trip}
                      goToZone={goToZone}
                      setSelectedTrip={setSelectedTrip}
                    />
                  ))}
                </tbody>
              </Table>
            </div>
          </Container>
        </>
      )}
    </>
  );
};

export default TripVisualizer;
