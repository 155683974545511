import React, { useState } from 'react';
import { Form, ButtonToolbar, OverlayTrigger, Tooltip } from 'react-bootstrap';
// @ts-ignore ts-migrate(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import Select from 'react-select';
import customStyles from './themes';

export const mapToOptions = (opts: any[]) => opts.map((opt) => ({ value: opt, label: opt }));

const findDefaultValue = (value: any, defaultValue: any, options: any) => {
  if (defaultValue && value && defaultValue !== '' && value !== '' && value === defaultValue) {
    const vAttribute = options.filter((e: any) => defaultValue === e.value);
    return vAttribute[0];
  }
  return undefined;
};

type OwnProps = {
  direction?: string;
  tooltipText?: string;
  delayShow?: number;
  delayHide?: number;
  toolbarVariant?: string;
};

// @ts-ignore ts-migrate(2456) FIXME: Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof FormikSelect.defaultProps;

// @ts-ignore ts-migrate(7022) FIXME: 'FormikSelect' implicitly has type 'any' because i... Remove this comment to see the full error message
const FormikSelect = ({
  options,
  label,
  abbr,
  customOption,
  error,
  touched,
  helpText,
  name,
  defaultValue,
  value,
  isDisabled,
  direction,
  tooltipText,
  delayShow,
  delayHide,
  ...props
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Form.Group
      controlId={label}
      className={`${isDisabled ? 'disabled' : ''} ${error && touched ? 'is-invalid' : ''}`}
    >
      {label && (
        <Form.Label className="d-flex align-items-center text-white">
          {label} {abbr && <abbr className="text-danger ml-1">*</abbr>}
          {tooltipText && (
            <ButtonToolbar className="ml-2">
              <OverlayTrigger
                key={direction}
                placement={direction}
                delay={{ show: delayShow, hide: delayHide }}
                // @ts-ignore ts-migrate(2741) FIXME: Property 'id' is missing in type '{ children: any;... Remove this comment to see the full error message
                overlay={<Tooltip>{tooltipText}</Tooltip>}
                // @ts-ignore ts-migrate(2747) FIXME: 'OverlayTrigger' components don't accept text as c... Remove this comment to see the full error message
              >
                ?
              </OverlayTrigger>
            </ButtonToolbar>
          )}
        </Form.Label>
      )}
      <Select
        styles={customStyles}
        className={`${isOpen ? 'border-on ' : ''}`}
        placeholder="Search..."
        onMenuOpen={() => setIsOpen(true)}
        onMenuClose={() => setIsOpen(false)}
        loadingMessage={() => 'Loading...'}
        noOptionsMessage={() => 'No options'}
        options={options}
        isSearchable
        name={name}
        defaultValue={defaultValue}
        value={findDefaultValue(value, defaultValue, options)}
        isDisabled={isDisabled}
        {...props}
      />
      {error && touched && <Form.Text className="text-danger">{error}</Form.Text>}
      {helpText && <Form.Text className="text-muted">{helpText}</Form.Text>}
    </Form.Group>
  );
};

FormikSelect.defaultProps = {
  direction: 'top',
  delayShow: 250,
  tooltipText: '',
  delayHide: 0,
  toolbarVariant: ''
};

export default FormikSelect;
