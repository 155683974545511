import { Corridor } from 'domain/entities/corridor.entity';
import { API } from './api';

enum Path {
  CORRIDOR = '/corridor'
}

export const getCorridor = (roadId: string) =>
  API.get<Corridor>(Path.CORRIDOR, {
    params: {
      roadId
    }
  }).then((response) => response.data);
