import { Trip } from 'domain/entities/trip.entity';
import { TripsSummary } from 'domain/entities/tripSummary';
import { PlateState } from 'domain/entities/waleDetection.entity';
import { API } from './api';

enum Path {
  STRUCTURED_TRIP_DATA = '/trips/structured',
  TRIPS_SUMMARY = '/trips/summary',
  STRUCTURED_CSV_TRIP_DATA = '/trips/csv'
}

export const getStructuredTrips = (
  startDate: Date,
  endDate: Date,
  plateNumber: string | null,
  plateState: PlateState | null,
  elemsPerPage: number,
  page: number,
  id: string | null
): Promise<Trip[]> =>
  API.get<Trip[]>(Path.STRUCTURED_TRIP_DATA, {
    params: {
      start_date: startDate.getTime(),
      end_date: endDate.getTime(),
      ...(plateNumber && { plate_number: plateNumber }),
      ...(plateState && { plate_state: plateState }),
      limit: elemsPerPage,
      page,
      ...(id && { id })
    }
  }).then((response) => response.data);

export const getTripsSummary = (startDate?: Date, endDate?: Date): Promise<TripsSummary> =>
  API.get<TripsSummary>(Path.TRIPS_SUMMARY, {
    params: {
      ...(startDate && { start_date: startDate.getTime() }),
      ...(endDate && { end_date: endDate.getTime() })
    }
  }).then((response) => response.data);

export const getStructuredCSVTrips = (
  startDate: Date,
  endDate: Date,
  plateNumber: string | null,
  plateState: PlateState | null
) =>
  API.get(Path.STRUCTURED_CSV_TRIP_DATA, {
    params: {
      start_date: startDate.getTime(),
      end_date: endDate.getTime(),
      ...(plateNumber && { plate_number: plateNumber }),
      ...(plateState && { plate_state: plateState })
    },
    responseType: 'blob'
  });
