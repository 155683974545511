import { getVehicleEventMetrics } from 'data/waleDetection.repository';
import { isEqual } from 'lodash';
import { getWaleLanes } from 'presentation/helpers/waleId';

// Buffer time for vehicle events that won't have an ALPR reading
const BUFFER_TIME = 20 * 1000; // 20 seconds

export const eventCountForMetrics = (waleId: string, lane?: number) => {
  const areMetricsOnlyForExpressLane =
    lane === undefined
      ? isEqual(getWaleLanes(waleId), getWaleLanes(waleId, 'EL'))
      : getWaleLanes(waleId, 'EL').includes(lane);
  return areMetricsOnlyForExpressLane ? 20 : 100;
};

export const getVehicleEventMetricsUseCase = (waleId: string, date: Date, lane?: number) => {
  const queryDate = new Date(date.getTime() - BUFFER_TIME);
  return getVehicleEventMetrics(waleId, queryDate, lane, eventCountForMetrics(waleId, lane));
};
