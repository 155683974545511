import React from 'react';
import { LocationEvent } from 'domain/entities/locationEvent.entity';
import { EventCard } from 'components/EventCard/EventCard';
import { useImageUrls, useSearchStrings } from './LocationEventElement.view-model';

const LocationEventElement = ({ locationEvent }: { locationEvent: LocationEvent }) => {
  const imageUrls = useImageUrls(locationEvent);
  const searchStrings = useSearchStrings(locationEvent);

  const PATH_TO_EVENTS = '/wale/events';

  return (
    <EventCard
      cardText={locationEvent.locationId}
      startedAt={locationEvent.capturedAt}
      leftImageUrl={(!locationEvent.front && 'no-image') || imageUrls.front}
      rightImageUrl={(!locationEvent.back && 'no-image') || imageUrls.back}
      leftImageLink={(searchStrings.front && `${PATH_TO_EVENTS}?${searchStrings.front}`) || ''}
      rightImageLink={(searchStrings.back && `${PATH_TO_EVENTS}?${searchStrings.back}`) || ''}
    />
  );
};

export default LocationEventElement;
