import { getStructuredTrips } from 'data/trip.repository';
import { Trip } from 'domain/entities/trip.entity';
import { PlateState } from 'domain/entities/waleDetection.entity';
import { subtractDays } from 'services/timeUtils';

export const getFilteredStructuredTripsUseCase = async (
  startDate: Date | null,
  endDate: Date | null,
  plateNumber: string | null,
  plateState: PlateState | null,
  elemsPerPage: number,
  page: number,
  id: string | null
): Promise<Trip[]> => {
  const realEndDate = endDate ?? new Date();
  const realStartDate = startDate ?? new Date(subtractDays(realEndDate, 365));
  return getStructuredTrips(
    realStartDate,
    realEndDate,
    plateNumber,
    plateState,
    elemsPerPage,
    page,
    id
  );
};
