/* eslint-disable no-underscore-dangle */
import { GetWaleDetectionMode, getWaleDetectionByDate } from 'data/waleDetection.repository';
import { HumanReview, WaleDetection } from 'domain/entities/waleDetection.entity';
import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import InnerImageZoom from 'react-inner-image-zoom';
import defaultImage from 'assets/images/blissway_default.png';
import { FormikSelect } from 'components';
import { mapToOptions } from 'components/Select/FormikSelect';
import { useQuery } from '@tanstack/react-query';
import { getEventLabelsUseCase } from 'domain/use-cases/eventLabels/getEventLabels.use-case';
import { getRoadDateTime } from 'services/timeUtils';
import { updateWaleDetectionReviewUseCase } from 'domain/use-cases/waleDetection/updateWaleDetectionReview.use-case';
import { useUser } from 'presentation/hooks/data/useUser';
import { isEqual } from 'lodash';

export type EventVisualizerInputProps = {
  waleId: string;
  startDate: Date;
  endDate: Date | null;
  batchSize: number | null;
  quickLabels: string[];
  labels: string[];
};

const QUICK_LABEL_KEYS = ['q', 'w', 'e', 'f', 'c'];
const OTHER_LABEL_KEYS = ['r', 'd', 'v'];

const saveLastReviewedToLocalStorage = (
  quickLabels: string[],
  otherLabels: string[],
  endDate: Date | null,
  batchSize: number | null,
  reviewedTotal: number,
  waleId: string,
  lastReviewedDate?: Date | string,
  laneNumber?: number
) => {
  localStorage.setItem(
    'lastLabelReview',
    JSON.stringify({
      waleId,
      lastReviewedDate,
      laneNumber,
      quickLabels,
      otherLabels,
      endDate,
      batchSize,
      reviewedTotal
    })
  );
};

export const LabelingEventVisualizer = ({
  waleId,
  startDate,
  endDate,
  batchSize,
  quickLabels,
  labels: otherLabels,
  onFinishedBatch
}: EventVisualizerInputProps & { onFinishedBatch: () => void }) => {
  const [queryTime, setQueryTime] = useState(startDate);

  const [reviewedList, setReviewedList] = useState<Set<string>>(new Set());
  const [currentEvent, setCurrentEvent] = useState<WaleDetection | null>(null);
  const [selectedLabels, setSelectedLabels] = useState<string[]>([]);

  const user = useUser();
  const sendReview = async () => {
    if (!currentEvent?._id) return;

    const labelsToSend = [...selectedLabels];
    labelsToSend.sort();

    let modifiedLabels = true;

    if (
      labelsToSend.length === 0 &&
      currentEvent.humanReview?.internal?.labels?.event === undefined
    ) {
      modifiedLabels = false;
    } else if (currentEvent.humanReview?.internal?.labels?.event) {
      const originalLabels = [...currentEvent.humanReview.internal.labels.event];
      originalLabels.sort();
      if (isEqual(labelsToSend, originalLabels)) {
        modifiedLabels = false;
      }
    }

    const humanReviewInput: HumanReview = {
      internal: {
        labeledBy: user.email,
        completedAt: new Date(),
        labels: { event: labelsToSend }
      }
    };

    let newReviewed = 0;

    if (modifiedLabels) {
      await updateWaleDetectionReviewUseCase(currentEvent._id, humanReviewInput);
      if (batchSize !== null)
        setReviewedList((currentList) => {
          const newSet = new Set(currentList);
          newSet.add(currentEvent!._id);
          newReviewed = newSet.size - currentList.size;
          return newSet;
        });
    }

    saveLastReviewedToLocalStorage(
      quickLabels,
      otherLabels,
      endDate,
      batchSize,
      reviewedList.size + newReviewed,
      waleId,
      currentEvent?.capturedAt ?? undefined,
      currentEvent?.laneNumber
    );

    getNextEvent(reviewedList.size + newReviewed);
  };

  const getEvent = (direction: GetWaleDetectionMode) => (reviewedSoFar?: number) =>
    getWaleDetectionByDate(direction)(waleId, queryTime).then((event) => {
      if (
        event === null ||
        (endDate && new Date(event.capturedAt) > endDate) ||
        (batchSize && reviewedSoFar !== undefined && reviewedSoFar >= batchSize)
      ) {
        onFinishedBatch();
      } else {
        setQueryTime(new Date(event.capturedAt));
        setCurrentEvent(event);
        if (event.humanReview?.internal?.labels?.event) {
          setSelectedLabels(event.humanReview.internal.labels.event);
        } else {
          setSelectedLabels([]);
        }
      }
    });
  const getNextEvent = getEvent(GetWaleDetectionMode.NEXT);
  const getPreviousEvent = getEvent(GetWaleDetectionMode.PREV);

  const { isFetching, isLoading, data } = useQuery({
    queryKey: ['labels'],
    queryFn: getEventLabelsUseCase
  });

  useEffect(() => {
    getNextEvent();
  }, []);

  const handleSelectedLabel = (selectedLabel: string) => {
    if (selectedLabels.includes(selectedLabel)) {
      setSelectedLabels((labels) => labels.filter((label) => label !== selectedLabel));
    } else {
      setSelectedLabels((labels) => [...labels, selectedLabel]);
    }
  };

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if ((event.target as HTMLElement | null)?.tagName === 'INPUT') {
        return;
      }
      const quickKeyIndex = QUICK_LABEL_KEYS.indexOf(event.key.toLowerCase());
      if (!event.repeat && quickKeyIndex !== -1) {
        handleSelectedLabel(quickLabels[quickKeyIndex]);
      }
      const otherKeyIndex = OTHER_LABEL_KEYS.indexOf(event.key.toLowerCase());
      if (!event.repeat && otherKeyIndex !== -1) {
        handleSelectedLabel(otherLabels[otherKeyIndex]);
      }
      if (event.key === ' ') {
        event.preventDefault();
        if (!event.repeat) sendReview();
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [quickLabels, selectedLabels]);

  if (!currentEvent || isFetching || isLoading) return null;

  return (
    <Col key={`event${currentEvent.capturedAt}`}>
      <Row>
        <Col xs={1}>
          <Button onClick={() => getPreviousEvent(reviewedList.size)}>Prev</Button>
        </Col>
        <Col xs={10}>
          <Row>
            <Col xs={2}>
              <Row>WALE: {currentEvent.waleId}</Row> <Row>ID: {currentEvent._id}</Row>
              <Row>Lane: {currentEvent.laneNumber}</Row>
            </Col>

            <Col xs={3}>Captured At: {getRoadDateTime(currentEvent.capturedAt)}</Col>
            {currentEvent.humanReview?.internal?.labels?.event ? (
              <Col xs={3}>
                <Row>Last labeled by: {currentEvent.humanReview.internal.labeledBy}</Row>
                <Row>
                  Current labels: {currentEvent.humanReview.internal.labels.event.toString()}
                </Row>
              </Col>
            ) : null}
            <Col xs={3}>
              {endDate
                ? `Batch End Date: ${getRoadDateTime(endDate)}`
                : `Progress: ${reviewedList.size} / ${batchSize}`}
            </Col>
          </Row>
        </Col>
        <Col xs={1}>
          <Button onClick={() => getNextEvent(reviewedList.size)}>Next</Button>
        </Col>
      </Row>

      <Row>
        <Col xs={9}>
          <Row>
            {currentEvent.imageUrls?.map((imageUrl, idx) => (
              <Col
                // eslint-disable-next-line react/no-array-index-key
                key={`capture${idx}${currentEvent._id}`}
                style={{ padding: 0, margin: 0, maxWidth: '800px' }}
                xs={4}
              >
                <InnerImageZoom
                  zoomPreload
                  zoomScale={1.5}
                  zoomType="hover"
                  className="wale-img fade-in"
                  src={imageUrl ?? defaultImage}
                />
              </Col>
            ))}
          </Row>
        </Col>
        <Col xs={3}>
          <Row>Quick labels:</Row>
          {quickLabels.map((label, idx) => (
            <Row key={`quickaccess${label}`}>
              <Button
                onClick={() => {
                  handleSelectedLabel(label);
                }}
              >
                {label} [{QUICK_LABEL_KEYS[idx].toUpperCase()}]
              </Button>
            </Row>
          ))}

          <Row>Labels:</Row>
          {otherLabels.map((label, idx) => (
            <Row key={`otherlabels${label}`}>
              <Button
                onClick={() => {
                  handleSelectedLabel(label);
                }}
              >
                {label}
                {OTHER_LABEL_KEYS.length > idx ? ` [${OTHER_LABEL_KEYS[idx].toUpperCase()}]` : ''}
              </Button>
            </Row>
          ))}

          <Row>All labels:</Row>
          <Row>
            <Col>
              <FormikSelect
                key={`selectedlabels${selectedLabels}`}
                isMulti
                placeholder="No selected labels"
                options={mapToOptions(data.labels)}
                onChange={(selected: { value: string }[] | null) => {
                  setSelectedLabels((selected ?? []).map((sel) => sel.value));
                }}
                defaultValue={mapToOptions(selectedLabels)}
              />
            </Col>
          </Row>
          <Row>
            <Button
              onClick={() => {
                sendReview();
              }}
            >
              Save Review [Space]
            </Button>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};
