import FormikSelect from 'components/Select';
import { userRoles } from 'pages/Users/UserForm';
import { roleName, UserRole } from 'pages/Users/userRoles';
import React, { useState } from 'react';
import { Button, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { BackToHomeBtn } from 'components';
import { RequiredEbox } from 'presentation/EboxWalePairing/EboxWalePairTypes';
import { useUser } from 'presentation/hooks/data/useUser';
import { useAlert } from 'presentation/hooks/useAlert';
import CopyToClipboardButton from '../../components/Button/CopyToClipboardButton';
import {
  mapToOptionsEboxPairs,
  onActionTokenFunctions,
  useEboxTokenPairs,
  useRandomNumber
} from './Tokens.view-model';

export const Tokens = () => {
  const user = useUser();
  const [role, setRole] = useState<number>(user.role);
  const [longToken, setLongToken] = useState('');
  const [invalidateTooltipText, setInvalidateTooltipText] = useState('');
  const [selectedEboxPair, setSelectedEboxPair] = useState<RequiredEbox | null>(null);
  const [eboxTokenPair, setEboxTokenPair] = useState<RequiredEbox & { token: string }>({
    waleId: null,
    eboxId: '',
    eboxName: '',
    token: ''
  });
  const [inputNumber, setInputNumber] = useState('');
  const { sendAlert } = useAlert();
  const randomNumber = useRandomNumber();
  const eboxPairs = useEboxTokenPairs(user.role);

  const { onGenerateButtonClick, onInputChange, onInvalidateButtonClick, onWaleTokenClick } =
    onActionTokenFunctions(
      setLongToken,
      role,
      randomNumber,
      setInvalidateTooltipText,
      selectedEboxPair,
      setEboxTokenPair,
      setSelectedEboxPair,
      sendAlert,
      inputNumber,
      setInputNumber
    );

  return (
    <Row className="full-height">
      <Col className="bliss-card m-3 tokens-overscroll">
        <Col>
          <BackToHomeBtn route="/profile" text="Back to Profile" />
        </Col>

        <Row>
          <Col
            lg={{ span: 6, offset: 3 }}
            md={{ span: 8, offset: 2 }}
            sm={{ span: 10, offset: 1 }}
            xs={11}
          >
            <h2 className="mb-5">
              <span className="text-blue text-slim text-uppercase">Tokens</span>
            </h2>
            <h3>Generate User Token</h3>
            <Col>
              In this page you can generate a token to access to our APIs. Keep in mind that if you
              reload this page after you generate a token, you can&apos;t see it again, you can only
              generate new ones.
            </Col>
            {user.role === UserRole.Admin && (
              <Col className="margin-top-tokens">
                <h6>Select Role for Token</h6>
                <FormikSelect
                  key="userRoleSelect"
                  placeholder={roleName(user.role)}
                  options={userRoles}
                  onChange={(elem: { value: UserRole }) => setRole(elem.value)}
                />
              </Col>
            )}
            <Button
              className="mt-4 btn-dark"
              disabled={!!longToken}
              onClick={onGenerateButtonClick}
            >
              Generate
            </Button>
            {longToken && (
              <>
                <h6 className="margin-top-tokens">Your token is:</h6>
                <Col className="break-words">{longToken}</Col>
                <Col>
                  <CopyToClipboardButton content={longToken} />
                </Col>
              </>
            )}
            <h3 className="margin-top-tokens">Invalidate User Tokens</h3>
            <Col>
              <p>
                Use this button to invalidate all the currently active tokens you have previously
                generated. If you do this, you will be logged out of the Dashboard sooner than
                usual.
              </p>
              <p>
                To confirm this process, write the number <b>{randomNumber}</b> below before
                pressing the button.
              </p>
            </Col>
            <Row>
              <Col className="margin-top-tokens">
                <input
                  className="bliss-input-field bliss-input-common-style"
                  onChange={onInputChange}
                />
                <OverlayTrigger
                  placement="top"
                  show={invalidateTooltipText.length > 0}
                  overlay={<Tooltip id="invalidate-tooltip">{invalidateTooltipText}</Tooltip>}
                >
                  <Button onClick={onInvalidateButtonClick} className="btn-dark margin-left-tokens">
                    Invalidate
                  </Button>
                </OverlayTrigger>
              </Col>
            </Row>
            {user.role === UserRole.Admin && (
              <>
                <h3 className="margin-top-tokens">Generate Ebox Token</h3>
                <Col>Here you can generate tokens for the Eboxes during their setup.</Col>
                <Col>
                  <FormikSelect
                    key="waleSelect"
                    className="margin-top-tokens"
                    placeholder="Select Ebox"
                    options={mapToOptionsEboxPairs(eboxPairs)}
                    onChange={(elem: { value: RequiredEbox }) => setSelectedEboxPair(elem.value)}
                  />
                </Col>
                <Col>
                  <Button
                    className="mt-4 btn-dark"
                    disabled={!selectedEboxPair}
                    onClick={onWaleTokenClick}
                  >
                    Generate Token
                  </Button>
                </Col>
                {eboxTokenPair.token && (
                  <Col className="margin-top-tokens">
                    <p>
                      Your token for Ebox {eboxTokenPair.eboxName} / {eboxTokenPair.eboxId}{' '}
                      {eboxTokenPair.waleId ? `(${eboxTokenPair.waleId}) ` : ''}is:
                    </p>
                    <Col className="break-words">{eboxTokenPair.token}</Col>
                    <CopyToClipboardButton content={eboxTokenPair.token} />
                  </Col>
                )}
              </>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
