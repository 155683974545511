import { useHistory } from 'react-router-dom';

export const useDeleteQueryParam = () => {
  const history = useHistory();
  return (key: string) => {
    const url = new URL(window.location.href);
    url.searchParams.delete(key);
    history.replace({ pathname: url.pathname, search: url.search });
  };
};
