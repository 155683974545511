import { PlateState } from 'domain/entities/waleDetection.entity';
import alabama from '../assets/images/plates/us-al.png';
import alaska from '../assets/images/plates/us-ak.png';
import arizona from '../assets/images/plates/us-az.png';
import arkansas from '../assets/images/plates/us-ar.png';
import california from '../assets/images/plates/us-ca.png';
import colorado from '../assets/images/plates/us-co.png';
import connecticut from '../assets/images/plates/us-ct.png';
import delaware from '../assets/images/plates/us-de.png';
import dc from '../assets/images/plates/us-dc.png';
import florida from '../assets/images/plates/us-fl.png';
import georgia from '../assets/images/plates/us-ga.png';
import hawaii from '../assets/images/plates/us-hi.png';
import idaho from '../assets/images/plates/us-id.png';
import illinois from '../assets/images/plates/us-il.png';
import indiana from '../assets/images/plates/us-in.png';
import iowa from '../assets/images/plates/us-ia.png';
import kansas from '../assets/images/plates/us-ks.png';
import kentucky from '../assets/images/plates/us-ky.png';
import louisiana from '../assets/images/plates/us-la.png';
import maine from '../assets/images/plates/us-me.png';
import maryland from '../assets/images/plates/us-md.png';
import massachusetts from '../assets/images/plates/us-ma.png';
import michigan from '../assets/images/plates/us-mi.png';
import minnesota from '../assets/images/plates/us-mn.png';
import mississippi from '../assets/images/plates/us-ms.png';
import missouri from '../assets/images/plates/us-mo.png';
import montana from '../assets/images/plates/us-mt.png';
import nebraska from '../assets/images/plates/us-ne.png';
import nevada from '../assets/images/plates/us-nv.png';
import newHampshire from '../assets/images/plates/us-nh.png';
import newJersey from '../assets/images/plates/us-nj.png';
import newMexico from '../assets/images/plates/us-nm.png';
import newYork from '../assets/images/plates/us-ny.png';
import northCarolina from '../assets/images/plates/us-nc.png';
import northDakota from '../assets/images/plates/us-nd.png';
import ohio from '../assets/images/plates/us-oh.png';
import oklahoma from '../assets/images/plates/us-ok.png';
import oregon from '../assets/images/plates/us-or.png';
import pennsylvania from '../assets/images/plates/us-pa.png';
import rhodeIsland from '../assets/images/plates/us-ri.png';
import southCarolina from '../assets/images/plates/us-sc.png';
import southDakota from '../assets/images/plates/us-sd.png';
import tennessee from '../assets/images/plates/us-tn.png';
import texas from '../assets/images/plates/us-tx.png';
import utah from '../assets/images/plates/us-ut.png';
import vermont from '../assets/images/plates/us-vt.png';
import virginia from '../assets/images/plates/us-va.png';
import washington from '../assets/images/plates/us-wa.png';
import westVirginia from '../assets/images/plates/us-wv.png';
import wisconsin from '../assets/images/plates/us-wi.png';
import wyoming from '../assets/images/plates/us-wy.png';
import none from '../assets/images/plates/none.png';

export const stateLookup: Record<string, { fullName: string; image: string }> = {
  [PlateState.Alabama]: { fullName: 'Alabama', image: alabama },
  [PlateState.Alaska]: { fullName: 'Alaska', image: alaska },
  [PlateState.Arizona]: { fullName: 'Arizona', image: arizona },
  [PlateState.Arkansas]: { fullName: 'Arkansas', image: arkansas },
  [PlateState.California]: { fullName: 'California', image: california },
  [PlateState.Colorado]: { fullName: 'Colorado', image: colorado },
  [PlateState.Connecticut]: { fullName: 'Connecticut', image: connecticut },
  [PlateState.Delaware]: { fullName: 'Delaware', image: delaware },
  [PlateState.DC]: { fullName: 'DC', image: dc },
  [PlateState.Florida]: { fullName: 'Florida', image: florida },
  [PlateState.Georgia]: { fullName: 'Georgia', image: georgia },
  [PlateState.Hawaii]: { fullName: 'Hawaii', image: hawaii },
  [PlateState.Idaho]: { fullName: 'Idaho', image: idaho },
  [PlateState.Illinois]: { fullName: 'Illinois', image: illinois },
  [PlateState.Indiana]: { fullName: 'Indiana', image: indiana },
  [PlateState.Iowa]: { fullName: 'Iowa', image: iowa },
  [PlateState.Kansas]: { fullName: 'Kansas', image: kansas },
  [PlateState.Kentucky]: { fullName: 'Kentucky', image: kentucky },
  [PlateState.Louisiana]: { fullName: 'Louisiana', image: louisiana },
  [PlateState.Maine]: { fullName: 'Maine', image: maine },
  [PlateState.Maryland]: { fullName: 'Maryland', image: maryland },
  [PlateState.Massachusetts]: { fullName: 'Massachusetts', image: massachusetts },
  [PlateState.Michigan]: { fullName: 'Michigan', image: michigan },
  [PlateState.Minnesota]: { fullName: 'Minnesota', image: minnesota },
  [PlateState.Mississippi]: { fullName: 'Mississippi', image: mississippi },
  [PlateState.Missouri]: { fullName: 'Missouri', image: missouri },
  [PlateState.Montana]: { fullName: 'Montana', image: montana },
  [PlateState.Nebraska]: { fullName: 'Nebraska', image: nebraska },
  [PlateState.Nevada]: { fullName: 'Nevada', image: nevada },
  [PlateState.NewHampshire]: { fullName: 'New Hampshire', image: newHampshire },
  [PlateState.NewJersey]: { fullName: 'New Jersey', image: newJersey },
  [PlateState.NewMexico]: { fullName: 'New Mexico', image: newMexico },
  [PlateState.NewYork]: { fullName: 'New York', image: newYork },
  [PlateState.NorthCarolina]: { fullName: 'North Carolina', image: northCarolina },
  [PlateState.NorthDakota]: { fullName: 'North Dakota', image: northDakota },
  [PlateState.Ohio]: { fullName: 'Ohio', image: ohio },
  [PlateState.Oklahoma]: { fullName: 'Oklahoma', image: oklahoma },
  [PlateState.Oregon]: { fullName: 'Oregon', image: oregon },
  [PlateState.Pennsylvania]: { fullName: 'Pennsylvania', image: pennsylvania },
  [PlateState.RhodeIsland]: { fullName: 'Rhode Island', image: rhodeIsland },
  [PlateState.SouthCarolina]: { fullName: 'South Carolina', image: southCarolina },
  [PlateState.SouthDakota]: { fullName: 'South Dakota', image: southDakota },
  [PlateState.Tennessee]: { fullName: 'Tennessee', image: tennessee },
  [PlateState.Texas]: { fullName: 'Texas', image: texas },
  [PlateState.Utah]: { fullName: 'Utah', image: utah },
  [PlateState.Vermont]: { fullName: 'Vermont', image: vermont },
  [PlateState.Virginia]: { fullName: 'Virginia', image: virginia },
  [PlateState.Washington]: { fullName: 'Washington', image: washington },
  [PlateState.WestVirginia]: { fullName: 'West Virginia', image: westVirginia },
  [PlateState.Wisconsin]: { fullName: 'Wisconsin', image: wisconsin },
  [PlateState.Wyoming]: { fullName: 'Wyoming', image: wyoming }
};

export const isDefaultState = (stateCode: string) => {
  if (stateCode in stateLookup && 'image' in stateLookup[stateCode]) return false;
  return true;
};

export const getStateFullname = (stateCode?: string) => {
  if (stateCode && stateCode in stateLookup) return stateLookup[stateCode].fullName;
  return stateCode;
};

export const getPlateStateStyle = (stateCode: string) => {
  if (!isDefaultState(stateCode)) return stateLookup[stateCode].image;
  return none;
};
