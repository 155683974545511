import {
  combineLatest,
  distinctUntilChanged,
  filter,
  fromEvent,
  merge,
  Observable,
  share,
  shareReplay
} from 'rxjs';

export enum KeyCode {
  D = 'KeyD',
  A = 'KeyA',
  E = 'KeyE',
  S = 'KeyS',
  C = 'KeyC',
  F = 'KeyF',
  B = 'KeyB',
  M = 'KeyM',
  ControlLeft = 'ControlLeft',
  ControlRight = 'ControlRight'
}

const keyDown$ = fromEvent<KeyboardEvent>(document, 'keydown');
const keyUp$ = fromEvent<KeyboardEvent>(document, 'keyup');

// Observables for all keydown and keyup events

// All KeyboardEvents - emitted only when KeyboardEvent changes (key or type)
const keyEvents$ = merge(keyDown$, keyUp$).pipe(
  distinctUntilChanged((a, b) => a.code === b.code && a.type === b.type),
  share()
);
const createKeyPressStream = (charCode: KeyCode) =>
  keyEvents$.pipe(filter((event) => event.code === charCode.valueOf()));

const streams: Record<string, Observable<KeyboardEvent>> = {
  [KeyCode.D]: createKeyPressStream(KeyCode.D),
  [KeyCode.A]: createKeyPressStream(KeyCode.A),
  [KeyCode.E]: createKeyPressStream(KeyCode.E),
  [KeyCode.S]: createKeyPressStream(KeyCode.S),
  [KeyCode.C]: createKeyPressStream(KeyCode.C),
  [KeyCode.F]: createKeyPressStream(KeyCode.F),
  [KeyCode.B]: createKeyPressStream(KeyCode.B),
  [KeyCode.M]: createKeyPressStream(KeyCode.M),
  [KeyCode.ControlLeft]: createKeyPressStream(KeyCode.ControlLeft).pipe(shareReplay()),
  [KeyCode.ControlRight]: createKeyPressStream(KeyCode.ControlRight)
};

export const shortcut = (shortcuts: KeyCode[]): Observable<KeyboardEvent[]> => {
  // Create KeyboardEvent Observable for specified KeyCode

  // Create Event Stream for every KeyCode in shortcuts
  const keyCodeEvents$ = shortcuts.map((s) => streams[s]);

  // Emit when specified keys are pressed (keydown).
  // Emit only when all specified keys are pressed at the same time.
  // More on combineLatest below
  return combineLatest(keyCodeEvents$).pipe(
    filter<KeyboardEvent[]>((arr) => arr.every((a) => a.type === 'keydown'))
  );
};
