import { Wale } from 'domain/entities/wale.entity';
import { VehicleEventMetrics } from 'domain/entities/waleDetection.entity';
import { WaleStatus } from 'domain/entities/waleStatus.entity';
import { getVehicleEventMetricsUseCase } from 'domain/use-cases/waleDetection/getVehicleEventMetrics.use-case';
import { getWaleStatusUseCase } from 'domain/use-cases/waleStatus/getWaleStatus.use-case';
import { OperationStatus } from '../OperationStatusIndicator';
import getWALEOperationalStatus from './getWALEOperationalStatus';

export type WaleWithMetrics = Wale & {
  vehicleEventMetrics: VehicleEventMetrics;
  waleStatus: WaleStatus | null;
  status: OperationStatus;
  statusMessages?: string[];
  alertCounts?: Record<OperationStatus, number>;
};

const MAX_CONCURRENT_CALLS = 10;

const getWalesWithMetrics = async (endDate: Date, wales: Wale[]): Promise<WaleWithMetrics[]> => {
  const returnList = [];
  const walesClone = [...wales];

  while (walesClone.length) {
    // eslint-disable-next-line no-await-in-loop
    const data = await Promise.all(
      walesClone.splice(0, MAX_CONCURRENT_CALLS).map(async (wale) => {
        const [vehicleEventMetrics, waleStatus] = await Promise.all([
          getVehicleEventMetricsUseCase(wale.waleId, endDate),
          getWaleStatusUseCase(endDate, wale.waleId)
        ]);

        const { status, statusMessages } = getWALEOperationalStatus({
          ...wale,
          vehicleEventMetrics,
          waleStatus,
          status: 'unknown'
        });

        const result: WaleWithMetrics = {
          ...wale,
          vehicleEventMetrics,
          waleStatus,
          status,
          statusMessages
        };

        return result;
      })
    );

    const activeWaleData = data.filter((item) => item.waleStatus?.systemScheduleOn);

    returnList.push(...activeWaleData);
  }

  return returnList;
};

export default getWalesWithMetrics;
