/* eslint-disable camelcase */
import React, { useState } from 'react';
import { Row, Col, Button, Container } from 'react-bootstrap';
// @ts-ignore ts-migrate(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import Creatable from 'react-select/creatable';
// @ts-ignore ts-migrate(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import DatetimeRangePicker from 'react-datetime-range-picker';
// @ts-ignore ts-migrate(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import Select from 'react-select';

import { errorCodeLookup } from 'components/Select/ErrorCodeSelect';
import { useWaleList } from 'presentation/hooks/useWaleList';
import { getLabeledWaleDetectionsUseCase } from 'domain/use-cases/waleDetection/getWaleDetection.use-case';
import { getEventLabelsUseCase } from 'domain/use-cases/eventLabels/getEventLabels.use-case';
import { genericErrorLog } from 'data/api/genericErrorMessage';
import customStyles from '../../components/Select/themes';
import { fromRoadToUnix } from '../../services/timeUtils';

const NUMBER_OF_EVENTS = 20;

const errorCodeOptions = Object.keys(errorCodeLookup).map((key) => ({
  // @ts-ignore ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  label: errorCodeLookup[key],
  value: key
}));

interface searchParameters {
  date: { start?: Date; end?: Date };
  waleIds: string[];
  labels: any;
  errorCodes: any;
}

export interface LabeledDetectionsParams {
  limit: number;
  waleIds: string[];
  labels: string[];
  errors_gas?: string[];
  endDate?: number;
  startDate?: number;
}

const SearchBarLabels = ({ setEventsData }: any) => {
  const [eventLabelsDB, setEventLabelsDB] = useState(''); // available labels in DB
  const [labelsFetched, setLabelsFetched] = useState(false);
  const [filterByDate, setFilterByDate] = useState(false);
  const [searchParams, setSearchParams] = useState<searchParameters>({
    date: {}, // {startRange, endRange}
    waleIds: [],
    labels: {}, // {{label: "someLabel", value:1}}
    errorCodes: {}
  });
  const waleList = useWaleList();

  const mapToOptions = (opts: any[]) => opts.map((opt) => ({ value: opt, label: opt }));

  if (!labelsFetched) {
    getEventLabelsUseCase()
      .then((data: any) => {
        setEventLabelsDB(data?.labels ?? []);
        setLabelsFetched(true);
      })
      .catch(genericErrorLog);
  }

  const handleDateCheckbox = () => {
    const newFilterByDate = !filterByDate;
    if (!newFilterByDate) setSearchParams({ ...searchParams, date: {} });
    setFilterByDate(newFilterByDate);
  };

  const handleWaleIdsChange = (selection: any[]) => {
    const selectionIds = selection.map((opt) => opt.value);
    if (selectionIds.includes('*')) {
      if (selectionIds.length > waleList.length) {
        setSearchParams({ ...searchParams, waleIds: [] });
      } else {
        setSearchParams({ ...searchParams, waleIds: waleList });
      }
    } else {
      setSearchParams({ ...searchParams, waleIds: selectionIds });
    }
  };

  const searchEvents = () => {
    if (searchParams.waleIds.length > 0) {
      const params: LabeledDetectionsParams = {
        limit: NUMBER_OF_EVENTS,
        waleIds: searchParams.waleIds,
        labels: Object.values(searchParams.labels).map((item) => (item as any).label)
      };
      if (searchParams.errorCodes) {
        params.errors_gas = Object.values(searchParams.errorCodes).map(
          (item) => (item as any).value
        );
      }

      if (filterByDate) {
        params.endDate = fromRoadToUnix((searchParams.date as any).end);
        params.startDate = fromRoadToUnix((searchParams.date as any).start);
      }

      getLabeledWaleDetectionsUseCase(params)
        .then((data) => {
          setEventsData(data);
        })
        .catch(genericErrorLog);
    }
  };

  return (
    <Container className="bliss-card" style={{ margin: '12px' }}>
      <Row style={{ margin: '0px' }}>
        <Col md={6}>
          <h2 className="text-blue text-slim text-uppercase">Search events by Labels</h2>
        </Col>
      </Row>
      <Row style={{ margin: '10px' }}>
        <Col>
          <Creatable
            isClearable
            isValidNewOption={() => false}
            isMulti
            onChange={(labels: any) => {
              setSearchParams({ ...searchParams, labels: { ...labels } });
            }}
            options={Object.values(eventLabelsDB).map((item, i) => ({
              label: item,
              value: i
            }))}
            styles={customStyles}
            placeholder="Filter by Labels (AND)"
          />
        </Col>
        <Col>
          <Button type="submit" onClick={searchEvents}>
            Search
          </Button>
        </Col>
      </Row>
      <Row style={{ margin: '10px' }}>
        <Col>
          <Select
            isMulti
            styles={customStyles}
            placeholder="Filter by error code (OR)"
            options={errorCodeOptions}
            onChange={(errors: any) => {
              setSearchParams({ ...searchParams, errorCodes: errors });
            }}
          />
        </Col>
      </Row>
      <Row style={{ margin: '10px' }}>
        <Col>
          <Select
            placeholder="Select wale Ids (OR)"
            isMulti
            styles={customStyles}
            isClearable={false}
            closeMenuOnSelect={false}
            options={[{ value: '*', label: 'Select/Deselect All' }, ...mapToOptions(waleList)]}
            value={mapToOptions(searchParams.waleIds)}
            onChange={handleWaleIdsChange}
          />
        </Col>
        <Col>
          Filter by date (MT)
          <input
            type="checkbox"
            style={{ margin: 10 }}
            defaultChecked={filterByDate}
            onChange={handleDateCheckbox}
          />
          {filterByDate && (
            <DatetimeRangePicker
              timeFormat="HH:mm:ss"
              closeOnSelect
              onChange={(item: any) => {
                setSearchParams({ ...searchParams, date: item });
              }}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default SearchBarLabels;
