import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';

export const CarouselControls = ({
  carouselIndex,
  setCarouselIndex,
  carouselPaused,
  setCarouselPaused,
  selectedLocationsLength
}: {
  carouselIndex: number;
  setCarouselIndex: (a: number | null) => void;
  carouselPaused: boolean;
  setCarouselPaused: (a: boolean) => void;
  selectedLocationsLength: number;
}) => (
  <Row>
    <Col>Controls: </Col>
    <Col className="d-flex flex-nowrap">
      <Button
        className="carousel-button carousel-button-left"
        onClick={() =>
          setCarouselIndex((selectedLocationsLength + carouselIndex - 1) % selectedLocationsLength)
        }
      >
        ←
      </Button>
      <Button
        className="carousel-button carousel-button-play-stop"
        onClick={() => setCarouselPaused(!carouselPaused)}
      >
        {carouselPaused ? '▶' : '■'}
      </Button>
      <Button
        className="carousel-button carousel-button-right"
        onClick={() => setCarouselIndex((carouselIndex + 1) % selectedLocationsLength)}
      >
        →
      </Button>
    </Col>
  </Row>
);
