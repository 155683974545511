/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { withFormik, Form, Field, getIn } from 'formik';
import { Button } from 'react-bootstrap';
// @ts-ignore ts-migrate(7016) FIXME: Could not find a declaration file for module 'yup'... Remove this comment to see the full error message
import * as Yup from 'yup';
import { NOT_SET_DEMO_PARAM, toIsoDateString } from '../../services/timeUtils';
import { roleName, UserRole } from './userRoles';
import { FormikInput, FormikSelect, FormikCheckBox } from '../../components';

export const userRoles = (
  Object.values(UserRole).filter((v) => typeof v === 'number') as UserRole[]
).map((role) => ({ label: roleName(role), value: role }));

const UserForm = (props: any) => {
  const { action, errors, submitVariant, setFieldValue, touched, user, values } = props;
  const btnMessage = action === 'new' ? 'Create' : 'Save Changes';
  const handleRoleChange = (data: any) => {
    setFieldValue('user[role]', data.value);
    if (data.value !== UserRole.Admin) {
      setFieldValue('user[demo]', false);
      setFieldValue('user[demoDate]', '');
      setFieldValue('user[demoHour]', NOT_SET_DEMO_PARAM.toString());
      setFieldValue('user[demoMinute]', NOT_SET_DEMO_PARAM.toString());
    }
  };

  const handleOnDemoChange = () => {
    const { demo: demoValue } = getIn(values, 'user');
    if (demoValue) {
      setFieldValue('user[demoDate]', '');
      setFieldValue('user[demoHour]', NOT_SET_DEMO_PARAM.toString());
      setFieldValue('user[demoMinute]', NOT_SET_DEMO_PARAM.toString());
    }
  };
  return (
    <Form autoComplete="off">
      <Field name="user[firstName]">
        {({ field }: any) => (
          <FormikInput
            {...field}
            abbr
            label="First Name"
            className="form-input"
            error={getIn(errors, field.name)}
            touched={getIn(touched, field.name)}
          />
        )}
      </Field>
      <Field name="user[lastName]">
        {({ field }: any) => (
          <FormikInput
            {...field}
            abbr
            label="Last Name"
            className="form-input"
            error={getIn(errors, field.name)}
            touched={getIn(touched, field.name)}
          />
        )}
      </Field>
      <Field name="user[email]">
        {({ field }: any) => (
          <FormikInput
            {...field}
            abbr
            label="Email"
            className="form-input"
            error={getIn(errors, field.name)}
            touched={getIn(touched, field.name)}
          />
        )}
      </Field>
      {action === 'new' && (
        <>
          <Field name="user[password]">
            {({ field }: any) => (
              <FormikInput
                {...field}
                abbr
                label="Password"
                inputType="password"
                className="form-input"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
          <Field name="user[passwordConfirmation]">
            {({ field }: any) => (
              <FormikInput
                {...field}
                abbr
                label="Password Confirmation"
                inputType="password"
                className="form-input"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </>
      )}
      <Field name="user[demo]">
        {({ field }: any) => (
          <FormikCheckBox
            field={field}
            label="Demo"
            className="my-3 text-white"
            onClick={handleOnDemoChange}
            error={errors[field.name]}
            touched={touched[field.name]}
          />
        )}
      </Field>
      {values?.user.demo && (
        <Field name="user[demoDate]">
          {({ field }: any) => (
            <FormikInput
              {...field}
              inputType="date"
              label="Demo date"
              className="bliss-input"
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      )}
      {values?.user.demo && (
        <Field name="user[demoHour]">
          {({ field }: any) => (
            <FormikInput
              {...field}
              inputType="number"
              label="Demo hour"
              className="bliss-input"
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      )}
      {values?.user.demo && (
        <Field name="user[demoMinute]">
          {({ field }: any) => (
            <FormikInput
              {...field}
              inputType="number"
              label="Demo minute"
              className="bliss-input"
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      )}
      <Field name="user[role]">
        {({ field }: any) => (
          <FormikSelect
            {...field}
            abbr
            label="Role"
            placeholder="Select User Role"
            defaultValue={user.role}
            options={userRoles}
            onChange={(data: any) => handleRoleChange(data)}
            error={getIn(errors, field.name)}
            touched={getIn(touched, field.name)}
          />
        )}
      </Field>
      <Button type="submit" variant={submitVariant} block className="my-5">
        {btnMessage}
      </Button>
    </Form>
  );
};

const setInitialValues = (props: any) => {
  const {
    _id,
    demo,
    demoDate,
    demoHour,
    demoMinute,
    email,
    firstName,
    lastName,
    password,
    passwordConfirmation,
    role
  } = props.user;
  const formattedDemoDate = demoDate ? toIsoDateString(demoDate) : '';
  return {
    user: {
      _id,
      demo,
      demoDate: formattedDemoDate,
      demoHour,
      demoMinute,
      email,
      firstName,
      lastName,
      password,
      passwordConfirmation,
      role
    }
  };
};

const validationSchema = Yup.object().shape({
  user: Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    email: Yup.string().email('Email is not valid').required('Email is required'),
    role: Yup.number().required('User role is required'),
    demoDate: Yup.string().when('demo', {
      is: true,
      then: Yup.string().required('Demo Date is required')
    }),
    demoHour: Yup.number().when('demo', {
      is: true,
      then: Yup.number()
        .lessThan(60, 'Demo Hour out of range')
        .moreThan(-2, 'Demo Hour out of range')
        .integer('Demo Hour must be integer')
        .required('Demo Hour is required')
    }),
    demoMinute: Yup.number().when('demo', {
      is: true,
      then: Yup.number()
        .lessThan(60, 'Demo Minute out of range')
        .moreThan(-2, 'Demo Minute out of range')
        .integer('Demo Minute must be integer')
        .required('Demo Minute is required')
    }),
    password: Yup.string().when('_id', (_id: any) => {
      if (!_id) return Yup.string().required('Password is required');
      return Yup.string();
    }),
    passwordConfirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match')
  })
});

const handleSubmit = (values: any, { props }: any) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: (props) => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  // @ts-ignore ts-migrate(2322) FIXME: Type '(props: any) => boolean' is not assignable t... Remove this comment to see the full error message
  validateOnMount: (props: any) => props.action !== 'new'
})(UserForm);
