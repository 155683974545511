import React from 'react';
import { Row, Col } from 'react-bootstrap';

const StateItem = ({
  plateState,
  percentage,
  pos
}: {
  plateState: string;
  percentage: number;
  pos: number;
}) => (
  <Row className="cars" key={plateState}>
    <Col md={8} xs={8}>
      <p className="car-name">
        {plateState === 'Others' ? (
          <span className="car-number" style={{ visibility: 'hidden' }}>
            {pos}.
          </span>
        ) : (
          <span className="car-number">{pos}.</span>
        )}
        {plateState}
      </p>
    </Col>
    <Col md={4} xs={4} className="text-right">
      <p className="car-percentage">{percentage.toFixed(1)}%</p>
    </Col>
  </Row>
);

const TopStatesColumns = ({
  left,
  right
}: {
  left: { plateState: string; percentage: number; key?: number }[];
  right: { plateState: string; percentage: number; key?: number }[];
}) => {
  const toStateItem = (
    state: { plateState: string; percentage: number; key?: number },
    pos: number
  ) => (
    <StateItem
      plateState={state.plateState}
      percentage={state.percentage}
      pos={pos}
      key={state.key ?? state.plateState}
    />
  );

  return (
    <Row>
      <Col md={6}>{left.map((state, index) => toStateItem(state, index + 1))}</Col>
      <Col md={6}>{right.map((state, index) => toStateItem(state, left.length + index + 1))}</Col>
    </Row>
  );
};

export const TopStates = ({
  statesList,
  topStatesAmount
}: {
  statesList: { plateState: string; percentage: number }[];
  topStatesAmount: number;
}) => {
  if (statesList.length <= 1) {
    const half = Math.ceil(topStatesAmount / 2);
    const halfArray = Array.from(Array(half).keys());
    const emptyLeft = halfArray.map((i) => ({ plateState: '-', percentage: 0, key: i }));
    const emptyRight = halfArray.map((i) => ({ plateState: '-', percentage: 0, key: i }));
    emptyRight.push({ plateState: 'Others', percentage: 0, key: half });
    return <TopStatesColumns left={emptyLeft} right={emptyRight} />;
  }

  const middle = Math.floor(statesList.length / 2);
  const left = statesList.slice(0, middle);
  const right = statesList.slice(middle);
  return <TopStatesColumns left={left} right={right} />;
};
