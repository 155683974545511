import { EboxWalePair } from 'presentation/EboxWalePairing/EboxWalePairTypes';
import { API } from './api';

enum Path {
  GET_LIST = '/eboxes/wale_pairs',
  LINK = '/eboxes/link',
  UNLINK = '/eboxes/unlink'
}

export const getEboxWalePairs = (): Promise<EboxWalePair[]> =>
  API.get<EboxWalePair[]>(Path.GET_LIST).then((response) => response.data);

export const unlinkEboxWalePair = (ebox: string, wale: string) =>
  API.post(Path.UNLINK, { ebox, wale });

export const linkEboxWalePair = (ebox: string, wale: string) => API.post(Path.LINK, { ebox, wale });
