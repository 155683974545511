import { genericErrorMessage } from 'data/api/genericErrorMessage';
import { Wale } from 'domain/entities/wale.entity';
import { createWaleUseCase } from 'domain/use-cases/wale/createWale.use-case';
import {
  getLane,
  getOrientation,
  getRoadDirection,
  getSideOrientation,
  getWaleIdBlockWithInfoInLocation,
  isOldWaleIdFormat,
  parseLocationMileFromWaleId,
  standardRoadDirections
} from 'presentation/helpers/waleId';
import { SendAlertInput } from 'presentation/hooks/useAlert';

const getOrientationWord = (waleId: string) => {
  if (getOrientation(waleId) === 'F') return 'front';
  if (getOrientation(waleId) === 'B') return 'back';
  return undefined;
};

const rewriteWaleFields = (wale: any, waleId: string, locationName: string) => {
  const newWale = { ...wale };
  // eslint-disable-next-line no-underscore-dangle
  newWale._id = undefined;
  newWale.oldConventionWaleId = undefined;
  newWale.previousEboxIds = undefined;
  newWale.active = false;
  newWale.orientation = getOrientationWord(waleId);
  newWale.configurations.system.orientation = getOrientationWord(waleId);
  newWale.configurations.vehicle_detector.lane_number = getLane(waleId);
  if (!newWale.configurations.magnetometer) {
    newWale.configurations.magnetometer = {};
  } else {
    newWale.configurations.magnetometer.sensor_ids = [];
    newWale.configurations.magnetometer.rf_channel = undefined;
    newWale.configurations.magnetometer.avg_delay_ms = undefined;
    newWale.configurations.magnetometer.transmit_interval = undefined;
  }
  newWale.external_parameters.detection_filter.previous_filter_parameters = undefined;
  newWale.waleId = waleId;
  newWale.down = false;
  newWale.cameraDown = false;
  newWale.createdAt = undefined;
  newWale.updatedAt = undefined;
  newWale.humanName = `${locationName}-${getRoadDirection(
    waleId
  )}${getWaleIdBlockWithInfoInLocation(waleId)}`;
  newWale.maintenanceStatus = [];
  newWale.previousMaintenanceStatuses = [];

  return newWale;
};

const isWaleIdFormatCorrect = (waleId: string) => {
  try {
    const splitWaleId = waleId.split('-');
    if (!standardRoadDirections.includes(getRoadDirection(waleId))) return false;
    const laneNumber = getLane(waleId);
    if (Number.isNaN(laneNumber) || laneNumber <= 0) return false;
    if (!['B', 'F'].includes(getOrientation(waleId))) return false;
    if (!['L', 'R'].includes(getSideOrientation(waleId))) return false;
    if (Number.isNaN(parseLocationMileFromWaleId(waleId))) return false;

    if (isOldWaleIdFormat(waleId)) {
      if (!splitWaleId[2].includes('_')) return false;
      return true;
    }

    if (!splitWaleId[3].includes('_')) return false;
    for (let i = 0; i < splitWaleId[5].length; i += 1) {
      const char = splitWaleId[5].charAt(i);
      if (i % 2 === 0 && !['E', 'G'].includes(char)) return false;
      if (i % 2 === 1 && Number.isNaN(Number(char))) return false;
    }
    return true;
  } catch {
    return false;
  }
};

export const onButtonClick = (
  waleId: string,
  locationName: string,
  copyWale: Wale | null,
  sendAlert: (a: SendAlertInput) => void,
  setButtonForceDisabled: (a: boolean) => void,
  onCreationSuccess: () => void
) => {
  setButtonForceDisabled(true);
  let errorMessage = '';
  if (copyWale === null) {
    errorMessage = 'WALE not set';
  } else if (!isWaleIdFormatCorrect(waleId)) {
    errorMessage = 'WALE not compliant with standard format.';
  } else {
    const waleToSend = rewriteWaleFields(copyWale, waleId, locationName);
    createWaleUseCase(waleToSend)
      .then(() => {
        onCreationSuccess();
        sendAlert({ type: 'success', message: 'WALE created!', timeout: 3000 });
      })
      .catch(genericErrorMessage(false));
  }

  if (errorMessage.length > 0) {
    sendAlert({
      type: 'error',
      message: errorMessage,
      timeout: 3000
    });
  }
};
