import { useHistory } from 'react-router-dom';

export const useSetQueryParam = () => {
  const history = useHistory();
  return (key: string, value: string) => {
    const url = new URL(window.location.href);
    url.searchParams.set(key, value);
    history.replace({ pathname: url.pathname, search: url.search });
  };
};
