import React from 'react';
import { withFormik, Form, Field, getIn } from 'formik';
import { Button, Col, Row } from 'react-bootstrap';
// @ts-ignore ts-migrate(7016) FIXME: Could not find a declaration file for module 'yup'... Remove this comment to see the full error message
import * as Yup from 'yup';
import { FormikInput } from '../../components';

const ChangePasswordForm = (props: any) => {
  const { errors, onHide, touched } = props;
  return (
    <Form autoComplete="off">
      <Row>
        <Col md={12}>
          <Field name="user[currentPassword]">
            {({ field }: any) => (
              <FormikInput
                {...field}
                abbr
                label="Current Password"
                inputType="password"
                className="form-input"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={12}>
          <Field name="user[newPassword]">
            {({ field }: any) => (
              <FormikInput
                {...field}
                abbr
                label="New Password"
                inputType="password"
                className="form-input"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={12}>
          <Field name="user[passwordConfirmation]">
            {({ field }: any) => (
              <FormikInput
                {...field}
                abbr
                label="Confirm New Password"
                inputType="password"
                className="form-input"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row className="d-flex justify-content-end my-4">
        <Col md={6}>
          <Button type="submit" variant="dark" block onClick={onHide}>
            Change Password
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
const validationSchema = Yup.object().shape({
  user: Yup.object().shape({
    currentPassword: Yup.string().required('Current Password is required'),
    newPassword: Yup.string().required('New Password is required'),
    passwordConfirmation: Yup.string().oneOf(
      [Yup.ref('newPassword'), null],
      'New Passwords must match'
    )
  })
});
const handleSubmit = (values: any, { props }: any) => {
  const { formRequest } = props;
  formRequest(values);
};
export default withFormik({
  mapPropsToValues: (props) => ({ user: (props as any).user }),
  validationSchema,
  handleSubmit,
  enableReinitialize: true
})(ChangePasswordForm);
