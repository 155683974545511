import { WaleIdsByLocation } from 'domain/use-cases/wale/getWales.use-case';
import { getLane, getWaleLocation } from 'presentation/helpers/waleId';
import _ from 'lodash';
import {
  CorridorDisplayValues,
  MetroLocationInfo
} from './components/CorridorDisplay/CorridorDisplay.view-model';

export type RealLocationPairWithMileage = {
  isAscendingMileage: boolean;
  lane?: number;
  back?: string;
  front?: string;
};

interface LocationAndMileage {
  locationId: string;
  isAscendingMileage: boolean;
}

export const getSelectedLocations = (
  corridorLocations: MetroLocationInfo[],
  isAscendingMileage: boolean
): LocationAndMileage[] => {
  const returnValue: LocationAndMileage[] = [];
  corridorLocations
    .filter((loc) => loc.chartValues.some((elem) => elem === CorridorDisplayValues.SELECTED))
    .forEach((loc) => {
      const locationIds = _.uniq(loc.walesInLocation.map(getWaleLocation));
      locationIds.forEach((locId) =>
        returnValue.push({
          locationId: locId,
          isAscendingMileage
        })
      );
    });
  if (isAscendingMileage) returnValue.reverse();
  return returnValue;
};

export const getWalePairsToShow = (
  waleIdsByLocationAndLane: WaleIdsByLocation,
  selectedLocations: LocationAndMileage[],
  waleIds: string[],
  carouselLocations: string[] | null,
) => {
  const filteredWaleIdsWithoutLocation: RealLocationPairWithMileage[] = [];
  const iterableWalePairs = Array.from(waleIdsByLocationAndLane.entries());
  selectedLocations.forEach((location) => {
    const foundElements = iterableWalePairs.filter(
      ([locationWithLane]) => getWaleLocation(locationWithLane) === location.locationId
    );
    // This sort is required to show the WALE locations in the same shape of the road display
    foundElements.sort(
      ([keyA], [keyB]) => (location.isAscendingMileage ? 1 : -1) * (getLane(keyA) - getLane(keyB))
    );

    foundElements.forEach((elem) =>
      filteredWaleIdsWithoutLocation.push({
        front: waleIds.find((waleId) => waleId === elem[1].front),
        back: waleIds.find((waleId) => waleId === elem[1].back),
        isAscendingMileage: location.isAscendingMileage
      })
    );
  });

  let partialReturnList = filteredWaleIdsWithoutLocation;
  if (carouselLocations !== null) {
    const newReturnList: RealLocationPairWithMileage[] = [];
    carouselLocations.forEach((carouselLocation) => {
      const pairsInLocation = filteredWaleIdsWithoutLocation.filter(
        (pair) =>
          (pair.back && getWaleLocation(pair.back) === carouselLocation) ||
          (pair.front && getWaleLocation(pair.front) === carouselLocation)
      );
      newReturnList.push(...pairsInLocation);
    });
    partialReturnList = newReturnList;
  }

  return partialReturnList;
};

export const getCarouselLocationIds = (
  selectedLocations: LocationAndMileage[],
  carouselIndex: number | null,
  numberOfElements: number
): string[] | null => {
  if (carouselIndex === null) return null;
  if (selectedLocations.length === 0) return [];
  const realNumberOfElements = Math.min(numberOfElements, selectedLocations.length);
  const indexes = _.range(carouselIndex, carouselIndex + realNumberOfElements).map(
    (listIdx) => listIdx % selectedLocations.length
  );

  return indexes.map((idx) => selectedLocations[idx].locationId);
};
