import { toIsoDateString } from './timeUtils';

function setInitialMe(response?: any) {
  const result = {
    email: '',
    demo: false,
    demoDate: '',
    demoHour: '',
    demoMinute: '',
    firstName: '',
    lastName: '',
    role: 2,
    admin: false,
    id: '0'
  };
  if (response && response.user) {
    // eslint-disable-next-line no-underscore-dangle
    result.id = response.user._id || '0';
    result.email = response.user.email || '';
    result.demo = response.user.demo || false;
    result.demoDate = toIsoDateString(response.user.demoDate);
    result.demoHour = response.user.demoHour;
    result.demoMinute = response.user.demoMinute;
    result.firstName = response.user.firstName || '';
    result.lastName = response.user.lastName || '';
    result.role = response.user.role;
    result.admin = response.user.role === 1;
    localStorage.setItem('demo', result.demo ? 'true' : 'false');
    if (result.demo) {
      localStorage.setItem('demoDate', result.demoDate);
      localStorage.setItem('demoHour', result.demoHour);
      localStorage.setItem('demoMinute', result.demoMinute);
    }
  }
  return result;
}

export default setInitialMe;
