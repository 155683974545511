import { genericErrorMessage } from 'data/api/genericErrorMessage';
import { eboxIdsCompareFn, eboxNamesCompareFn } from 'domain/entities/ebox.entity';
import { getEboxWalePairsUseCase } from 'domain/use-cases/ebox/getEboxWalePairs.use-case';
import { waleIdsCompareFn } from 'presentation/helpers/waleId';
import { useEffect, useState } from 'react';
import {
  EboxWalePair,
  LooseEbox,
  LooseWale,
  RequiredEboxWalePair,
  SortParams
} from './EboxWalePairTypes';

export const useEboxWalePairs = (reFetch: number, sortParams: SortParams) => {
  const [eboxWalePairs, setEboxWalePairs] = useState<EboxWalePair[]>([]);

  useEffect(() => {
    getEboxWalePairsUseCase().then(setEboxWalePairs).catch(genericErrorMessage(false));
  }, [reFetch]);

  const completeEboxWalePairs = eboxWalePairs.filter(
    (eboxWalePair) => eboxWalePair.eboxId && eboxWalePair.waleId
  ) as RequiredEboxWalePair[];
  const looseEboxes = eboxWalePairs.filter((eboxWalePair) => !eboxWalePair.waleId) as LooseEbox[];
  const looseWales = eboxWalePairs.filter((eboxWalePair) => !eboxWalePair.eboxId) as LooseWale[];
  looseEboxes.sort((a, b) => eboxNamesCompareFn(a.eboxName, b.eboxName));
  looseWales.sort((a, b) =>
    waleIdsCompareFn(looseWales.map((pair) => pair.waleId))(a.waleId, b.waleId)
  );

  const sortingFunctions = {
    waleId: (a: RequiredEboxWalePair, b: RequiredEboxWalePair) =>
      waleIdsCompareFn(completeEboxWalePairs.map((pair) => pair.waleId))(a.waleId, b.waleId),
    eboxId: (a: RequiredEboxWalePair, b: RequiredEboxWalePair) =>
      eboxIdsCompareFn(a.eboxId, b.eboxId),
    eboxName: (a: RequiredEboxWalePair, b: RequiredEboxWalePair) =>
      eboxNamesCompareFn(a.eboxName, b.eboxName)
  };
  completeEboxWalePairs.sort(sortingFunctions[sortParams.field]);
  if (!sortParams.ascending) completeEboxWalePairs.reverse();
  return { completeEboxWalePairs, looseEboxes, looseWales };
};
