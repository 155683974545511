import { chain, sumBy } from 'lodash';
import { getStateFullname } from 'services/statePlateStyles';
import { PlateState } from './waleDetection.entity';

export interface TripsSummary {
  tripsWithPlateState: number;
  tripsWithMakeModel: number;
  plateState: { [state in PlateState]?: number };
  makeModel: { [makeModel: string]: number };
  startDate?: Date;
  endDate?: Date;
}

export const getTopStates = (tripSummary: TripsSummary, limit = 10) => {
  const states = chain(tripSummary.plateState)
    .map((value, state) => ({
      plateState: getStateFullname(state) ?? '',
      percentage: ((value || 0) / tripSummary.tripsWithPlateState) * 100
    }))
    .orderBy('percentage', 'desc')
    .value()
    .slice(0, limit);

  return [
    ...states,
    {
      plateState: 'Others',
      percentage: 100 - sumBy(states, 'percentage')
    }
  ];
};

export const getTopMakeModels = (tripSummary: TripsSummary, limit = 10) => {
  const makeModels = chain(tripSummary.makeModel)
    .map((value, makeModel) => ({
      makeModel,
      percentage: ((value || 0) / tripSummary.tripsWithMakeModel) * 100
    }))
    .orderBy('percentage', 'desc')
    .value()
    .slice(0, limit);

  return [
    ...makeModels,
    { makeModel: 'Others', percentage: 100 - sumBy(makeModels, 'percentage') }
  ];
};
