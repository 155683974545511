import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { WaleStatus } from 'domain/entities/waleStatus.entity';
import { getRoadShortDateTime, getSecondsSince } from 'services/timeUtils';
import { useEndDate } from 'presentation/hooks/useEndDate';
import { useStatusData } from './StatusTable.view-model';

export type WaleStatusMonitor = Omit<WaleStatus, 'measuredAt'> & { measuredAt: Date | null };

const DANGER_STATUS_TIME_SECS = 3 * 60;
const DANGER_CAPTURE_QUEUE = 1000;
const DANGER_OLDEST_CAPTURE_TIME_SECS = 5 * 60;
const WARNING_VOLTAGE = 12.8;
const DANGER_VOLTAGE = 12.5;

interface WaleStatusInput {
  statusData: WaleStatusMonitor;
  show: boolean;
  hideWaleLane: () => void;
  showHideWaleButton: boolean;
}

const StatusTable = ({ statusData, show, hideWaleLane, showHideWaleButton }: WaleStatusInput) => {
  const {
    time,
    displayedBrightnessValue,
    autoBrightness,
    unixTime,
    batteryVoltage,
    solarPower,
    cpuTemperature,
    cpuUsage,
    memoryUsage,
    diskUsage,
    popUpStyle,
    captureQueue,
    oldestCaptureTimestamp,
    handleClick
  } = useStatusData(statusData);

  const endDate = useEndDate();
  const secondsSinceLastCapture = getSecondsSince(unixTime, endDate);

  let statusTimeStyle = '';
  if (!statusData.systemScheduleOn) statusTimeStyle = 'ooh-status';
  else if (secondsSinceLastCapture >= DANGER_STATUS_TIME_SECS) statusTimeStyle = 'warning-status';

  const captureDanger = captureQueue && captureQueue >= DANGER_CAPTURE_QUEUE;
  const oldestCaptureDanger =
    getSecondsSince(oldestCaptureTimestamp, endDate) >= DANGER_OLDEST_CAPTURE_TIME_SECS;

  const statusQueueStyle =
    (captureDanger || oldestCaptureDanger) && statusData.systemScheduleOn ? 'danger-status' : '';

  let powerStyle = '';

  if (!batteryVoltage) powerStyle = 'warning-status';
  if (batteryVoltage && statusData.systemScheduleOn) {
    if (batteryVoltage < WARNING_VOLTAGE) powerStyle = 'warning-status';
    if (batteryVoltage < DANGER_VOLTAGE) powerStyle = 'danger-status';
  }
  const batteryVoltageText = batteryVoltage?.toFixed(1) ?? '-';
  const shortSolarPower = Number.isNaN(Number(solarPower)) ? '-' : Number(solarPower).toFixed(0);

  if (!show && showHideWaleButton) {
    return (
      <Row className="d-flex justify-content-around align-items-center">
        <Button style={{ paddingTop: '3px', paddingBottom: '3px' }} onClick={hideWaleLane}>
          Hide WALE Lane Box
        </Button>
      </Row>
    );
  }
  return (
    <Row
      className="d-flex justify-content-around align-items-center"
      style={{ visibility: show ? 'visible' : 'hidden' }}
      onClick={handleClick}
    >
      <Col>
        <Row>
          <p className={`mr-3 ${statusTimeStyle}`}>{time}</p>
          <p className={statusQueueStyle}>Q: {captureQueue ?? '-'}</p>
        </Row>
        <Row>
          <p
            className={`mr-2 ${powerStyle}`}
          >{`${batteryVoltageText} V / ${shortSolarPower} W ▼`}</p>
          {!autoBrightness && <p>☼: {displayedBrightnessValue}</p>}
          <div className={`expand-pop-up ${popUpStyle}`}>
            <Row className="d-flex justify-content-between">
              <p>Battery Voltage</p>
              <p>{`${batteryVoltageText} V`}</p>
            </Row>
            <Row className="d-flex justify-content-between">
              <p>Solar Power</p>
              <p>{`${solarPower} W`}</p>
            </Row>
            <Row className="d-flex justify-content-between">
              <p>Brightness</p>
              <p>{autoBrightness ? 'Auto' : displayedBrightnessValue}</p>
            </Row>
            <Row className="d-flex justify-content-between">
              <p>CPU Temperature</p>
              <p>{`${cpuTemperature} C`}</p>
            </Row>
            <Row className="d-flex justify-content-between">
              <p>CPU Usage</p>
              <p>{`${cpuUsage} %`}</p>
            </Row>
            <Row className="d-flex justify-content-between">
              <p>Memory Usage</p>
              <p>{`${memoryUsage} %`}</p>
            </Row>
            <Row className="d-flex justify-content-between">
              <p>Disk Usage</p>
              <p>{`${diskUsage} %`}</p>
            </Row>
            {oldestCaptureTimestamp && (
              <Row className={`d-flex justify-content-between ${statusQueueStyle}`}>
                <p>Oldest capture</p>
                <p>{getRoadShortDateTime(oldestCaptureTimestamp)}</p>
              </Row>
            )}
          </div>
        </Row>
      </Col>
    </Row>
  );
};

export default StatusTable;
