const upperCaseMakes = new Map<string, string>([
  ['aad', 'AAD'],
  ['ac', 'AC'],
  ['ace ev group', 'ACE EV Group'],
  ['aec', 'AEC'],
  ['aga', 'AGA'],
  ['ail', 'AIL'],
  ['alfa-romeo', 'Alfa Romeo'],
  ['am general', 'AM General'],
  ['amc', 'AMC'],
  ['amz', 'AMZ'],
  ['apis', 'APIS'],
  ['aro', 'ARO'],
  ['avani', 'AVANI'],
  ['aws', 'AWS'],
  ['awz', 'AWZ'],
  ['bac', 'BAC'],
  ['baic', 'BAIC'],
  ['baw', 'BAW'],
  ['bet', 'BET'],
  ['bioman', 'BIOMAN'],
  ['bmc', 'BMC'],
  ['bmtf', 'BMTF'],
  ['bmw', 'BMW'],
  ['byd', 'BYD'],
  ['cmc', 'CMC'],
  ['ct&t united', 'CT&T United'],
  ['cts', 'CTS'],
  ['daf', 'DAF'],
  ['defunct', 'DEFUNCT'],
  ['dfm', 'DFM'],
  ['dim', 'DIM'],
  ['dina', 'DINA'],
  ['dkw', 'DKW'],
  ['dok-ing', 'DOK-ING'],
  ['dr motor', 'DR Motor'],
  ['dyl motorcycles', 'DYL Motorcycles'],
  ['e-z-go', 'E-Z-GO'],
  ['elvo', 'ELVO'],
  ['eraz', 'ErAZ'],
  ['fap', 'FAP'],
  ['fas', 'FAS'],
  ['faw', 'FAW'],
  ['fca', 'FCA'],
  ['fn', 'FN'],
  ['fnm', 'FNM'],
  ['fnss', 'FNSS'],
  ['fsc żuk', 'FSC Żuk'],
  ['fsm', 'FSM'],
  ['fso', 'FSO'],
  ['fsr tarpan', 'FSR Tarpan'],
  ['gac fiat', 'GAC Fiat'],
  ['gac ireland', 'GAC Ireland'],
  ['gaz', 'GAZ'],
  ['gem', 'GEM'],
  ['gkd', 'GKD'],
  ['glm', 'GLM'],
  ['gm', 'GM'],
  ['gm korea', 'GM Korea'],
  ['gmc', 'GMC'],
  ['gta', 'GTA'],
  ['htt', 'HTT'],
  ['iame', 'IAME'],
  ['iava', 'IAVA'],
  ['icml', 'ICML'],
  ['ida', 'IDA'],
  ['ika', 'IKA'],
  ['imt', 'IMT'],
  ['inkas', 'INKAS'],
  ['izh', 'IZh'],
  ['jpx', 'JPX'],
  ['kal', 'KAL'],
  ['kraz', 'KrAZ'],
  ['ksu gazal-1', 'KSU Gazal-1'],
  ['ktm', 'KTM'],
  ['land-rover', 'Land Rover'],
  ['laz', 'LAZ'],
  ['liaz', 'LIAZ'],
  ['lmx', 'LMX'],
  ['luaz', 'LuAZ'],
  ['man', 'MAN'],
  ['mava-renault', 'MAVA-Renault'],
  ['mci', 'MCI'],
  ['mdi', 'MDI'],
  ['mebea', 'MEBEA'],
  ['mercedes-benz', 'Mercedes-Benz'],
  ['mg', 'MG'],
  ['mtx', 'MTX'],
  ['mvm', 'MVM'],
  ['nag', 'NAG'],
  ['nio', 'NIO'],
  ['nsu', 'NSU'],
  ['paz', 'PAZ'],
  ['pgo', 'PGO'],
  ['raesr', 'RAESR'],
  ['raf', 'RAF'],
  ['ram', 'RAM'],
  ['reo', 'REO'],
  ['roman', 'ROMAN'],
  ['saab', 'Saab'],
  ['saic', 'SAIC'],
  ['saipa', 'SAIPA'],
  ['saz', 'SAZ'],
  ['scg', 'SCG'],
  ['seat', 'SEAT'],
  ['seaz', 'SeAZ'],
  ['siam di tella', 'SIAM Di Tella'],
  ['sin', 'SIN'],
  ['smz', 'SMZ'],
  ['snvi', 'SNVI'],
  ['srt', 'SRT'],
  ['ssc', 'SSC'],
  ['tac', 'TAC'],
  ['tafe', 'TAFE'],
  ['tagaz', 'TagAZ'],
  ['tam', 'TAM'],
  ['th!nk', 'TH!NK'],
  ['tmc', 'TMC'],
  ['togg', 'TOGG'],
  ['tvr', 'TVR'],
  ['tvs', 'TVS'],
  ['uaz', 'UAZ'],
  ['ud trucks', 'UD Trucks'],
  ['umm', 'UMM'],
  ['vam', 'VAM'],
  ['vdl berkhof', 'VDL Berkhof'],
  ['vega evx', 'Vega EVX'],
  ['vlf automotive', 'VLF Automotive'],
  ['vpg', 'VPG'],
  ['vuhl', 'VUHL'],
  ['zaz', 'ZAZ'],
  ['zenn', 'ZENN'],
  ['zsd nysa', 'ZSD Nysa'],
  ['zx auto', 'ZX Auto']
]);

const upperCaseModels = new Map<string, string>([
  ['4runner', '4Runner'],
  ['ram', 'RAM'],
  ['rav4', 'RAV4']
]);

const capitalize = (s: string) => {
  if (s === null || s === undefined) {
    return s;
  }
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const formatMakeModel = (makeModel: string) => {
  const [make, model] = makeModel.split('_');
  const upperCaseMake = upperCaseMakes.get(make.toLowerCase()) || capitalize(make);
  const upperCaseModel = model ? upperCaseModels.get(model.toLowerCase()) || capitalize(model) : '';

  return `${upperCaseMake} ${upperCaseModel}`;
};
