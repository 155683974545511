import Confetti from 'react-confetti';
import { useWindowWidth } from 'presentation/hooks/useWindowWidth';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import useInterval from 'presentation/hooks/useInterval';
import { sleep } from 'presentation/helpers/sleep';

const REDIRECT_WAIT = 5 * 1000; // 5 seconds

export const FinishedBatch = ({ redirectFunction }: { redirectFunction: () => void }) => {
  const width = useWindowWidth();

  useInterval(async () => {
    await sleep(REDIRECT_WAIT);
    redirectFunction();
  }, REDIRECT_WAIT);

  return (
    <>
      <Confetti width={width} numberOfPieces={500} />
      <Row>
        <Col xs={{ offset: 2, span: 10 }} md={{ offset: 4, span: 4 }}>
          <h4>Review finished!</h4>
          <h6>
            You will be redirected in 5 seconds.
            <div
              role="link"
              tabIndex={-1}
              onClick={redirectFunction}
              onKeyDown={(e) => {
                if (e.key === 'Enter') redirectFunction();
              }}
            >
              Click here to redirect now.
            </div>
          </h6>
        </Col>
      </Row>
    </>
  );
};
