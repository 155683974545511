import { shortcut, KeyCode } from 'presentation/helpers/shorcuts';
import { useEffect } from 'react';

export const useShortcut = (shortcuts: KeyCode[], callback: () => void) => {
  useEffect(() => {
    const subscription = shortcut(shortcuts).subscribe(() => {
      callback();
    });
    return () => {
      try {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return subscription.unsubscribe() as any;
      } catch {
        return null;
      }
    };
  }, [shortcuts, callback]);
};

export { KeyCode };
