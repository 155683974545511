import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import { InitialSelector } from './InitialSelector';
import { EventVisualizerInputProps, LabelingEventVisualizer } from './LabelingEventVisualizer';
import { FinishedBatch } from './FinishedBatch';

const initialParamState = {
  waleId: null,
  startDate: null,
  endDate: null,
  batchSize: null,
  quickLabels: [],
  labels: []
};

export const LabelingMain = () => {
  const [params, setParams] = useState<{
    waleId: string | null;
    startDate: Date | null;
    endDate: Date | null;
    batchSize: number | null;
    quickLabels: string[];
    labels: string[];
  }>(initialParamState);

  const [finished, setFinished] = useState(false);

  const setParamsFunction = (
    waleId: string,
    startDate: Date,
    endDate: Date | null,
    batchSize: number | null,
    quickLabels: string[],
    labels: string[]
  ) => setParams({ waleId, startDate, endDate, batchSize, quickLabels, labels });

  const redirectFunction = () => {
    setParams(initialParamState);
    setFinished(false);
  };

  if (finished)
    return (
      <Row className="full-height">
        <Col className="bliss-card m-3">
          <FinishedBatch redirectFunction={redirectFunction} />{' '}
        </Col>
      </Row>
    );

  return (
    <Row className="full-height">
      <Col className="bliss-card m-3">
        {params.waleId && params.startDate && (params.endDate || params.batchSize) ? (
          <LabelingEventVisualizer
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...(params as NonNullable<EventVisualizerInputProps>)}
            onFinishedBatch={() => setFinished(true)}
          />
        ) : (
          <InitialSelector setParams={setParamsFunction} />
        )}
      </Col>
    </Row>
  );
};
