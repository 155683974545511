import React, { useState, useEffect } from 'react';
import { getWaleEventImageUseCase } from 'domain/use-cases/waleDetection/getLocationEventImages.use-case';
import { useIsMounted } from 'presentation/hooks/useIsMounted';
import { LocationEvent } from 'domain/entities/locationEvent.entity';
import { ZoneEvent } from 'domain/entities/zoneEvent.entity';
import { EventCard } from 'components/EventCard/EventCard';
import { genericErrorLog } from 'data/api/genericErrorMessage';

const ZoneEventElement = ({
  zoneEvent,
  setLocationEventData,
  setSelectedZone
}: {
  zoneEvent: ZoneEvent;
  setLocationEventData: (a: LocationEvent[]) => void;
  setSelectedZone: (a: ZoneEvent) => void;
}) => {
  const getBestSideCapture = (ze: ZoneEvent, location: 'back' | 'front') =>
    ze.locationEvents.slice(1).reduce((prev, curr) => {
      if (!curr[location]) return prev;
      if ((curr[location]?.bestCapture?.score || 0) >= (prev?.bestCapture?.score || 0)) {
        return curr[location];
      }
      return prev;
    }, ze.locationEvents[0][location]);

  const bestFrontEvent = getBestSideCapture(zoneEvent, 'front');
  const bestBackEvent = getBestSideCapture(zoneEvent, 'back');
  const [imageUrls, setImageUrls] = useState({ back: '', front: '' });
  const isMounted = useIsMounted();

  const handleImageClick = () => {
    setLocationEventData(zoneEvent.locationEvents);
    setSelectedZone(zoneEvent);
  };

  useEffect(() => {
    getWaleEventImageUseCase(bestFrontEvent)
      .then((frontImage) => {
        getWaleEventImageUseCase(bestBackEvent).then((backImage) => {
          if (isMounted.current) setImageUrls({ back: backImage, front: frontImage });
        });
      })
      .catch(genericErrorLog);
  }, []);

  return (
    <EventCard
      cardText={zoneEvent.zoneId}
      startedAt={zoneEvent.startedAt}
      leftImageUrl={(!bestFrontEvent && 'no-image') || imageUrls.front}
      rightImageUrl={(!bestBackEvent && 'no-image') || imageUrls.back}
      onImageClick={handleImageClick}
      leftImageText={bestFrontEvent?.waleId}
      rightImageText={bestBackEvent?.waleId}
    />
  );
};

export default ZoneEventElement;
