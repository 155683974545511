import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { AlertError, AlertInfo, AlertWarning, AlertSuccess } from './alertTypes';

const Notification = (props: any) => {
  const notify = (message: any, kind: any, timeout: any = 10) => {
    const settings = { autoClose: timeout, hideProgressBar: false };
    if (kind === 'info') {
      toast.info(<AlertInfo message={message} />, {
        ...settings,
        toastId: `uniqueInfo|${message.slice(0, 5)}`
      });
    } else if (kind === 'warning') {
      toast.warn(<AlertWarning message={message} />, {
        ...settings,
        toastId: `uniqueWarning|${message.slice(0, 5)}`
      });
    } else if (kind === 'success') {
      toast.success(<AlertSuccess message={message} />, {
        ...settings,
        toastId: `uniqueSuccess|${message.slice(0, 5)}`
      });
    } else if (kind === 'error') {
      toast.error(<AlertError message={message} />, {
        ...settings,
        toastId: `uniqueError|${message.slice(0, 5)}`
      });
    } else {
      toast(message, settings);
    }
  };

  useEffect(() => {
    const {
      alert: { message, show, kind, timeout }
    } = props;

    if (show) {
      notify(message, kind, timeout);
    }
  });

  return <ToastContainer />;
};

const mapStateToProps = (state: any) => {
  const { alert } = state.utils;
  return {
    alert
  };
};

export default connect(mapStateToProps)(Notification);
