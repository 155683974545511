/* eslint-disable no-underscore-dangle */
import React, { useState, useRef } from 'react';
import _ from 'lodash';

import ReactTooltip from 'react-tooltip';
import { Wale } from 'domain/entities/wale.entity';
import { useAlert } from 'presentation/hooks/useAlert';
import { getWaleIndexSettingsUseCase } from 'domain/use-cases/wale/getWales.use-case';
import { updateWaleUseCase } from 'domain/use-cases/wale/updateWale.use-case';
import { shortWaleId } from 'presentation/helpers/waleId';
import ComponentDataTable from '../../components/DataTable';
import { transpose, unflatten } from '../../services/generalUtils';
import { DISPLAYED_ROWS_NAMES, CONDITIONAL_ROW_STYLES } from './Columns';

const ARRAY_FIELDS = [
  { name: 'configurations.lidar.lanedists', convert: Number },
  {
    name: 'configurations.magnetometer.sensor_ids',
    convert: (input: string) => String(input).trim()
  },
  { name: 'configurations.magnetometer.sensor_lanes', convert: Number },
  { name: 'configurations.magnetometer.triggering_sensors', convert: Number },
  { name: 'configurations.magnetometer.trigger_offsets', convert: Number },
  { name: 'configurations.magnetometer.trigger_distance_offsets', convert: Number }
];

const DEFAULT_CAMERA_TYPE = 'ar';
const DEFAULT_LIGHT_TYPE = 'Internal';

const ConfigDataTable = ({ columns, displayedWALEs, reRenderNumber }: any) => {
  const [onRequest, setOnRequest] = useState(false);
  const [editingId, setEditingId] = useState('');
  const [wales, setWales] = useState([{}]);
  const { sendAlert } = useAlert();

  // Filter a list of WALE Objects by the attribute selected that is in the displayedWales list.
  // TODO: move this to a general utils file where it can be reused
  const filterWalesByListAndAttribute = (
    waleList: any[],
    displayedWales: any[],
    attribute: string
  ) =>
    waleList.filter((wale) => wale && wale[attribute] && displayedWales.includes(wale[attribute]));

  const formData = useRef([]).current;
  const isEditing = (record: any) => record.name === editingId;

  const handleFailureRequest = (error: any) => {
    setOnRequest(false);
    sendAlert({ type: 'error', message: error?.response?.data?.error });
  };

  const handleSuccessRequest = (data: Wale[]) => {
    const savedData = data.map((wale) => ({
      shortWaleId: shortWaleId(wale.waleId),
      waleId: wale.waleId,
      ..._.omit(wale, 'waleId')
    }));
    setWales(savedData);
    setOnRequest(false);
  };

  const handleIndexRequest = () => {
    setOnRequest(true);
    getWaleIndexSettingsUseCase().then(handleSuccessRequest).catch(handleFailureRequest);
  };

  const generatePreviousValuesList = (
    mainObject: any,
    fieldNames: string[],
    previousValuesFieldName: string,
    createdAt: Date
  ) => {
    const previousValuesArray = mainObject[previousValuesFieldName] ?? [];
    const lastValue: { end?: Date } | undefined = _.last(previousValuesArray);
    const newValue: Record<string, string | Date> = { end: new Date() };
    fieldNames.forEach((fieldName) => {
      newValue[fieldName] = mainObject[fieldName];
    });
    newValue.start = lastValue?.end ?? createdAt;
    previousValuesArray.push(newValue);
    return previousValuesArray;
  };

  const handleSave = async () => {
    const tempData = wales.map((obj) => _.cloneDeep(obj));
    await getWaleIndexSettingsUseCase()
      .then(async (data: Wale[]) => {
        const serverWales = data;
        const formDataToSend = formData.map((wale: Wale) => {
          const indexofWales = wales.findIndex((wl) => wale._id === (wl as Wale)._id);
          const index = tempData.findIndex((wl) => wale._id === (wl as Wale)._id);
          tempData[index] = _.merge(tempData[index], unflatten(wale));
          const indexOfServerWale = serverWales.findIndex((sw) => wale._id === sw._id);

          const waleObjectToSend = { ...wale };

          ARRAY_FIELDS.forEach((object) => {
            const { name, convert } = object;
            if (_.has(waleObjectToSend, name)) {
              if (!(waleObjectToSend as any)[name].trim()) {
                (waleObjectToSend as any)[name] = [];
              } else {
                (waleObjectToSend as any)[name] = (waleObjectToSend as any)[name]
                  .split(',')
                  .map(convert);
              }
            }
          });

          // add obsolete vehicle filtering parameters to the list of previous ones
          if (
            _.has(waleObjectToSend, 'external_parameters.detection_filter.slope') ||
            _.has(waleObjectToSend, 'external_parameters.detection_filter.offset')
          ) {
            (waleObjectToSend as any)[
              'external_parameters.detection_filter.previous_filter_parameters'
            ] = generatePreviousValuesList(
              (serverWales[indexOfServerWale] as Wale).external_parameters.detection_filter,
              ['slope', 'offset'],
              'previous_filter_parameters',
              (tempData[index] as Wale).createdAt
            );
          }

          const editedFieldsMatchServer = Object.keys(wale).every((key) =>
            _.isEqual(_.get(serverWales[indexOfServerWale], key), _.get(wales[indexofWales], key))
          );

          if (editedFieldsMatchServer) {
            return { waleId: serverWales[indexOfServerWale].waleId, waleObjectToSend };
          }
          return { waleId: serverWales[indexOfServerWale].waleId, waleObjectToSend: null };
        });

        const walesToSend = formDataToSend.filter((obj) => obj.waleObjectToSend !== null);
        const failedWales = formDataToSend.filter((obj) => obj.waleObjectToSend == null);
        const responses = await Promise.all(
          walesToSend.map(async (obj) => {
            try {
              await updateWaleUseCase(obj.waleObjectToSend!);
              return { waleId: obj.waleId, success: true };
            } catch {
              return { waleId: obj.waleId, success: false };
            }
          })
        );
        const successfulWales = responses
          .filter((obj) => obj.success === true)
          .map((obj) => obj.waleId);
        const failedRequestWales = responses
          .filter((obj) => obj.success === false)
          .map((obj) => obj.waleId);
        setEditingId('');
        if (failedWales.length > 0)
          sendAlert({
            type: 'error',
            timeout: 15000,
            message: `Couldn't update WALEs ${failedWales
              .map((obj) => obj.waleId)
              .join(
                ', '
              )} because you were not looking at their latest version. Reload the page and try again.`
          });

        if (failedRequestWales.length > 0) {
          sendAlert({
            type: 'error',
            message: `Error when updating ${failedRequestWales.join(', ')}.`
          });
        }

        if (successfulWales.length > 0) {
          sendAlert({
            type: 'success',
            message: `Successfully updated ${successfulWales.length} WALEs.`
          });
        }
      })
      .catch(handleFailureRequest);
    handleIndexRequest();
    while (formData.length > 0) formData.pop();
  };

  const handleActionClick = ({ row }: any, action: any) => {
    switch (action) {
      case 'discard':
        setEditingId('');
        break;
      case 'save':
        handleSave();
        break;
      default:
        setEditingId(row.name);
        break;
    }
  };

  const formOnChange = (event: any) => {
    const { name, value, id } = event.target;
    const index = formData.findIndex((wale) => (wale as any)._id === id);

    if (index > -1) {
      // @ts-ignore ts-migrate(2322) FIXME: Type 'any' is not assignable to type 'never'.
      formData[index][name] = value;
    } else {
      const aux = { _id: id };
      // @ts-ignore ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      aux[name] = value;
      // @ts-ignore ts-migrate(2345) FIXME: Argument of type '{ _id: any; }' is not assignable... Remove this comment to see the full error message
      formData.push(aux);
    }
  };

  const styles = {
    headCells: {
      style: {
        background: '#1ba3dc'
      }
    },
    cells: {
      style: {
        'white-space': 'nowrap',
        overflow: 'hidden',
        'text-overflow': 'ellipsis'
      }
    }
  };
  const filteredWales = filterWalesByListAndAttribute(wales, displayedWALEs, 'shortWaleId');

  // If all the displayed WALEs have the same camera display those parameters, otherwise
  // default to AR's
  const displayedCameraType = (() => {
    if (filteredWales.length === 0) return DEFAULT_CAMERA_TYPE;
    const firstCameraType = filteredWales[0].configurations.camera.camera_type;
    const allCamerasTheSame = filteredWales.every(
      (wale) => wale.configurations.camera.camera_type === firstCameraType
    );
    if (allCamerasTheSame) return firstCameraType;
    return DEFAULT_CAMERA_TYPE;
  })();

  // If all the displayed WALEs have the same lights display those parameters, otherwise
  // default to internal lights
  const displayedLightType = (() => {
    if (filteredWales.length === 0) return DEFAULT_LIGHT_TYPE; 
    const firstLightType = filteredWales[0].configurations.camera.light_control.lightType;
    const allLightsTheSame = filteredWales.every(
      (wale) => wale.configurations.camera.light_control.lightType === firstLightType
    );
    if (allLightsTheSame) return firstLightType;
    return DEFAULT_LIGHT_TYPE;
  })();
  

  const actualRows = DISPLAYED_ROWS_NAMES.filter((rowName) => {
    if (rowName.startsWith('configurations.camera.params')) {
      return rowName.startsWith(`configurations.camera.params.${displayedCameraType}`);
    }
    if (rowName.startsWith('configurations.camera.dusk_dawn')) {
      return displayedCameraType === 'ar';
    }
    if (rowName.startsWith('configurations.camera.light_control')) {
      if (displayedLightType === 'External')
        return true;
      return rowName === 'configurations.camera.light_control.lightType';
    }
    return true;
  });

  const data = transpose(filteredWales, actualRows, 'shortWaleId').map((row) => ({
    ...row,
    displayName: row.name.replace('configurations.', '').replace('camera.params.', 'camera.')
  }));
  const cols = columns(filteredWales, handleActionClick, isEditing, formOnChange, reRenderNumber);
  return (
    <>
      <ComponentDataTable
        onRequest={onRequest}
        columns={cols}
        data={data}
        totalRows={wales.length}
        RowsPage={25}
        moreData={false}
        fixedHeader
        customStyles={styles}
        conditionalRowStyles={CONDITIONAL_ROW_STYLES}
        striped
        resourceRequest={() => {
          if (!onRequest) handleIndexRequest();
        }}
      />
      {data.map((row) => (
        <ReactTooltip
          key={`settingsTip-${String(row.name)}-${reRenderNumber}`}
          id={`settingsTip-${String(row.name)}-${reRenderNumber}`}
          delayShow={250}
        >
          {String(row.name)}
        </ReactTooltip>
      ))}
    </>
  );
};

export default ConfigDataTable;
