import { debounceGetWalesSettings, getWales, getWaleChangeHistory } from 'data/wale.repository';
import { Wale, walesCompareFn } from 'domain/entities/wale.entity';
import { getLocationAndLaneId, getWalePair, isFront } from 'presentation/helpers/waleId';

export type WaleIdsByLocation = Map<string, { front: string; back: string }>;

export const getWalesUseCase = (): Promise<Wale[]> =>
  getWales().then((wales) => wales.sort(walesCompareFn(wales)));

export const getWaleIdsByLocationAndLaneUseCase = async () => {
  const waleIdsByLocation: WaleIdsByLocation = new Map();
  const wales = await getWales();
  wales.sort(walesCompareFn(wales));
  wales.forEach((wale) => {
    const { waleId } = wale;

    try {
      const locationAndLane = getLocationAndLaneId(waleId);

      if (!waleIdsByLocation.get(locationAndLane)) {
        waleIdsByLocation.set(locationAndLane, {
          front: isFront(waleId) ? waleId : getWalePair(waleId),
          back: isFront(waleId) ? getWalePair(waleId) : waleId
        });
      }
    } catch {
      waleIdsByLocation.set(waleId, { front: waleId, back: waleId });
    }
  });

  return waleIdsByLocation;
};

export const getWaleIndexSettingsUseCase = () => debounceGetWalesSettings();

export const getWaleChangeHistoryUseCase = (waleId: string) => getWaleChangeHistory(waleId);
