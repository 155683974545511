import moment from 'moment';
import { range, groupBy, mapValues, sum, min, max } from 'lodash';
import {
  getLidarLogDurationMins,
  isLidarLogSignalNormal,
  LidarLog
} from 'domain/entities/lidarLog.entity';
import { DownloadInfo } from 'presentation/helpers/download';
import { RawUptimeData } from 'domain/use-cases/getUptimeData.use-case';
import { DailyMetrics } from 'domain/entities/dailyMetrics.entity';
import { Video } from 'domain/entities/video.entity';
import { WaleStatusUptime } from 'domain/entities/waleStatus.entity';

export enum UptimeSummary {
  Up = 'up',
  Down = 'down',
  Out = 'out',
  Null = ''
}

interface SingleDailyMetricsInfo {
  start: Date;
  end: Date;
  /** True if any of the time slots is in schedule */
  inSchedule: boolean;
  scheduledTime: number;
  eventsCount: number;
  uptimeSummary: UptimeSummary;
  statusSensingPeriodSecs: number;
}

interface LidarLogInfo {
  [index: number]: {
    totalMinutes: number;
    /** True if all the lidar logs of the interval have a normal signal */
    signalNormal: boolean;
    download: DownloadInfo[];
  };
}

interface VideoInfo {
  [index: number]: {
    totalMinutes: number;
    download: DownloadInfo[];
  };
}

interface StatusInfo {
  [index: number]: { docsCount: number };
}

export interface SingleEnhancedUptime extends SingleDailyMetricsInfo {
  lidarMins: number;
  /** null if no lidar data */
  lidarSignalNormal: boolean | null;
  lidarDownload: DownloadInfo[];
  videoMins: number;
  videoDownload: DownloadInfo[];
  statusCount: number;
}

export interface EnhancedUptime {
  waleId: string;
  intervalMinutes: number;
  timeSlots: SingleEnhancedUptime[];
}

export const processUptimeUseCase = (
  rawUptimeData: RawUptimeData,
  intervalMinutes: number,
  startDate: Date
): EnhancedUptime => {
  const { dailyMetrics, lidarLogs, videos, statuses } = rawUptimeData;

  // Process all the necessary data
  const dailyMetricsInfo = getDailyMetricsInfo(dailyMetrics, startDate, intervalMinutes);
  const lidarLogInfo = getLidarLogInfo(lidarLogs, startDate, intervalMinutes);
  const videoInfo = getVideoInfo(videos, startDate, intervalMinutes);
  const statusInfo = getStatusInfo(statuses, startDate, intervalMinutes);

  // Integrate info into a single object
  const numberOfIntervals = Math.min(
    Math.round((24 * 60) / intervalMinutes),
    Object.keys(dailyMetricsInfo).length
  );
  return {
    waleId: rawUptimeData.waleId,
    intervalMinutes,
    timeSlots: range(numberOfIntervals).map((index) => ({
      ...dailyMetricsInfo[index],
      lidarSignalNormal: lidarLogInfo[index]?.signalNormal ?? null,
      lidarMins: lidarLogInfo[index]?.totalMinutes ?? 0,
      lidarDownload: lidarLogInfo[index]?.download ?? [],
      videoMins: videoInfo[index]?.totalMinutes ?? 0,
      videoDownload: videoInfo[index]?.download ?? [],
      statusCount: statusInfo[index]?.docsCount ?? 0
    }))
  };
};

const getDailyMetricsInfo = (
  dailyMetrics: DailyMetrics | null,
  startDate: Date,
  intervalMinutes: number
): { [index: number]: SingleDailyMetricsInfo } => {
  if (!dailyMetrics || !dailyMetrics.capturingUptime.eventsCount) return {};
  const { timeSlots, intervalMinutes: uptimeIntervalMinutes } =
    dailyMetrics.capturingUptime.eventsCount;
  const grouped = groupBy(timeSlots, (ts) =>
    getIntervalIndex(ts.start, startDate, intervalMinutes)
  );

  // intervalTimeSlots is not empty because it comes from a groupBy call, so non-null assertions are valid
  return mapValues(grouped, (intervalTimeSlots) => ({
    start: min(intervalTimeSlots.map((ts) => ts.start))!, // eslint-disable-line @typescript-eslint/no-non-null-assertion
    end: moment(max(intervalTimeSlots.map((ts) => ts.start))!) // eslint-disable-line @typescript-eslint/no-non-null-assertion
      .add(uptimeIntervalMinutes, 'minutes')
      .toDate(),
    eventsCount: sum(intervalTimeSlots.map((ts) => ts.count)),
    inSchedule: intervalTimeSlots.reduce(
      (anyInSchedule, ts) => anyInSchedule || ts.inSchedule,
      false
    ),
    scheduledTime:
      sum(intervalTimeSlots.map((ts) => (ts.inSchedule ? 1 : 0))) * uptimeIntervalMinutes,
    uptimeSummary: intervalTimeSlots.reduce(
      (summary, ts) => getUptimeSummary(summary, ts.count > 0, ts.inSchedule),
      UptimeSummary.Null
    ),
    statusSensingPeriodSecs: dailyMetrics.status.sensingPeriodSecs
  }));
};

const getLidarLogInfo = (
  lidarLogs: LidarLog[],
  startDate: Date,
  intervalMinutes: number
): LidarLogInfo => {
  const grouped = groupBy(lidarLogs, (l) =>
    getIntervalIndex(l.startedAt, startDate, intervalMinutes)
  );
  return mapValues(grouped, (intervalLogs) => ({
    totalMinutes: sum(intervalLogs.map((l) => getLidarLogDurationMins(l))),
    signalNormal: intervalLogs.reduce(
      (allNormal, l) => allNormal && isLidarLogSignalNormal(l),
      true
    ),
    download: intervalLogs.map(getDownloadInfo)
  }));
};

const getVideoInfo = (videos: Video[], startDate: Date, intervalMinutes: number): VideoInfo => {
  const grouped = groupBy(videos, (v) => getIntervalIndex(v.startedAt, startDate, intervalMinutes));
  return mapValues(grouped, (intervalVideos) => ({
    totalMinutes: sum(intervalVideos.map((v) => v.duration / 60)),
    download: intervalVideos.map(getDownloadInfo)
  }));
};

const getStatusInfo = (
  statuses: WaleStatusUptime[],
  startDate: Date,
  intervalMinutes: number
): StatusInfo => {
  const grouped = groupBy(statuses, (s) =>
    getIntervalIndex(s.measuredAt, startDate, intervalMinutes)
  );
  return mapValues(grouped, (intervalStatuses) => ({ docsCount: intervalStatuses.length }));
};

/**
 * When grouping uptimes, we prefer to always display the worst case, acording to the priority:
 *    'up' is better than 'out', which is better than 'down'.
 * For example, if the summary was 'up' and one of the intervals is 'down', the overall uptime
 * of the group of intervals will be 'down'.
 */
const getUptimeSummary = (
  previousSummary: UptimeSummary,
  hasEvents: boolean,
  inSchedule: boolean
): UptimeSummary => {
  let currentUptimeSummary: UptimeSummary = UptimeSummary.Null;
  if (inSchedule) {
    currentUptimeSummary = hasEvents ? UptimeSummary.Up : UptimeSummary.Down;
  } else if (hasEvents) {
    currentUptimeSummary = UptimeSummary.Out;
  }

  if (previousSummary === UptimeSummary.Null) return currentUptimeSummary;
  if (previousSummary === UptimeSummary.Up && currentUptimeSummary !== UptimeSummary.Null)
    return currentUptimeSummary;
  if (previousSummary === UptimeSummary.Out && currentUptimeSummary === UptimeSummary.Down)
    return currentUptimeSummary;
  return previousSummary;
};

const getIntervalIndex = (date: Date, startDate: Date, intervalMinutes: number) => {
  const minuteOfDay = moment.duration(moment(date).diff(startDate)).asMinutes();
  return Math.floor(minuteOfDay / intervalMinutes);
};

const getDownloadInfo = (item: LidarLog | Video): DownloadInfo => ({
  url: item.downloadUrl,
  fileName: item.filename.split('/').slice(-1)[0]
});
