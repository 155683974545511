import React, { useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
// @ts-ignore ts-migrate(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import DatePicker from 'react-datepicker';
// @ts-ignore ts-migrate(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import Select from 'react-select';
// @ts-ignore ts-migrate(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import Toggle from 'react-toggle';

import { BackToHomeBtn } from 'components';
import customStyles from 'components/Select/themes';
import { forceLocalDateToMatchRoad, getStartOfDay } from 'services/timeUtils';
import { INTERVAL_MINUTES_OPTIONS, useEnhancedWaleUptimes } from './WaleUptime.view-model';
import { Color } from './components/GenericMetricDisplay';
import { WaleUptimeTable } from './components/WaleUptimeTable';

export const WaleUptimeView = () => {
  const [date, setDate] = useState(getStartOfDay());
  const [intervalMinutes, setIntervalMinutes] = useState(60);
  /** When true, groups data per metric, otherwise, groups per WALE */
  const [groupByMetric, setGroupByMetric] = useState(true);
  const { uptimeList, updating, lastUpdate } = useEnhancedWaleUptimes(date, intervalMinutes);

  return (
    <Container fluid className="bliss-card my-3">
      <Row>
        <Col md={1} xs={4}>
          <BackToHomeBtn />
        </Col>
        <Col md={10} xs={4} className="text-center">
          <h2>
            <span className="text-blue text-slim text-uppercase">Uptime Status</span>
          </h2>
        </Col>
      </Row>
      <Row>
        <Col md={2} style={{ marginLeft: '10px' }}>
          <Row>
            <DatePicker
              selected={forceLocalDateToMatchRoad(date)}
              placeholderText="From"
              dateFormat="dd-MM-yyyy"
              className="form-control"
              disabled={updating}
              showYearDropdown
              onChange={(d: any) => setDate(getStartOfDay(d))}
            />
          </Row>
          <Row>
            {updating ? 'Updating...' : `Last update: ${lastUpdate.toLocaleTimeString()} (local)`}
          </Row>
        </Col>
        <Col md={2}>
          <Select
            styles={customStyles}
            placeholder="Select interval minutes"
            isDisabled={updating}
            options={INTERVAL_MINUTES_OPTIONS.map((i: number) => ({ label: String(i), value: i }))}
            onChange={(option: { label: string; value: number }) => {
              setIntervalMinutes(option.value);
            }}
          />
        </Col>
        <Col md={2}>
          <Row>
            <Toggle
              defaultChecked={groupByMetric}
              onChange={() => setGroupByMetric(!groupByMetric)}
            />
          </Row>
          <Row>Group rows by metric instead of WALE</Row>
        </Col>
        <Col style={{ display: 'flex', justifyContent: 'right' }}>
          <table>
            <tbody>
              <tr>
                <td style={{ backgroundColor: Color.Down, width: '20px' }} />
                <td>
                  Metric NOT OK and in schedule
                  <br />
                  (in any 5 minutes interval)
                </td>
                <td style={{ backgroundColor: Color.Up, width: '20px' }} />
                <td>
                  Metric OK and in schedule
                  <br />
                  (in all 5 minutes intervals)
                </td>
                <td style={{ backgroundColor: Color.Out, width: '20px' }} />
                <td>
                  Metric OK but out of schedule
                  <br />
                  (in any 5 minutes interval)
                </td>
              </tr>
            </tbody>
          </table>
        </Col>
      </Row>
      <WaleUptimeTable uptimeList={uptimeList} groupByWale={!groupByMetric} />
    </Container>
  );
};
