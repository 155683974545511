/* eslint-disable react/jsx-props-no-spreading */
import {
  getWaleLanes,
  getWaleLocation,
  isFront,
  isLocationPartOfMainCorridor,
  isWaleReadingExpressLane,
  isWaleReadingGeneralPurposeLane,
  islocationEgress,
  islocationIngress
} from 'presentation/helpers/waleId';
import React from 'react';
import { CorridorDisplayValues, MetroLocationInfo } from './CorridorDisplay.view-model';

const ArrowDisplay = ({
  locationId,
  isAscendingMileage,
  hasELWaleOnFirstLane,
  hasGPLWaleOnLastLane,
  isIngress,
  isEgress,
  sharedAttributes
}: {
  locationId: string;
  isAscendingMileage: boolean;
  hasELWaleOnFirstLane: boolean;
  hasGPLWaleOnLastLane: boolean;
  isIngress: boolean;
  isEgress: boolean;
  sharedAttributes: any;
}) => (
  <>
    {(hasELWaleOnFirstLane && isEgress) || (hasGPLWaleOnLastLane && isIngress) ? (
      <text dx={-8} {...sharedAttributes} id={`${locationId}-arrow1`}>
        {isAscendingMileage ? '⇖' : '⇘'}
      </text>
    ) : null}
    {(hasELWaleOnFirstLane && isIngress) || (hasGPLWaleOnLastLane && isEgress) ? (
      <text dx={8} {...sharedAttributes} id={`${locationId}-arrow2`}>
        {isAscendingMileage ? '⇗' : '⇙'}
      </text>
    ) : null}
  </>
);

const TrianglePairDisplay = ({
  isAscendingMileage,
  backWaleElemId,
  frontWaleElemId,
  fillTriangleFlag,
  sharedAttributes
}: {
  isAscendingMileage: boolean;
  backWaleElemId: string;
  frontWaleElemId: string;
  fillTriangleFlag: boolean;
  sharedAttributes: any;
}) => (
  <>
    {(backWaleElemId && isAscendingMileage) || (frontWaleElemId && !isAscendingMileage) ? (
      <text
        dy={16}
        {...sharedAttributes}
        id={isAscendingMileage ? backWaleElemId : frontWaleElemId}
      >
        {fillTriangleFlag ? '▲' : '△'}
      </text>
    ) : null}
    {(frontWaleElemId && isAscendingMileage) || (backWaleElemId && !isAscendingMileage) ? (
      <text dy={0} {...sharedAttributes} id={isAscendingMileage ? frontWaleElemId : backWaleElemId}>
        {fillTriangleFlag ? '▼' : '▽'}
      </text>
    ) : null}
  </>
);

const onElementClick = (
  locationId: string,
  corridorLocations: MetroLocationInfo[],
  setCorridorLocations: (a: MetroLocationInfo[]) => void
) => {
  const newCorridorArray = corridorLocations.map((loc) => {
    if (loc.locationId !== locationId) return loc;
    return {
      ...loc,
      chartValues: loc.chartValues.map((val) => {
        if (val === CorridorDisplayValues.BASE) return CorridorDisplayValues.SELECTED;
        if (val === CorridorDisplayValues.SELECTED) return CorridorDisplayValues.BASE;
        return val;
      })
    };
  });
  setCorridorLocations(newCorridorArray);
};

export const LocationLaneDisplay = (props: any) => {
  const {
    x,
    y,
    baseStroke,
    selectedStroke,
    value,
    lane,
    lanes,
    isAscendingMileage,
    corridorLocations,
    setCorridorLocations,
    carouselLocations,
    carouselStroke,
    firstCarouselStroke
  } = props;
  if (!x || !y) return null;
  const { walesInLocation, chartValues, laneOffset, locationId } = value;
  const isMainCorridor = isLocationPartOfMainCorridor(locationId);

  const actualLane = lane - laneOffset;
  let hasELWaleOnFirstLane = false;
  let hasGPLWaleOnLastLane = false;
  const isIngress = islocationIngress(locationId);
  const isEgress = islocationEgress(locationId);

  const walesInLane = walesInLocation.filter((waleId: string) => {
    // Main Corridor case takes the WALEs in the lane
    if (isMainCorridor) return getWaleLanes(waleId).includes(actualLane);
    // Ingress/egress points group all lanes into the one in the extreme
    if (lane === Math.min(...lanes) && isWaleReadingExpressLane(waleId)) {
      hasELWaleOnFirstLane = true;
      return true;
    }
    if (lane === Math.max(...lanes) && isWaleReadingGeneralPurposeLane(waleId)) {
      hasGPLWaleOnLastLane = true;
      return true;
    }
    return false;
  });

  const frontWales: string[] = walesInLane.filter(isFront);
  const backWales: string[] = walesInLane.filter((w: string) => !isFront(w));

  const frontWaleElemId = frontWales.join('|');
  const backWaleElemId = backWales.join('|');

  let fillColor = baseStroke;
  let fillTriangleFlag = false;

  if (chartValues[lane - 1] === CorridorDisplayValues.SELECTED) {
    fillColor = selectedStroke;
    fillTriangleFlag = true;
    const backWaleLocations = backWales.map(getWaleLocation);
    const frontWaleLocations = frontWales.map(getWaleLocation);
    if (carouselLocations && carouselLocations.length > 0 && carouselStroke) {
      if (
        (backWaleElemId && backWaleLocations.some((loc) => carouselLocations.includes(loc))) ||
        (frontWaleElemId && frontWaleLocations.some((loc) => carouselLocations.includes(loc)))
      ) {
        fillColor = carouselStroke;
        if (
          (backWaleElemId && backWaleLocations.includes(carouselLocations[0])) ||
          (frontWaleElemId && frontWaleLocations.includes(carouselLocations[0]))
        ) {
          fillColor = firstCarouselStroke;
        }
      }
    }
  }

  const onEachElementClick = () => {
    onElementClick(locationId, corridorLocations, setCorridorLocations);
  };
  const sharedAttributes = {
    x,
    y,
    fill: fillColor,
    fontSize: 30,
    textAnchor: 'middle',
    onClick: onEachElementClick
  };

  if (!isMainCorridor) {
    return (
      <ArrowDisplay
        locationId={locationId}
        isAscendingMileage={isAscendingMileage}
        hasELWaleOnFirstLane={hasELWaleOnFirstLane}
        hasGPLWaleOnLastLane={hasGPLWaleOnLastLane}
        isIngress={isIngress}
        isEgress={isEgress}
        sharedAttributes={{ dy: 8, ...sharedAttributes }}
      />
    );
  }
  return (
    <TrianglePairDisplay
      isAscendingMileage={isAscendingMileage}
      backWaleElemId={backWaleElemId}
      frontWaleElemId={frontWaleElemId}
      fillTriangleFlag={fillTriangleFlag}
      sharedAttributes={sharedAttributes}
    />
  );
};
