import React from 'react';
import { Trip } from 'domain/entities/trip.entity';
import { ZoneEvent } from 'domain/entities/zoneEvent.entity';
import { LocationEvent } from 'domain/entities/locationEvent.entity';
import LocationEventElement from './LocationEventElement';
import TripInfoBlock from '../ZoneEvents/TripInfoBlock';

const LocationEventVisualizer = ({
  locationEvents,
  handleBack,
  selectedTrip,
  selectedZone
}: {
  locationEvents: LocationEvent[];
  handleBack: () => void;
  selectedTrip: Trip;
  selectedZone: ZoneEvent;
}) => (
  <>
    <TripInfoBlock handleBack={handleBack} trip={selectedTrip} zone={selectedZone} />
    {locationEvents.map((locationEvent) => (
      <LocationEventElement key={locationEvent.locationId} locationEvent={locationEvent} />
    ))}
  </>
);

export default LocationEventVisualizer;
