import { BackToHomeBtn, FormikSelect } from 'components';
import { Wale } from 'domain/entities/wale.entity';
import { getLocationHumanNameFromWaleHumanName } from 'presentation/helpers/waleId';
import { useAlert } from 'presentation/hooks/useAlert';
import { useWales } from 'presentation/hooks/useWaleList';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { onButtonClick } from './CreateWale.view-model';

const CreateWale = () => {
  const waleList = useWales();
  const waleListOptions = waleList.map((wale) => ({ value: wale, label: wale.waleId }));
  const [selectedWale, setSelectedWale] = useState<Wale | null>(null);
  const [inputWaleID, setInputWaleID] = useState('');
  const [inputLocationName, setInputLocationName] = useState('');
  const [buttonForceDisabled, setButtonForceDisabled] = useState(false);
  const { sendAlert } = useAlert();

  const onCreationSuccess = () => {
    setInputLocationName('');
    setInputWaleID('');
  };

  useEffect(() => {
    setButtonForceDisabled(false);
  }, [selectedWale, inputWaleID, inputLocationName]);

  return (
    <Row className="full-height">
      <Col className="bliss-card m-3 tokens-overscroll">
        <Col>
          <BackToHomeBtn route="/ebox/linking" text="Back to Linking" />
        </Col>
        <Row>
          <Col
            lg={{ span: 6, offset: 3 }}
            md={{ span: 8, offset: 2 }}
            sm={{ span: 10, offset: 1 }}
            xs={11}
          >
            <h2 className="mb-5">
              <span className="text-blue text-slim text-uppercase">Create WALE</span>
            </h2>
            <Row>
              <Col>WALE to base the new WALE on:</Col>
              <Col>
                <FormikSelect
                  key={`createWale${waleList.length}`}
                  options={waleListOptions}
                  onChange={(elem: { value: Wale }) => setSelectedWale(elem.value)}
                  style={{ width: '100%' }}
                />
              </Col>
            </Row>
            <Row className="m-b-10">
              <Col>New WALE ID</Col>
              <Col>
                <input
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    setInputWaleID(event.target.value)
                  }
                  value={inputWaleID}
                  placeholder={selectedWale ? `e.g. ${selectedWale.waleId}` : 'Wale ID'}
                  className="bliss-input-field px-2 bliss-input-common-style"
                />
              </Col>
            </Row>
            <Row className="m-b-10">
              <Col>WALE location&apos;s human name</Col>
              <Col>
                <input
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    setInputLocationName(event.target.value)
                  }
                  value={inputLocationName}
                  placeholder={
                    selectedWale?.humanName
                      ? `e.g. ${getLocationHumanNameFromWaleHumanName(selectedWale.humanName)}`
                      : 'Location human name'
                  }
                  className="bliss-input-field px-2 bliss-input-common-style"
                />
              </Col>
            </Row>
            <Row className="d-flex justify-content-center m-t-20">
              <Button
                disabled={
                  buttonForceDisabled ||
                  selectedWale === null ||
                  inputWaleID.length === 0 ||
                  inputLocationName.length === 0
                }
                onClick={() =>
                  onButtonClick(
                    inputWaleID,
                    inputLocationName,
                    selectedWale,
                    sendAlert,
                    setButtonForceDisabled,
                    onCreationSuccess
                  )
                }
              >
                Create WALE
              </Button>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default CreateWale;
