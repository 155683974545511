import React, { useEffect, useState } from 'react';
import { Row, Col, Image, Button } from 'react-bootstrap';
// @ts-ignore ts-migrate(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import InnerImageZoom from 'react-inner-image-zoom';
import AlprTable from 'presentation/WaleEvents/detection/AlprTable';
import defaultImage from '../../assets/images/blissway_default.png';
import { getRoadDateTimeMilli } from '../../services/timeUtils';
import { getPlateStateStyle } from '../../services/statePlateStyles';
import getCaptureData from './getCaptureData';

const PrevNextButtons = ({
  captureIndex,
  numberOfCaptures,
  captureData,
  prevCapture,
  nextCapture
}: any) => (
  <>
    <Col style={{ maxWidth: '20%' }}>
      <Row className="justify-content-md-center">
        <Button onClick={prevCapture}>Prev</Button>
      </Row>
    </Col>
    <Col>
      <Row className="justify-content-md-center" style={{ marginTop: '-5px' }}>
        Capture {numberOfCaptures ? captureIndex + 1 : 0} of {numberOfCaptures}
      </Row>
      <Row className="justify-content-md-center" style={{ marginBottom: '5px' }}>
        {captureData.capturedAt
          ? getRoadDateTimeMilli(captureData.capturedAt)
          : '<Img captured at>'}
      </Row>
    </Col>
    <Col style={{ maxWidth: '20%' }}>
      <Row className="justify-content-md-center">
        <Button onClick={nextCapture}>Next</Button>
      </Row>
    </Col>
  </>
);

const Capture = ({ eventData, onlyLabels }: any) => {
  const [captureIndex, setCaptureIndex] = useState(0);
  const numberOfCaptures = eventData?.captures?.length ?? 0;
  const captureData = getCaptureData(eventData, captureIndex);

  useEffect(() => setCaptureIndex(0), [eventData]);

  const plateNumber = captureData.alprData.plateNumber[0].prediction;
  const plateState = captureData.alprData.plateState.prediction;

  const nextCapture = () => {
    if (numberOfCaptures) {
      const newIndex = captureIndex >= numberOfCaptures - 1 ? 0 : captureIndex + 1;
      setCaptureIndex(newIndex);
    }
  };

  const prevCapture = () => {
    if (numberOfCaptures) {
      const newIndex = captureIndex <= 0 ? numberOfCaptures - 1 : captureIndex - 1;
      setCaptureIndex(newIndex);
    }
  };

  return (
    <>
      <Row className="justify-content-md-center">
        <InnerImageZoom
          className="event-img fade-in"
          src={captureData.imageUrl ? captureData.imageUrl : defaultImage}
        />
        <div className="plate">
          <Image className="plate-img fade-in inline" src={getPlateStateStyle(plateState)} />
          <p className={`plate-number ${plateState}`}>{plateNumber}</p>
        </div>
      </Row>
      <Row style={{ marginTop: '10px' }}>
        <PrevNextButtons
          captureIndex={captureIndex}
          numberOfCaptures={numberOfCaptures}
          captureData={captureData}
          prevCapture={prevCapture}
          nextCapture={nextCapture}
        />
      </Row>
      <Row>
        <AlprTable
          alprData={captureData.alprData}
          humanReview={eventData?.humanReview}
          vehicleEventId={eventData?._id}
          captureIndex={captureIndex}
          onlyLabels={onlyLabels ?? false}
        />
      </Row>
    </>
  );
};

export default Capture;
