import { getWaleDetectionById } from 'data/waleDetection.repository';
import { LocationEvent } from 'domain/entities/locationEvent.entity';
import { TripWaleEvent } from 'domain/entities/tripWaleEvent.entity';

export const getWaleEventImageUseCase = async (waleEvent: TripWaleEvent | null) => {
  if (!waleEvent) {
    return '';
  }
  const { eventId } = waleEvent;

  const data = await getWaleDetectionById(eventId);
  if (data && data.imageUrls) {
    // TODO this logic should probably be in trip builder but the DB already has the
    // wrong indexes (captureIndex 0, score -1 for no ALPR)... Tidy this up.
    const captureIndex =
      waleEvent.bestCapture.score <= 0
        ? Math.floor(data.imageUrls.length / 2)
        : waleEvent.bestCapture.index;
    return data.imageUrls[captureIndex];
  }
  return '';
};

const getLocationEventImagesUseCase = async (locationEvent: LocationEvent) => ({
  frontImage: await getWaleEventImageUseCase(locationEvent.front),
  backImage: await getWaleEventImageUseCase(locationEvent.back)
});
export default getLocationEventImagesUseCase;
