import React from 'react';
import { SortParams } from '../EboxWalePairTypes';

const EboxWaleHeader = ({
  sortParams,
  setSortParams
}: {
  sortParams: SortParams;
  setSortParams: (a: SortParams) => void;
}) => {
  const onSortClick = (fieldName: SortParams['field']) => {
    setSortParams({
      field: fieldName,
      ascending: sortParams.field !== fieldName ? true : !sortParams.ascending
    });
  };

  const arrowCharacter = (field: SortParams['field']) => {
    if (sortParams.field === field) {
      if (sortParams.ascending) return ' ▲';
      return ' ▼';
    }
    return '';
  };

  return (
    <thead>
      <tr
        className="text-center justify-content-between center-row-columns"
        style={{ borderBottom: '1px solid', margin: '10px', marginTop: 0, cursor: 'pointer' }}
        key="top-row-trips"
      >
        <th onClick={() => onSortClick('waleId')}>Wale ID{arrowCharacter('waleId')}</th>
        <th onClick={() => onSortClick('eboxName')}>Ebox Name{arrowCharacter('eboxName')}</th>
        <th onClick={() => onSortClick('eboxId')}>Ebox ID{arrowCharacter('eboxId')}</th>
        <th> </th>
      </tr>
    </thead>
  );
};

export default EboxWaleHeader;
