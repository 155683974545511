import store from 'services/store';
import { sendAlert } from 'store/actions/utils';

const errorCodeToMessage: Record<number, string> = {
  401: 'Wrong email or password.',
  403: "Forbidden: you don't have permission to access this resource."
};
const defaultErrorMessage = 'There was a problem, please try again later.';

export const genericErrorMessage = (isLogin: boolean) => (err: any) => {
  const errorMessage =
    err?.response?.status in errorCodeToMessage && (isLogin || err?.response?.status !== 401)
      ? errorCodeToMessage[err?.response?.status]
      : defaultErrorMessage;
  store.dispatch(sendAlert({ kind: 'error', message: errorMessage }));
};

// eslint-disable-next-line no-console
export const genericErrorLog = (err: any) => console.log(err);

// This function is necessary to send the dispatch signal outside a component
export const warningMessage = (text: string) =>
  store.dispatch(sendAlert({ kind: 'warning', message: text }));
