import clsx from 'clsx';
import React from 'react';
import { Check, XOctagon, AlertCircle, Info } from 'react-feather';

export type OperationStatus =
  | 'operational'
  | 'underperforming-metrics'
  | 'degraded-metrics'
  | 'degraded-system'
  | 'unknown';

type OperationStatusIndicatorProps = {
  status: OperationStatus;
};

const OperationStatusIndicator = ({ status }: OperationStatusIndicatorProps) => {
  const commonClasses = clsx('size-6');

  if (status === 'operational') {
    return <Check className={clsx(commonClasses, 'text-green-400')} />;
  }

  if (status === 'underperforming-metrics') {
    return <Info className={clsx(commonClasses, 'text-purple-400')} />;
  }

  if (status === 'degraded-metrics') {
    return <AlertCircle className={clsx(commonClasses, 'text-yellow-400')} />;
  }

  if (status === 'degraded-system') {
    return <XOctagon className={clsx(commonClasses, 'text-red-400')} />;
  }

  return null;
};

export default OperationStatusIndicator;
