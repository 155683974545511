import { sub, isBefore, format } from 'date-fns';
import { getEndDate } from 'presentation/hooks/useEndDate';
import { fromRoadToUnix } from 'services/timeUtils';
import { getRoad, isFront } from 'presentation/helpers/waleId';
import { statusPrecedenceCompare } from '../Overview.view-model';
import { OperationStatus } from '../OperationStatusIndicator';
import { WaleWithMetrics } from './getWalesWithMetrics';

/** Battery voltage */
const BATTERY_VOLTAGE_DEGRADED_THRESHOLD = 12.5;

/** Empty captures */
const EMPTY_FRONT_CAPTURE_DEGRADED_THRESHOLD = 0.7;
const EMPTY_BACK_CAPTURE_DEGRADED_THRESHOLD = 0.4;
const EMPTY_FRONT_CAPTURE_UNDERPERFORMING_THRESHOLD = 0.5;
const EMPTY_BACK_CAPTURE_UNDERPERFORMING_THRESHOLD = 0.2;

/** Empty events */
const EMPTY_FRONT_EVENTS_DEGRADED_THRESHOLD = 0.6;
const EMPTY_BACK_EVENTS_DEGRADED_THRESHOLD = 0.25;
const EMPTY_FRONT_EVENTS_UNDERPERFORMING_THRESHOLD = 0.4;
const EMPTY_BACK_EVENTS_UNDERPERFORMING_THRESHOLD = 0.1;

/** Blur */
const BLUR_UNDERPERFORMING_THRESHOLD = 0.3;

/** Bright / Dark */
const BRIGHT_UNDERPERFORMING_THRESHOLD = 0.5;
const DARK_UNDERPERFORMING_THRESHOLD = 0.5;

/** Capture queue */
const CAPTURE_QUEUE_DEGRADED_THRESHOLD = 20000;
const CAPTURE_QUEUE_UNDERPERFORMING_THRESHOLD = 1000;

const getCaptureExceptionHours = (roadId: string): number => {
  const specialHours: Record<string, number> = {
    'CO-I70W': 4,
    'CO-I70E': 4,
    'BW-HQB': 87600 // 10 years, just so we don't have to add a special condition
  };
  if (roadId in specialHours) return specialHours[roadId];
  return 1;
};

const getWALEOperationalStatus = (
  wale: WaleWithMetrics
): { status: OperationStatus; statusMessages?: string[] } => {
  const endDate = getEndDate();

  const alerts: Array<{ status: OperationStatus; statusMessage: string }> = [];

  /** Uptime check */
  if (
    wale.waleStatus?.measuredAt &&
    isBefore(new Date(wale.waleStatus?.measuredAt), sub(endDate, { minutes: 5 }))
  ) {
    alerts.push({
      status: 'degraded-system',
      statusMessage: `No status received in the past 5 minutes. Last event recieved: ${format(
        fromRoadToUnix(new Date(wale.waleStatus?.measuredAt)),
        'Pp'
      )}`
    });
  }

  /** No battery voltage check */
  if (!wale.waleStatus?.battery?.voltage) {
    alerts.push({
      status: 'degraded-system',
      statusMessage: `Battery voltage not being read by WALE.`
    });
  }

  /** Battery voltage check */
  if (
    wale.waleStatus?.battery?.voltage &&
    wale.waleStatus?.battery.voltage < BATTERY_VOLTAGE_DEGRADED_THRESHOLD
  ) {
    alerts.push({
      status: 'degraded-system',
      statusMessage: `Battery voltage (${wale.waleStatus?.battery.voltage.toFixed(
        2
      )}V) is below ${BATTERY_VOLTAGE_DEGRADED_THRESHOLD.toFixed(2)}V.`
    });
  }

  // metrics checks are irrelevant if the schedule isn't active
  if (!wale.waleStatus?.vehicleScheduleOn) {
    return {
      status: alerts.length ? alerts[0]?.status : 'operational',
      statusMessages: alerts.map((alert) => alert.statusMessage)
    };
  }

  /** Capture check */
  if (
    wale.vehicleEventMetrics.maxDate &&
    isBefore(
      new Date(wale.vehicleEventMetrics.maxDate),
      sub(endDate, { hours: getCaptureExceptionHours(getRoad(wale.waleId)) })
    )
  ) {
    alerts.push({
      status: 'degraded-system',
      statusMessage: `No vehicle events in the past hour. Last event captured: ${format(
        fromRoadToUnix(new Date(wale.vehicleEventMetrics.maxDate)),
        'Pp'
      )}`
    });
  }

  /** Empty capture checks */
  const isFrontWALE = isFront(wale.waleId);
  const EMPTY_CAPTURE_DEGRADED_THRESHOLD = isFrontWALE
    ? EMPTY_FRONT_CAPTURE_DEGRADED_THRESHOLD
    : EMPTY_BACK_CAPTURE_DEGRADED_THRESHOLD;
  const EMPTY_CAPTURE_UNDERPERFORMING_THRESHOLD = isFrontWALE
    ? EMPTY_FRONT_CAPTURE_UNDERPERFORMING_THRESHOLD
    : EMPTY_BACK_CAPTURE_UNDERPERFORMING_THRESHOLD;
  const emptyCapturePercentage = wale.vehicleEventMetrics.emptyCaptures;

  if (emptyCapturePercentage > EMPTY_CAPTURE_DEGRADED_THRESHOLD) {
    alerts.push({
      status: 'degraded-metrics',
      statusMessage: `Percentage of empty captures (${(emptyCapturePercentage * 100).toFixed(
        2
      )}%) is greater than ${(EMPTY_CAPTURE_DEGRADED_THRESHOLD * 100).toFixed(0)}%.`
    });
  } else if (emptyCapturePercentage > EMPTY_CAPTURE_UNDERPERFORMING_THRESHOLD) {
    alerts.push({
      status: 'underperforming-metrics',
      statusMessage: `Percentage of empty captures (${(emptyCapturePercentage * 100).toFixed(
        2
      )}%) is greater than ${(EMPTY_CAPTURE_UNDERPERFORMING_THRESHOLD * 100).toFixed(0)}%.`
    });
  }

  /** Empty event checks */
  const EMPTY_EVENTS_DEGRADED_THRESHOLD = isFrontWALE
    ? EMPTY_FRONT_EVENTS_DEGRADED_THRESHOLD
    : EMPTY_BACK_EVENTS_DEGRADED_THRESHOLD;
  const EMPTY_EVENTS_UNDERPERFORMING_THRESHOLD = isFrontWALE
    ? EMPTY_FRONT_EVENTS_UNDERPERFORMING_THRESHOLD
    : EMPTY_BACK_EVENTS_UNDERPERFORMING_THRESHOLD;
  const emptyEventPercentage = wale.vehicleEventMetrics.emptyEvents;

  if (emptyEventPercentage > EMPTY_EVENTS_DEGRADED_THRESHOLD) {
    alerts.push({
      status: 'degraded-metrics',
      statusMessage: `Percentage of empty events (${(emptyEventPercentage * 100).toFixed(
        2
      )}%) is greater than ${(EMPTY_EVENTS_DEGRADED_THRESHOLD * 100).toFixed(0)}%.`
    });
  } else if (emptyEventPercentage > EMPTY_EVENTS_UNDERPERFORMING_THRESHOLD) {
    alerts.push({
      status: 'underperforming-metrics',
      statusMessage: `Percentage of empty events (${(emptyEventPercentage * 100).toFixed(
        2
      )}%) is greater than ${(EMPTY_EVENTS_UNDERPERFORMING_THRESHOLD * 100).toFixed(0)}%.`
    });
  }

  /** Blur checks */
  const blurPercentage = wale.vehicleEventMetrics.laplaceBlurry;
  if (blurPercentage > BLUR_UNDERPERFORMING_THRESHOLD) {
    alerts.push({
      status: 'underperforming-metrics',
      statusMessage: `Blur percentage (${(blurPercentage * 100).toFixed(2)}%) is greater than ${(
        BLUR_UNDERPERFORMING_THRESHOLD * 100
      ).toFixed(0)}%.`
    });
  }

  /** Bright check */
  const { bright } = wale.vehicleEventMetrics;
  if (bright > BRIGHT_UNDERPERFORMING_THRESHOLD) {
    alerts.push({
      status: 'underperforming-metrics',
      statusMessage: `Bright percentage (${(bright * 100).toFixed(2)}%) is greater than ${(
        BRIGHT_UNDERPERFORMING_THRESHOLD * 100
      ).toFixed(0)}%.`
    });
  }

  /** Dark check */
  const { dark } = wale.vehicleEventMetrics;
  if (dark > DARK_UNDERPERFORMING_THRESHOLD) {
    alerts.push({
      status: 'underperforming-metrics',
      statusMessage: `Dark percentage (${(dark * 100).toFixed(2)}%) is greater than ${(
        DARK_UNDERPERFORMING_THRESHOLD * 100
      ).toFixed(0)}%.`
    });
  }

  /** Capture queue checks */
  const captureQueueLength = wale.waleStatus.system?.captureQueue || 0;
  if (captureQueueLength > CAPTURE_QUEUE_DEGRADED_THRESHOLD) {
    alerts.push({
      status: 'degraded-metrics',
      statusMessage: `Size of capture queue (${captureQueueLength}) is greater than ${CAPTURE_QUEUE_DEGRADED_THRESHOLD}.`
    });
  } else if (captureQueueLength > CAPTURE_QUEUE_UNDERPERFORMING_THRESHOLD) {
    alerts.push({
      status: 'underperforming-metrics',
      statusMessage: `Size of capture queue (${captureQueueLength}) is greater than ${CAPTURE_QUEUE_UNDERPERFORMING_THRESHOLD}.`
    });
  }

  const sortedAlerts = alerts.sort(statusPrecedenceCompare);

  return {
    status: alerts.length ? sortedAlerts[0]?.status : 'operational',
    statusMessages: sortedAlerts.map((alert) => alert.statusMessage)
  };
};

export default getWALEOperationalStatus;
