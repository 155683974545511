import React from 'react';
import { Row, Col } from 'react-bootstrap';
import 'assets/stylesheets/dashboard.scss';
import {
  TOP_MAKE_MODELS_AMOUNT,
  TOP_STATES_AMOUNT,
  useLatestTripSummary
} from 'presentation/TollingAndTraffic/TollingAndTraffic.view-model';
import { getRoadDate } from 'services/timeUtils';
import { TopStates } from './TopStates';
import { TopVehicles } from './TopVehicles';

export const TripsSummary = () => {
  const tripsSummary = useLatestTripSummary();
  let message = '';
  if (!tripsSummary) {
    message = 'Loading data...';
  } else if (tripsSummary.startDate && tripsSummary.endDate) {
    message = `*Data from ${getRoadDate(tripsSummary.startDate)} to 
    ${getRoadDate(tripsSummary.endDate)}`;
  } else {
    message = '*Data for the previous week';
  }
  return (
    <div className="d-flex flex-column justify-content-between h-100">
      <Row>
        <Col md={8}>
          <Row className="cars">
            <Col md={12} className="mb-2">
              <h5 className="car-title">Top States</h5>
            </Col>
          </Row>
          <TopStates
            statesList={tripsSummary?.plateStates || []}
            topStatesAmount={TOP_STATES_AMOUNT}
          />
        </Col>
        <Col md={4}>
          <Row className="cars">
            <Col md={12} className="mb-2">
              <h5 className="car-title">Top Vehicles</h5>
            </Col>
          </Row>
          <TopVehicles
            vehiclesList={tripsSummary?.makeModels || []}
            topMakeModelsAmount={TOP_MAKE_MODELS_AMOUNT}
          />
        </Col>
      </Row>
      <Row>
        <Col>{message}</Col>
      </Row>
    </div>
  );
};
