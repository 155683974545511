import React from 'react';

const BasicLaneStats = ({
  throughput,
  speedMPH,
  isOffline
}: {
  throughput: number | null | undefined;
  speedMPH: number | null | undefined;
  isOffline: boolean;
}) => {
  const adjThroughput = isOffline ? '-' : throughput;
  const adjSpeed = isOffline ? '-' : speedMPH;

  return (
    <>
      <h2 className="text-blue" data-cy="lane_throughput">
        {adjThroughput?.toLocaleString('en')}
      </h2>
      <p className="text-small text-white">Traffic volume</p>
      <p className="text-small">/vhl</p>
      <h2 className="text-blue" data-cy="lane_speed">
        {adjSpeed}
      </h2>
      <p className="text-small text-white">Speed</p>
      <p className="text-small">/mph</p>
    </>
  );
};

export default BasicLaneStats;
