import { FormikSelect } from 'components';
import { genericErrorMessage } from 'data/api/genericErrorMessage';
import { linkEboxWalePairUseCase } from 'domain/use-cases/ebox/linkEboxWalePair.use-case';
import { SendAlertInput } from 'presentation/hooks/useAlert';
import React, { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { EboxWalePair, LooseEbox, LooseWale } from './EboxWalePairTypes';

const LinkEboxAndWale = ({
  looseEboxes,
  looseWales,
  triggerFetch,
  sendAlert
}: {
  looseEboxes: LooseEbox[];
  looseWales: LooseWale[];
  triggerFetch: () => void;
  sendAlert: (a: SendAlertInput) => void;
}) => {
  const [selectedLinkPair, setSelectedLinkPair] = useState<Omit<EboxWalePair, 'eboxName'>>({
    eboxId: null,
    waleId: null
  });
  const looseEboxesOptions = looseEboxes.map((eboxWalePair) => ({
    value: eboxWalePair.eboxId,
    label: `${eboxWalePair.eboxName} / ${eboxWalePair.eboxId}`
  }));

  const looseWaleOptions = looseWales.map((eboxWalePair) => ({
    value: eboxWalePair.waleId,
    label: eboxWalePair.waleId
  }));

  const onLinkButtonClick = () => {
    if (!selectedLinkPair.eboxId || !selectedLinkPair.waleId) {
      sendAlert({
        type: 'error',
        message: 'Wale ID or Ebox ID not set.',
        timeout: 3000
      });
      return;
    }
    linkEboxWalePairUseCase(selectedLinkPair.eboxId, selectedLinkPair.waleId)
      .then(() => {
        sendAlert({
          type: 'success',
          message: 'Devices linked!',
          timeout: 3000
        });
        setSelectedLinkPair({ eboxId: null, waleId: null });
        triggerFetch();
      })
      .catch(genericErrorMessage(false));
  };

  return (
    <>
      <h2 className="text-blue text-slim">Link Ebox and WALE</h2>
      Select an Ebox and a WALE to be linked together:
      <Row>
        <Col>
          <FormikSelect
            key={`eboxLinkingSelect${looseEboxesOptions.length}`}
            placeholder="Ebox"
            options={looseEboxesOptions}
            onChange={(elem: { value: string }) =>
              setSelectedLinkPair({ waleId: selectedLinkPair.waleId, eboxId: elem.value })
            }
          />
        </Col>
        <Col>
          <FormikSelect
            key={`waleLinkingSelect${looseWaleOptions.length}`}
            placeholder="WALE"
            options={looseWaleOptions}
            onChange={(elem: { value: string }) =>
              setSelectedLinkPair({ waleId: elem.value, eboxId: selectedLinkPair.eboxId })
            }
          />
        </Col>
        <Col>
          <Button
            onClick={onLinkButtonClick}
            disabled={selectedLinkPair.eboxId === null || selectedLinkPair.waleId === null}
          >
            Link
          </Button>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col xl={3} lg={4} md={6} sm={8} xs={12}>
          <Row>
            <Col sm={6} xs={5} className="align-self-center">
              Can&apos;t find a WALE?
            </Col>
            <Col sm={6} xs={5} className="align-self-center">
              <Button as={Link} to="/wale/create">
                Create WALE
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <hr />
    </>
  );
};

export default LinkEboxAndWale;
