import React from 'react';
import { Text } from 'recharts';

export const LocationTick = (props: any) => {
  const {
    payload: { value }
  } = props;
  const fontSize = value.length > 5 ? '0.6rem' : '1rem';
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Text {...props} fontSize={fontSize}>
      {value}
    </Text>
  );
};
