import { genericErrorMessage } from 'data/api/genericErrorMessage';
import { eboxNamesCompareFn } from 'domain/entities/ebox.entity';
import { generateLongTokenUseCase } from 'domain/use-cases/auth/generateLongToken.use-case';
import { generateEboxTokenUseCase } from 'domain/use-cases/auth/generateEboxToken.use-case';
import { invalidateTokensUseCase } from 'domain/use-cases/auth/invalidateTokens.use-case';
import { getEboxWalePairsUseCase } from 'domain/use-cases/ebox/getEboxWalePairs.use-case';
import { UserRole } from 'pages/Users/userRoles';
import { RequiredEbox } from 'presentation/EboxWalePairing/EboxWalePairTypes';
import { SendAlertInput, useAlert } from 'presentation/hooks/useAlert';
import { ChangeEvent, useEffect, useState } from 'react';
import { setTooltipMessage } from 'services/generalUtils';

export const useRandomNumber = () => {
  const [randomNumber, setRandomNumber] = useState<number>(0);
  useEffect(() => {
    setRandomNumber(Math.floor(Math.random() * 10000));
  }, []);
  return randomNumber;
};

export const mapToOptionsEboxPairs = (eboxWalePairs: RequiredEbox[]) =>
  eboxWalePairs.map((pair) => ({
    value: pair,
    label: `${pair.eboxName} / ${pair.eboxId}${pair?.waleId ? ` (${pair?.waleId})` : ''}`
  }));

export const useEboxTokenPairs = (role: UserRole) => {
  const [pairs, setPairs] = useState<RequiredEbox[]>([]);
  useEffect(() => {
    if (role !== UserRole.Admin) return;
    getEboxWalePairsUseCase()
      .then((allPairs) => {
        const filteredPairs = allPairs.filter(
          (pair) => pair.eboxId && pair.eboxName
        ) as RequiredEbox[];
        setPairs(filteredPairs);
      })
      .catch(genericErrorMessage(false));
  }, [role]);
  pairs.sort((a, b) => eboxNamesCompareFn(a.eboxName, b.eboxName));
  return pairs;
};

export const onActionTokenFunctions = (
  setLongToken: (a: string) => void,
  role: UserRole,
  randomNumber: number,
  setInvalidateTooltipText: (a: string) => void,
  selectedEboxPair: RequiredEbox | null,
  setEboxTokenPair: (a: RequiredEbox & { token: string }) => void,
  setSelectedEboxPair: (a: RequiredEbox | null) => void,
  sendAlert: ({ type, message, timeout }: SendAlertInput) => void,
  inputNumber: string,
  setInputNumber: (num: string) => void,
) => {
  const setInvalidateMessage = (message: string) =>
    setTooltipMessage(message, setInvalidateTooltipText);

  const onGenerateButtonClick = () =>
    generateLongTokenUseCase(role)
      .then((data) => setLongToken(data.longRefreshToken))
      .catch(() =>
        sendAlert({
          type: 'error',
          message: 'There was a problem, log in again before retrying.'
        })
      );

  const onInvalidateButtonClick = () => {
    if (String(randomNumber) !== inputNumber) {
      setInvalidateMessage("Numbers don't match!");
      return;
    }
    invalidateTokensUseCase()
      .then(() => setInvalidateMessage('Tokens Invalidated!'))
      .catch(() => setInvalidateMessage('There was a problem. Please reload and try again.'));
  };

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const numb = event.target.value.trim();
    setInputNumber(numb);
  };

  const onWaleTokenClick = () => {
    if (!selectedEboxPair) return;
    generateEboxTokenUseCase(selectedEboxPair)
      .then((resp: { refreshToken: string }) => {
        setEboxTokenPair({
          ...selectedEboxPair,
          token: resp.refreshToken
        });
        setSelectedEboxPair(null);
      })
      .catch(() =>
        sendAlert({
          type: 'error',
          message: 'There was a problem, log in again before retrying.'
        })
      );
  };

  return {
    onGenerateButtonClick,
    onInvalidateButtonClick,
    onInputChange,
    onWaleTokenClick
  };
};
