import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import { MaintenanceStatus } from 'domain/entities/wale.entity';
// @ts-ignore ts-migrate(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import Select from 'react-select';
import customStyles from 'components/Select/themes';

const EditableCell = ({ row, column, onChange, dataType, dropdownOptions }: any) => {
  const [value, setValue] = useState(get(row, column.name));

  const handleOnChange = (e: any) => {
    setValue(e.target.value);
    onChange(e);
  };

  const maintenanceOptions = () =>
    Object.values(MaintenanceStatus).map((status) => ({
      label: status,
      value: status
    }));

  const onMaintenanceChange = (selections: { value: string }[]) => {
    if (!selections) {
      setValue([]);
      onChange({ target: { name: row.name, value: [], id: column.id } });
      return;
    }
    const values = selections.map((label: { value: string }) => label.value);
    setValue(values);
    onChange({ target: { name: row.name, value: values, id: column.id } });
  };

  useEffect(() => {
    const remoteValue = get(row, column.name);
    if (typeof value !== 'string' || value === String(remoteValue)) setValue(remoteValue);
  }, [column.name, row]);

  if (column?.editing) {
    if (dataType === 'boolean') {
      return (
        <select
          value={String(value)}
          onChange={handleOnChange}
          name={row.name}
          id={column.id}
          className="text-black"
        >
          <option disabled>Choose</option>
          <option value="true">True</option>
          <option value="false">False</option>
        </select>
      );
    }
    if (dataType === 'maintenance_array') {
      return (
        <div style={{ width: '14em' }}>
          <Select
            isClearable={false}
            isValidNewOption={() => false}
            isMulti
            onChange={onMaintenanceChange}
            options={maintenanceOptions()}
            value={maintenanceOptions().filter((option) => value.includes(option.value))}
            styles={customStyles}
            placeholder="Maintenance status"
            menuPosition="fixed"
          />
        </div>
      );
    }
    if (dataType === 'single_select') {
      return (
        <select
          value={String(value)}
          onChange={handleOnChange}
          name={row.name}
          id={column.id}
          className="text-black"
        >
          <option value="" disabled>
            Select an option
          </option>
          {(dropdownOptions as (number | string)[]).map((option) => (
            <option key={`${row.name}-${option}`} value={option}>
              {option}
            </option>
          ))}
        </select>
      );
    }
    return (
      <input
        type="text"
        name={row.name}
        onChange={handleOnChange}
        value={value}
        id={column.id}
        style={{ width: '100%' }}
        className="text-black"
      />
    );
  }

  return (
    <div
      style={{
        ...(String(value) !== String(get(row, column.name)) && {
          textShadow: '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black',
          color: 'red',
          background: 'blue'
        })
      }}
    >
      {dataType === 'maintenance_array' ? `${value.length} issues` : String(value)}
    </div>
  );
};

export default EditableCell;
