import { useEffect, useState } from 'react';

import { EnhancedUptime, processUptimeUseCase } from 'domain/use-cases/processUptime.use-case';
import { useWaleList } from 'presentation/hooks/useWaleList';
import { getUptimeDataMultipleWALEs, RawUptimeData } from 'domain/use-cases/getUptimeData.use-case';
import { genericErrorLog } from 'data/api/genericErrorMessage';

export const INTERVAL_MINUTES_OPTIONS = [5, 15, 30, 60];

export const useEnhancedWaleUptimes = (date: Date, intervalMinutes: number) => {
  const waleIds = useWaleList();
  const [rawUptimeList, setRawUptimeList] = useState<RawUptimeData[]>([]);
  const [uptimeList, setUptimeList] = useState<EnhancedUptime[]>([]);
  const [lastUpdate, setLastUpdate] = useState(new Date());
  const [updating, setUpdating] = useState(true);

  useEffect(() => {
    setUpdating(true);
    getUptimeDataMultipleWALEs(waleIds, date)
      .then((uptimes) => {
        setRawUptimeList(uptimes);
        setLastUpdate(new Date());
        setUpdating(false);
      })
      .catch((error) => {
        genericErrorLog(error);
        setUpdating(false);
      });
  }, [date, waleIds]);

  // Compute processed uptime data
  useEffect(() => {
    try {
      setUptimeList(rawUptimeList.map((raw) => processUptimeUseCase(raw, intervalMinutes, date)));
    } catch (e) {
      genericErrorLog(e);
      setUptimeList([]);
    }
  }, [rawUptimeList, intervalMinutes]);

  // TODO could also add an interval to refresh automatically every 5 minutes

  return { uptimeList, lastUpdate, updating };
};
