import React, { useState, useEffect, useRef } from 'react';
import { Button, Table, Container } from 'react-bootstrap';
import 'rsuite/styles/index.less';
// @ts-ignore ts-migrate(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import Toggle from 'react-toggle';
import { DownloadInfo, downloadLinksAsZip } from 'presentation/helpers/download';
import SearchBarLabels from './SearchLabelsBar';
import Capture from './EventCapture';
import { getRoadDateTime } from '../../services/timeUtils';

const EVENTS_PER_ROW = 3;
const WIDTH = `${Math.floor(100 / EVENTS_PER_ROW)}%`;

const EventSelectToggle = ({ event, setSelected, selectedDict, allSelected }: any) => {
  const selected = useRef();

  const handleOnChange = () => {
    const newSelectedDict = selectedDict;
    // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    const isChecked = selected.current.checked;
    const eventKey = `${event._id}_${event.waleId}`;

    if (eventKey in selectedDict) {
      if (isChecked) newSelectedDict[eventKey] = event.imageUrls;
      else delete newSelectedDict[eventKey];
    }
    if (!(eventKey in selectedDict) && isChecked) {
      newSelectedDict[eventKey] = event.imageUrls;
    }
    setSelected(newSelectedDict);
  };

  useEffect(() => {
    // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    selected.current.checked = allSelected;
  }, [allSelected]);

  return (
    <Container fluid className="bliss-card">
      <input
        type="checkbox"
        style={{ margin: 10 }}
        defaultChecked={false}
        // @ts-ignore ts-migrate(2322) FIXME: Type 'MutableRefObject<undefined>' is not assignab... Remove this comment to see the full error message
        ref={selected}
        onChange={handleOnChange}
      />
      <h4 className="font-weight-bold mw=150">Event ID: {event._id}</h4>
      <p>Date: {getRoadDateTime(event.capturedAt)}</p>
      <p>WALE: {event.waleId}</p>
      <Capture eventData={event} onlyLabels />
    </Container>
  );
};

const LabelsIndex = () => {
  const [eventsData, setEventsData] = useState([]);
  const [captureDict, setCaptureDict] = useState<{ [eventId: string]: string[] }>({}); // { <eventId> : [<captureLinks>] }
  const [selectToggle, setSelectToggle] = useState(false);

  const arrayToTable = (array: any) => {
    const nRows = Math.ceil(array.length / EVENTS_PER_ROW);
    // @ts-ignore ts-migrate(2569) FIXME: Type 'IterableIterator<number>' is not an array ty... Remove this comment to see the full error message
    const rowArray = [...Array(nRows).keys()];
    return rowArray.map((rowIndex) => (
      <tr key={`row${rowIndex}`}>
        {/* @ts-ignore ts-migrate(2569) FIXME: Type 'IterableIterator<number>' is not an array ty... Remove this comment to see the full error message */}
        {[...Array(EVENTS_PER_ROW).keys()].map((columnIndex) => (
          <td key={`column${columnIndex}`} style={{ width: WIDTH }}>
            {array[rowIndex * 3 + columnIndex]}
          </td>
        ))}
      </tr>
    ));
  };

  const downloadCaptures = async () => {
    if (Object.keys(captureDict).length === 0) return;

    const downloadList: DownloadInfo[] = [];

    Object.keys(captureDict).forEach((key) => {
      captureDict[key].forEach((capture) => {
        const afterSlashMatch = /([^/]+$)/.exec(capture);
        if (!afterSlashMatch) return;

        const jpgMatch = /(.*)(?=.jpg)/.exec(afterSlashMatch[0]);
        if (!jpgMatch) return;

        downloadList.push({ url: capture, fileName: `${jpgMatch[0]}_${key}.jpg` });
      });
    });

    downloadLinksAsZip(downloadList, 'captures');
  };

  const handleToggleChange = () => {
    const newSelectedDict = {};
    const newSelectToggle = !selectToggle;
    if (eventsData.length > 0 && newSelectToggle) {
      eventsData.forEach((event) => {
        // @ts-ignore ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        newSelectedDict[`${(event as any)._id}_${(event as any).waleId}`] = (
          event as any
        ).imageUrls;
      });
    }
    setSelectToggle(newSelectToggle);
    setCaptureDict(newSelectedDict);
  };

  return (
    <>
      <SearchBarLabels setEventsData={setEventsData} />
      <div>
        <Toggle defaultChecked={selectToggle} onChange={handleToggleChange} />
        <span>Select all events for download</span>
      </div>
      <Button variant="outline-success" onClick={downloadCaptures}>
        Download pictures
      </Button>
      <Table borderless size="sm" style={{ color: '#ffffff' }}>
        <tbody>
          {arrayToTable(
            eventsData.map((eventData) => (
              <EventSelectToggle
                event={eventData}
                setSelected={setCaptureDict}
                selectedDict={captureDict}
                allSelected={selectToggle}
              />
            ))
          )}
        </tbody>
      </Table>
    </>
  );
};

export default LabelsIndex;
