import React, { useEffect } from 'react';
import { Row, Col, Button, Image } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { ArrowLeft } from 'react-feather';
import { withFormik, Field, Form } from 'formik';
// @ts-ignore ts-migrate(7016) FIXME: Could not find a declaration file for module 'yup'... Remove this comment to see the full error message
import * as Yup from 'yup';
import { forgotPasswordUseCase } from 'domain/use-cases/auth/resetPassword.use-case';
import { FormikInput } from '../../components';
import { sendAlert } from '../../store/actions/utils';
import Logo from '../../assets/images/LogoText.png';
import './style.scss';

const RecoverPassword = (props: any) => {
  const { onHide, isValid, submitVariant, errors, touched } = props;
  useEffect(() => {
    document.body.className = 'login-bg';
  }, []);
  return (
    <Row className="full-height login">
      <Col md={3} className="text-center">
        <Image src={Logo} className="img-fluid" />
      </Col>
      <Col md={9}>
        <Row>
          <Col md={{ span: 4, offset: 4 }}>
            <Link to="/" className="text-blue text-medium">
              <Button
                variant={submitVariant}
                size="lg"
                className="btn-circle"
                type="submit"
                disabled={!isValid}
                onClick={onHide}
              >
                <ArrowLeft size={15} className="align-baseline" />
              </Button>
              Go Back
            </Link>
            <h4 className="text-white mt-4">Forgot your password?</h4>
            <Form className="mt-5">
              <Field name="email">
                {({ field }: any) => (
                  <FormikInput
                    {...field}
                    placeholder="Enter your email"
                    inputType="email"
                    error={errors[field.name]}
                    touched={touched[field.name]}
                  />
                )}
              </Field>
              <Row>
                <Col md={{ span: 6, offset: 6 }}>
                  <Button
                    variant={submitVariant}
                    // @ts-ignore ts-migrate(2322) FIXME: Type '"md"' is not assignable to type '"sm" | "lg"... Remove this comment to see the full error message
                    size="md"
                    block
                    className="mt-4 btn-dark"
                    type="submit"
                    disabled={!isValid}
                    onClick={onHide}
                  >
                    Send
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state: any) => {
  const { ongoingRequest } = state.auth;
  return { ongoingRequest };
};

const initialValues = { email: '' };

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Mandatory field')
});

const handleSubmit = (values: any, { props }: any) => {
  const { dispatch, history } = props;
  forgotPasswordUseCase(values.email)
    .then(() => {
      dispatch(
        sendAlert({
          kind: 'success',
          message:
            "An email with instructions has been sent to that address, if it's in our records.",
          timeout: 4000
        })
      );
      history.push('/login');
    })
    .catch(() => {
      dispatch(
        sendAlert({ kind: 'error', message: 'Could not send the email. Please try again later.' })
      );
    });
};

export default withRouter(
  connect(mapStateToProps)(
    withFormik({
      mapPropsToValues: () => initialValues,
      validationSchema,
      handleSubmit
    })(RecoverPassword)
  )
);
