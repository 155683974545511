import React, { useState, useEffect, useRef } from 'react';
import { Table } from 'react-bootstrap';
// @ts-ignore ts-migrate(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import Creatable from 'react-select/creatable';
import { useShortcut, KeyCode } from 'presentation/hooks/useShortcut';
import ErrorCodeSelect, { errorCodeToLabel } from 'components/Select/ErrorCodeSelect';
import { HumanReview, PlateState } from 'domain/entities/waleDetection.entity';
import StateSelect from 'components/StateSelect';
import { updateWaleDetectionReviewUseCase } from 'domain/use-cases/waleDetection/updateWaleDetectionReview.use-case';
import { getEventLabelsUseCase } from 'domain/use-cases/eventLabels/getEventLabels.use-case';
import { genericErrorLog } from 'data/api/genericErrorMessage';
import { useUser } from 'presentation/hooks/data/useUser';
import { BasicActionButtons } from '../../../components';
import { getStateFullname } from '../../../services/statePlateStyles';

const toPercent = (v: any) => v && (v * 100).toFixed(2);

const TableRow = ({ rowData, isEditing, editComponent, hideRow }: any) => {
  let alprString;
  if (rowData.alpr && rowData.label === 'Engine') alprString = `${rowData.alpr.prediction}`;
  else if (rowData.alpr)
    alprString = `${rowData.alpr.prediction} (${toPercent(rowData.alpr.score)} %)`;

  if (!hideRow) {
    return (
      <tr style={{ height: '55px', maxWidth: '100%' }}>
        <td style={{ textAlign: 'left', width: '20%' }}> {rowData.label} </td>
        <td style={{ textAlign: 'right', width: '30%' }}>{rowData.alpr ? alprString : ''}</td>
        <td style={{ textAlign: 'right', width: '30%' }}>
          {isEditing ? editComponent : rowData.humanInternal}
        </td>
        <td style={{ textAlign: 'right', width: '20%' }}>{rowData.humanExternal}</td>
      </tr>
    );
  }
  return null;
};

const EditBtns = ({ isEditing, handleActionClick, vehicleEventId }: any) => (
  <BasicActionButtons
    item={{ _id: vehicleEventId }}
    clickHandler={handleActionClick}
    editClick={!isEditing}
    saveClick={isEditing}
    discardClick={isEditing}
    withDestroy={false}
    withEdit={false}
    withShow={false}
  />
);

// TODO: move table to components folder once it's refactored to new architecture, since it's
// being used by a different (largely unused) page
const AlprTable = ({ alprData, humanReview, vehicleEventId, captureIndex, onlyLabels }: any) => {
  const user = useUser();
  const errorCodeRef = useRef();
  const [humanReviewState, setHumanReviewState] = useState<HumanReview | null>(humanReview);
  const [hideRows, setHideRows] = useState(onlyLabels);
  const internalHumanReview = humanReviewState?.internal;
  const captureLabels = internalHumanReview?.labels
    ? internalHumanReview?.labels[captureIndex]
    : null;

  const initialGeneralLabels = internalHumanReview?.labels?.event;
  const initialCaptureLabels = internalHumanReview?.labels?.[captureIndex];
  const tableStyle = {
    margin: '5%',
    marginTop: '15px',
    marginBottom: 0,
    color: 'white'
  };

  const [isEditing, setIsEditing] = useState(false);
  const [reviewed, setReviewed] = useState(internalHumanReview !== undefined);
  const [humanPlateState, setHumanPlateState] = useState(internalHumanReview?.plateState || '');

  const [humanPlateNumber, setHumanPlateNumber] = useState(internalHumanReview?.plateNumber || '');
  const [humanMakeModel, setHumanMakeModel] = useState(internalHumanReview?.makeModel || '');
  const [humanErrorCode, setHumanErrorCode] = useState(internalHumanReview?.errorCode);

  const [globalEventLabels, setGlobalEventLabels] = useState<string[]>(initialGeneralLabels ?? []); // whole event labels
  const [eventLabelsDB, setEventLabelsDB] = useState<string[]>([]); // available labels in DB
  const [eventLabels, setEventLabels] = useState<string[]>(initialCaptureLabels ?? []); // selected labels in component

  const handleStateChange = (state: PlateState | null) => setHumanPlateState(state || '');

  useEffect(() => {
    setReviewed(internalHumanReview !== undefined);
    setHumanPlateState(internalHumanReview?.plateState || '');
    setHumanPlateNumber(internalHumanReview?.plateNumber || '');
    setHumanMakeModel(internalHumanReview?.makeModel || '');
    setHumanErrorCode(internalHumanReview?.errorCode);
  }, [vehicleEventId, internalHumanReview]);

  useEffect(() => {
    setEventLabels([]);
  }, [vehicleEventId, captureLabels]);

  useEffect(() => {
    setHumanReviewState(humanReview);
  }, [vehicleEventId]);

  const plateStateData = {
    label: 'Plate state',
    alpr: {
      prediction: getStateFullname(alprData.plateState.prediction),
      score: alprData.plateState.score
    },
    humanInternal: reviewed ? getStateFullname(humanPlateState) : null,
    humanExternal: getStateFullname(humanReview?.external?.plateState)
  };
  const plateNumberData = {
    label: 'Plate number',
    alpr: alprData.plateNumber[0],
    humanInternal: reviewed ? humanPlateNumber : null,
    humanExternal: humanReview?.external?.plateNumber
  };
  const makeData = {
    label: 'Make',
    alpr: alprData.make[0]
  };
  const makeModelData = {
    label: 'Make model',
    alpr: alprData.makeModel[0],
    humanInternal: reviewed ? humanMakeModel : null
  };
  const colorData = {
    label: 'Color',
    alpr: alprData.color[0]
  };
  const engineData = {
    label: 'Engine',
    alpr: alprData.engine
  };
  const errorData = {
    label: 'Error',
    humanInternal: reviewed ? errorCodeToLabel(humanErrorCode) : null,
    humanExternal: humanReview?.external?.errorCode
  };

  const labelData = {
    label: `Labels (#${captureIndex + 1})`,
    humanInternal: initialCaptureLabels?.join(',')
  };

  const eventLabelData = {
    label: 'Event Labels',
    humanInternal: initialGeneralLabels?.join(',')
  };

  const customStyles = {
    // @ts-ignore ts-migrate(7006) FIXME: Parameter 'provided' implicitly has an 'any' type.
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? 'red' : '#1e1e2d',
      padding: 10
    })
  };

  const handleSave = (id: any) => {
    if (!id) return;
    const plateNumber = humanPlateNumber;
    const makeModel = humanMakeModel;
    // @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    const errorCode = errorCodeRef.current.value;

    const plateState = humanPlateState;

    const humanReviewInput: HumanReview = {
      internal: {
        labeledBy: user.email,
        completedAt: new Date(),
        plateState:
          plateState !== '' && Object.values(PlateState).includes(plateState as PlateState)
            ? (plateState as PlateState)
            : undefined,
        plateNumber: plateNumber !== '' ? plateNumber : undefined,
        makeModel: makeModel !== '' ? makeModel : undefined,
        errorCode: errorCode !== '' ? errorCode : undefined,
        labels: { event: [...globalEventLabels].sort(), [captureIndex]: [...eventLabels].sort() }
      }
    };
    updateWaleDetectionReviewUseCase(id, humanReviewInput)
      .then((data: any) => {
        // Warning, this will update the humanReview locally which might make it differ from the one stored at higher levels
        setHumanReviewState(data.humanReview);
        setIsEditing(false);
      })
      .catch(genericErrorLog);
  };

  const handleActionClick = ({ _id }: any, action: any) => {
    switch (action) {
      case 'edit':
        getEventLabelsUseCase()
          .then((data) => setEventLabelsDB(data?.labels ?? []))
          .catch(genericErrorLog);

        if (captureLabels && captureLabels.length > 0) {
          setEventLabels(captureLabels);
        }
        setIsEditing(true);
        break;

      case 'save':
        handleSave(_id);
        break;

      default:
        setIsEditing(false);
        break;
    }
  };

  useShortcut([KeyCode.ControlLeft, KeyCode.E], () => setIsEditing((prev) => !prev));
  useShortcut([KeyCode.ControlLeft, KeyCode.S], () => {
    if (isEditing) {
      handleSave(vehicleEventId);
    }
  });
  useShortcut([KeyCode.ControlLeft, KeyCode.C], () => {
    if (isEditing) {
      if (humanReviewState?.external?.status === 'completed') {
        setHumanPlateNumber(humanReviewState.external.plateNumber ?? '');
        setHumanPlateState(humanReviewState.external.plateState ?? '');
      } else {
        setHumanPlateNumber(plateNumberData.alpr.prediction);
        setHumanPlateState(alprData.plateState.prediction);
      }
      setHumanMakeModel(makeModelData.alpr.prediction);
    }
  });

  return (
    <>
      <div style={{ marginLeft: '20px', display: 'flex' }}>
        {!onlyLabels && (
          <EditBtns
            isEditing={isEditing}
            handleActionClick={handleActionClick}
            vehicleEventId={vehicleEventId}
          />
        )}
      </div>
      <Table style={tableStyle}>
        <thead style={{ fontWeight: 600 }}>
          <tr
            onClick={() => {
              setHideRows(onlyLabels && !hideRows);
            }}
          >
            <th style={{ textAlign: 'left' }}>Parameter</th>
            <th style={{ textAlign: 'right' }}>ALPR</th>
            <th style={{ textAlign: 'right' }}>Internal</th>
            <th style={{ textAlign: 'right' }}>GAS</th>
          </tr>
        </thead>
        <tbody style={{ fontWeight: 300 }}>
          <TableRow
            hideRow={hideRows}
            rowData={plateStateData}
            isEditing={isEditing}
            editComponent={
              <StateSelect defaultValue={humanPlateState} setSelectedState={handleStateChange} />
            }
          />
          <TableRow
            hideRow={hideRows}
            rowData={plateNumberData}
            isEditing={isEditing}
            editComponent={
              <input
                onChange={(e) => {
                  setHumanPlateNumber(e.target.value);
                }}
                value={humanPlateNumber}
              />
            }
          />
          <TableRow
            hideRow={hideRows}
            rowData={makeModelData}
            isEditing={isEditing}
            editComponent={
              // @ts-ignore ts-migrate(2322) FIXME: Type 'MutableRefObject<undefined>' is not assignab... Remove this comment to see the full error message
              <input
                onChange={(e) => {
                  setHumanMakeModel(e.target.value);
                }}
                value={humanMakeModel}
              />
            }
          />
          <TableRow
            hideRow={onlyLabels}
            rowData={errorData}
            isEditing={isEditing}
            editComponent={
              <ErrorCodeSelect
                ref={errorCodeRef}
                // @ts-ignore ts-migrate(2322) FIXME: Type '{ ref: MutableRefObject<undefined>; selected... Remove this comment to see the full error message
                selectedErrorCode={String(humanErrorCode)}
              />
            }
          />
          <TableRow
            rowData={labelData}
            isEditing={isEditing}
            editComponent={
              <Creatable
                isClearable
                isMulti
                onChange={(value: { label: string }[]) =>
                  setEventLabels((value ?? []).map((elem) => elem.label))
                }
                options={Object.values(eventLabelsDB).map((item, i) => ({
                  label: item,
                  value: i
                }))}
                value={eventLabels.map((item, index) => ({ label: item, value: index }))}
                styles={customStyles}
              />
            }
          />
          <TableRow
            rowData={eventLabelData}
            isEditing={isEditing}
            editComponent={
              <Creatable
                isClearable
                isMulti
                onChange={(value: { label: string }[]) =>
                  setGlobalEventLabels((value ?? []).map((elem) => elem.label))
                }
                options={Object.values(eventLabelsDB).map((item, i) => ({
                  label: item,
                  value: i
                }))}
                value={globalEventLabels.map((item, index) => ({ label: item, value: index }))}
                styles={customStyles}
              />
            }
          />
          <TableRow rowData={engineData} hideRow={hideRows} />
          <TableRow rowData={makeData} hideRow={hideRows} />
          <TableRow rowData={colorData} hideRow={hideRows} />
        </tbody>
      </Table>
    </>
  );
};

export default AlprTable;
