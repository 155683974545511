import { Trip } from 'domain/entities/trip.entity';
import { ZoneEvent } from 'domain/entities/zoneEvent.entity';
import React from 'react';
import { Button } from 'react-bootstrap';
import { getStateFullname } from 'services/statePlateStyles';
import { getRoadDate, getRoadTime } from 'services/timeUtils';
import ZonesPopup from './ZonesPopup';

export const TripVisualizerElement = ({
  trip,
  goToZone,
  setSelectedTrip
}: {
  trip: Trip;
  goToZone: (a: ZoneEvent[]) => void;
  setSelectedTrip: any;
}) => {
  const { plateNumber, plateState, startedAt, fare, isDuplicate, zoneEvents, tripId } = trip;
  const normalFontStyle = { fontWeight: 'normal' };
  const handleClick = () => {
    goToZone(zoneEvents);
    setSelectedTrip(trip);
  };

  return (
    <tr className="text-center justify-content-between center-row-columns">
      <th style={normalFontStyle}>{tripId}</th>
      <th style={normalFontStyle}>{plateNumber}</th>
      <th style={normalFontStyle}>{getStateFullname(plateState)}</th>
      <th style={normalFontStyle}>{getRoadDate(startedAt)}</th>
      <th style={normalFontStyle}>{getRoadTime(startedAt)}</th>
      <th style={normalFontStyle}>{`$ ${fare}`}</th>
      <th style={normalFontStyle}>{isDuplicate ? 'Yes' : ''}</th>
      <th style={normalFontStyle}>
        <ZonesPopup zones={zoneEvents} />
      </th>
      <th>
        <Button onClick={handleClick}>See Zones</Button>
      </th>
    </tr>
  );
};
