import React from 'react';
import { EnhancedUptime } from 'domain/use-cases/processUptime.use-case';
import { getRoad, shortWaleId } from 'presentation/helpers/waleId';
import { UptimeMetrics, DisplayUptimeMetrics } from './DisplayUptimeMetrics';

export const SingleWaleUptimeRow = ({ waleUptime }: { waleUptime: EnhancedUptime }) => {
  const road = getRoad(waleUptime.waleId);
  const shortId = shortWaleId(waleUptime.waleId);

  return (
    <>
      <tr>
        <td rowSpan={5}>
          {road}
          <br />
          {shortId}
        </td>
        <td style={{ textAlign: 'left' }}>{UptimeMetrics.DetectionsCount}</td>
        {waleUptime.timeSlots.map(DisplayUptimeMetrics[UptimeMetrics.DetectionsCount])}
      </tr>
      <tr>
        <td style={{ textAlign: 'left' }}>{UptimeMetrics.LidarMinutes}</td>
        {waleUptime.timeSlots.map(DisplayUptimeMetrics[UptimeMetrics.LidarMinutes](shortId))}
      </tr>
      <tr>
        <td style={{ textAlign: 'left' }}>{UptimeMetrics.LidarNormal}</td>
        {waleUptime.timeSlots.map(DisplayUptimeMetrics[UptimeMetrics.LidarNormal])}
      </tr>
      <tr>
        <td style={{ textAlign: 'left' }}>{UptimeMetrics.VideoMinutes}</td>
        {waleUptime.timeSlots.map(DisplayUptimeMetrics[UptimeMetrics.VideoMinutes](shortId))}
      </tr>
      <tr>
        <td style={{ textAlign: 'left' }}>{UptimeMetrics.StatusesCount}</td>
        {waleUptime.timeSlots.map(DisplayUptimeMetrics[UptimeMetrics.StatusesCount])}
      </tr>
    </>
  );
};
