import { UserRole } from 'pages/Users/userRoles';
import store from 'services/store';
import { NOT_SET_DEMO_PARAM, addCurrentTime } from '../../services/timeUtils';
import { useUser } from './data/useUser';

export const useEndDate = () => {
  const user = useUser();

  return calculateEndDate({
    demo: user.demo,
    demoDate: user.demoDate,
    demoHour: user.demoHour,
    demoMinute: user.demoMinute,
    role: user.role
  });
};

export const getEndDate = () => {
  const { user } = store.getState().auth;

  const endDate = calculateEndDate({
    demo: user.demo,
    demoDate: user.demoDate,
    demoHour: user.demoHour,
    demoMinute: user.demoMinute,
    role: user.role
  });

  return endDate;
};

const calculateEndDate = ({
  demo,
  demoDate,
  demoHour,
  demoMinute,
  role
}: {
  demo: boolean;
  demoDate: Date;
  demoHour: number;
  demoMinute: number;
  role: UserRole;
}) => {
  let endDate = new Date();
  if (demo) {
    endDate = new Date(addCurrentTime(demoDate, demoHour, demoMinute));
  }
  const localDemoDate = role === UserRole.Admin && sessionStorage.getItem('demoDate');
  if (localDemoDate) {
    const localDemoHour = parseInt(
      sessionStorage.getItem('demoHour') ?? NOT_SET_DEMO_PARAM.toString(),
      10
    );
    const localDemoMinute = parseInt(
      sessionStorage.getItem('demoMinute') ?? NOT_SET_DEMO_PARAM.toString(),
      10
    );
    endDate = new Date(
      addCurrentTime(
        localDemoDate,
        Number.isNaN(localDemoHour) ? NOT_SET_DEMO_PARAM : localDemoHour,
        Number.isNaN(localDemoMinute) ? NOT_SET_DEMO_PARAM : localDemoMinute
      )
    );
  }
  return endDate;
};
