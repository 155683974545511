import React from 'react';

import { Row, Col } from 'react-bootstrap';
import { Minus } from 'react-feather';
import ThroughputBar from './ThroughputBar';
import BasicLaneStats from './BasicLaneStats';

const KMHR_TO_MPH = 0.621371;
const MAX_THEORETICAL_THROUGHPUT = 1800;

export const convertedSpeed = (speedKmHour: number | null) =>
  speedKmHour ? Math.round(speedKmHour * KMHR_TO_MPH) : null;

export const Lane = ({
  trafficInformation = undefined,
  title,
  numberOfLines,
  maxTheoreticalThroughput = MAX_THEORETICAL_THROUGHPUT
}: {
  trafficInformation?: { vehiclesPerHour: number; speedKmHour: number | null };
  title: string;
  numberOfLines: number;
  maxTheoreticalThroughput?: number;
}) => {
  const isOffline =
    trafficInformation?.speedKmHour === null || trafficInformation?.speedKmHour === undefined;
  const subTitle = `${numberOfLines} line${numberOfLines > 1 ? 's' : ''}`;

  return (
    <Row>
      <Col className="lane p-0">
        <Row>
          <Col md={1} xs={1}>
            <span className="text-blue">
              <Minus />
            </span>
          </Col>
          <Col md={10} xs={10}>
            <h5 className="text-white mb-0"> {title}</h5>
            <p className="text-medium">{subTitle}</p>
          </Col>
        </Row>
        <Row>
          <Col md={6} xs={12} className="mt-4 buffer-top">
            <ThroughputBar
              throughput={trafficInformation && Math.round(trafficInformation?.vehiclesPerHour)}
              maxThroughput={maxTheoreticalThroughput}
              isOffline={isOffline}
            />
          </Col>
          <Col md={6} xs={12} className="mt-4">
            <BasicLaneStats
              throughput={trafficInformation && Math.round(trafficInformation.vehiclesPerHour)}
              speedMPH={trafficInformation && convertedSpeed(trafficInformation.speedKmHour)}
              isOffline={isOffline}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Lane;
