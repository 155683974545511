import {
  getWaleDetection,
  getWaleDetectionById,
  getWaleDetectionByDate,
  GetWaleDetectionMode,
  getWaleDetectionWithLabels
} from 'data/waleDetection.repository';
import {
  getLocationAndLaneId,
  getWaleLocation,
  getWalePair,
  isFront
} from 'presentation/helpers/waleId';
import { LabeledDetectionsParams } from 'pages/LabelSearch/SearchLabelsBar';

const getNextWaleDetectionByDate = getWaleDetectionByDate(GetWaleDetectionMode.NEXT);
const getPrevWaleDetectionByDate = getWaleDetectionByDate(GetWaleDetectionMode.PREV);
const requestMap = {
  [GetWaleDetectionMode.NEXT]: getNextWaleDetectionByDate,
  [GetWaleDetectionMode.PREV]: getPrevWaleDetectionByDate
};

export const getWaleDetectionPairByIdUseCase = async (id: string) => {
  const event = await getWaleDetectionById(id);
  if (!event) return { frontEvent: null, backEvent: null, location: null };

  const mode = isFront(event.waleId) ? GetWaleDetectionMode.PREV : GetWaleDetectionMode.NEXT;

  const getWaleDetectionByDateRequest = requestMap[mode];

  const pairEvent = await getWaleDetectionByDateRequest(
    getWalePair(event.waleId),
    new Date(event?.capturedAt),
    event.laneNumber
  );

  const frontEvent = isFront(event.waleId) ? event : pairEvent;
  const backEvent = !isFront(event.waleId) ? event : pairEvent;

  return {
    frontEvent,
    backEvent,
    location: getWaleLocation(event.waleId),
    locationAndLane: getLocationAndLaneId(event.waleId)
  };
};

export const getWaleDetectionByDateUseCase = async (
  waleId: string,
  date: Date,
  videoSource: boolean,
  lane?: number
) => getNextWaleDetectionByDate(waleId, date, lane, videoSource);

export const getPrevWaleDetectionByDateUseCase = async (
  waleId: string,
  date: Date,
  videoSource: boolean,
  lane?: number
) => getPrevWaleDetectionByDate(waleId, date, lane, videoSource);

export const getWaleDetectionUseCase = (
  waleId: string,
  endDate: Date,
  lane?: number,
  alpr = true
) => getWaleDetection(waleId, endDate, lane, alpr);

export const getLabeledWaleDetectionsUseCase = (params: LabeledDetectionsParams) =>
  getWaleDetectionWithLabels(params);
