import React from 'react';
import { Container, Row, Table } from 'react-bootstrap';
import range from 'lodash/range';

import { EnhancedUptime } from 'domain/use-cases/processUptime.use-case';
import { SingleWaleUptimeRow } from './SingleWaleUptimeRow';
import { UptimeDataByMetric } from './UptimeDataByMetric';

const zeroPadded = (i: number) => String(i).padStart(2, '0');

const HOURS_ARRAY = range(24).map((i) => `${zeroPadded(i)}-${zeroPadded(i + 1)}`);

const UptimeTableHeader = ({
  intervalsPerHour,
  groupByWale
}: {
  intervalsPerHour: number;
  groupByWale: boolean;
}) => (
  <thead>
    <tr>
      {groupByWale ? (
        <>
          <th>WALE</th>
          <th>Metric</th>
        </>
      ) : (
        <>
          <th>Metric</th>
          <th>WALE</th>
        </>
      )}
      {HOURS_ARRAY.map((i) => (
        <th colSpan={intervalsPerHour} key={i}>
          {i}
        </th>
      ))}
    </tr>
  </thead>
);

export const WaleUptimeTable = ({
  uptimeList,
  groupByWale
}: {
  uptimeList: EnhancedUptime[];
  groupByWale: boolean;
}) => {
  const intervalsPerHour = uptimeList.length ? Math.round(60 / uptimeList[0].intervalMinutes) : 1;

  return (
    <Container fluid>
      <Row>
        <Table
          responsive
          className="metrics-table"
          variant="dark"
          style={{ background: 'transparent', fontSize: 'small', width: '100%' }}
          bordered
          hover
          size="sm"
        >
          <UptimeTableHeader intervalsPerHour={intervalsPerHour} groupByWale={groupByWale} />
          <tbody>
            {groupByWale ? (
              uptimeList.map((uptime) => (
                <SingleWaleUptimeRow key={uptime.waleId} waleUptime={uptime} />
              ))
            ) : (
              <UptimeDataByMetric uptimeData={uptimeList} />
            )}
          </tbody>
        </Table>
      </Row>
    </Container>
  );
};
