/* eslint-disable react/jsx-props-no-spreading */
import React, { ChangeEvent, useCallback, useState, useEffect } from 'react';
import { Row, Col, Container, Button } from 'react-bootstrap';
import { useShortcut, KeyCode } from 'presentation/hooks/useShortcut';
import { RoadDateTimePicker } from 'components/RoadDateTimePicker/RoadDateTimePicker';
import { useSelector } from 'react-redux';
import { isInternal } from 'pages/Users/userRoles';
import Select from 'components/Select';
import { mapToOptions } from 'components/Select/FormikSelect';
import { useQueryParam } from 'presentation/hooks/useQueryParam';
import { getLocationAndLaneId } from 'presentation/helpers/waleId';
import { useWaleEventsViewModel } from './WaleEvents.view-model';
import { WaleDetectionResume } from './detection/WaleDetection';
import { Can } from '../../services/abilityContext';

const ANY_LANES_KEYWORD = 'Any';

const LaneSelect = ({
  lanes,
  setSelectedLane,
  value
}: {
  lanes: number[];
  setSelectedLane: (lane: number | null) => void;
  value: number | null;
}) => {
  const laneOptions = lanes.map((lane) => (
    <option value={lane} key={lane} placeholder="Select Lane">
      {lane}
    </option>
  ));

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedLane(event.target.value === ANY_LANES_KEYWORD ? null : Number(event.target.value));
  };

  return (
    <div className="flex items-center">
      <label htmlFor="select" style={{ margin: '0' }}>
        Lane
        <select
          defaultValue={ANY_LANES_KEYWORD}
          value={value || ANY_LANES_KEYWORD}
          onChange={handleChange}
          className="py-1 px-2 ml-8 rounded text-black"
        >
          <option value={ANY_LANES_KEYWORD} key={ANY_LANES_KEYWORD} placeholder="Select Lane">
            {ANY_LANES_KEYWORD}
          </option>
          {laneOptions}
        </select>
      </label>
    </div>
  );
};

export const WaleEvents = () => {
  const queryLane = useQueryParam('lane');
  const queryWaleId = useQueryParam('waleId');
  const queryDate = useQueryParam('date');
  const [date, setDate] = useState<Date | null>(
    queryDate && Date.parse(queryDate) ? new Date(queryDate) : null
  );

  const user = useSelector((state) => (state as any).auth.user);
  const [location, setLocation] = useState<string | null>(
    queryWaleId ? getLocationAndLaneId(queryWaleId) : null
  );
  const [lane, setLane] = useState<number | null>(queryLane ? Number(queryLane) : null);
  const [waleDetectionId, setWaleDetectionId] = useState<string | null>(null);
  const [showByOrientation, setShowByOrientation] = useState({ front: true, back: true });
  const [hideTableAndPlate, setHideTableAndPlate] = useState<boolean>(true);
  const [videoSource, setVideoSource] = useState<boolean>(false);

  const waleEventsViewModel = useWaleEventsViewModel(
    setLocation,
    videoSource,
    lane,
    location,
    date
  );

  const { selectedLocation } = waleEventsViewModel.inputs.location;
  const { setInputs } = waleEventsViewModel.inputs;

  const { frontWaleDetection, backWaleDetection } = waleEventsViewModel.data;
  const availableLocations = waleEventsViewModel.data.locations;
  const nextWaleDetectionFront =
    waleEventsViewModel.actions.getNextWaleDetection.frontWaleDetection;
  const nextWaleDetectionBack = waleEventsViewModel.actions.getNextWaleDetection.backWaleDetection;
  const prevWaleDetectionFront =
    waleEventsViewModel.actions.getPrevWaleDetection.frontWaleDetection;
  const prevWaleDetectionBack = waleEventsViewModel.actions.getPrevWaleDetection.backWaleDetection;

  const handleEventIdInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setWaleDetectionId(event.target.value);
  };

  const search = (): void => {
    if (waleDetectionId) {
      setWaleDetectionId(null);
      return setInputs({ eventIdInput: waleDetectionId });
    }
    return setInputs({
      selectedDate: date ?? new Date(), // no date selected = get last event
      selectedLocation: location ?? selectedLocation
    });
  };

  const nextDetection = useCallback(() => {
    if (!isInternal(user?.role)) return;
    if (showByOrientation.front) nextWaleDetectionFront();
    if (showByOrientation.back) nextWaleDetectionBack();
  }, [nextWaleDetectionFront, nextWaleDetectionBack, showByOrientation]);

  const prevDetection = useCallback(() => {
    if (!isInternal(user?.role)) return;
    if (showByOrientation.front) prevWaleDetectionFront();
    if (showByOrientation.back) prevWaleDetectionBack();
  }, [prevWaleDetectionFront, prevWaleDetectionBack, showByOrientation]);

  useShortcut([KeyCode.ControlLeft, KeyCode.A], prevDetection);
  useShortcut([KeyCode.ControlLeft, KeyCode.D], nextDetection);
  useShortcut([KeyCode.ControlLeft, KeyCode.F], () =>
    setShowByOrientation((prev) =>
      prev.front && prev.back ? { front: true, back: false } : { front: true, back: true }
    )
  );
  useShortcut([KeyCode.ControlLeft, KeyCode.B], () =>
    setShowByOrientation((prev) =>
      prev.front && prev.back ? { front: false, back: true } : { front: true, back: true }
    )
  );
  useShortcut([KeyCode.ControlLeft, KeyCode.M], () => {
    if (!isInternal(user?.role)) return;
    setVideoSource(!videoSource);
    console.log('Video source:', !videoSource);
  });

  useEffect(() => {
    if (selectedLocation) {
      setLocation(selectedLocation);
    }
  }, [selectedLocation]);

  const toggleText = hideTableAndPlate ? 'Show ALPR prediction' : 'Hide ALPR prediction';

  return (
    <>
      <Can I="read" a="all">
        <Container className="bliss-card my-3 mw-100 px-5 py-3">
          <Row className="justify-content-between align-middle wale-events-options">
            <Button onClick={prevDetection}>Prev Detection</Button>
            <input
              onChange={handleEventIdInputChange}
              type="text"
              value={waleDetectionId || ''}
              name="id_event_database"
              placeholder="Search by ID"
              className="px-3 rounded-pill"
            />
            <Select
              placeholder="Select Location"
              key={availableLocations}
              isClearable
              options={mapToOptions(availableLocations)}
              defaultValue={location}
              value={location}
              onChange={(selection: { value: string } | null) => {
                setLocation(selection ? selection.value : null);
              }}
            />
            <LaneSelect
              value={lane}
              lanes={waleEventsViewModel.data.lanes}
              setSelectedLane={setLane}
            />
            <div className="datetime">
              <RoadDateTimePicker
                onChange={setDate}
                inputProps={{ placeholder: 'Search by date (MT)' }}
                initialValue={date}
              />
            </div>
            <Button onClick={search}>Search</Button>
            <Button onClick={() => setHideTableAndPlate(!hideTableAndPlate)}>{toggleText}</Button>
            <Button onClick={nextDetection}>Next Detection</Button>
          </Row>
        </Container>
      </Can>

      <Row className="p-3">
        {frontWaleDetection && showByOrientation.front ? (
          <Col>
            <WaleDetectionResume
              detectionData={frontWaleDetection}
              prevFn={prevWaleDetectionFront}
              nextFn={nextWaleDetectionFront}
              hideALPR={hideTableAndPlate}
            />
          </Col>
        ) : null}
        {backWaleDetection && showByOrientation.back ? (
          <Col>
            <WaleDetectionResume
              detectionData={backWaleDetection}
              prevFn={prevWaleDetectionBack}
              nextFn={nextWaleDetectionBack}
              hideALPR={hideTableAndPlate}
            />
          </Col>
        ) : null}
      </Row>
    </>
  );
};

export default WaleEvents;
