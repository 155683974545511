import { NewPasswordRequest } from 'pages/Profile/ChangePassword';
import { UserRole } from 'pages/Users/userRoles';
import { API } from './api';

enum Path {
  PASSWORD_UPDATE = '/auth/password_update',
  PASSWORD_RESET = '/auth/reset',
  PASSWORD_FORGOT = '/auth/forgot',
  SIGN_IN = '/auth/login',
  VALIDATE_TOKEN = '/auth/me',
  LONG_TOKEN = '/auth/long_refresh',
  INVALIDATE_TOKENS = '/auth/invalidate_tokens',
  GENERATE_EBOX_TOKEN = '/auth/generate_ebox_token'
}

export const updatePassword = (params: { user: NewPasswordRequest }) =>
  API.post(Path.PASSWORD_UPDATE, params);

export const resetPassword = (password: string, token: string) =>
  API.post(Path.PASSWORD_RESET, { user: { password, token } });

export const forgotPassword = (email: string) =>
  API.post(Path.PASSWORD_FORGOT, { user: { email } });

export const logIn = (email: string, password: string) =>
  API.post(Path.SIGN_IN, { user: { email, password } });

export const validateUserToken = () => API.get(Path.VALIDATE_TOKEN);

export const generateLongToken = (role?: UserRole) =>
  API.post(Path.LONG_TOKEN, {
    params: { refreshToken: localStorage.getItem('jwtRefresh'), ...(role && { role }) }
  }).then((response) => response.data);

export const invalidateTokens = () =>
  API.post(Path.INVALIDATE_TOKENS, {
    params: { refreshToken: localStorage.getItem('jwtRefresh') }
  });

export const generateEboxToken = (eboxId: string) =>
  API.post(Path.GENERATE_EBOX_TOKEN, {
    params: { refreshToken: localStorage.getItem('jwtRefresh'), eboxId }
  }).then((response) => response.data);
