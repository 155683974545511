import { useDispatch } from 'react-redux';
import { sendAlert as sendAlertToStore } from 'store/actions/utils';

type AlertType = 'info' | 'warning' | 'success' | 'error';
export type SendAlertInput = {
  type: AlertType;
  message: string;
  timeout?: number;
};
export const useAlert = () => {
  const dispatch = useDispatch();
  const sendAlert = ({ type, message, timeout }: SendAlertInput) => {
    dispatch(
      sendAlertToStore({
        kind: type,
        message,
        ...(timeout && { timeout })
      })
    );
  };
  return { sendAlert };
};
