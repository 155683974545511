import React, { useState, useEffect } from 'react';
import { WaleDetection } from 'domain/entities/waleDetection.entity';
import { Row, Col, Button, Container, Table, Image } from 'react-bootstrap';
import InnerImageZoom from 'react-inner-image-zoom';
import getCaptureData from 'pages/LabelSearch/getCaptureData';
import defaultImage from 'assets/images/blissway_default.png';
import { getPlateStateStyle } from 'services/statePlateStyles';
import { getRoadDateTimeMilli } from 'services/timeUtils';
import { Can } from 'services/abilityContext';
import { Link } from 'react-router-dom';
import AlprTable from './AlprTable';

const getIndexOfBestDetection = (captures: any): number => {
  let res = Math.floor((captures.length - 1) / 2);
  let highestScore = 0;
  captures.forEach((capture: any, captureIndex: number) => {
    if (
      capture.alpr &&
      capture.alpr.length &&
      capture.alpr[0]?.data?.plateNumber &&
      capture.alpr[0]?.data?.plateNumber.length
    ) {
      if (capture.alpr[0].data.plateNumber[0].score > highestScore) {
        highestScore = capture.alpr[0].data.plateNumber[0].score;
        res = captureIndex;
      }
    }
  });
  return res;
};

const DetectionTable = ({ eventData }: { eventData: WaleDetection | null }) => (
  <Table borderless size="sm">
    <tbody>
      <tr>
        <td colSpan={2} className="wale-title no-blue-link">
          {eventData?.waleId ? (
            <Link to={`/wale/settings?id=${eventData.waleId}`}>{eventData.waleId}</Link>
          ) : (
            '<WALE ID>'
          )}
        </td>
      </tr>
      <tr>
        <td className="wale-table-label">Event ID</td>
        <td className="wale-table-data">{eventData?.eventId ?? 'No event data'}</td>
      </tr>
      <tr>
        <td className="wale-table-label">Triggered at</td>
        <td className="wale-table-data">
          {eventData?.triggeredAt ? getRoadDateTimeMilli(eventData?.triggeredAt) : 'No event data'}
        </td>
      </tr>
      <tr>
        <td className="wale-table-label">Lane Number</td>
        <td className="wale-table-data">{eventData?.laneNumber ?? 'No event data'}</td>
      </tr>
      <tr>
        <td className="wale-table-label">Source</td>
        <td className="wale-table-data">{eventData?.source ?? 'No source'}</td>
      </tr>
    </tbody>
  </Table>
);

const PrevNextButtons = ({
  captureIndex,
  numberOfCaptures,
  captureData,
  prevCapture,
  nextCapture
}: any) => (
  <>
    <Col>
      <Row className="justify-content-md-center">
        <Button onClick={prevCapture}>Prev</Button>
      </Row>
    </Col>
    <Col>
      <Row className="justify-content-md-center">
        Capture {numberOfCaptures ? captureIndex + 1 : 0} of {numberOfCaptures}
      </Row>
      <Row className="justify-content-md-center">
        {captureData.capturedAt
          ? getRoadDateTimeMilli(captureData.capturedAt)
          : '<Img captured at>'}
      </Row>
    </Col>
    <Col>
      <Row className="justify-content-md-center">
        <Button onClick={nextCapture}>Next</Button>
      </Row>
    </Col>
  </>
);

export const WaleDetectionResume = ({
  detectionData,
  prevFn,
  nextFn,
  hideALPR,
  onlyLabels = false
}: {
  detectionData: WaleDetection | null;
  prevFn: () => void;
  nextFn: () => void;
  hideALPR: boolean;
  // eslint-disable-next-line react/require-default-props
  onlyLabels?: boolean;
}) => {
  const [captureIndex, setCaptureIndex] = useState(0);
  const numberOfCaptures = detectionData?.captures?.length ?? 0;
  const captureData = getCaptureData(detectionData, captureIndex);

  useEffect(() => {
    if (detectionData?.captures?.length)
      setCaptureIndex(getIndexOfBestDetection(detectionData.captures));
  }, [detectionData]);

  const plateNumber = captureData.alprData.plateNumber[0].prediction;
  const plateState = captureData.alprData.plateState.prediction;

  const waleEventImgStyle =
    detectionData && detectionData.source === 'video-manual'
      ? 'event-img fade-in from-video'
      : 'event-img fade-in';

  return (
    <Container className="bliss-card p-3">
      <Row
        className="text-center justify-content-between"
        style={{ borderBottom: '1px solid', margin: '10px', marginTop: 0 }}
      >
        <Can I="read" a="all">
          <Col xs={2}>
            <Button onClick={prevFn}>Prev</Button>
          </Col>
        </Can>
        <Col>
          <DetectionTable eventData={detectionData} />
        </Col>
        <Can I="read" a="all">
          <Col xs={2}>
            <Button onClick={nextFn}>Next</Button>
          </Col>
        </Can>
      </Row>
      <Col className="justify-content-center c-flex text-center ">
        <div style={{ maxWidth: '800px', margin: 'auto' }}>
          <InnerImageZoom
            zoomPreload
            zoomScale={1.5}
            zoomType="hover"
            className={waleEventImgStyle}
            src={captureData.imageUrl ? captureData.imageUrl : defaultImage}
          />
        </div>
        {!hideALPR && (
          <Can I="read" a="all">
            <div className="plate">
              <Image className="plate-img fade-in inline" src={getPlateStateStyle(plateState)} />
              <p className={`plate-number ${plateState}`}>{plateNumber}</p>
            </div>
          </Can>
        )}
      </Col>
      <Row>
        <PrevNextButtons
          captureIndex={captureIndex}
          numberOfCaptures={numberOfCaptures}
          captureData={captureData}
          prevCapture={() =>
            setCaptureIndex(captureIndex !== 0 ? captureIndex - 1 : numberOfCaptures - 1)
          }
          nextCapture={() => setCaptureIndex((captureIndex + 1) % numberOfCaptures)}
        />
      </Row>
      {!hideALPR && (
        <Can I="read" a="all">
          <Row>
            <AlprTable
              alprData={captureData.alprData}
              humanReview={detectionData?.humanReview}
              vehicleEventId={detectionData?.eventId}
              captureIndex={captureIndex}
              onlyLabels={onlyLabels ?? false}
            />
          </Row>
        </Can>
      )}
    </Container>
  );
};
