import { genericErrorLog } from 'data/api/genericErrorMessage';
import { getWaleIdsByLocationAndLaneUseCase, WaleIdsByLocation } from 'domain/use-cases/wale/getWales.use-case';
import { useEffect, useState } from 'react';

export const useWaleIdsByLocationAndLane = (): WaleIdsByLocation => {
  const [waleByLocation, setWaleByLocation] = useState<WaleIdsByLocation>(new Map());

  useEffect(() => {
    getWaleIdsByLocationAndLaneUseCase()
      .then(setWaleByLocation)
      .catch(genericErrorLog);
  }, []);

  return waleByLocation;
};
