import clsx from 'clsx';
import React from 'react';
import { MaintenanceStatus } from 'domain/entities/wale.entity';
import { MAINTENANCE_STATUS_ICONS } from 'presentation/WaleMonitor/components/DisplayWaleMonitor';
import { Image } from 'react-bootstrap';
import _ from 'lodash';
import { getRoadProject, getWaleLocation, shortWaleId } from 'presentation/helpers/waleId';
import { OperationStatus } from './OperationStatusIndicator';

type WALEProps = {
  id: string;
  maintenanceStatus: MaintenanceStatus[];
  status: OperationStatus;
  statusMessages: string[];
};

const WALE = ({ id, maintenanceStatus, status, statusMessages }: WALEProps) => {
  const params = new URLSearchParams({
    locations: getWaleLocation(id),
    project: getRoadProject(id),
    waleType: 'ALL',
    maxWalesPerRow: '2'
  });

  return (
    <span
      className={clsx(
        'flex items-center mb-2 gap-2 pl-2 border-l-4 text-base pr-2',
        status === 'operational' && 'border-green-400',
        status === 'underperforming-metrics' && 'border-purple-400',
        status === 'degraded-metrics' && 'border-yellow-400',
        status === 'degraded-system' && 'border-red-400'
      )}
    >
      <span
        className="bg-gray-600 px-1 text-sm rounded-lg text-white min-w-5 text-center"
        title={`${statusMessages?.length} alerts`}
      >
        {statusMessages?.length}
      </span>
      <a
        className={clsx('text-gray-200 hover:text-white m-0 cursor-pointer')}
        title={statusMessages?.join('\n\n')}
        href={`/wale/monitor?${params.toString()}`}
        target="_blank"
        rel="noreferrer"
      >
        {shortWaleId(id)}
      </a>{' '}
      <div className="flex gap-1 ml-1">
        {maintenanceStatus.map((mStatus) => (
          <Image
            key={mStatus}
            src={MAINTENANCE_STATUS_ICONS.get(mStatus)}
            className={clsx('maintenance-icon-color size-4')}
            title={_.startCase(mStatus)}
          />
        ))}
      </div>
    </span>
  );
};

export default WALE;
