import React from 'react';
import memoize from 'memoize-one';
import { BasicActionButtons } from '../../components';
import { roleName } from './userRoles';

const getRoleName = (item: any): string => roleName(item.role);

const columns = memoize((clickHandler) => [
  {
    name: 'FIRST NAME',
    selector: 'firstName',
    sortable: true,
    grow: '2'
  },
  {
    name: 'LAST NAME',
    selector: 'lastName',
    sortable: true,
    grow: '2'
  },
  {
    name: 'EMAIL',
    selector: 'email',
    sortable: true,
    grow: '3'
  },
  {
    name: 'ROLE',
    selector: 'role',
    cell: (item: any) => getRoleName(item),
    sortable: true,
    grow: '2'
  },
  {
    name: 'DEMO',
    selector: 'demo',
    cell: (item: any) => (item.demo ? 'Yes' : 'No'),
    sortable: true,
    grow: '2'
  },
  {
    name: 'DEMO DATE',
    selector: 'demoDate',
    cell: (item: any) => (item.demoDate ? item.demoDate.toISOString().split('T')[0] : ''),
    sortable: true,
    grow: '2'
  },
  {
    name: 'DEMO HOUR',
    selector: 'demoHour',
    sortable: true,
    grow: '2'
  },
  {
    name: 'EMAIL',
    selector: 'email',
    sortable: true,
    grow: '2'
  },
  {
    cell: (item: any) => (
      <BasicActionButtons item={item} clickHandler={clickHandler} withShow={false} editClick />
    ),
    ignoreRowClick: true,
    allowOverflow: false,
    button: true,
    grow: '1',
    minWidth: '140px'
  }
]);

export default columns;
