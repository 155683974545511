import { genericErrorLog } from 'data/api/genericErrorMessage';
import { TrafficData } from 'domain/entities/trafficData.entity';
import { getTopMakeModels, getTopStates } from 'domain/entities/tripSummary';
import { getTrafficUseCase } from 'domain/use-cases/traffic/getTraffic.use-case';
import {
  getTripsSummaryUseCase,
  getLatestTripsSummaryUseCase
} from 'domain/use-cases/trip/getTripsSummary.use-case';
import moment from 'moment';
import { useEndDate } from 'presentation/hooks/useEndDate';
import useInterval from 'presentation/hooks/useInterval';
import { useCallback, useState } from 'react';

const TRAFFIC_UPDATE_INTERVAL = 300 * 1000;
const TRAFFIC_LAST_SECONDS = 600;
const TRIPS_UPDATE_INTERVAL = 3600 * 1000;
export const TOP_MAKE_MODELS_AMOUNT = 5;
export const TOP_STATES_AMOUNT = 10;

export const useTrafficUpdatedInformation = () => {
  const [traffic, setTraffic] = useState<TrafficData | null>(null);
  const endDate = useEndDate();
  useInterval(() => {
    getTrafficUseCase(moment(endDate).subtract(TRAFFIC_LAST_SECONDS, 'seconds').toDate(), endDate)
      .then(setTraffic)
      .catch(genericErrorLog);
  }, TRAFFIC_UPDATE_INTERVAL);

  return traffic;
};

const useTripsSummary = (days?: number) => {
  const [tripsSummary, setTripsSummary] = useState<{
    plateStates: { plateState: string; percentage: number }[];
    makeModels: { makeModel: string; percentage: number }[];
    startDate: Date | undefined;
    endDate: Date | undefined;
  } | null>(null);
  const endDate = useEndDate();

  const getTrips = useCallback(() => {
    const startDate = moment(endDate).subtract(days, 'days').toDate();
    const summaryCase =
      days === undefined
        ? async () => getLatestTripsSummaryUseCase(endDate)
        : async () => getTripsSummaryUseCase(startDate, endDate);
    summaryCase()
      .then((data) => {
        setTripsSummary({
          plateStates: getTopStates(data, TOP_STATES_AMOUNT),
          makeModels: getTopMakeModels(data, TOP_MAKE_MODELS_AMOUNT),
          startDate: data.startDate,
          endDate: data.endDate
        });
      })
      .catch(genericErrorLog);
  }, [days]);

  useInterval(() => {
    getTrips();
  }, TRIPS_UPDATE_INTERVAL);

  return tripsSummary;
};

export const useLatestTripSummary = () => useTripsSummary();

export { useTrafficTimeSeries } from './hooks/useTrafficChartData';
