import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { useAbility } from '@casl/react';
import { Navbar, Nav, NavDropdown, Col } from 'react-bootstrap';
import { useEndDate } from 'presentation/hooks/useEndDate';
import { useAlert } from 'presentation/hooks/useAlert';
import { UserRole } from 'pages/Users/userRoles';
import { useUser } from 'presentation/hooks/data/useUser';
import { useQueryParam } from 'presentation/hooks/useQueryParam';
import { useSetQueryParam } from 'presentation/hooks/useSetQueryParam';
import Select from 'components/Select';
import customStyles from 'components/Select/themes';
import { useDeleteQueryParam } from 'presentation/hooks/useDeleteQueryParam';
import { roadProjects } from 'presentation/helpers/waleId';
import { AbilityContext, Can } from '../../services/abilityContext';
import { updateAbility } from '../../services/ability';
import { requestSignOut, validateToken } from '../../store/actions/auth';
import { PrincipalNavbar } from './PrincipalNavbar';
import { roadTimeObject } from '../../services/timeUtils';
import { ChangeDemoDateModal } from './ChangeDemoDateModal';

const PATH_PROFILE = '/profile';
const PATH_USERS = '/users';
const PATH_WALE_EVENTS = '/wale/events';
const PATH_WALE_MONITOR = '/wale/monitor';
const PATH_WALE_SETTINGS = '/wale/settings';
const PATH_WALE_UPTIME = '/wale/uptime';
const PATH_WALE_LABELS = '/wale/labels';
const PATH_WALE_EBOX_LINK = '/ebox/linking';
const PATH_EVENT_LABELING = '/events/label_batch';

const NavbarLogged = ({ projectChanged }: { projectChanged: () => void }) => {
  const user = useUser();
  const dispatch = useDispatch();
  // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'Context<unknown>' is not assigna... Remove this comment to see the full error message
  const ability = useAbility(AbilityContext);
  const [currentTime, setCurrentTime] = useState(roadTimeObject());
  const [showDemoDateModal, setShowDemoDateModal] = useState(false);
  const [project, setProject] = useState(
    useQueryParam('project') ?? sessionStorage.getItem('project') ?? 'All'
  );
  const userEndTime = roadTimeObject(useEndDate());
  const isOlderData = Math.abs(userEndTime.diff(currentTime, 'minutes')) > 10;
  const { sendAlert } = useAlert();
  const { pathname } = window.location;
  const history = useHistory();
  const setQueryParam = useSetQueryParam();
  const deleteQueryParam = useDeleteQueryParam();
  const projectParam = useQueryParam('project');

  // First render loads the project in the session storage.
  useEffect(() => {
    sessionStorage.setItem('project', project);
    projectChanged();
  }, []);

  useEffect(() => {
    const projectStorage = sessionStorage.getItem('project');
    if (!projectParam && projectStorage && projectStorage !== 'All') {
      setQueryParam('project', projectStorage);
    }
  }, [history.location]);

  // If project is changed, page is reloaded.
  useEffect(() => {
    if (project !== 'All') setQueryParam('project', project);
    else deleteQueryParam('project');
    const value = sessionStorage.getItem('project');
    if (value !== null && value !== project) {
      sessionStorage.setItem('project', project);
      projectChanged();
    }
  }, [project]);

  useEffect(() => {
    if (user.role === UserRole.Agency)
      sendAlert({
        type: 'warning',
        message: "BLISSWAY's testing period is over, showing data from the last day of testing.",
        timeout: 30000
      });
  }, [user]);

  const updateAbilityRoles = () => {
    updateAbility(ability, user.role);
  };

  const handleOnMount = () => {
    if (user.email === '') {
      dispatch(validateToken());
    }
    document.body.className = '';
  };

  useEffect(handleOnMount, []);
  useEffect(() => {
    const roadTimeInterval = setInterval(() => {
      setCurrentTime(roadTimeObject());
    }, 1000);
    return () => clearInterval(roadTimeInterval);
  }, []);

  useEffect(updateAbilityRoles, [user]);

  const roadsideClassName =
    pathname.includes('roadside') || pathname.match(/^\/$/) ? 'nav-button active' : 'nav-button';
  const tollingTrafficClassName = pathname.includes('traffic_metrics')
    ? 'nav-button active'
    : 'nav-button';
  const tripsClassName = pathname.includes('trips') ? 'nav-button active' : 'nav-button';

  return (
    <PrincipalNavbar>
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Can I="read" a="all">
            <Nav.Link
              data-cy="link_roadside"
              as={Link}
              to="/roadside"
              className={roadsideClassName}
            >
              Overview
            </Nav.Link>
          </Can>
          <Can I="read" a="all">
            <Nav.Link
              data-cy="link_traffic_metrics"
              as={Link}
              to="/traffic_metrics"
              className={tollingTrafficClassName}
            >
              Traffic Metrics
            </Nav.Link>
          </Can>
          <Can I="read" a="all">
            <Nav.Link data-cy="link_trips" as={Link} to="/trips" className={tripsClassName}>
              Trip Building
            </Nav.Link>
          </Can>
        </Nav>
        <Can I="read" a="all">
          <Nav>
            <Col
              className="navbar-select"
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}
            >
              <Select
                styles={customStyles}
                options={roadProjects}
                defaultValue={project}
                value={project}
                onChange={(option: { value: string }) => setProject(option.value)}
              />
            </Col>
          </Nav>
        </Can>
        <Nav>
          <Nav.Item className={`feed ${isOlderData && 'old-feed'}`}>
            <div
              role="button"
              tabIndex={0}
              onClick={() => {
                if (user.role === UserRole.Admin) setShowDemoDateModal(true);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && user.role === UserRole.Admin) setShowDemoDateModal(true);
              }}
            >
              <span className="d-inline-block text-justify">
                {isOlderData ? 'Data from ' : 'LIVE DATA FEED '}
              </span>
              <span className="d-inline-block text-justify mx-1">
                {userEndTime.format(`${isOlderData ? 'MMMM Do' : ''} LTS`)}
              </span>
              <div className={isOlderData ? 'old-live-feed' : 'live-feed'} />
            </div>
            {showDemoDateModal && (
              <ChangeDemoDateModal
                showModal={showDemoDateModal}
                closeModal={() => setShowDemoDateModal(false)}
              />
            )}
          </Nav.Item>
          <NavDropdown
            alignRight
            id="basic-nav-dropdown"
            title=""
            data-cy="navbar_options_dropdown"
            style={{ marginTop: '0.2em' }}
          >
            <NavDropdown.Item as={Link} to={PATH_PROFILE} data-cy="link_user_profile">
              Profile
            </NavDropdown.Item>
            <Can I="manage" a="all">
              <NavDropdown.Item as={Link} to={PATH_USERS}>
                Users
              </NavDropdown.Item>
            </Can>
            <Can I="read" a="events">
              <NavDropdown.Item as={Link} to={PATH_WALE_EVENTS}>
                WALE Events
              </NavDropdown.Item>
            </Can>
            <Can I="read" a="all">
              <NavDropdown.Item as={Link} to={PATH_WALE_MONITOR}>
                WALE Monitor
              </NavDropdown.Item>
            </Can>
            <Can I="read" a="all">
              <NavDropdown.Item as={Link} to={PATH_WALE_SETTINGS}>
                WALE Settings
              </NavDropdown.Item>
            </Can>
            <Can I="manage" a="all">
              <NavDropdown.Item as={Link} to={PATH_WALE_EBOX_LINK}>
                WALE / Ebox Linking
              </NavDropdown.Item>
            </Can>
            <Can I="read" a="all">
              <NavDropdown.Item as={Link} to={PATH_WALE_UPTIME}>
                WALE Uptime
              </NavDropdown.Item>
            </Can>
            <Can I="manage" a="all">
              <NavDropdown.Item as={Link} to={PATH_EVENT_LABELING}>
                Label Batches
              </NavDropdown.Item>
            </Can>
            <Can I="manage" a="all">
              <NavDropdown.Item as={Link} to={PATH_WALE_LABELS}>
                Labels
              </NavDropdown.Item>
            </Can>
            <NavDropdown.Item onClick={() => dispatch(requestSignOut())}>Logout</NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </PrincipalNavbar>
  );
};

export default NavbarLogged;
