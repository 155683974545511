import { Trip } from 'domain/entities/trip.entity';
import { ZoneEvent } from 'domain/entities/zoneEvent.entity';
import { useQueryParam } from 'presentation/hooks/useQueryParam';
import React from 'react';
import { Button } from 'react-bootstrap';
import { getStateFullname } from 'services/statePlateStyles';
import { getRoadDateAndTimeWithoutYear } from 'services/timeUtils';

const TripInfoBlock = ({
  handleBack,
  trip,
  zone
}: {
  handleBack: () => void;
  trip: Trip;
  zone?: ZoneEvent | null;
}) => {
  const { plateNumber, plateState, startedAt, endedAt, fare, isDuplicate, tripId } = trip;
  const backButtonText = zone ? 'Back to Zones' : 'Back to Trips';

  // URL params
  const tripIdFromUrl = useQueryParam('id');

  return (
    <div className="bliss-card my-3 px-3">
      <div className="d-flex justify-content-between align-items-center">
        {!tripIdFromUrl || zone ? <Button onClick={handleBack}>{backButtonText}</Button> : <p />}
        <div>
          <b className="mr-2">Plate Number: {plateNumber}</b>
          <div>Plate State: {getStateFullname(plateState)}</div>
        </div>
        <div>
          <p>Start: {getRoadDateAndTimeWithoutYear(startedAt)}</p>
          <p>End: {getRoadDateAndTimeWithoutYear(endedAt)}</p>
        </div>
        <div>
          <p>Fare: {`$ ${fare}`}</p>
          <p>{isDuplicate ? 'Timeout: Yes' : ''}</p>
        </div>
        <div>
          <p>Trip ID: {tripId}</p>
          {zone ? <p>Zone ID: {zone.zoneId}</p> : <p />}
        </div>
      </div>
    </div>
  );
};

TripInfoBlock.defaultProps = {
  zone: null
};

export default TripInfoBlock;
