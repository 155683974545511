import React from 'react';
import { Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import MainLogo from '../MainLogo';

const PrincipalNavbar = ({ children }: any) => (
  <Navbar bg="dark" expand="lg" fixed="top" variant="dark">
    <Navbar.Brand as={Link} to="/">
      <MainLogo />
    </Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    {children}
  </Navbar>
);

const GradientNavbar = ({ children }: any) => (
  <Navbar bg="dark" expand="lg" fixed="top" variant="dark">
    {children}
  </Navbar>
);

export { PrincipalNavbar, GradientNavbar };
