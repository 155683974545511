import { getZonesList, ZoneEvent } from 'domain/entities/zoneEvent.entity';
import React from 'react';
import { Col } from 'react-bootstrap';

const ZonesPopup = ({ zones }: { zones: ZoneEvent[] }) => (
  <div className="d-flex justify-content-around align-items-center">
    <Col className="justify-content-center">{getZonesList(zones)}</Col>
  </div>
);

export default ZonesPopup;

// const [openPopUp, setOpenPopUp] = useState(false);
// const popUpStyle = openPopUp ? 'pop-up' : 'pop-up d-none';
// <Col>
//     <Button className="justify-content-start" style={{ display: 'grid' }} onClick={() => setOpenPopUp(!openPopUp)}>
//       List
//     </Button>
//     <div className={popUpStyle} style={{ zIndex: 1 }}>
//       <Row className="d-flex justify-content-between">
//         {zones.map((zone) => (
//           <p>{zone.zoneId} </p>
//         ))}
//       </Row>
//     </div>
//   </Col>
