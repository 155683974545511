import React from 'react';
import ReactTooltip from 'react-tooltip';
import { Button } from 'react-bootstrap';

type OwnProps = {
  direction?: string;
  text: string;
  variant?: string;
  delayShow?: number;
  delayHide?: number;
  toolbarVariant?: string;
};

// @ts-ignore ts-migrate(2456) FIXME: Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof ButtonTooltip.defaultProps;

// @ts-ignore ts-migrate(7022) FIXME: 'ButtonTooltip' implicitly has type 'any' because ... Remove this comment to see the full error message
const ButtonTooltip = ({
  toolbarVariant,
  direction,
  text,
  variant,
  children,
  delayShow,
  delayHide,
  ...props
}: Props) => (
  <div className={toolbarVariant}>
    <Button {...props} variant={variant} type="button" data-tip data-for={`registerTip-${text}`}>
      {children}
    </Button>

    <ReactTooltip
      id={`registerTip-${text}`}
      // @ts-ignore ts-migrate(2769) FIXME: No overload matches this call.
      delay={{ show: delayShow, hide: delayHide }}
      place={direction}
    >
      {text}
    </ReactTooltip>
  </div>
);

ButtonTooltip.defaultProps = {
  direction: 'top',
  variant: 'info',
  delayShow: 250,
  delayHide: 0,
  toolbarVariant: ''
};

export default ButtonTooltip;
