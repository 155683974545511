const customStyles = {
  menu: (provided: any) => ({
    ...provided,
    backgroundColor: '#363646',
    zIndex: 1000
  }),
  // @ts-ignore ts-migrate(7006) FIXME: Parameter 'provided' implicitly has an 'any' type.
  option: (provided, state) => ({
    ...provided,
    borderBottom: '1px solid ##282735',
    color: state.isSelected ? '#1ba3dc' : '#9696a0',
    padding: 6,
    backgroundColor: '#363646'
  }),
  input: (provided: any) => ({
    ...provided,
    color: 'white'
  }),
  control: (provided: any) => ({
    ...provided,
    backgroundColor: '#363646',
    color: '#1ba3dc',
    border: '1px solid #737386'
  }),
  // @ts-ignore ts-migrate(7006) FIXME: Parameter 'provided' implicitly has an 'any' type.
  singleValue: (provided, state) => ({
    ...provided,
    opacity: state.isDisabled ? 0.5 : 1,
    transition: 'opacity 300ms',
    backgroundColor: '#363646',
    color: '#9696a0'
  })
};
export default customStyles;
