import React from 'react';
import { Link } from 'react-router-dom';
import { Eye, Edit, Trash, Save, XCircle } from 'react-feather';
import ButtonTooltip from '../Tooltips';

type OwnProps = {
  item: {
    _id: string;
  };
  path?: string;
  clickHandler: (...args: any[]) => any;
  destroyText?: string;
  editClick?: boolean;
  showClick?: boolean;
  withShow?: boolean;
  withEdit?: boolean;
  withDestroy?: boolean;
  saveClick?: boolean;
  discardClick?: boolean;
};

// @ts-ignore ts-migrate(2456) FIXME: Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof BasicActionButtons.defaultProps;

// @ts-ignore ts-migrate(7022) FIXME: 'BasicActionButtons' implicitly has type 'any' bec... Remove this comment to see the full error message
const BasicActionButtons = ({
  item,
  path,
  clickHandler,
  showClick,
  editClick,
  destroyText,
  withShow,
  withEdit,
  withDestroy,
  saveClick,
  discardClick
}: Props) => (
  <>
    {showClick ? (
      <ButtonTooltip
        onClick={() => clickHandler(item, 'show')}
        variant="info"
        size="sm"
        text="Show"
      >
        <Eye width="15px" height="15px" />
      </ButtonTooltip>
    ) : (
      withShow && (
        <ButtonTooltip to={`/${path}/${item.id}`} variant="info" size="sm" text="Show" as={Link}>
          <Eye width="15px" height="15px" />
        </ButtonTooltip>
      )
    )}
    {}
    {editClick ? (
      <ButtonTooltip
        onClick={() => clickHandler(item, 'edit')}
        variant="success"
        size="sm"
        className="mx-1"
        text="Edit"
      >
        <Edit width="15px" height="15px" />
      </ButtonTooltip>
    ) : (
      withEdit && (
        <ButtonTooltip
          to={`/${path}/${item.id}/edit`}
          variant="info"
          size="sm"
          className="mx-1"
          text="Edit"
          as={Link}
        >
          <Edit width="15px" height="15px" />
        </ButtonTooltip>
      )
    )}
    {withDestroy && (
      <ButtonTooltip
        onClick={() => clickHandler(item, 'destroy')}
        variant="danger"
        size="sm"
        className="mx-1"
        text={destroyText}
      >
        <Trash width="15px" height="15px" />
      </ButtonTooltip>
    )}
    {saveClick && (
      <ButtonTooltip
        onClick={() => clickHandler(item, 'save')}
        variant="success"
        size="sm"
        className="mx-1"
        text="Save"
      >
        <Save width="15px" height="15px" />
      </ButtonTooltip>
    )}
    {discardClick && (
      <ButtonTooltip
        onClick={() => clickHandler(item, 'discard')}
        variant="danger"
        size="sm"
        className="mx-1"
        text="Stop Editing"
      >
        <XCircle width="15px" height="15px" />
      </ButtonTooltip>
    )}
  </>
);

BasicActionButtons.defaultProps = {
  destroyText: 'Erase',
  path: '',
  editClick: false,
  showClick: false,
  withShow: true,
  withEdit: true,
  withDestroy: true,
  saveClick: false,
  discardClick: false
};

export default BasicActionButtons;
