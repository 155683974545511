import React from 'react';
import CorridorAlerts from './CorridorAlerts';
import LastUpdatedButton from './LastUpdatedButton';
import { useCorridorOverviews } from './Overview.view-model';

const Overview = () => {
  const corridorOverviewsQuery = useCorridorOverviews();

  return (
    <div className="m-8">
      <div className="flex items-center justify-between mb-4">
        <h1 className="text-3xl font-bold text-white">Roadside Overview</h1>
        <LastUpdatedButton corridorOverviewsQuery={corridorOverviewsQuery} />
      </div>

      <div className="grid grid-cols-1 xl:grid-cols-2 gap-x-3">
        {!corridorOverviewsQuery.isLoading && corridorOverviewsQuery.data ? (
          <CorridorAlerts corridors={corridorOverviewsQuery.data} />
        ) : (
          <p>loading...</p>
        )}
      </div>
    </div>
  );
};

export default Overview;
