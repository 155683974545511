import { defineAbility, AbilityBuilder, PureAbility } from '@casl/ability';
// @ts-ignore ts-migrate(7016) FIXME: Could not find a declaration file for module 'jwt-... Remove this comment to see the full error message
import jwt from 'jwt-decode';
import { UserRole } from '../pages/Users/userRoles';

const adminAbility = (can: any) => {
  can('manage', 'all');
};

const basicAbility = (can: any) => {
  can('read', 'all');
};

const otherAbility = (can: any) => {
  can('read', 'events');
};

const setUpAbility = (can: any, cannot: any) => {
  const payload = localStorage.getItem('jwt');
  if (payload) {
    const { role } = jwt(payload);
    if (role === UserRole.Admin) {
      adminAbility(can);
    } else if (role === UserRole.Basic) {
      basicAbility(can);
    } else {
      otherAbility(can);
    }
  }
};

export default defineAbility((can, cannot) => setUpAbility(can, cannot));

export const updateAbility = (ability: any, role = null) => {
  const { can, cannot, rules } = new AbilityBuilder(PureAbility);
  if (role) {
    if (role === UserRole.Admin) {
      adminAbility(can);
    } else if (role === UserRole.Basic) {
      basicAbility(can);
    } else {
      otherAbility(can);
    }
  } else {
    setUpAbility(can, cannot);
  }
  ability.update(rules);
};
