// @ts-ignore ts-migrate(7016) FIXME: Could not find a declaration file for module 'file... Remove this comment to see the full error message
import FileSaver from 'file-saver';
import get from 'lodash/get';

const responseToBlob = (response: any): Blob => {
  const format = response.headers['content-type'].split('/')[1];
  let blob;
  const { data } = response;
  if (format === 'xlsx') {
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i !== byteCharacters.length; i += 1) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    // @ts-ignore ts-migrate(2322) FIXME: Type 'Blob' is not assignable to type 'string'.
    blob = new Blob([byteArray], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });
  } else if (format === 'csv') {
    const readUTF8 = data.toString('utf8');
    // @ts-ignore ts-migrate(2322) FIXME: Type 'Blob' is not assignable to type 'string'.
    blob = new Blob([readUTF8], { type: 'application/vnd.ms-excel' });
  } else {
    // @ts-ignore ts-migrate(2322) FIXME: Type 'Blob' is not assignable to type 'string'.
    blob = new Blob([data], { type: response.headers['content-type'] });
  }
  return blob;
};

export const downloadFile = (response: any, fileName: any) => {
  const blob = responseToBlob(response) as any;
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = `${fileName}-Id`;
  link.click();
  window.URL.revokeObjectURL(url);
};

export const downloadCsv = (csv: any, fileName = 'data.csv') => {
  if (csv) {
    const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' }) as any;
    FileSaver.saveAs(csvData, fileName);
  }
};

export const toNormalCase = (header: any) => {
  const result = header.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};

/**
 * Flatten a nested object into a one-level object
 * @param {Object} obj The object to flatten
 * @param {String} prefix The key of the previous level, e.g. {a: {b: c}} prefix = 'a.b'
 * @param {Array} res The acumulator of the reduce function.
 *
 * @returns {Object}
 *
 * Example input/output
 *
 * input = {  key1: 'value', a:  { b:  { c: 'leaf'} } }
 * output = {'key1': 'value', 'a.b.c': 'leaf'}
 *  */

export const flatten = (obj: any, prefix = '', res = {}) =>
  Object.entries(obj).reduce((r, [key, val]) => {
    const k = `${prefix}${key}`;
    if (key === 'previousEboxIds') {
      return r;
    }
    if (typeof val === 'object') {
      flatten(val, `${k}.`, r);
    } else {
      // @ts-ignore ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      res[k] = val;
    }
    return r;
  }, res);

/* eslint-disable no-param-reassign */
/* eslint-disable array-callback-return */
/* eslint-disable no-return-assign */

/**
 * Unflattens a flattened object
 * flatten^-1() = unflatten()
 * @param {Object} data The object to be unflattened
 *
 * @returns {Object}
 *
 * input = {'key1': 'value', 'a.b.c': 'leaf'}
 * output = {  key1: 'value', a:  { b:  { c: 'leaf'} } }
 */
export const unflatten = (data: any) => {
  const result = {};

  Object.keys(data).forEach((i) => {
    const keys = i.split('.');
    keys.reduce(
      // @ts-ignore ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      (r, e, j) => r[e] || (r[e] = keys.length - 1 === j ? data[i] : {}),
      result
    );
  });

  return result;
};

/**
 *
 * @param {Array} wales array of wales documents, 1  wale = 1 row
 * @returns {Array} array of transposed wales, 1 wale = 1 column
 *
 * Example.
 *
 * input: [ {waleId: "CO-I70E-232_7-F1",  eboxId: "dc:a6:32:ff:d3:a2"},
 *          {waleId: "CO-I70E-232_6-F1", eboxId: "dc:a6:32:ff:d3:3f"}]
 *
 * return: [{ name: 'waleId', 'CO-I70E-232_7-F1': 'CO-I70E-232_7-F1',       'CO-I70E-232_6-F1: 'CO-I70E-232_6-F1' },
 *           {name: "eboxId", "CO-I70E-232_7-F1": "dc:a6:32:ff:d3:a2", "CO-I70E-232_6-F1": "dc:a6:32:ff:d3:3f" }]
 */
export const transpose = (wales: any, allowedParams: string[], attribute = 'waleId') => {
  if (wales.length === 0) return [];
  const data: any[] = [];

  allowedParams.forEach((param) => {
    const row = { name: param };
    for (let j = 0; j < wales.length; j += 1) {
      // @ts-ignore ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      row[wales[j][attribute]] = get(wales[j], param);
    }
    data.push(row);
  });

  return data;
};

export const copyToClipboard = (
  copiedContent: string,
  onSuccess: () => void,
  onFailure: () => void
) => {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(copiedContent).then(onSuccess);
  } else if (document.execCommand) {
    // Deprecated usage, but it's a decent failsafe in case we don't have SSL for some reason.
    const el = document.createElement('textarea');
    el.value = copiedContent;
    el.style.top = '0';
    el.style.left = '0';
    el.style.position = 'fixed';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    onSuccess();
  } else {
    onFailure();
  }
};

export const setTooltipMessage = (message: string, setterFunction: (message: string) => void) => {
  setterFunction(message);
  setTimeout(() => setterFunction(''), 3000);
};
