import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Image } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { withFormik, Field, Form } from 'formik';
import { AlertTriangle } from 'react-feather';
// @ts-ignore ts-migrate(7016) FIXME: Could not find a declaration file for module 'yup'... Remove this comment to see the full error message
import * as Yup from 'yup';
import { FormikInput } from '../../components';
import { requestSignIn } from '../../store/actions/auth';
import Logo from '../../assets/images/LogoText.png';
import './style.scss';

const Login = (props: any) => {
  const { onHide, isValid, errors, touched } = props;
  const [browser, setBrowser] = useState(false);

  const checkBrowser = () => {
    const isCompatible =
      navigator.userAgent.indexOf('hrome') !== -1 || navigator.userAgent.indexOf('irefox') !== -1;
    setBrowser(!isCompatible);
    document.body.className = 'login-bg';
  };
  useEffect(checkBrowser, []);

  const checkAlert = () => {
    setBrowser(false);
  };

  return (
    <div>
      <Row className="text-blue login">
        <Col md={3} className="text-center">
          <Image src={Logo} className="img-fluid" />
        </Col>
        <Col md={9}>
          <Row className="text-left">
            <Col md={{ span: 4, offset: 4 }}>
              <Form>
                <Field name="email">
                  {({ field }: any) => (
                    <FormikInput
                      {...field}
                      inputType="email"
                      label="Email"
                      error={errors[field.name]}
                      touched={touched[field.name]}
                    />
                  )}
                </Field>
                <Field name="password">
                  {({ field }: any) => (
                    <FormikInput
                      {...field}
                      inputType="password"
                      label="Password"
                      error={errors[field.name]}
                      touched={touched[field.name]}
                    />
                  )}
                </Field>
                <Row>
                  <Col md={{ span: 6, offset: 6 }}>
                    <Link to="/recover_password">
                      <p className="text-medium text-gray">Forgot password?</p>
                    </Link>
                    <Button
                      // @ts-ignore ts-migrate(2322) FIXME: Type '"md"' is not assignable to type '"sm" | "lg"... Remove this comment to see the full error message
                      size="md"
                      className="mt-4 btn-primary"
                      block
                      type="submit"
                      disabled={!isValid}
                      onClick={onHide}
                    >
                      Log In
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
      {browser && (
        <Row className="browser-alert-container">
          <Col md={{ span: 4, offset: 8 }}>
            <div className="browser-alert">
              <div className="icon">
                <AlertTriangle size={40} fill="#DED024" />
              </div>
              <div className="content">
                <p className="title">We noticed you are using an unsupported browser.</p>
                <p>
                  The user experience has been designed exclusively for the Chrome and Firefox
                  browsers in a desktop environment.
                </p>
                <Button
                  variant="link"
                  className="text-right text-blue text-uppercase"
                  onClick={() => checkAlert()}
                >
                  Proceed Anyway
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  const { ongoingRequest, signedIn } = state.auth;
  return { ongoingRequest, signedIn };
};

const initialValues = { email: '', password: '' };

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Mandatory field'),
  password: Yup.string().required('Mandatory field')
});

const handleSubmit = (values: any, { props }: any) => {
  const { dispatch } = props;
  dispatch(requestSignIn({ user: { email: values.email, password: values.password } }));
};

export default withRouter(
  connect(mapStateToProps)(
    withFormik({
      mapPropsToValues: () => initialValues,
      validationSchema,
      handleSubmit
    })(Login)
  )
);
