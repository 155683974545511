import { formatMakeModel } from 'presentation/helpers/format-car-make-model';
import React from 'react';
import { Row, Col } from 'react-bootstrap';

const VehicleItem = ({
  vehicle,
  index
}: {
  vehicle: { makeModel: string; percentage: number };
  index: number;
}) => (
  <Row>
    <Col md={8} xs={8}>
      <p className={index === 10 ? 'car-name shift-left-small' : 'car-name'}>
        {vehicle.makeModel === 'Others' ? (
          <span className="car-number" style={{ visibility: 'hidden' }}>
            {index}.
          </span>
        ) : (
          <span className="car-number">{index}.</span>
        )}
        {formatMakeModel(vehicle.makeModel)}
      </p>
    </Col>
    <Col md={4} xs={4} className="text-right">
      <p className="car-percentage">{vehicle.percentage.toFixed(1)}%</p>
    </Col>
  </Row>
);

export const TopVehicles = ({
  vehiclesList,
  topMakeModelsAmount
}: {
  vehiclesList: { makeModel: string; percentage: number }[];
  topMakeModelsAmount: number;
}) => (
  <Row className="cars">
    <Col md={12}>
      {vehiclesList.length <= 1
        ? Array.from(Array(topMakeModelsAmount + 1).keys()).map((i) => {
            const makeModel = i === topMakeModelsAmount ? 'Others' : '-';
            return <VehicleItem vehicle={{ makeModel, percentage: 0 }} index={i + 1} key={i} />;
          })
        : vehiclesList.map((vehicle, index) => (
            <VehicleItem vehicle={vehicle} index={index + 1} key={vehicle.makeModel} />
          ))}
    </Col>
  </Row>
);
