/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Modal, Button, ButtonToolbar } from 'react-bootstrap';

type OwnDefaultModalProps = {
  title: string;
  body: string;
  variantBtnClose?: string;
  titleBtnClose: string;
  variantBtnSave?: string;
  titleBtnSave: string;
  handleConfirm: (...args: any[]) => any;
  handleClose: (...args: any[]) => any;
};

// @ts-ignore ts-migrate(2456) FIXME: Type alias 'DefaultModalProps' circularly referenc... Remove this comment to see the full error message
type DefaultModalProps = OwnDefaultModalProps & typeof DefaultModal.defaultProps;

// @ts-ignore ts-migrate(7022) FIXME: 'DefaultModal' implicitly has type 'any' because i... Remove this comment to see the full error message
const DefaultModal = ({
  title,
  body,
  variantBtnClose,
  titleBtnClose,
  variantBtnSave,
  titleBtnSave,
  handleClose,
  handleConfirm,
  show,
  ...props
}: DefaultModalProps) => (
  <Modal {...props} show={show} onHide={handleClose}>
    <Modal.Header closeButton>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>{body}</Modal.Body>
    <Modal.Footer>
      <Button className="btn" variant={variantBtnClose} onClick={handleClose}>
        {titleBtnClose}
      </Button>
      <Button className="btn" variant={variantBtnSave} onClick={handleConfirm}>
        {titleBtnSave}
      </Button>
    </Modal.Footer>
  </Modal>
);

DefaultModal.defaultProps = {
  variantBtnClose: 'primary',
  variantBtnSave: 'success'
};

type OwnCenteredModalProps = {
  header?: string;
  title: string;
  body: string;
  titlebtn: string;
  size?: string;
};

// @ts-ignore ts-migrate(2456) FIXME: Type alias 'CenteredModalProps' circularly referen... Remove this comment to see the full error message
type CenteredModalProps = OwnCenteredModalProps & typeof CenteredModal.defaultProps;

// @ts-ignore ts-migrate(7022) FIXME: 'CenteredModal' implicitly has type 'any' because ... Remove this comment to see the full error message
const CenteredModal = ({
  header,
  title,
  body,
  titlebtn,
  size,
  onClickHide,
  ...props
}: CenteredModalProps) => (
  <Modal {...props} size={size} aria-labelledby="contained-modal-title-vcenter" centered>
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">{header}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <h4>{title}</h4>
      <p>{body}</p>
    </Modal.Body>
    <Modal.Footer>
      <Button onClick={onClickHide}>{titlebtn}</Button>
    </Modal.Footer>
  </Modal>
);

CenteredModal.defaultProps = {
  header: null,
  size: 'lg'
};

const ModalCenter = (props: any) => {
  const [modalShow, setModalShow] = useState(false);
  const modalClose = () => setModalShow(false);
  const { header, title, body, titlebtn, size, titlebtnmodal } = props;

  return (
    <ButtonToolbar>
      <Button variant="primary" onClick={() => setModalShow(true)}>
        {titlebtnmodal}
      </Button>

      <CenteredModal
        show={modalShow}
        onHide={modalClose}
        header={header}
        title={title}
        body={body}
        titlebtn={titlebtn}
        size={size}
        titlebtnmodal={titlebtnmodal}
      />
    </ButtonToolbar>
  );
};

export { DefaultModal, ModalCenter };
