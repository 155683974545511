export interface Ebox {
  eboxId: string;
  name: string;
  extendedName?: string;
  version: string;
  wale: string;
  remoteItId: string;
}

export const eboxCompareFn = (first: Ebox, second: Ebox) =>
  eboxNamesCompareFn(first.name, second.name);

export const eboxIdsCompareFn = (firstId: string, secondId: string) => {
  if (firstId < secondId) return -1;
  if (firstId > secondId) return 1;
  return 0;
};

export const eboxNamesCompareFn = (firstName: string, secondName: string) => {
  try {
    const firstNameLetter = firstName.slice(0, 1);
    const secondNameLetter = secondName.slice(0, 1);
    if (firstNameLetter < secondNameLetter) return -1;
    if (firstNameLetter > secondNameLetter) return 1;

    const firstNameNumber = Number(firstName.slice(1));
    const secondNameNumber = Number(secondName.slice(1));

    if (firstNameNumber < secondNameNumber) return -1;
    if (firstNameNumber > secondNameNumber) return 1;
    return 0;
  } catch {
    if (firstName < secondName) return -1;
    if (firstName > secondName) return 1;
    return 0;
  }
};
