import { takeEvery, put, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { signInUseCase } from 'domain/use-cases/auth/signIn.use-case';
import { validateTokenUseCase } from 'domain/use-cases/auth/validateToken.use-case';
// @ts-ignore ts-migrate(7016) FIXME: Could not find a declaration file for module 'jwt-... Remove this comment to see the full error message
import jwt from 'jwt-decode';
import { genericErrorMessage } from 'data/api/genericErrorMessage';
import { authTypes } from '../actions/auth';
import setInitialMe from '../../services/userUtils';

function* runDefaultSaga(
  callRequest: { request: any; params?: any },
  successCallback: any,
  failureCallback: any
): any {
  try {
    const response = yield call(callRequest.request, callRequest.params);
    yield successCallback(response.data, response);
  } catch (err: any) {
    genericErrorMessage(true)(err);
    failureCallback();
  }
}

function* updateJWT(authorization: any) {
  localStorage.setItem('jwt', authorization);
  yield put({
    type: authTypes.SET_INFO_USER,
    result: jwt(authorization)
  });
}

// SIGN IN
function* signInSuccessCallback(result: any, response: any) {
  if (result.errors) {
    throw new Error(result.errors.join('\n'));
  } else {
    const user = setInitialMe(result);
    const authorization = result.accessToken;
    yield updateJWT(authorization);
    const { refreshToken } = result;
    yield localStorage.setItem('jwtRefresh', refreshToken);
    yield put({ type: authTypes.SIGN_IN_SUCCESS, result, response, user });
    yield put(push('/roadside'));
  }
}
function* signInFailureCallback() {
  yield put({
    type: authTypes.SIGN_IN_FAILURE
  });
}
export function* signIn(action: any): any {
  yield runDefaultSaga(
    { request: signInUseCase, params: action.params },
    signInSuccessCallback,
    signInFailureCallback
  );
}

export function* signOut() {
  yield localStorage.removeItem('jwt');
  yield localStorage.removeItem('jwtRefresh');
  yield localStorage.removeItem('project');
  yield sessionStorage.removeItem('project');
  yield put({ type: authTypes.SIGN_OUT_SUCCESS });
  yield put(push('/'));
}

// VALIDATE TOKENS
function* validateTokensSuccessCallback(result: any, response: any) {
  if (result.user) {
    const user = setInitialMe(result);
    yield put({
      type: authTypes.VALIDATE_TOKEN_SUCCESS,
      result,
      response,
      user
    });
  }
}

function* validateTokensFailureCallback() {
  yield put({ type: authTypes.CLEAR_AUTH_INFO });
  yield put(push('/login'));
}

export function* validateToken() {
  yield runDefaultSaga(
    { request: validateTokenUseCase },
    validateTokensSuccessCallback,
    validateTokensFailureCallback
  );
}

// DEFINE authSagas
export default function* authSagas() {
  yield takeEvery(authTypes.SIGN_IN_REQUEST, signIn);
  yield takeEvery(authTypes.SIGN_OUT_REQUEST, signOut);
  yield takeEvery(authTypes.VALIDATE_TOKEN_REQUEST, validateToken);
}
