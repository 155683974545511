import React from 'react';
import { LineChart, Tooltip, Line, XAxis, YAxis } from 'recharts';
import { Col } from 'react-bootstrap';
import { useWindowWidth } from 'presentation/hooks/useWindowWidth';
import { useEndDate } from 'presentation/hooks/useEndDate';
import { roadTimeObject } from 'services/timeUtils';
import { sendAlert } from 'store/actions/utils';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';

const toNormalCase = (header: string) => {
  const result = header.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};

const formatData = (title: string) => (value: number, name: string) => {
  switch (title) {
    case 'Speed':
      return [`${value.toLocaleString('en')}/mph`, `${toNormalCase(name)} `];
    case 'Traffic Volume':
      return [`${value.toLocaleString('en')}/vlh`, `${toNormalCase(name)} `];
    case 'Toll Rate':
      return [`${value.toLocaleString('en')}/USD`, `${toNormalCase(name)} `];
    default:
      return [value.toLocaleString('en'), toNormalCase(name)];
  }
};

const RATIO = 1350 / 1415;
const TOTAL_ELEMENTS = 24;

export interface GraphData {
  displayHour: string;
  regularLane?: number;
  fastLane: number;
}

const getTitleMessage = (data: GraphData[], dispatch: Dispatch, endDate: Date) => {
  const roadObject = roadTimeObject(endDate);
  if (data.length === 0)
    if (roadObject.hour() === 7 && roadObject.minute() < 30) {
      dispatch(
        sendAlert({
          kind: 'warning',
          message: 'Express Lane just opened, not enough chart data to display.',
          timeout: 15000
        })
      );
    } else return '(Loading...)';
  else if (roadTimeObject(endDate).hour() < 7) {
    dispatch(
      sendAlert({
        kind: 'warning',
        message:
          'Currently outside EL operating hours (7:00 AM to 7:00 PM). Displaying chart data from yesterday.',
        timeout: 15000
      })
    );
  }
  return '';
};
const Graph = ({ title, data }: { title: string; data: GraphData[] }) => {
  const windowWidth = useWindowWidth();
  const width = Math.floor(
    windowWidth * RATIO * ((data.length || TOTAL_ELEMENTS) / TOTAL_ELEMENTS)
  );
  
  const dispatch = useDispatch();
  const endDate = useEndDate();
  const titleAdditionalMessage = getTitleMessage(data, dispatch, endDate);

  return (
    <Col md={12} style={{ overflow: 'hidden' }}>
      <p className="title" style={{ marginBottom: '0em' }}>
        {title} {titleAdditionalMessage}
      </p>
      <LineChart width={width} height={160} data={data}>
        <XAxis dataKey="displayHour" interval="preserveStartEnd" style={{ visibility: 'hidden' }} />
        {title === 'Speed' && <YAxis domain={['dataMin', 'dataMax']} hide />}
        <Tooltip
          contentStyle={{ backgroundColor: '#1c1c2b', border: 'none' }}
          formatter={formatData(title)}
          separator=""
        />
        <Line
          type="monotone"
          dataKey="regularLane"
          strokeDasharray="3  3"
          stroke="#e21e80"
          strokeWidth={2.5}
          dot={{
            stroke: '#fff',
            strokeWidth: 1,
            fill: '#e21e80',
            strokeDasharray: '0',
            r: 4.5
          }}
          isAnimationActive={false}
        />
        <Line
          type="monotone"
          dataKey="fastLane"
          dot={{ stroke: '#fff', strokeWidth: 1, fill: '#1ba3dc', r: 4.5 }}
          stroke="#1ba3dc"
          strokeWidth={2.5}
          isAnimationActive={false}
        />
      </LineChart>
    </Col>
  );
};

const CustomXAxis = ({ data }: { data: { displayHour: string }[] }) => {
  const windowWidth = useWindowWidth();
  const width = Math.floor(
    windowWidth * RATIO * ((data.length || TOTAL_ELEMENTS) / TOTAL_ELEMENTS)
  );
  return (
    <Col md={12} className="text-blue-gray mb-0 pb-0" style={{ overflow: 'hidden' }}>
      <LineChart width={width} height={40} data={data}>
        <XAxis
          dataKey="displayHour"
          interval="preserveStartEnd"
          axisLine={false}
          tickLine={false}
          tickFormatter={(value) => (value && value.includes(' ') ? value.split(' ')[0] : '')}
        />
      </LineChart>
    </Col>
  );
};
export { Graph, CustomXAxis };
