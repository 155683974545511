import { SingleEnhancedUptime, UptimeSummary } from 'domain/use-cases/processUptime.use-case';
import { formatDate } from 'services/timeUtils';
import { Color, GenericMetricDisplay } from './GenericMetricDisplay';

const TIME_THRESHOLD = 0.95;
const STATUS_THRESHOLD = 0.8;

export enum UptimeMetrics {
  DetectionsCount = '# Detections',
  LidarMinutes = 'Lidar minutes',
  LidarNormal = 'Lidar normal',
  VideoMinutes = 'Video minutes',
  StatusesCount = '# Statuses'
}

const formatInterval = (start: Date, end: Date) =>
  `${formatDate(start, 'YYYYMMDD_HHmm')}_${formatDate(end, 'HHmm')}`;

const getThresholdColor = (available: number, threshold: number, inSchedule: boolean) => {
  let color: Color = Color.Default;

  if (inSchedule) {
    color = available >= threshold ? Color.Up : Color.Down;
  } else if (available > 0) color = Color.Out;

  return color;
};

const DetectionsCountDisplay = (slotUptime: SingleEnhancedUptime) => {
  const { start, inSchedule, eventsCount, uptimeSummary } = slotUptime;
  let color: Color = Color.Default;
  if (uptimeSummary === UptimeSummary.Up) color = Color.Up;
  if (uptimeSummary === UptimeSummary.Down) color = Color.Down;
  if (uptimeSummary === UptimeSummary.Out) color = Color.Out;

  return GenericMetricDisplay(inSchedule || eventsCount ? eventsCount : '', color, start);
};

const LidarMinutesDisplay = (slotUptime: SingleEnhancedUptime, waleId: string) => {
  const { start, end, inSchedule, lidarMins, lidarDownload, scheduledTime } = slotUptime;
  const color = getThresholdColor(lidarMins, scheduledTime * TIME_THRESHOLD, inSchedule);
  const text = inSchedule || lidarMins ? lidarMins.toFixed(0) : '';
  const zipName = `lidar_logs_${waleId}_${formatInterval(start, end)}`;

  return GenericMetricDisplay(text, color, start, zipName, lidarDownload);
};

const LidarSignalNormalDisplay = (slotUptime: SingleEnhancedUptime) => {
  const { start, lidarSignalNormal } = slotUptime;
  let color = Color.Default;
  if (lidarSignalNormal !== null) color = lidarSignalNormal ? Color.Up : Color.Down;

  return GenericMetricDisplay('', color, start);
};

const VideoMinutesDisplay = (slotUptime: SingleEnhancedUptime, waleId: string) => {
  const { start, end, inSchedule, videoMins, videoDownload, scheduledTime } = slotUptime;
  const color = getThresholdColor(videoMins, scheduledTime * TIME_THRESHOLD, inSchedule);
  const text = inSchedule || videoMins ? videoMins.toFixed(0) : '';
  const zipName = `videos_${waleId}_${formatInterval(start, end)}`;

  return GenericMetricDisplay(text, color, start, zipName, videoDownload);
};

const StatusCountDisplay = (slotUptime: SingleEnhancedUptime) => {
  const { start, inSchedule, statusCount, scheduledTime, statusSensingPeriodSecs } = slotUptime;
  const expectedStatuses = ((scheduledTime * 60) / statusSensingPeriodSecs) * STATUS_THRESHOLD;
  const color = getThresholdColor(statusCount, expectedStatuses, inSchedule);

  return GenericMetricDisplay(inSchedule || statusCount ? statusCount : '', color, start);
};

/**
 * Object containing all functions for displaying an uptime metric.
 * These functions receive a single enhanced uptime, and display the corresponding metric.
 * Lidar and video minutes metrics also require reception of the waleId in order to download
 * a zip including the waleId on the filename.
 */
export const DisplayUptimeMetrics = {
  [UptimeMetrics.DetectionsCount]: DetectionsCountDisplay,
  [UptimeMetrics.LidarMinutes]: (waleId: string) => (slot: SingleEnhancedUptime) =>
    LidarMinutesDisplay(slot, waleId),
  [UptimeMetrics.LidarNormal]: LidarSignalNormalDisplay,
  [UptimeMetrics.VideoMinutes]: (waleId: string) => (slot: SingleEnhancedUptime) =>
    VideoMinutesDisplay(slot, waleId),
  [UptimeMetrics.StatusesCount]: StatusCountDisplay
};
