import { useCallback, useState } from 'react';
import { getWaleStatusUseCase } from 'domain/use-cases/waleStatus/getWaleStatus.use-case';
import { getWaleDetectionUseCase } from 'domain/use-cases/waleDetection/getWaleDetection.use-case';
import { useEndDate } from 'presentation/hooks/useEndDate';
import { useInterval } from 'presentation/hooks/useInterval';

import { WALE_DETECTION_EMPTY } from 'domain/entities/samples/waleDetection.sample';
import { WALE_STATUS_SAMPLE } from 'domain/entities/samples/waleStatus.sample';
import { genericErrorLog } from 'data/api/genericErrorMessage';
import { getVehicleEventMetricsUseCase } from 'domain/use-cases/waleDetection/getVehicleEventMetrics.use-case';
import { VehicleEventMetrics } from 'domain/entities/waleDetection.entity';
import { VEHICLE_EVENT_METRICS_SAMPLE } from 'domain/entities/samples/vehicleEventMetrics.sample';
import { sleep } from 'presentation/helpers/sleep';

const UPDATE_WALE_STATUS_INTERVAL = 45 * 1000;
const UPDATE_CAPTURE_INTERVAL = 3 * 1000;
const UPDATE_VEHICLE_EVENT_METRICS_INTERVAL = 60 * 1000;

export const useVehicleEventMetrics = (waleId: string, lane?: number) => {
  const [vehicleEventMetrics, setVehicleEventMetrics] = useState<VehicleEventMetrics>(
    VEHICLE_EVENT_METRICS_SAMPLE
  );
  const endDate = useEndDate();

  const fetchAndSetVehicleEventMetrics = () => {
    getVehicleEventMetricsUseCase(waleId, endDate, lane)
      .then((s) => setVehicleEventMetrics(s))
      .catch(genericErrorLog);
  };

  useInterval(fetchAndSetVehicleEventMetrics, UPDATE_VEHICLE_EVENT_METRICS_INTERVAL);

  return vehicleEventMetrics;
};

export const useWaleStatus = (waleId?: string) => {
  const [waleStatus, setWaleStatus] = useState(WALE_STATUS_SAMPLE);
  const endDate = useEndDate();

  const fetchAndSetWaleStatus = () => {
    if (!waleId) return;
    getWaleStatusUseCase(endDate, waleId)
      .then((s) => setWaleStatus(s ?? WALE_STATUS_SAMPLE))
      .catch(genericErrorLog);
  };

  useInterval(fetchAndSetWaleStatus, UPDATE_WALE_STATUS_INTERVAL);

  return waleStatus;
};

export const useWaleDetection = (waleId: string, offset: number, pause: boolean, lane?: number) => {
  const [waleDetection, setWaleDetection] = useState(WALE_DETECTION_EMPTY);
  const endDate = useEndDate();
  const alpr = false;

  const fetchAndSetWaleDetection = useCallback(async () => {
    await sleep(offset);
    if (pause) return;
    getWaleDetectionUseCase(waleId, endDate, lane, alpr)
      .then((data) => setWaleDetection(data ?? WALE_DETECTION_EMPTY))
      .catch(genericErrorLog);
  }, [waleDetection, pause]);

  useInterval(fetchAndSetWaleDetection, UPDATE_CAPTURE_INTERVAL);

  return waleDetection;
};
